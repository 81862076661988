import {ProductL} from "@business/types";

export const SubscriptionsProductsList: Array<ProductL> = [
  {
    name: 'Basic - Free',
    description: 'Basic -- no cost subscription with limited functionality.',
    catalogNumber: 'basic',
    level: 'basic',
    price: 0.00,
    currency: '$',
    units: 'no expiration',
    allowedInvitees: 0,
    allowedRepositories: 0,
    orgType: [],  // forces it not to show as option for either academic or commercial
  },
  {
    name: 'Pilot - Invitation Only',
    description: 'Pilot -- no cost subscription with full functionality for a limited duration.',
    catalogNumber: 'pilot',
    level: 'pilot',
    price: 0.00,
    currency: '$',
    units: 'variable length',
    orgType: [],  // forces it not to show as option for either academic or commercial
  },
  {
    name: 'Pilot (Academic)- Invitation Only',
    description: 'Pilot -- no cost subscription with full functionality for a limited duration.',
    catalogNumber: 'pilot_A',
    level: 'pilot',
    price: 0.00,
    currency: '$',
    units: 'variable length',
    orgType: [],  // forces it not to show as option for either academic or commercial
  },
  {
    name: 'Starter - month',
    description: 'Single month subscription to a limited set of 3RnD core capabilities. (Single user license)',
    catalogNumber: 'starter-month',
    level: 'starter',
    price: 79.00,
    currency: '$',
    units: 'month',
  },
  {
    name: 'Starter - annual',
    description: 'Full year subscription to a limited set of 3RnD core capabilities. (Single user license)',
    catalogNumber: 'starter-annual',
    level: 'starter',
    price: 899.00,
    currency: '$',
    units: 'month'
  },
  {
    name: 'Academic+ - month',
    description: 'Single month subscription for academic users to all 3RnD functionality. (5 user seats)',
    catalogNumber: 'academic-month',
    level: 'academic',
    price: 229.00,
    currency: '$',
    units: 'month',
    orgType: ['academic']
  },
  {
    name: 'Academic+ - annual',
    description: 'Full year subscription for academic users to all 3RnD functionality. (5 user seats)',
    catalogNumber: 'academic-annual',
    level: 'academic',
    price: 2400.00,
    currency: '$',
    units: 'month',
    orgType: ['academic']
  },
  {
    name: 'Commercial+ - month',
    description: 'Single month subscription for commercial users to all 3RnD functionality. (Single user license)',
    catalogNumber: 'commercial-month',
    level: 'commercial',
    price: 339.00,
    currency: '$',
    units: 'month',
    orgType: ['commercial']
  },
  {
    name: 'Commercial+ - annual',
    description: 'Full month subscription for commercial users to all 3RnD functionality. (Single user license)',
    catalogNumber: 'commercial-annual',
    level: 'commercial',
    price: 3600.00,
    currency: '$',
    units: 'month',
    orgType: ['commercial']
  }
]

export function getSubscriptionDetails(userSubscription: string) {
  return SubscriptionsProductsList.find((p) => p.catalogNumber === userSubscription );
}