import React, { useState } from 'react';
import styled from 'styled-components/macro';
import BasicTable from '@as_core/tables/BasicTable';
import { getObjectValueByKey } from '@utils/misc';
import { FieldsTypeT } from '@subApps/admin/components/ObjectDetails';
import { getTableRows } from '@subApps/admin/components/shared';
import SearchField from '@as_core/components/inputs/SearchField';
import {RepositoryDetailsT} from "@subApps/account/repositoryManagement/useRepos";

const columns: FieldsTypeT[] = [
  {
    value: 'view',
    label: '',
    type: 'action',
    id: { key: 'uuid', label: 'Repo' },
    width: 40,
  },
  { value: 'uuid', label: 'Repo Id', width: 290 },
  { value: 'name', label: 'Repo Name', width: 275 },
  { value: 'ownerAuthEmail', label: 'Owner Email', width: 275 },
  { value: 'numberInvitedUsers', label: 'Invited Users', width: 75 },
];

interface RepoListI {
  repos: RepositoryDetailsT[];
  handleSelect: (v: string) => void;
}

const debug = false;
const RepoList = (props: RepoListI) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  if (debug) console.log('RepoList | repos: ', props.repos);

  // HANDLERS
  const handleClick = (action: string, repoId: string) => {
    if (debug) console.log('RepoList | handleClick action: ', action, ' repoId:', repoId);
    props.handleSelect(repoId);
  };

  // search function
  function search(event) {
    const term = event.target.value;
    if (debug) console.log('RepoList | search term:', term);
    if (term.length > 0) {
      return props.repos.filter(
        (repo) =>
          getObjectValueByKey(repo, 'uuid')
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          getObjectValueByKey(repo, 'name')
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          getObjectValueByKey(repo, 'ownerAuthEmail')
            .toLowerCase()
            .includes(term.toLowerCase())
      );
    } else {
      return props.repos;
    }
  }

  const filteredRepos = searchTerm !== '' ? search(searchTerm) : props.repos;
  if (debug) console.log(searchTerm, filteredRepos);

  return (
    <RepoListContainer>
      {props.repos ? (
        <>
          <SearchField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={search}
          />
          <ScrollContainer>
            <BasicTable
              key={'repo-table'}
              fields={columns}
              rows={getTableRows(filteredRepos, columns, handleClick)}
            />
          </ScrollContainer>
        </>
      ) : null}
    </RepoListContainer>
  );
};
export default RepoList;

const RepoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 975px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
`;
