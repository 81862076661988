import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
      size?: number;
    }

const NaturalProductsIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <polyline stroke={colorHighlight} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
          points="351.9,62.8 372,7.5 400.6,17.9 341.1,181.3"/>
        <line stroke={colorHighlight} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x1="308.8" y1="181.3" x2="339.9" y2="95.7"/>
        <path stroke={colorHighlight} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M131.6,7.5L50.7,131.4l4.6,0.5c24.7,2.8,49.3-6.1,66.3-24.1l3.2-3.4l4.6,0.5c24.7,2.8,49.3-6.1,66.3-24.1
    l3.2-3.4l4.6,0.5c24.7,2.8,49.3-6.1,66.3-24.1l3.2-3.4L131.6,7.5z"/>
        <polyline stroke={colorHighlight} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
                  points="199.1,77.5 131.6,7.5 124.9,104.4 			"/>
        <line stroke={colorHighlight} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
                x1="165.9" y1="101" x2="195" y2="181"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M270.7,181.5h141.4c3.4,0,6.7,1.8,8.5,4.7c7.5,12,13.7,24.8,18.4,38.3"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M73,224.5c4.7-13.5,10.9-26.4,18.4-38.3c1.8-2.9,5-4.7,8.5-4.7h136"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M457.2,360.5c-28.5,83.8-107.8,144-201.2,144c-117.4,0-212.5-95.1-212.5-212.5c0-21.8,3.3-42.9,9.4-62.7
		c1.5-5,6.6-8,11.7-6.9c16.4,3.7,19.4,16.9,41,16.9c25.1,0,25.1-17.7,50.1-17.7c25.1,0,25.1,17.7,50.1,17.7s25.1-17.7,50.1-17.7
		s25.1,17.7,50.1,17.7c25.1,0,25.1-17.7,50.1-17.7c25.1,0,25.1,17.7,50.1,17.7c21.6,0,24.6-13.2,41-16.9c5.1-1.1,10.2,1.9,11.7,6.9
		c6.1,19.8,9.4,40.9,9.4,62.7c0,11.7-0.9,23.2-2.8,34.3"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M101.5,274.9c0.7-11.7,2-23.1,4-34.2"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M105.1,340.9c-1.7-10-2.9-20.4-3.6-30.9"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M395.8,384c-25,71.3-78.2,120.5-139.8,120.5"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M217,398.2c-3.8-31.3-6-67.5-6-106.2"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M287.8,442.3c-8.1,38.5-19.4,62.2-31.8,62.2"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M408.5,239.5c7,19,7,55,4,79.5"/>
        <rect stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x="116.1" y="392.3" width="0" height="0"/>
        <rect stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x="139.1" y="411.5" width="0" height="0"/>
        <rect stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x="143.7" y="382.7" width="0" height="0"/>
        <rect stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x="338.5" y="281.2" width="0" height="0"/>
        <rect stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x="364.5" y="296.2" width="0" height="0"/>
        <rect stroke={color} strokeWidth={15} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              x="364" y="267" width="0" height="0"/>
      </svg>
    </>
  )
}

export default NaturalProductsIcon;