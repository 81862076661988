import { PlotData } from '../plot.types';

export const getDataRows = (data: PlotData) => {
  const keys = new Set();

  data.forEach((datum) => {
    const names = Object.getOwnPropertyNames(datum);
    names.forEach((name) => keys.add(name));
  });

  const header = [...keys] as Array<string>;
  const rows = [];
  data.forEach((datum) => {
    const row = header.map((header) => datum[header]);
    rows.push(row);
  });
  return [header, ...rows];
};

export const getDataCsv = (data: PlotData) => {
  const rows = getDataRows(data);
  const containsQuote = (s) => ('' + s).includes('"');
  return rows
    .map((row) =>
      row
        .map(String)
        .map((s) => (containsQuote(s) ? s.replaceAll('"', '""') : s))
        .map((s) => (containsQuote(s) ? `"${s}"` : s))
        .join(',')
    )
    .join('\r\n');
};

export const getDownloadLink = (data: PlotData) => {
  const rows = getDataCsv(data);
  const blob = new Blob([rows], { type: 'text/csv' });
  const uri = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', uri);
  link.setAttribute('download', 'data.csv');
  return link;
};
