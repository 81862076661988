import React from 'react';
import styled from 'styled-components/macro';
import PanelChildren from '@components/layout/PanelChildren';
import { FlexBanner, FlexBannerGroup} from "@components/layout/FlexStyles";
import { TextRegularBold } from "@components/elements/TextStyles";
import {Close} from "@mui/icons-material";

interface PagePanelT {
  title?: string;
  width?: string;
  height?: string;
  enableClose?: boolean;
  onClose?: ()=>void;
  children?: React.ReactNode;
}

const PagePanel= (props:PagePanelT) => {
  const { title='', width="100%", height="max-content", enableClose=false, onClose} = props;

  return (
    <PanelContainer id="idPanelContainer" width={width} height={height}>
      { (title || enableClose) && (
      <FlexBanner>
        <FlexBannerGroup><TextRegularBold color={'primary'}>{title}</TextRegularBold></FlexBannerGroup>
        <FlexBannerGroup>{enableClose ? <ClosedIcon onClick={onClose}/> : null}</FlexBannerGroup>
      </FlexBanner>
      ) }
      <PanelChildren>{props.children}</PanelChildren>
    </PanelContainer>
  );
};

export default PagePanel;

const PanelContainer = styled.div<{width?: string; height?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 30px);
  height: calc(${(p) => p.height } - 10px);
  margin: 10px 10px 5px 5px;
  padding: 5px;
  background: ${(p) => p.theme.palette.backgroundSecondary };
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary };
  border-radius: 15px;
`;
PanelContainer.defaultProps = {
  width: '100%',
}

const ClosedIcon = styled(Close)`
  cursor: pointer;
`

