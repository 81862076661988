import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const OrdersAddIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = style.name === "dark" ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M19.4,5.2h-2V3c0-0.8-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5v2.2h-2.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h2.5v2.3
c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V8.3h2c0.8,0,1.5-0.7,1.5-1.5C20.9,5.9,20.2,5.2,19.4,5.2z"/>
      <circle fill={colorHighlight} cx="11.5" cy="21" r="1.9"/>
      <circle fill={colorHighlight} cx="19.4" cy="21" r="1.9"/>
      <path fill={colorHighlight} d="M21.7,16.4H11.2c-0.3,0-0.6-0.2-0.7-0.5L8.1,9.2c-0.1-0.4,0.1-0.8,0.5-1c0.4-0.1,0.8,0.1,1,0.5l2.2,6.2H21
l0.8-6c0.1-0.4,0.4-0.7,0.8-0.6c0.4,0.1,0.7,0.4,0.6,0.8l-0.9,6.6C22.4,16.1,22.1,16.4,21.7,16.4z"/>
      <path fill={color} d="M22,17.2H10.1L6.2,6.8C6.1,6.6,6,6.4,5.8,6.3c0,0,0,0,0,0L1.3,4.1C1,3.9,0.5,4,0.3,4.4c-0.2,0.4,0,0.8,0.3,1l4.2,2.2l4,10.7
c0.1,0.3,0.4,0.5,0.7,0.5c0,0,0,0,0,0c0,0,0,0,0,0H22c0.4,0,0.8-0.3,0.8-0.8S22.4,17.2,22,17.2z"/>
    </svg>
  )
}

export default OrdersAddIcon;