import React from 'react';

import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { VolcanoBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface VolcanoModalEditorProps {
  plot?: PlotConfig<VolcanoBase>;
  onSave?: (config: PlotConfig<VolcanoBase>) => void;
}

export const VolcanoModalEditor = ({
  plot,
  onSave,
}: VolcanoModalEditorProps) => {
  const updateFoldChange = (columnName: string) => {
    const newPlotConfig: PlotConfig<VolcanoBase> = {
      ...plot,
      config: { ...plot.config, foldChangeColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updatePValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<VolcanoBase> = {
      ...plot,
      config: { ...plot.config, pValueColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-key'
        label={'Fold Change Variable'}
        value={plot.config.foldChangeColumn}
        options={plot.columns}
        onChange={updateFoldChange}
      />
      <br />
      <VariableSelect
        id='variable-value'
        label={'P-Value Variable'}
        value={plot.config.pValueColumn}
        options={plot.columns}
        onChange={updatePValue}
      />
    </Stack>
  );
};
