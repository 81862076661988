import {
  nanColor,
  chiColorRange,
  uniScoresColorRange,
  inhibitionColorRange,
  zebrafishColorRange,
  abbvieColorRange
}
  from "../config/colors";
import {createFieldCoronaAngles} from "@components/universe/utils/corona";
import _ from "lodash";
import {isNumeric} from "mathjs";
import {scaleLinear} from "d3";
import {EdgeT, SettingsT, GraphSettingsT, VertexT} from "@components/universe/types";
import {StyleT} from "@theme/AppStyles";

export const chiScoresColorScale = scaleLinear<string>()
    .domain([0, 1])
    .range(chiColorRange);

export const uniScoresColorScale = scaleLinear<string>()
    .domain([0, 1])
    .range(uniScoresColorRange);

export const inhibitionScale = scaleLinear<string>()
    .domain([0, 100])
    .range(inhibitionColorRange);

export const zebrafishScale = scaleLinear<string>()
    .domain([1.0, 0.0])
    .range(zebrafishColorRange);

export const abbvieScale = scaleLinear<string>()
    .domain([0, 100])
    .range(abbvieColorRange);


// take the defined fields from the settings and place it onto right form
// for the corona rings.
export function getRingFieldsFromSettings(settings: SettingsT) {
  const rawFields = [];
  if (settings?.primary) rawFields.push(settings?.primary);
  for (let i=0; i<settings?.secondary?.length; i++) {
    rawFields.push(settings.secondary[i]);
  }
  return createFieldCoronaAngles([{status: 'primary'}, {status: 'secondary'}],
      settings?.primary, settings?.secondary, settings?.data_fields);
}

export function getGraphSettings(settings: SettingsT, height: number, width: number):GraphSettingsT {
  const newSettings: GraphSettingsT = {
    ...settings,
    coronaFields: getRingFieldsFromSettings(settings),
    delaunay: undefined,
    nodeColorBy: settings.primary,
    edgeType: 'biol',
    forceWeight: 1.2,
    selectedDatasetId: '',
    hoveredVertex: undefined,
    hoveredEdges: [],
    selectedVertex: undefined,
    selectedNeighborVertices: [],
    selectedEdges: [],
    mouseSelectionType: 'none',
    dimensions: { height: height, width: width}
  }
  if (!Object.hasOwn(newSettings,'radiusFactor')) {
    if (newSettings?.mode === 'cell_health') {
      newSettings.radiusFactor = 4;
    }
    newSettings.radiusFactor = 1.5;
  }
  return newSettings;
}

// apply the color scale for the nodes (zoomed-out)
function applyColorScale(value:number, valueType:string, isPrimary: boolean) {
  if (isNaN(value) || !isNumeric(value)) return nanColor;
  if (valueType === '%') return inhibitionScale(value);
  if (valueType === '%_inverse') return inhibitionScale(100 - value);
  if (valueType === 'zebrafish') return zebrafishScale((2.0-Math.log10(value))/4.0);
  if (valueType === 'abbvie' || valueType === "abbvie_test") return abbvieScale(value);
  if (valueType === 'scores') {
    if (isPrimary) return chiScoresColorScale(value);
    return uniScoresColorScale(value);
  }
  return nanColor;
}

// set the AesSettings that is used for the layout -- simulation
export function getAesValues(
  vertices: VertexT[],
  edges: {[key: string]: EdgeT[]},
  images: { [key: string]: string},
  response_type: string,
  primary: string,
  edgeWeightFactor: number,
  style: StyleT) {
  if (!vertices)  return {};
  // Vertex
  const aesVertexValues = {};
  for (const node of vertices) {
    // preset all the colors
    const colors = {}
    for (const target of Object.keys(node?.data)) {
      colors[target] = applyColorScale(node.data[target], response_type, (target===primary));
    }
    const nodeImage = _.get(images[node.id], style.name, '');
    aesVertexValues[node?.id] = {
      show: true,
      radius: 5,
      fill: colors,
      image: nodeImage
    };
  }
  // Edges -- preset the values for all the network graphs
  const aesEdgesValues = {};
  for (const edgeType of Object.keys(edges)) {
    const aesValues = [];
    for (let i=0; i<edges[edgeType].length; i++) {
      aesValues.push({...edges[edgeType][i], forceWeight: edgeWeightFactor * edges[edgeType][i].weight});
    }
    aesEdgesValues[edgeType] = aesValues;
  }

  return {
    vertex: aesVertexValues,
    edges: aesEdgesValues
  };
}