
export function viewToColumns(view, allFields) {
  return view
    .filter((fieldId) => {
      return allFields[fieldId]?.cfields === true
    })
    .map((fieldId) => ({
      key: fieldId,
      title: allFields[fieldId].symbol,
      dataKey: allFields[fieldId].data_key,
      description: allFields[fieldId].long_name,
      format: allFields[fieldId].value_renderer,
      resizeable: allFields[fieldId].table.resizable,
      width: allFields[fieldId].table.width,
      minWidth: allFields[fieldId].table.minWidth,
      align: allFields[fieldId].table.align,
      sortable: allFields[fieldId].table.sortable,
      type: allFields[fieldId].value_type,
    }));
}
