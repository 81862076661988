import { useState } from 'react';
import {StripeProductT} from "@business/products/useStripeProducts";
import {getCartPriceType} from "@business/orders/components/utils";

// Define the type of the object for clarity. Adjust it based on your needs.
export type CartItemT = {
  product: StripeProductT,
  quantity: number,
  priceType: string
}
export type CartObjectType = {
  [catalogNumber: string]: CartItemT
};

// The hook's return type
export type UseCartObjectReturnType = {
  data: CartObjectType;
  length: () => number;
  add: (product: StripeProductT, quantity: number, priceType: string) => void;
  delete: (catalogNumber: string) => void;
  reset: () => void;
};

const useShoppingCart = (initialData: CartObjectType = {}): UseCartObjectReturnType => {
  const [data, setData] = useState<CartObjectType>(initialData);

  const add = (product: StripeProductT, quantity: number, priceType: string) => {
    const catNo = product.metadata.catalogNumber;
    if (Object.hasOwn(data, catNo)) {
      // product already in cart -- update data but also check in case of discounted price code
      const newQuantity = data[catNo].quantity + quantity;
      const basePriceType = priceType.split('_')[0];
      const newPriceType = getCartPriceType({product: product, quantity: newQuantity, priceType: basePriceType});
      // console.log('useShoppingCart.add | original', product.metadata.catalogNumber, quantity, priceType);
      // console.log('useShoppingCart.add | updated', product.metadata.catalogNumber, newQuantity, newPriceType);

      setData(prev => ({
        ...prev,
        [catNo]: {product: product, quantity: newQuantity, priceType: newPriceType}
      }));
    } else {
      setData(prevData => ({
        ...prevData,
        [catNo]: {product: product, quantity: quantity, priceType: priceType}
      }));
    }

  };

  const length = () => {
    return Object.keys(data).length;
  }

  const deleteItem = (catalogNumber: string) => {
    setData(prev => (
      Object.fromEntries(Object.entries(prev)
        .filter(([key]) => key !== catalogNumber)
      )));
  };

  const reset = () => {
    setData({});
  };

  return { data, add, length, delete: deleteItem, reset };
};

export default useShoppingCart;
