import React from 'react';
import styled from 'styled-components/macro';
import { roundOf } from '../../../utils/misc';

type Props = {
  score?: number | null;
  className?: string;
}

const ScoreTile = (props: Props) => {
  const { score } = props;
  return (
    <Container className={props?.className}>
      <Title>Chemical Similarity</Title>
      <Score>{score===null ? 0.85 : roundOf(score, 2).toFixed(2)}</Score>
    </Container>
  );
};

export default ScoreTile;

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: ${(p) => p.theme.borderRadius};
`;
const Title = styled.div`
  text-align: center;
  font-size: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textPrimary};
`;
const Score = styled.div`
  font-size: ${(p) => p.theme.sizes.xlarge};
  color: ${(p) => p.theme.palette.titleSecondary};
  padding-bottom: ${(p) => p.theme.sizes.xxsmall}; ;
`;
