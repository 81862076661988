import React, { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import './InputDate.css'; // Assuming this is where your styles are defined

interface InputDateProps {
  onDateSelect: (date: Dayjs | null) => void;
  selectedDate: string | null; // Assuming selectedDate is a string or null
}

const InputDate: React.FC<InputDateProps> = ({ onDateSelect, selectedDate }) => {
  const handleDateChange = useCallback(
    (newDate: Dayjs | null) => {
      onDateSelect(newDate);
    },
    [onDateSelect]
  );

  const formattedDate: Dayjs | null = selectedDate ? dayjs(selectedDate) : null;

  return (
    <div className="containerDate">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={formattedDate}
          onChange={handleDateChange}

        />
      </LocalizationProvider>
    </div>
  );
};

export default InputDate;
