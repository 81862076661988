import React from 'react';
import styled from 'styled-components/macro';

import { line, curveBasis } from 'd3';
import {labelColors} from "../config/universe";
import {CoronaSegmentT} from "./CoronaSegment";

const LABEL_DISTANCE = 0.5;
const SEGMENT_DOT_SIZE = 0.5;

interface PropsI  {
  field: number,
  segment: CoronaSegmentT,
  secondaryColor: string,
  secondaryField: number,
  barScale: (v: number) => number,
  isNeighbor: boolean
}
const FieldDetail = (props:PropsI) => {
  const {
    field,
    segment,
    secondaryColor = null,
    secondaryField = null,
    barScale = null,
    isNeighbor = false,
  } = props;
  const radius = (segment.outerRadius + segment.innerRadius) / 2;
  const alpha = (segment.endAngle + segment.startAngle) / 2 - Math.PI / 2;
  const originX = Math.cos(alpha) * radius;
  const originY = Math.sin(alpha) * radius;
  const x1 = originX / (isNeighbor ? 4 : 4);
  const y1 = originY / (isNeighbor ? 1.5 : 2);
  const x2 = 1.2 * x1;
  const y2 = y1;
  const x3 = x2 + (x2 < -0.01 ? -3 : 3);
  const path = line().curve(curveBasis)([
    [0, 0],
    [x1, y1],
    [x2, y2],
    [x3, y1],
  ]);

  const getDisplayValue = (v:number | string): string => {
    if (v === null || v === undefined) return '';
    if (typeof(v) === 'string') {
      if (v === 'NT' || v === 'NA') return v;
    } else if (typeof(v) === 'number') {
      if (v > 100) return v.toFixed(0);
      if (v > 10) return v.toFixed(1);
      return v.toFixed(2);
    }
    return v.toString();
  }

  const barWidth = (x3 - x2) / 3;

  return (
    <g className='field-detail' transform={`translate(${originX} ${originY})`}>
      {isNeighbor && (
        <g
          className='field-comparison-bars'
          transform={`translate(${
            x2 + (x2 < -0.01 ? -LABEL_DISTANCE : LABEL_DISTANCE)
          } ${y2})`}
        >
          <g
            transform={`translate(${barWidth < 0 ? 2 * barWidth : 0} ${
              -barScale(field) || 0
            })`}
          >
            <RectValueNeighbor
              x='0'
              y='0'
              width={Math.abs(barWidth)}
              height={barScale(field) || 0}
              rx='0.1'
              ry='0.1'
              color={labelColors['selected']}
            />
            <BarLabel dx='-0.3' dy='-0.2'>
              { getDisplayValue(field) }
            </BarLabel>
          </g>
          <g
            transform={`translate(${barWidth} ${
              -barScale(secondaryField) || 0
            })`}
          >
            <RectValueSecondary
              x='0'
              y='0'
              width={Math.abs(barWidth)}
              height={barScale(secondaryField) || 0}
              rx='0.1'
              ry='0.1'
              color={secondaryColor}
            />
            <BarLabel dx='0.3' dy='-0.2'>
              { getDisplayValue(secondaryField) }
            </BarLabel>
          </g>
        </g>
      )}
      <Path d={path} strokeWidth={1 / (isNeighbor ? 8 : 5)} />
      <Circle cx='0' cy='0' r={SEGMENT_DOT_SIZE} />
      <g
        className='top-label'
        transform={`translate(${
          x2 + (x2 < -0.01 ? -LABEL_DISTANCE : LABEL_DISTANCE)
        } ${y2 - LABEL_DISTANCE})`}
      >
        {!isNeighbor && (
          <g>
            <Text textAnchor={x2 < -0.01 ? 'end' : 'start'} foreground={false}>
              { getDisplayValue(field) }
            </Text>
            <Text textAnchor={x2 < -0.01 ? 'end' : 'start'} foreground={true}>
              { getDisplayValue(field) }
            </Text>
          </g>
        )}
      </g>
      <g
        className='bottom-label'
        transform={`translate(${
          x2 + (x2 < -0.01 ? -LABEL_DISTANCE : LABEL_DISTANCE)
        } ${y2 + LABEL_DISTANCE * (isNeighbor ? 2 : 3)})`}
      >
        <Text
          textAnchor={x2 < -0.01 ? 'end' : 'start'}
          foreground={false}
          hasSecondaryFields={isNeighbor}
        >
          {segment?.fieldSymbol || segment?.fieldName}
        </Text>
        <Text
          textAnchor={x2 < -0.01 ? 'end' : 'start'}
          foreground={true}
          hasSecondaryFields={isNeighbor}
        >
          {segment?.fieldSymbol || segment?.fieldName}
        </Text>
      </g>
    </g>
  );
};

export default FieldDetail;

const Path = styled.path`
  fill: none;
  stroke: ${(p) => p.theme.palette.titlePrimary};
  stroke-linecap: round;
`;

const Circle = styled.circle`
  fill: ${(p) => p.theme.palette.titlePrimary};
`;

const RectValueNeighbor = styled.rect<{color?: string}>`
  stroke: none;
  fill: ${(p) => p.theme.palette.accentPrimary};
  fill-opacity: 1;
`;

const RectValueSecondary = styled.rect<{color?: string}>`
  stroke: none;
  fill: ${(p) => p.color? p.color : p.theme.palette.accentPrimary};
  fill-opacity: 0.9;
`;

const BarLabel = styled.text`
  font-size: 0.04rem;
  stroke: none;
  fill: ${(p) => p.theme.palette.titlePrimary};
  fill-opacity: 0.9;
`;

const Text = styled.text<{hasSecondaryFields?: boolean, foreground: boolean}>`
  font-size: ${(p) =>
    (window.devicePixelRatio > 1 && p.hasSecondaryFields ? 1.6 : 1) *
    (p.hasSecondaryFields ? 0.032 : 0.08)}rem;
  fill: ${(p) => p.foreground ? p.theme.palette.titlePrimary : p.theme.palette.backgroundPrimary};
  stroke: ${(p) => p.foreground ? 'none' : p.theme.palette.backgroundPrimary};
  stroke-width: 0.01rem;
`;
Text.defaultProps = {
  hasSecondaryFields: false
}
