import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InsightsIcon from "@as_core/icons/insights.icon";
import SettingsIcon from '@mui/icons-material/Settings';

export const adminMenuItems: MenuItemsT[] = [
  {
    id: 'a.users',
    name: 'User/Repo Administration',
    pathname: '/admin/users',
    icon:  <ManageAccountsOutlinedIcon />
  },
  {
    id: 'a.repos',
    name: 'Repo Administration',
    pathname: '/admin/repos',
    icon:  <GroupsOutlinedIcon />
  },
  {
    id: 'a.insights',
    name: 'Insights',
    pathname: '/admin/insights',
    icon: <InsightsIcon />
  },
  {
    id: 'a.settings',
    name: 'Settings Information',
    pathname: '/admin/settings',
    icon: <SettingsIcon />
  }
];

const debug = false;
export const getAdminMenus = (subscription: string) => {
  if (debug) console.log('getMenuAdmin | subscription', subscription);
  return adminMenuItems;
}


