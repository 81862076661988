import styled from "styled-components/macro";

export const PagePanelContainer = styled.div<{overflowY?: string}>`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: calc(100vh - 50px);
  width: calc(100vw - 50px);
  overflow-y: ${(p) => p.overflowY };
  overflow-x: hidden;
`;
PagePanelContainer.defaultProps = {
  overflowY: 'auto',
}