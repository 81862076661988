import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const MyCompoundsIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M11.9 6.29986L6.50002 9.39986C5.60002 9.89986 6.40002 11.1999 7.30002
          10.6999L12.6 7.49986C13.5 7.09986 12.8 5.79986 11.9 6.29986Z" fill={colorHighlight}
          />
          <path d="M12.6993 19L7.39932 15.8C6.49932 15.3 5.79932 16.6 6.59932 17.1L11.8993
          20.3C12.7993 20.9 13.4993 19.5 12.6993 19Z" fill={colorHighlight}/>
          <path d="M18.3994 16.4996V10.0996C18.3994 9.09961 16.8994 9.09961 16.8994
          10.0996V16.4996C16.8994 17.4996 18.3994 17.4996 18.3994 16.4996Z" fill={colorHighlight}/>
          <path d="M21.3996 8.1998L12.9996 2.9998V0.799805C12.9996 -0.200195 11.4996
          -0.200195 11.4996 0.799805V2.9998L3.49964 7.6998C3.29964 7.7998 3.09964 8.0998
          3.09964 8.3998V17.8998L0.899638 19.2998C0.0996383 19.8998 0.999638 21.0998
          1.79964 20.4998L3.89964 19.1998L11.8996 23.8998C12.0996 23.9998 12.3996
          23.9998 12.6996 23.8998L21.4996 18.5998V8.1998H21.3996ZM19.8996 17.7998L12.2996
          22.2998L4.69964 17.7998V8.7998L12.2996 4.2998L19.8996 8.7998V17.7998Z" fill={color}/>
        </g>
      </svg>
    </>
  )
}

export default MyCompoundsIcon;