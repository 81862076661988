import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const DeSelectionIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={colorHighlight} d="M22,23c-.3,0-.5,0-.7-.3L1.3,2.7c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l20,20c.4.4.4,1,0,1.4s-.5.3-.7.3Z"/>
      <g>
        <path fill={color} d="M20.1,2.8h.4c.4,0,.7.3.7.6,0,.4.4.6.7.6s0,0,.1,0c.4,0,.7-.5.6-.9-.2-1.1-1.1-1.9-2.2-1.9h-.4c-.4,0-.8.3-.8.8s.3.8.8.8Z"/>
        <path fill={color} d="M14.6,2.8h2.5c.4,0,.8-.3.8-.8s-.3-.8-.8-.8h-2.5c-.4,0-.8.3-.8.8s.3.8.8.8Z"/>
        <path fill={color} d="M22,5.5c-.4,0-.8.3-.8.8v2.5c0,.4.3.8.8.8s.8-.3.8-.8v-2.5c0-.4-.3-.8-.8-.8Z"/>
        <path fill={color} d="M22.8,19.8v-2.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.2l1,1c.3-.1.5-.4.5-.7Z"/>
        <path fill={color} d="M22,15c.4,0,.8-.3.8-.8v-2.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.5c0,.4.3.8.8.8Z"/>
        <path fill={color} d="M4.5,2.8h1.5c.4,0,.8-.3.8-.8s-.3-.8-.8-.8h-2.5c-.2,0-.3,0-.4.1l1.4,1.4Z"/>
        <path fill={color} d="M9,2.8h2.5c.4,0,.8-.3.8-.8s-.3-.8-.8-.8h-2.5c-.4,0-.8.3-.8.8s.3.8.8.8Z"/>
        <path fill={color} d="M15,21.2h-2.5c-.4,0-.8.3-.8.8s.3.8.8.8h2.5c.4,0,.8-.3.8-.8s-.3-.8-.8-.8Z"/>
        <path fill={color} d="M9.4,21.2h-2.5c-.4,0-.8.3-.8.8s.3.8.8.8h2.5c.4,0,.8-.3.8-.8s-.3-.8-.8-.8Z"/>
        <path fill={color} d="M19.5,21.2h-1.6c-.4,0-.8.3-.8.8s.3.8.8.8h2.5c.1,0,.3,0,.4-.1l-1.4-1.4Z"/>
        <path fill={color} d="M2.8,4.5l-1-1c-.3.1-.5.4-.5.7v2.5c0,.4.3.8.8.8s.8-.3.8-.8v-2.2Z"/>
        <path fill={color} d="M2,13c.4,0,.8-.3.8-.8v-2.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.5c0,.4.3.8.8.8Z"/>
        <path fill={color} d="M2,18.5c.4,0,.8-.3.8-.8v-2.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.5c0,.4.3.8.8.8Z"/>
        <path fill={color} d="M3.9,21.2h-.4c-.4,0-.7-.3-.7-.6,0-.4-.5-.7-.9-.6-.4,0-.7.5-.6.9.2,1.1,1.1,1.9,2.2,1.9h.4c.4,0,.8-.3.8-.8s-.3-.8-.8-.8Z"/>
      </g>
      <g>
        <path fill={colorHighlight} d="M14.7,16.5c-.8.5-1.7.8-2.7.8-2.9,0-5.2-2.4-5.2-5.2s.3-1.9.8-2.7l-1.1-1.1c-.8,1.1-1.2,2.4-1.2,3.8,0,3.7,3,6.8,6.8,6.8s2.7-.4,3.8-1.2l-1.1-1.1Z"/>
        <path fill={colorHighlight} d="M9.3,7.5c.8-.5,1.7-.8,2.7-.8,2.9,0,5.2,2.4,5.2,5.2s-.3,1.9-.8,2.7l1.1,1.1c.8-1.1,1.2-2.4,1.2-3.8,0-3.7-3-6.8-6.8-6.8s-2.7.4-3.8,1.2l1.1,1.1Z"/>
      </g>
    </svg>
  )
}

export default DeSelectionIcon;