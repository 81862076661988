import { CompoundDoseResponseT } from '@components/dataSets/dataSet.types';
import { DrcCurve } from '@dataviz/dose-response-curve/dose-response-curve.types';
import { ScatterPlotDatum, ScatterPlotRawSerie } from '@nivo/scatterplot';
import { PlotData } from '@plotting/single-plot-view/plot.types';

export const getDrcScatterData = (
  data: PlotData,
  doseColumn: string,
  valueColumn: string,
  groupByColumn: string
): ScatterPlotRawSerie<ScatterPlotDatum>[] => {
  // Step 1: Group data by unique values in the groupColumn
  const groupedData: { [key: string]: PlotData[] } = {};

  data.forEach((point) => {
    const groupValue = point[groupByColumn];

    if (!groupedData[groupValue]) {
      groupedData[groupValue] = [];
    }

    groupedData[groupValue].push(point as any);
  });

  // Step 2: Transform grouped data into series format
  const series: ScatterPlotRawSerie<ScatterPlotDatum>[] = [];

  Object.keys(groupedData).forEach((group) => {
    const seriesData = groupedData[group].map((point) => ({
      x: point[doseColumn],
      y: point[valueColumn],
    }));

    series.push({
      id: group,
      data: seriesData,
    });
  });

  return series;
};

export const getDrcCurveData = (dataFormatted: CompoundDoseResponseT[]): DrcCurve[] => {

  if (!dataFormatted) {
    return []
  }

  const curves = dataFormatted.map((data) => {
    const fitParameters = data.results['LL.4'].parameters;
    return ({
      id: data.sample_id,
      min: fitParameters.lower,
      max: fitParameters.upper,
      slope: -fitParameters?.slope,
      IC50: fitParameters?.ED50.toFixed(4),
    });
  });

  return curves
}


