import React, { ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import RadioButtonSelector from '@pages/shared/RadioButtonSelector';
import { BasicTextInput } from '@as_core/account/forms/FormStyles';
import DefineStructure from '@components/compoundEditor/DefineStructure';
import { FlexItem, FlexRow } from '@components/layout/FlexStyles';
import { Slider } from '@mui/material';
import TextButton from '@as_core/controls/buttons/TextButton';
import SearchIcon from '@mui/icons-material/Search';

const choices = [
  {value: 'text', label: 'text'},
  {value: 'substructure', label: 'substructure'},
  {value: 'similarity', label: 'similarity'},
];

interface PropsI {
  smiles: string;
  onSmilesChange: (v:string) => void;
  text: string;
  onTextChange: (v:string) => void;
  searchType: string
  onSearchTypeChange: (e:ChangeEvent<HTMLInputElement>) => void;
  similarityThreshold: number;
  onSimilarityThresholdChange: (v:number) => void;
  onSubmit: () => void;
}

const debug = false;
const SearchCompound = (props:PropsI) => {

  const isDisabled = (): boolean => {
    if (props.searchType === 'text' && props.text.length > 3) return false;
    return !(props.smiles !== '');
  }

  const handleSlider = (e) => {
    props.onSimilarityThresholdChange(e.target.value);
  }

  if (debug) console.log('SearchCompound | props:', props);
  return (
    <SearchContainer>
      <SearchParametersContainer>
        <SearchTypeSelectorContainer>
          <TextHeader>Search compounds by:</TextHeader>
          <StyledRadioButtonSelector
          key='CompoundSearchSelector'
          radioKey='CompoundSearchButtonSelector'
          options={choices}
          selected={props.searchType}
          fontSize={12}
          onChange={props.onSearchTypeChange}
          vertical
          />
        </SearchTypeSelectorContainer>
        <SearchOptionsContainer>
          { props.searchType === 'text' ?
            <FlexItem width={'200px'}>
              <BasicTextInput
              value={props.text}
              placeholder='Search compounds by text'
              onChange={(e) => props.onTextChange(e.target.value)}
              />
            </FlexItem>
            : props.searchType === 'substructure' ?
          <DefineStructure smiles={props.smiles} updateSmiles={props.onSmilesChange}/>
          :
          <>
            <DefineStructure smiles={props.smiles} updateSmiles={props.onSmilesChange}/>
            <FlexRow v_centered>
              <FlexItem>
                <TextLabel>Similarity Threshold:</TextLabel>
              </FlexItem>
              <FlexItem width={'80px'}>
                <Slider
                value={props.similarityThreshold}
                step={0.05}
                marks
                size='small'
                min={0.0}
                max={0.5}
                valueLabelDisplay='auto'
                onChange={handleSlider}
                />
              </FlexItem>
              <FlexItem width={'20px'}>
                <TextLabel color='accent'> {props.similarityThreshold.toString()}</TextLabel>
              </FlexItem>
            </FlexRow>
          </>
          }
        </SearchOptionsContainer>
      </SearchParametersContainer>
      <FlexRow h_centered width={'100%'}>
        <FlexItem>
          <TextButton
          label={'Launch Search'}
          icon={<SearchIcon />}
          width={200}
          onClick={props.onSubmit}
          isDisabled={isDisabled()}
          />
        </FlexItem>
      </FlexRow>
    </SearchContainer>
  )
}

export default SearchCompound;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: max-content;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const SearchParametersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  height: 125px;
  padding: 5px;
`;

const SearchTypeSelectorContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`

const SearchOptionsContainer = styled(SearchTypeSelectorContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const TextHeader = styled.div`
  display: flex;
  font-size: 14px;
`

const StyledRadioButtonSelector = styled(RadioButtonSelector)`
  display: flex;
  margin-left: 10px;
  font-size: 14px;
`

const TextLabel = styled.div<{color?: string}>`
  display: flex;
  font-size: 12px;
  padding-right: 4px;
  color: ${(p) => p.color === 'accent' ? p.theme.palette.accentSecondary : p.theme.palette.textPrimary }
`;
TextLabel.defaultProps = {
  color: 'normal'
}