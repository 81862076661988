// BASED SETTINGS USED IN EdgeWebGLPane and VectorPane
export const canvasScaleFactor = 1;  // used in graphics.ts
// VERTEX SETTINGS
export const VERTEX_OUTER_RING_SIZE = 1.5; // used in vertex.ts
export const SELECTION_MAX_DISTANCE = 15; // used in vertex.ts

// COLOR SCALES
export const chiColorRange = ['#FECEF1', '#CA054D'];
export const uniScoresColorRange = ['#D7FCD4', '#3185FC'];
export const inhibitionColorRange = ['#F2F0F6', '#7D0112'];
export const zebrafishColorRange = ['#FF0000', '#9C9C9C'];
export const abbvieColorRange = ['#00FF00', '#0099FF'];

export const nanColor = '#9C9C9C';

// shadow (highlight colors) TODO - Do light dark mode
export const selectedColor = '#00FF77';
export const selectedVertexColor = '#FFFF00';
export const hoveredVertexColor = '#00FFFF';

// edges
export const standardEdgeColor = [0.36, 0.71, 0.82, 0.3];
export const hoveredEdgeColor = [0.36, 0.71, 0.82, 1.0];
export const selectedEdgeColor = [0.8, 0.41, 0.57, 1.0];