import React, {ReactNode} from 'react';
import styled from 'styled-components/macro';

interface PropsI {
  magnify?: number,
  highlight?: boolean,
  children: ReactNode
}

const InsetSvg = (props:PropsI) => {
  const { magnify = 1, highlight = false, children }= props;
  return (
    <Svg viewBox='0 0 100 100'>
      {highlight && <Circle cx='50' cy='50' r='50' />}
      <g
        transform={`scale(${magnify}) translate(${50 / magnify} ${
          50 / magnify
        })`}
      >
        {children}
      </g>
    </Svg>
  );
};

export default InsetSvg;

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const Circle = styled.circle`
  stroke: none;
  fill: ${(p) => p.theme.palette.backgroundQuaternary};
  opacity: 0.2;
`;
