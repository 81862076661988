import React from 'react';
import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { BarBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface BarModalEditorProps {
  plot?: PlotConfig<BarBase>;
  onSave?: (config: PlotConfig<BarBase>) => void;
}

export const BarModalEditor = ({ plot, onSave }: BarModalEditorProps) => {
  const updateGroupColumn = (columnName: string) => {
    const newGroupcolumns = [
      columnName,
      ...plot.config.groupByColumns.slice(1),
    ];
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, groupByColumns: newGroupcolumns },
    };
    onSave(newPlotConfig);
  };

  const updateValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-value'
        label={'Numeric Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValue}
      />
      <br />
      <VariableSelect
        id='group-value'
        label={'Grouping Variable'}
        value={plot.config.groupByColumns[0]}
        options={plot.columns}
        onChange={updateGroupColumn}
      />
    </Stack>
  );
};
