import React from 'react';
import styled from 'styled-components/macro';
import SubscriptionProductCard from './SubscriptionProductCard';
import { ProductT } from "@business/products/products.types";

type PropsI = {
  products: ProductT[];
  onAddToCart: (product: ProductT) => void;
};

const debug = false;
const ProductsCarousel = (props:PropsI) => {
  const { products, onAddToCart } = props;
  // For Future: const [carouselStart, setCarouselStart] = useState<number>(0);

  if (debug) console.log('ProductCarousel | products:', products);

  if (products === undefined) {
    return(<>No Products Available</>)
  }

  return (
    <>
      { products.length === 0 ? <
        CardCarouselContainer>No available products</CardCarouselContainer>
        :
        <CardCarouselContainer>
          <CardContainerScroll>
            { products.map((product, index) => {
              return(
                <CardContainer key={`card_container_${index}`}>
                  <SubscriptionProductCard
                    key={`product_card_${index}`}
                    product={product}
                    onAddToCart={onAddToCart}
                  />
                </CardContainer>
              )})}
          </CardContainerScroll>
        </CardCarouselContainer>
      }
    </>
  );
};

export default ProductsCarousel;

const CardCarouselContainer = styled.div`
  display: flex;
  position: relative;
  max-width: calc(100vw - 70px);
  flex-direction: row;
  overflow-x: auto;
`;

const CardContainerScroll = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  padding: 5px;
`

