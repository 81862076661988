// MatchCircle.tsx
import React from 'react';
import styles from '../../components-style/MatchCircle.module.css';

interface MatchCircleProps {
  percent: number; // The percentage to display
  circleColor: string; // Color of the circle
  progressColor: string; // Color of the progress bar
}

const MatchCircle: React.FC<MatchCircleProps> = ({
  percent,
  circleColor,
  progressColor,
}) => {
  // Calculate the circumference of the circle based on its radius
  const radius = 30; // Adjust this as needed
  const circumference = 2 * Math.PI * radius;

  // Calculate the strokeDashoffset to represent the progress
  const strokeDashoffset = circumference - (percent / 100) * circumference;

  return (
    <div className={styles.matchCircle}>
      <svg width='100' height='90'>
        <circle
          className={styles.matchRingCircle}
          style={{ stroke: circleColor }}
          r={radius}
          cx='50'
          cy='45'
        />
        <circle
          className={styles.matchRingMatch}
          style={{
            stroke: progressColor,
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: strokeDashoffset,
          }}
          r={radius}
          cx='50'
          cy='45'
        />
        <text
          x='50%'
          y='50%'
          textAnchor='middle'
          dy='.3em'
          className={styles.matchText}
        >
          {percent}%
        </text>
      </svg>
    </div>
  );
};

export default MatchCircle;
