import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { FlexRow } from '@components/layout/FlexStyles';
import SearchIcon from '@mui/icons-material/Search';
import IconSearch from '@components/icons/IconSearch';

import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import InputField from './InputField';
import MenuButton from '@components/elements/MenuButton';

interface DropdownTextSearchProps {
  searchText: string;
  searchResults: Array<string>;
  onChange: (v) => void;
  onSelect: (v) => void;
  width?: number;
  hideIcon?: boolean;
  hasShowButton?: boolean;
}

const SearchFieldAutocomplete = (props: DropdownTextSearchProps) => {
  const {
    hasShowButton,
    searchText,
    onChange,
    onSelect,
    width,
    hideIcon,
    searchResults,
  } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(
    !hasShowButton
  );

  const handleSelect = (_event: React.SyntheticEvent, value: string) => {
    setInputValue('');
    onSelect(value);

    if (hasShowButton) {
      setIsSearchVisible(false);
    }
  };

  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    if (value.length > 2) {
      onChange(value);
    }
  };
  // console.log('SearchFieldAutocomplete | searchResults', searchResults);

  return (
    <SelectorContainer>
      {hasShowButton && (
        <MenuButton
          icon={<IconSearch />}
          onClick={() => {
            setIsSearchVisible(!isSearchVisible);
          }}
          isActive={isSearchVisible}
          text={'Search'}
          tooltipPlacement={'bottom'}
        />
      )}
      {hasShowButton && isSearchVisible && (
        <StyledAutocomplete
          value={searchText}
          onChange={handleSelect}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          id='selector-text-search'
          options={searchResults}
          sx={{ width: width || 250 }}
          blurOnSelect
          isOptionEqualToValue={(option: string, value: string) => option === value}  // turn off warning
          renderInput={(params: AutocompleteRenderInputParams) => (
            <FlexRow v_centered>
              {hideIcon ? null : <StyledSearchIcon />}
              <InputField {...params} variant='standard' label='search ...' />
            </FlexRow>
          )}
        />
      )}
    </SelectorContainer>
  );
};

export default SearchFieldAutocomplete;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding-top: 4px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  font-size: 10px;
  & .MuiFormLabel-root {
    font-size: 12px;
    padding: 0;
    color: ${(p) => p.theme.palette.accentSecondary};
  }
  & .MuiInput-underline:hover {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
    border-radius: unset !important;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
  }
  & .MuiInput-underline.Mui-focused:after {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  }
  & .MuiInput-underline.Mui-focused:hover {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  }
  & .MuiAutocomplete-paper {
    color: red;
    font-size: 10px;
  }
  & .MuiAutocomplete-option.Mui-focused {
    font-size: 10px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  display: flex;
  padding-right: 5px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;
