import React, {ReactNode} from 'react';
import styled from 'styled-components/macro';

interface HeaderMenuPropsT {
  label: string,
  icon?: ReactNode;
  onClick?: (e) => void,
  isActive: boolean,
  isDisabled?: boolean,
  fontSize?: number,
  width?: number,
  height?: number,
}

const HeaderMenu = (props: HeaderMenuPropsT) => {
  return (
    <MenuButtonContainer
      width={props.width}
      height={props.height}
      onClick={props.isActive ? ()=>{} : props.onClick}
      isActive={props.isActive}
      isDisabled={props?.isDisabled ? props.isDisabled : false}
    >
      { props?.icon ? <IconContainer>{props.icon}</IconContainer> : null}
      <LabelContainer fontSize={props.fontSize}>{props.label}</LabelContainer>
    </MenuButtonContainer>
  );
}

HeaderMenu.defaultProps = {
  isActive: false,
  fontSize: 14,
  height: 32
}

export default React.memo(HeaderMenu);

const MenuButtonContainer = styled.div<
  { height: number, width: number, isActive?: boolean, isDisabled: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: ${(p) => p.height}px;
  color: ${(p) => p.isActive ?
    p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  border-bottom: 2px solid ${(p) => p.isActive ?
    p.theme.palette.accentPrimary : p.theme.palette.backgroundQuaternary};
  :hover {
    cursor: ${(p) => p.isDisabled ? 'not-allowed' : 'pointer'};
    color: ${(p) => p.isActive ? p.theme.palette.textPrimary
      : p.isDisabled ? p.theme.palette.textSecondary : p.theme.palette.accentSecondary };
  }
`;

const LabelContainer = styled.div<{ fontSize: number }>`
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  font-size: ${(p) => p.fontSize}px;
  white-space: nowrap;
`;

const IconContainer = styled.div`
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
`;
