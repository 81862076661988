import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
      size?: number;
    }

const KinomeUniverseIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M15.2,21.9l3-4.4l3.1,4.4" />
        <path stroke={color} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M8.8,2.1l-3,4.4L2.7,2.1" />
        <path stroke={color} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M18.2,17.5l-3.8-3.3" />
        <path stroke={color} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M5.8,17.6l3.8-3.4" />
        <path stroke={color} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M5.8,6.7l3.8,3.2" />
        <path stroke={color} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M18.2,6.5l-3.7,3.4" />
        <path fill={colorHighlight} d="M12,15.2c1.8,0,3.2-1.4,3.2-3.2S13.8,8.8,12,8.8S8.8,10.2,8.8,12S10.2,15.2,12,15.2z" />
        <path fill={color}
              d="M5.8,8.7C7,8.7,8,7.7,8,6.5c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C3.6,7.7,4.6,8.7,5.8,8.7z" />
        <path fill={color}
              d="M2.7,3.6c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C1.2,2.9,1.9,3.6,2.7,3.6z" />
        <path fill={color}
              d="M8.8,3.6c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5C8,0.6,7.3,1.3,7.3,2.1C7.3,2.9,8,3.6,8.8,3.6z" />
        <path fill={color}
              d="M18.2,8.7c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2S16,5.3,16,6.5C16,7.7,17,8.7,18.2,8.7z" />
        <path fill={color} d="M18.2,19.7c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2s-2.2,1-2.2,2.2S17,19.7,18.2,19.7z" />
        <path fill={color}
              d="M5.8,19.7c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2S4.6,19.7,5.8,19.7z" />
        <path fill={color} d="M18.2,19.7c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2s-2.2,1-2.2,2.2S17,19.7,18.2,19.7z" />
        <path fill={color}
              d="M21.3,23.4c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5S20.5,23.4,21.3,23.4z" />
        <path fill={color}
              d="M15.2,23.4c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5S14.4,23.4,15.2,23.4z" />
      </svg>
    </>
  )
}

export default KinomeUniverseIcon;