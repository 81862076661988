import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const CopyToClipboardIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M22,15h-7.8l2.5-2.5c.4-.4.4-1,0-1.4s-1-.4-1.4,0l-4,4c-.2.2-.3.5-.3.8,0,.2,0,.3,0,.5,0,.1.1.2.2.3l4,4c.2.2.5.3.7.3s.5,0,.7-.3c.4-.4.4-1,0-1.4l-2.3-2.3h7.6c.6,0,1-.4,1-1s-.4-1-1-1Z"/>
      <path fill={colorHighlight} d="M19.8,17.7v3.6c0,.5-.4.9-.9.9H5.1c-.5,0-.9-.4-.9-.9V3.6c0-.5.4-.9.9-.9h2.9v2.8h8v-2.8h2.9c.5,0,.9.4.9.9v10.4h1.5V3.6c0-1.3-1.1-2.4-2.4-2.4h-5c-.3-.7-1-1.2-1.9-1.2s-1.6.5-1.9,1.2h-5c-1.3,0-2.4,1.1-2.4,2.4v17.7c0,1.3,1.1,2.4,2.4,2.4h13.7c1.3,0,2.4-1.1,2.4-2.4v-3.6h-1.5ZM12,1c.6,0,1,.4,1,1s-.4,1-1,1-1-.4-1-1,.4-1,1-1Z"/>
    </svg>
  )
}

export default CopyToClipboardIcon;