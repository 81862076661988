import React, {useState, useEffect, ReactNode} from "react";
import styled from "styled-components/macro";
import {ProductCategoryT} from "@business/products/products.types";
import CardCarousel from "@as_core/elements/CardCarousel";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";

type PropsT = {
  productCategories: ProductCategoryT[];
  active?: string;
  onSelect?: (v: string) => void;
  debug?: boolean;
}

const InfoText = 'Select the screen/assay category to see the available products in each category. '
  + 'Multiple types of products can be ordered together in a single shopping cart, but after payment, they '
  + 'will be split into separate requests to the labs that will perform the experiments and generate the results.';

const ProductCategorySelector = (props:PropsT) => {
  const { productCategories, active } = props;
  const [cards, setCards] = useState<ReactNode[]>([]);
  if (props.debug) console.log(props);

  useEffect(()=>{
    const productCards = productCategories.map((c, index) => (
      <CategorySelector
        key = {`category_selector_${index}`}
        active = {props?.active === c.categoryCode}
        onClick = {() => props.onSelect(c.categoryCode)}
      >
        <Icon active={props?.active === c.categoryCode}>
          {c?.icon ? c.icon : null}
        </Icon>
        <ProductTitle>{c.categoryName}</ProductTitle>
      </CategorySelector>
    ));
    setCards(productCards);
  },[productCategories, active])

  if (props.debug) console.log('cards', cards);

  return(
    <CategoryContainer>
      <CategoryTitle>Select Type of Assay/Screen:<HoverInfoText text={InfoText}/></CategoryTitle>
      <CategorySelectorContainer>
        <CardCarousel
          cards={cards}
        />
      </CategorySelectorContainer>
    </CategoryContainer>
  );
}

export default ProductCategorySelector;

const CategoryContainer = styled.span`
  display: flex;
  width: 100%;
  margin: 5px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CategorySelectorContainer = styled.span`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-left: 15px;
  width: 100%;
  font-size: 16px;
`;


const CategorySelector = styled.span<{active:boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => p.active ? 'default' : 'pointer'};
  width: 200px;
  color: ${(p) => p.active ?  
          p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  border-radius: 15px;
  padding: 5px;
  background-color: ${(p) => p.active ? 
          p.theme.palette.backgroundPrimary : p.theme.palette.backgroundTertiary};
  border: 1px solid ${(p) => p.active? 
          p.theme.palette.accentPrimary : p.theme.palette.accentSecondary};

  :hover {
    background-color: ${(p) => p.active ? 
            p.theme.palette.backgroundPrimary : p.theme.palette.backgroundQuaternary};
  }
`;

const Icon = styled.div<{active:boolean}>`
  display: flex;
  padding-right: 5px;
  ${(p) => p.active ? `` : `filter: grayscale(1.0)`};
`

const ProductTitle = styled.div`
  display: flex;
  font-size: 14px;
  padding-left: 5px;

`;
