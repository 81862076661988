import { Serie } from '@nivo/line';
import { KeyColor, PlotData } from '@plotting/single-plot-view/plot.types';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';

export const getLineSeries = (
  data: PlotData,
  timeColumn: string,
  valueColumn: string
): Serie[] => {
  return [
    {
      id: 'Unique series',
      data: data.map((point) => {
        return {
          x: point[timeColumn],
          y: point[valueColumn],
        };
      }),
    },
  ];
};

export const getLineSeriesWithGroupBy = (
  data: PlotData,
  timeColumn: string,
  valueColumn: string,
  groupByColumn: string,
  colorConfig?: KeyColor[]
): Serie[] => {

  // Step 1: Group data by unique values in the groupColumn
  const groupedData: { [key: string]: PlotData[] } = {};

  data.forEach((point) => {
    const groupValue = point[groupByColumn];
    if (!groupedData[groupValue]) {
      groupedData[groupValue] = [];
    }
    groupedData[groupValue].push(point as any);
  });


  // Step 2: create a color scale: 1 group -> 1 color
  const groups = Object.keys(groupedData);
  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors.scheme);

  // Step 3: Transform grouped data into series format
  const series: Serie[] = [];

  Object.keys(groupedData).forEach((group) => {
    const seriesData = groupedData[group].map((point) => ({
      x: point[timeColumn],
      y: point[valueColumn],
    }));

    let color = defaultColorScale(String(group));
    if (colorConfig) {
      const selectedColorItem = colorConfig.find((item) => item.id === group);
      if (selectedColorItem) {
        color = selectedColorItem.color; // Use the color from selected item if found
      }
    }

    series.push({
      id: group,
      data: seriesData,
      color: color
    });
  });

  return series;
};
