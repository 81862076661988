import React, { useContext } from 'react';
import { PlotContext } from '../plot.context';
import { BoxplotBase, PlotConfig } from '@plotting/single-plot-view/plot.types';
import Boxplot from '@dataviz/boxplot/Boxplot';
import { Theme } from '@nivo/core';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';
import { getBoxplotRankedData } from './data-wrangling-utils/get-boxplot-ranked-data';

type BoxplotWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<BoxplotBase>;
  isSmallChart?: boolean;
};

export const BoxplotWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: BoxplotWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<BoxplotBase> }).plot;

  let sortedData = plot.data;

  if (plot.config.isSorted) {
    sortedData = getBoxplotRankedData(
      plot.data,
      plot.config.groupByColumn,
      plot.config.valueColumn
    );
  }

  return (
    <Boxplot
      data={sortedData}
      margin={
        isSmallChart ? { ...DEFAULT_SMALL_GRAPH_MARGIN, left: 100 } : undefined
      }
      groupByColumn={plot.config.groupByColumn}
      valueColumn={plot.config.valueColumn}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      orientation={plot.config.orientation}
      datavizTheme={datavizTheme}
      isLogScale={plot.config.isLogScale}
    />
  );
};
