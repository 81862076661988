import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const FavoritesIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1998 7.7002L10.1998 10.0002L7.6998
        10.2002C7.2998 10.2002 6.9998 10.5002 6.8998 10.8002C6.7998 11.1002 6.8998 11.5002 7.1998
        11.7002L9.09981 13.3002L8.5998 15.7002C8.4998 16.0002 8.6998 16.4002 8.8998 16.6002C9.1998
        16.8002 9.5998 16.8002 9.8998 16.6002L11.9998 15.3002L14.0998 16.6002C14.3998 16.8002
        14.7998 16.8002 15.0998 16.6002C15.3998 16.4002 15.4998 16.0002 15.3998 15.7002L14.8998
        13.3002L16.7998 11.7002C17.0998 11.5002 17.1998 11.1002 17.0998 10.8002C16.9998 10.5002
        16.6998 10.2002 16.2998 10.2002L13.7998 10.0002L12.7998 7.7002C12.6998 7.4002 12.2998
        7.2002 11.9998 7.2002C11.6998 7.2002 11.2998 7.4002 11.1998 7.7002Z" fill={colorHighlight}/>
        <path d="M2.5 17.5L12 23L21.5 17.5V6.5L12 1L2.5 6.5V17.5Z" stroke={color} strokeWidth="2"
              strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>
  )
}

export default FavoritesIcon;