import React from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { color as d3Color } from 'd3-color';
import { scaleLinear, scaleQuantile } from 'd3-scale';
import { memo, useState } from 'react';
import { useTheme } from 'styled-components/macro';
import { BarChartTooltip } from './BarChartTooltip';

export interface DistributionEntry extends BarDatum {
  name: string;
  label?: string;
  count: number;
}

interface Props {
  data: Array<DistributionEntry>;
  selectedClass: string;
  linear?: boolean;
  onClick?: (DistributionEntry, ClickEvent) => void;
  marginTop?: number;
  marginBottom?: number;
}

const COLOR_BASE = 'white';
const COLOR_INTERVALS = [0.25, 0.5, 0.75, 1.0];

// eslint-disable-next-line react/display-name
export const BarChart = memo(
  ({
    data,
    selectedClass,
    onClick,
    linear = false,
    marginTop = 80,
    marginBottom = 100,
  }: Props) => {
    const theme = useTheme();
    const [hoverItem, setHoverItem] = useState(null);
    const tooltip = BarChartTooltip();
    const scale = scaleLinear<string>()
      .domain([0, 1])
      .range([COLOR_BASE, theme.palette.accentPrimary]);
    const colorIntervals = COLOR_INTERVALS.map((q) => scale(q));
    const values = data.map((entry) => entry.count);
    const colors = scaleQuantile<string>().domain(values).range(colorIntervals);
    let hoverTimeout = null;

    //console.log('BarChart {data}', data);

    const color = (node): string => {
      const name = node.data.name;
      let highlight = !hoverItem || name === hoverItem;
      let baseColor = colors(node.value);
      if (node.data.name === selectedClass) {
        baseColor = '#339BBE';
        highlight = true;
      }
      //console.log("name", name, " baseColor", baseColor)
      return highlight ? baseColor : d3Color(baseColor).darker().toString();
    };

    const clickHandler = (item, e) => {
      setHoverItem(null);
      const record = data[item.index];
      if (onClick) onClick(record, e);
    };

    const mouseEnter = (node, e) => {
      if (hoverTimeout) clearTimeout(hoverTimeout);
      setHoverItem(node.data.name);
      e.target.style.cursor = 'pointer';
    };

    const mouseLeave = () => {
      hoverTimeout = setTimeout(() => {
        setHoverItem(null);
      }, 800);
    };

    return (
      <ResponsiveBar
        data={data}
        keys={['count']}
        indexBy={'name'}
        valueScale={linear ? { type: 'linear' } : { type: 'symlog' }} // symlog or linear
        enableLabel={false}
        enableGridX={false}
        enableGridY={false}
        animate={false}
        colors={color}
        tooltip={tooltip as undefined}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onClick={clickHandler}
        theme={{
          fontSize: 11,
          textColor: theme.palette.textSecondary,
          axis: {
            domain: {
              line: {
                strokeWidth: 1,
                stroke: theme.palette.textSecondary,
              },
            },
          },
        }}
        padding={0.3}
        margin={{ top: marginTop, left: 40, bottom: marginBottom, right: 20 }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 90,
          format: (v) => `${v.toUpperCase().slice(0, 7)}`,
        }}
        borderRadius={1}
      />
    );
  }
);
