export const segmentImages = {
  'Agricultural Chemical': '/img/icons/AgriculturalChemical.svg',
  'Cosmetic Chemical': '/img/icons/Cosmetic.svg',
  'Industrial Chemical': '/img/icons/IndustrialChemicals.svg',
  'Natural Product': '/img/icons/NaturalProducts.svg',
  Nutraceutical: '/img/icons/Nutraceutical.svg',
  Pharmaceutical: '/img/icons/Pharmaceuticals.svg',
  Veterinary: '/img/icons/VeterinaryProduct.svg',
  'Vitamin/Supplement': '/img/icons/vitaminSupplement.svg',
  'Food Additive': '/img/icons/FoodAdditive.svg',
};
