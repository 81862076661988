import React from 'react';
import styled from 'styled-components/macro';
import Spinner from '@components/elements/Spinner';
import {Tooltip} from "@mui/material";

interface IconButtonProps {
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
  inProcess?: boolean;
  width?: number;
  label?: string;
}

const IconButton = (props: IconButtonProps) => {
  if (props?.tooltip) {
    return(
      <Tooltip title={props.tooltip}>
        <StyledButton
          key={props.label}
          onClick={props.onClick}
          disabled={props.disabled}
          width={props?.width ? props.width : 200}
        >
          { props?.inProcess ? <Spinner /> : props.icon }
        </StyledButton>
      </Tooltip>
    )
  } else {
    return(
      <StyledButton
        key={props.label}
        onClick={props.onClick}
        disabled={props.disabled}
        width={props?.width ? props.width : 200}
      >
        { props?.inProcess ? <Spinner /> : props.icon }
      </StyledButton>
    )
  }
}
export default IconButton;

const StyledButton = styled.div<{disabled: boolean, width: number}>`
  display: flex;
  z-index: 10;
  width: ${(p) => p.width}px;
  height: 28px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.palette.textSecondary};
  cursor: ${(p) => p.disabled ? 'default': 'pointer'};
  :hover {
    color: ${(p) => p.theme.palette.accentSecondary};
  }
`;
StyledButton.defaultProps = {
  disabled: false
}