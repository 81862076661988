import React from 'react';
import { IOrgan } from './types';
import style from './Organ.module.scss';
import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

const debug = false;
const Organ = (props: IOrgan) => {
  const { title, subtitle, id, src, titlePosition, url, tooltip } = props;
  if (debug) console.log('Organ | props:', props);

  const organRefText = useRef(null);

  useEffect(() => {
    const radians = (Number(titlePosition) * Math.PI) / 180.0;
    const width = organRefText.current.getBoundingClientRect().width;
    const height = organRefText.current.getBoundingClientRect().height;
    const yPosition = Math.cos(radians);
    const xPosition = Math.sin(radians);
    if (debug) {
      console.log(
        title,
        titlePosition,
        radians,
        xPosition,
        yPosition,
        width,
        height
      );
    }
    const xScale = 1.25;
    if (xPosition < 0) {
      organRefText.current.style.left = `${xScale * width * xPosition}px`;
    } else {
      organRefText.current.style.right = `-${xScale * width * xPosition}px`;
    }
    const yScale = 85;
    if (yPosition < 0) {
      organRefText.current.style.top = `${0.5 * height - yScale * yPosition}px`;
    } else {
      organRefText.current.style.bottom = `${
        0.5 * height + yScale * yPosition
      }px`;
    }
    if (debug) {
      console.log(
        title,
        'left',
        organRefText.current.style.left,
        'right',
        organRefText.current.style.right,
        'top',
        organRefText.current.style.top,
        'bottom',
        organRefText.current.style.bottom
      );
    }
  }, [subtitle, title, titlePosition]);

  return (
    <Link to={url}>
      <Tooltip arrow title={tooltip}>
        <div id={id} className={style.organ}>
          <div
            className={cn(
              style['organ__text'],
              style[`organ__text--${titlePosition}`]
            )}
            ref={organRefText}
          >
            <div className={style['organ__text-title']}>{title}</div>
            <div className={style['organ__text-subtitle']}>{subtitle}</div>
          </div>
          <img className={style['organ__img']} src={src} alt={title} />
        </div>
      </Tooltip>
    </Link>
  );
};

export default Organ;
