import React from 'react';
import styled from 'styled-components/macro';
import {RequestT} from '../hooks/useRequests';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import {RequestDetailsFields} from '../utils/tableDefinitions';
import {getRequestDetailsDisplayValue} from '../utils/renderValues';
import { Column } from './flexStyles';

interface PropsI {
  viewMode: string;
  request: RequestT;
  userEmails: UserEmailDictionaryT;
}

const debug = false;
const RequestDetailsTile = (props: PropsI) => {
  const {viewMode, request, userEmails} = props;
  if (debug) console.log('RequestDetailsTile | request:', request);

  if (!Object.keys(request)) {
    return(
      <ViewContainer>Request empty</ViewContainer>
    )
  }

  if (!Object.hasOwn(RequestDetailsFields, viewMode)) {
    return(
      <ViewContainer>Error: Unknown viewMode: {viewMode}</ViewContainer>
    )
  }

  return (
  <Column>
    <ViewContainer>
      {
        RequestDetailsFields[viewMode].map((item, index) =>
        getRequestDetailsDisplayValue(request, item, index, userEmails, '250px'))
      }
    </ViewContainer>
    <Footer>Request ID: {request.id}</Footer>
  </Column>
  );
};

export default RequestDetailsTile;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 400px;
  padding: 10px;
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundQuaternary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const Footer = styled.div`
  display: flex;
  font-size: 8px;
  color: ${(p) => p.theme.palette.textSecondary};
  width: 100%;
  justify-content: flex-end;
  min-width: 400px;
`;




