import React from 'react';
import { PieChartDatumType } from 'src/dataviz/piechart/PieChart';
import _ from 'lodash';
import { KeyColor, PlotData } from '@plotting/single-plot-view/plot.types';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';

// Data wrangling
// Function that takes the raw dataset and the graph editor config
// and build a dataset understandable by the PieChart component
export const getPieChartData = (
  data: PlotData,
  groupColumn: string,
  valueColumn: string,
  colorConfig?: KeyColor[]
): PieChartDatumType[] => {
  const groups = [...new Set(data.map((d) => d[groupColumn]))];

  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors.scheme);

  // If a group is provided several times, we will make a mean of its values
  const averages: PieChartDatumType[] = [];

  groups.forEach((group) => {
    const groupRows = data.filter((d) => d[groupColumn] === group);

    let color = defaultColorScale(String(group));

    if (colorConfig) {
      const selectedColorItem = colorConfig.find((item) => item.id === group);
      if (selectedColorItem) {
        color = selectedColorItem.color; // Use the color from selected item if found
      }
    }

    averages.push({
      id: String(group),
      value: _.mean(groupRows.map((v) => v[valueColumn])),
      label: String(group),
      color: color,
    });
  });

  return averages;
};
