import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import OptionsSelector, {
  OptionT,
} from '../../../ui-components/selectors/OptionsSelector';
import BasicTable from '@as_core/tables/BasicTable';
import DownloadFile from '@as_core/controls/outputs/DownloadFile';
import CopyToClipboard from '@as_core/controls/outputs/CopyToClipboard';
import { TextSmallBold } from '@as_core/elements/textStyles';
import UploadIcon from '@components/icons/upload.icon';
import GraphIcon from '@components/icons/graph.icon';
import KinomeUniverseIcon from '@components/icons/kinomeUniverse.icon';
import TileIcon from '@components/icons/tile.icon';
import TileView from './TileView';
import TableIcon from '@components/icons/table.icon';
import { FilterListOff } from '@mui/icons-material';

const ViewTypes: OptionT[] = [
  { value: 'tile', label: 'Tile View', icon: <TileIcon /> },
  { value: 'table', label: 'Table View', icon: <TableIcon /> },
];

const FilterTypes: OptionT[] = [
  { value: 'any', label: 'Any', icon: <FilterListOff /> },
  { value: 'upload', label: 'Upload Data', icon: <UploadIcon /> },
  { value: 'plot', label: 'Plotting', icon: <GraphIcon /> },
  { value: 'universe', label: 'Universe', icon: <KinomeUniverseIcon /> },
];

const IconsForTypes = {
  upload: <UploadIcon />,
  plot: <GraphIcon />,
  universe: <KinomeUniverseIcon />,
};

const TableData = {
  columns: [
    { label: '', value: 'type', width: 70 },
    { label: 'Example', value: 'name', width: 275 },
    { label: 'Description', value: 'description', width: 500, align: 'left' },
    { label: 'DOI', value: 'doi', width: 70 },
    { label: 'Data', value: 'file', width: 70 },
    { label: 'Tutorial', value: 'document', width: 70 },
  ],
  rows: [
    {
      type: ['upload'],
      name: 'Upload Compounds/Data',
      description:
        'A set of 15 selective kinase inhibitors taken from Table 3 of "A guide to selective kinase' +
        ' tool compounds", J.CM. Uitdehagg, et. al. British Journal of Pharmacology (2012) 166 858-876.',
      doi: ' DOI:10.1111/j.1476-5381.2012.01859.x',
      file: 'bph_selective_kinases.xlsx',
      document: 'bph_selective_kinases.pdf',
    },
    {
      type: ['upload', 'plot'],
      name: 'Dose Response Fits/Plots',
      description:
        'Example of how to upload, curve fit, and plot a dose response curve. ' +
        'The sample file had 4 sets of 10pt curves',
      file: 'sample_dose_response.xlsx',
      document: 'sample_dose_response.pdf',
    },
    {
      type: ['upload', 'plot'],
      name: 'Generic Data',
      description:
        'This is a simple example of uploading any type of tabular data to be used for plotting.',
      file: 'sample_any_data.xlsx',
      document: 'sample_any_data.pdf',
    },
    {
      type: ['upload', 'universe'],
      name: 'Target Panel Universe (Wide)',
      description:
        'Creating a custom universe using a sample dataset of 300 kinases targets assayed' +
        ' against 178 compounds from Reaction Biology.',
      doi: '10.1038/nbt.2017',
      file: 'kinome_wide_Anastassiadis.xlsx',
      document: 'kinome_wide_Anastassiadis.pdf',
    },
    {
      type: ['upload', 'universe'],
      name: 'Target Panel Universe (Narrow)',
      description:
        'Creating a custom universe using a sample dataset of 20,000 rows of data from CCLE',
      file: 'kinome_narrow_CCLE.xlsx',
      document: 'kinome_narrow_CCLE.pdf',
    },
    {
      type: ['upload', 'plot'],
      name: 'Bars & Pie Charts',
      description:
        'Using a general dataset from NIH-funded drugs from 2010-2016 create basic bar and pie charts',
      doi: '10.1073/pnas.1715368115',
      file: 'NIH-supported-drug-approvals.xlsx',
      document: 'NIH-supported-drug-approvals.pdf',
    },
  ],
};

function formatData(columns, row) {
  const formattedRow = {};
  columns.forEach((item) => {
    if (Object.hasOwn(row, item.value)) {
      let value = row[item.value];
      switch (item.value) {
        case 'document':
          value = <DownloadFile path={'/data/upload_examples/'} file={value} />;
          break;
        case 'type':
          value = (
            <Icons>
              {value.map((v: string) => (
                <Icon key={v}>{IconsForTypes[v]}</Icon>
              ))}
            </Icons>
          );
          break;
        case 'file':
          value = <DownloadFile path={'/data/upload_examples/'} file={value} />;
          break;
        case 'doi':
          value = <CopyToClipboard text={value} />;
          break;
        case 'name':
          value = <TextSmallBold color={'primary'}>{value}</TextSmallBold>;
      }
      formattedRow[item.value] = value;
    }
  });
  return formattedRow;
}

const UploadExamples = () => {
  const [rows, setRows] = useState([]);
  const [view, setView] = useState(ViewTypes[0].value);
  const [filter, setFilter] = useState(FilterTypes[0].value);

  useEffect(() => {
    const selectedData =
      filter === 'any'
        ? TableData['rows']
        : TableData['rows'].filter((t) => t.type.includes(filter));
    setRows(selectedData.map((r) => formatData(TableData['columns'], r)));
  }, [filter]);

  return (
    <>
      <Banner>
        <BannerContainer>
          <OptionsSelector
            label={'View:'}
            selected={view}
            Options={ViewTypes}
            onClick={setView}
          />
        </BannerContainer>
        <BannerContainer>
          <OptionsSelector
            label={'Filter:'}
            selected={filter}
            Options={FilterTypes}
            onClick={setFilter}
          />
        </BannerContainer>
        <BannerContainer />
      </Banner>
      <ContentContainer>
        {view === 'table' ? (
          <BasicTable fields={TableData['columns']} rows={rows} debug={false} />
        ) : (
          <TileView tiles={rows} />
        )}
      </ContentContainer>
    </>
  );
};

export default UploadExamples;

const ContentContainer = styled.div`
  display: flex;
  width: calc(100% - 20px);
  padding: 0 10px;
`;

const Banner = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  margin-left: 5px;
  width: 24px;
`;
