import React from 'react'

interface PropsT {
  size?: number;
  color?: string;
  colorHighlight?: string;
}

const IconBugs = ({
    size = 24,
    color = "#F2F2F2",
    colorHighlight = "#DA4B7F"
  }: PropsT) => {
  return (
    <>
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" width={size} height={size}
                 viewBox="0 0 512 512" xmlSpace="preserve">
        <path fill={color} d="M497.2,260.9h-88.6v-30.7c44.6-7.1,78.8-45.8,78.8-92.4v-19.7c0-8.2-6.6-14.8-14.8-14.8s-14.8,6.6-14.8,14.8
        v19.7c0,30.2-21,55.6-49.2,62.3v-13c0-13.6-11-24.6-24.6-24.6h-14.8v-54.2c0-31.1-22.3-57-51.7-62.8l11-24.7
        c3.3-7.5,0-16.2-7.5-19.5c-7.5-3.3-16.2,0-19.5,7.5l-15.8,35.5h-59.6L210.4,8.8c-3.3-7.5-12-10.8-19.5-7.5
        c-7.5,3.3-10.8,12-7.5,19.5l11,24.7c-29.4,5.8-51.7,31.7-51.7,62.8v54.2H128c-13.6,0-24.6,11-24.6,24.6v13
        c-28.2-6.7-49.2-32.1-49.2-62.3v-19.7c0-8.2-6.6-14.8-14.8-14.8s-14.8,6.6-14.8,14.8v19.7c0,46.6,34.2,85.3,78.8,92.4v30.7H14.8
        c-8.2,0-14.8,6.6-14.8,14.8s6.6,14.8,14.8,14.8h88.6v30.7c-44.6,7.1-78.8,45.8-78.8,92.4v19.7c0,8.2,6.6,14.8,14.8,14.8
        s14.8-6.6,14.8-14.8v-19.7c0-30.2,21-55.6,49.2-62.3v8.1c0,84.2,68.5,152.6,152.6,152.6s152.6-68.5,152.6-152.6v-8.1
        c28.2,6.7,49.2,32.1,49.2,62.3v19.7c0,8.2,6.6,14.8,14.8,14.8s14.8-6.6,14.8-14.8v-19.7c0-46.6-34.2-85.3-78.8-92.4v-30.7h88.6
        c8.2,0,14.8-6.6,14.8-14.8S505.4,260.9,497.2,260.9z M172.3,108.3c0-19,15.5-34.5,34.5-34.5h98.5c19,0,34.5,15.5,34.5,34.5v54.2
        H172.3V108.3z M270.8,192h108.3v167.4c0,67.9-55.2,123.1-123.1,123.1s-123.1-55.2-123.1-123.1V192h108.3" />
        <path fill={colorHighlight} d="M103.4,359.4c0,84.2,68.5,152.6,152.6,152.6s152.6-68.5,152.6-152.6l0-172.3c0-13.6-11-24.6-24.6-24.6h-14.8
        l-241.2,0c-13.6,0-24.6,11-24.6,24.6L103.4,359.4z M270.8,192h108.3v167.4c0,67.9-55.2,123.1-123.1,123.1s-123.1-55.2-123.1-123.1
        V192h108.3H270.8z" />
      </svg>
    </>

  )
}

export default IconBugs;