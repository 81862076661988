import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const PinIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4C8.692 4 6 6.71067 6 10.0433C6 14.7787 11.436 19.668 11.6673 19.8733C11.7627 19.958
        11.8813 20 12 20C12.1187 20 12.2373 19.958 12.3327 19.874C12.564 19.668 18 14.7787 18
        10.0433C18 6.71067 15.308 4 12 4Z" stroke={color} strokeWidth="1.5"/>
        <path d="M8.66699 9.99984C8.66699 11.8378 10.1623 13.3332 12.0003 13.3332C13.8383 13.3332 15.3337
        11.8378 15.3337 9.99984C15.3337 8.16184 13.8383 6.6665 12.0003 6.6665C10.1623 6.6665 8.66699
        8.16184 8.66699 9.99984Z" stroke={colorHighlight}/>
      </svg>
    </>
  )
}

export default PinIcon;