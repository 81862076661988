import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useThrottleRequests } from './useThrottleRequests';
import { CompoundUploadQueryT, CompoundUploadRequestT, CompoundUploadResultT, subCompound } from '../type';
import { Compounds } from './api/';
import pLimit from 'p-limit';

interface UseCompoundUploadReturn {
  throttle: {
    loading: boolean;
    values: CompoundUploadRequestT[] | CompoundUploadResultT[];
    errors: Error[];
    percentageLoaded: number;
    totalRequests: number;
  };
  progressMessage: string;
  percentageLoaded: number;
}

/**
 * Custom hook to manage the upload of compounds with throttled requests and chunking.
 *
 * @param token - Authentication token for API requests.
 * @param queries - Array of CompoundUploadQueryT representing each compound to upload.
 * @returns An object containing throttle state, progress message, and percentage loaded.
 */
export default function useCompoundUpload(
  token: string,
  queries: CompoundUploadQueryT[]
): UseCompoundUploadReturn {
  const { throttle, updateThrottle } = useThrottleRequests<CompoundUploadRequestT>();

  const [progressMessage, setProgressMessage] = useState<string>('');
  const [completedCompounds, setCompletedCompounds] = useState<number>(0);
  const [totalCompounds, setTotalCompounds] = useState<number>(0);
  const [isFinalBatch, setIsFinalBatch] = useState<boolean>(false);
  const processedChunks = new Set<number>(); // Track completed chunks

  useEffect(() => {
    if (!queries || queries.length === 0) {
      setProgressMessage('No compounds to upload.');
      setTotalCompounds(0);
      return;
    }

    const uploadCompounds = async () => {
      const validQueries = queries.filter((query) => !_.isEmpty(query.data.cfields));

      if (validQueries.length === 0) {
        setProgressMessage('No valid compounds to upload.');
        setTotalCompounds(0);
        return;
      }

      setTotalCompounds(validQueries.length);
      setCompletedCompounds(0);
      setProgressMessage('Starting upload of compounds...');

      const CHUNK_SIZE = 50;
      const MAX_CONCURRENT_CHUNKS = 3;
      const queryChunks = _.chunk(validQueries, CHUNK_SIZE);
      const totalChunks = queryChunks.length;
      let completedChunks = 0;

      const limit = pLimit(MAX_CONCURRENT_CHUNKS);

      const batchUploadFunctions: (() => Promise<void>)[] = queryChunks.map((chunk, chunkIndex) => {
        return async () => {
          // Skip chunk if already processed
          if (processedChunks.has(chunkIndex)) {
            return;
          }

          try {
            const dataToSend = chunk.map((query) => query.data);

            const response = await Compounds.uploadBatch(token, dataToSend);
            if (response.data.data && Array.isArray(response.data.data.results)) {
              response.data.data.results.forEach((res: subCompound, index: number) => {
                const originalQuery = chunk[index];
                if (res.success) {
                  updateThrottle.requestSucceededWithData({
                    response: res.data,
                    index: originalQuery.rowIndex,
                    statusCode: response.status,
                  } as CompoundUploadRequestT);
                } else {
                  updateThrottle.requestFailedWithError(
                    new Error(res.error || 'Unknown error')
                  );
                }

                setCompletedCompounds((prev) => prev + 1);
              });

              // Update the total requests to include the current batch size
              updateThrottle.adjustTotalRequests(chunk.length);
            } else {
              chunk.forEach(() => {
                updateThrottle.requestFailedWithError(new Error('Invalid response structure'));
                setCompletedCompounds((prev) => prev + 1);
              });
            }
          } catch (error) {
            console.error(`Failed to upload chunk ${chunkIndex + 1}:`, error);
            chunk.forEach(() => {
              updateThrottle.requestFailedWithError(new Error(error.message || 'Unknown error'));
              setCompletedCompounds((prev) => prev + 1);
            });
          } finally {
            // Mark chunk as processed
            processedChunks.add(chunkIndex);

            completedChunks += 1;
            setProgressMessage(`Uploaded ${completedChunks} of ${totalChunks} chunks.`);

            // If this is the last chunk, mark it as final
            if (completedChunks === totalChunks) {
              setIsFinalBatch(true);
            }
          }
        };
      });

      const limitedBatchUploads = batchUploadFunctions.map((fn) => limit(fn));

      await Promise.all(limitedBatchUploads);

      setProgressMessage('All uploads complete.');
    };

    uploadCompounds().then();
  }, [queries, token, updateThrottle]);

  useEffect(() => {
    // Set throttle.loading to false only after the final batch is completed
    if (isFinalBatch) {
      updateThrottle.setLoading(false);
    }
  }, [isFinalBatch, updateThrottle]);

  const percentageLoaded = totalCompounds > 0 ? (completedCompounds / totalCompounds) * 100 : 0;

  return { throttle, progressMessage, percentageLoaded };
}
