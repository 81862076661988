import React from 'react';
import { rgb, hsl } from 'd3-color';
import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { TooltipContainer } from '@dataviz/TooltipContainer';

export const getBarchartTooltip = () => {
  const BarchartTooltip = ({ id, indexValue, color, data }: BarTooltipProps<BarDatum>) => {
    const value = data[id];
    const nodeColor = rgb(color);

    const baseColor = hsl(nodeColor.formatHsl());
    baseColor.l = Math.max(0.7, baseColor.l + 0.2);

    return (
      <TooltipContainer>
        <p style={{ color: baseColor.toString(), fontSize: 14 }}>
          <b>{`${id}: ${indexValue}`}</b>
        </p>
        <p>{'Value: ' + parseFloat(Number(value).toFixed(3))}</p>
      </TooltipContainer>
    );
  };

  return BarchartTooltip;
};
