import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import useRequestStatus from '../hooks/useRequestStatus';
import { useQuery } from '@tanstack/react-query';
import {RequestT} from '../hooks/useRequests';
import AssayProcessMap from '../assay/AssayProcessMap';
import ConsultingProcessMap from '../consulting/ConsultingProcessMap';


type PropsT = {
  requestType: string;
  request: RequestT;
}

const debug = false;
const RequestProcessMap = (props:PropsT) => {
  const {requestType, request} = props;
  const [counts, setCounts] = useState<{[key:string]: number}>({});

  const { getRequestStatus } = useRequestStatus();

  const { isError, error, data: requestStatus } = useQuery({
    queryKey: ['requestStatus', request.id],
    queryFn: () => getRequestStatus(request)
  })
  if (isError) console.error(error.message);

  if (debug) console.log('common/RequestsProcessMap | requestType:', requestType);

  const getComponent = () => {
    switch (requestType) {
      case 'all':
        return null;
      case 'screen':
        return <AssayProcessMap counts={counts} showCounts={false}/>
      case 'consulting':
        return <ConsultingProcessMap counts={counts} showCounts={false}/>
      default:
        return <Error>{`unknown view type ${requestType}`}</Error>;
    }
  }

  useEffect(() => {
    if (requestStatus !== undefined) {
      const counts = {};
      Object.keys(requestStatus).forEach((k) => {
        if (requestStatus[k].complete) counts[k] = 1;
      })
      setCounts(counts);
    }
  }, [requestStatus]);

  if (debug) console.log('counts', counts);

  return (
    <>
      {getComponent()}
    </>
  )
}

export default RequestProcessMap;

const RequestProcessDetailsContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.width};
`;
RequestProcessDetailsContainer.defaultProps = {
  width: 'max-content'
}

const Error = styled.div`
    display: flex;
`;
