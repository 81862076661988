import React from 'react';
import styled from 'styled-components/macro';

interface ExtraProps {
  isActive?: boolean;
}

interface ITab {
  onClick: () => void;
  isActive?: boolean;
  title: string;
}

const Tab = (props: ITab) => {
  const { onClick, isActive, title } = props;

  return (
    <TabDiv isActive={isActive} onClick={onClick}>
      {title}
    </TabDiv>
  );
};

const TabDiv = styled.div<ExtraProps>`
  display: flex;
  padding: 0 10px;
  margin: 0 5px;
  border-radius: 5px;
  font-size: ${(p) => p.theme.sizes.xsmall};
  background-color: ${(p) =>
    p.isActive
      ? p.theme.palette.accentPrimary
      : p.theme.palette.backgroundQuaternary};
  &:hover {
    background-color: ${(p) => p.theme.palette.accentSecondary};
    cursor: pointer;
  }
`;

export default Tab;
