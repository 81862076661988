import React from 'react';
import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { DrcBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface DrcModalEditorProps {
  plot?: PlotConfig<DrcBase>;
  onSave?: (config: PlotConfig<DrcBase>) => void;
}

export const DrcModalEditor = ({ plot, onSave }: DrcModalEditorProps) => {
  const updateDoseColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<DrcBase> = {
      ...plot,
      config: { ...plot.config, doseColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateResponseColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<DrcBase> = {
      ...plot,
      config: { ...plot.config, responseColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateGroupByColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<DrcBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-dose'
        label={'Dose Variable'}
        value={plot.config.doseColumn}
        options={plot.columns}
        onChange={updateDoseColumn}
      />
      <VariableSelect
        id='variable-response'
        label={'Response Variable'}
        value={plot.config.responseColumn}
        options={plot.columns}
        onChange={updateResponseColumn}
      />
      <VariableSelect
        id='variable-groupBy'
        label={'Group by'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateGroupByColumn}
      />
    </Stack>
  );
};
