import React from "react";
import styled from "styled-components/macro";
import {MailOutline} from "@mui/icons-material";
import {Tooltip} from "@mui/material";

interface OpenEmailI {
  email: string;
  label?: string;
}
const OpenEmail = (props:OpenEmailI) => {
  const handleClick = (email:string) => {
    let link=document.createElement('a');
    link.href = 'mailto:' + email;
    link.click();
    link.remove();
  }

  return(
    <StyledTooltip
      title={'Open Email to User'}
      arrow
      placement={'bottom-end'}
      >
     <EmailLink onClick={()=>handleClick(props.email)}>
       <EmailIcon />
     </EmailLink>
    </StyledTooltip>
  )
}

export default OpenEmail;

const StyledTooltip = styled(Tooltip)`
  display: flex;
`;

const EmailLink = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  filter: grayscale(0.75);
  :hover {
    filter: grayscale(0.0);
  }
`;

const EmailIcon = styled(MailOutline)`
  display: flex;
  color: ${(p) => p.theme.palette.accentPrimary};
`;
