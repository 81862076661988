import React, {ReactNode} from 'react';
import styled from 'styled-components/macro';

interface PropsI {
  id: string,
  value?: string,
  fontSize?: number,
  checked?: boolean,
  children: ReactNode
}

const RadioButton = (props:PropsI) => {
  const { id, value = '', checked = false, fontSize=16, children } = props;
  function handleChange() {}

  return (
    <Container>
      <Input
        type='radio'
        id={`radiobutton-${id}`}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <Label htmlFor={`radiobutton-${id}`} fontSize={fontSize}>{children}</Label>
    </Container>
  );
};

export default RadioButton;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Label = styled.label<{fontSize: number}>`
  display: inline-block;
  width: 100%;
  padding-left: 1.2rem;
  color: ${(p) => p.theme.palette.titleSecondary};
  font-size: ${(p) => p.fontSize}px;
  pointer-events: all;
  cursor: pointer;

  &::before {
    position: absolute;
    left: 0.1rem;
    top: 30%;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.4rem;
    border: 2px solid ${(p) => p.theme.palette.textPrimary};
    border-radius: 50%;
    content: '';
  }
`;

const Input = styled.input`
  display: none;
  margin: 0 ${(p) => p.theme.sizes.xxsmall} 1px 0;
  pointer-events: all;

  &:checked + label::before {
    background-color: ${(p) => p.theme.palette.textPrimary};
  }
`;
