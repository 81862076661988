import {authClient} from "@utils/api/base";
import { handleError } from '@utils/misc';
import {AxiosRequestConfig, AxiosResponse} from "axios";
import useCognito from "@as_core/account/useCognito";
import {DataSetT} from "./types";

export const Universes = {
  all: (token: string, config) => authClient(token).get(`/cmp/universe/`, config),
  get: (token: string, uuid: string, config) => authClient(token).get(`/cmp/universe/${uuid}`, config),
  delete: (token: string, uuid: string) => authClient(token).delete(`/cmp/universe/${uuid}`),
  getNetwork: (token: string, uuid: string, config) => authClient(token).get(`/cmp/universe/${uuid}/graph`, config),
  getImages: (token: string, uuid: string, config) => authClient(token).get(`/cmp/universe/${uuid}/images`, config)
};

type Dictionary<T> = {
  [key: string]: T;
}

export type ImagesT = {
  [key: string]: {
    light: string;
    dark: string;
  };
}

export type ResponseT<T> = {
  data: T
  errors: string[]
}

export type UniverseT = {
  _id: string;
  uuid: string;
  title: string;
  createdOn: string;
  modifiedOn: string;
  userId: string;
  status: string;
  settings: Dictionary<string>;
  details: Dictionary<string>;
  dataReference: Dictionary<string>,
  response_type: string;
  data_uuid: string;
}

const universeDataPath = "/data/universe/"

const useUniverses = () => {
  const { getToken } = useCognito();

  const getAll = async (): Promise<UniverseT[]> => {
    let resp: AxiosResponse<ResponseT<UniverseT[]>>;
    try {
      resp = await Universes.all(getToken(), null);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data.data;
  };

  const getInfo = async (uuid: string, params: { [key: string]: string }): Promise<UniverseT> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<ResponseT<UniverseT>>;
    try {
      resp = await Universes.get(getToken(), uuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data.data;
  };

  const getNetwork = async (uuid: string, params: object ): Promise<ResponseT<UniverseT>> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<ResponseT<UniverseT>>;
    try {
      resp = await Universes.getNetwork(getToken(), uuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      console.error('Universes.getNetwork ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const getNetworkLocal = async ( universeType: string ): Promise<ResponseT<DataSetT>> => {
    const jsonPath = universeDataPath + universeType + '/network.json';
    let network:DataSetT;
    try {
      const response = await fetch(jsonPath);
      network = await response.json();
    } catch (err) {
      handleError(err);
      return { data: null, errors: ['error fetching file' + err]};
    }
    return { data: network, errors: [] };
  };

  const getImages = async ( uuid: string, params: object ): Promise<ResponseT<ImagesT>> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<ResponseT<ImagesT>>;
    try {
      resp = await Universes.getImages(getToken(), uuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      console.error('Universes.getImages ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  // retrieve the images from local directory of code repository
  const getImagesLocal = async ( universeType: string ): Promise<ResponseT<ImagesT>> => {
    const jsonPath = universeDataPath + universeType + '/images.json';
    let images: ImagesT;
    try {
      const response = await fetch(jsonPath);
      images = await response.json();
    } catch (err) {
      handleError(err);
      return { data: images, errors: ['error fetching file' + err]};
    }
    return { data: images, errors: [] };
  };

  // TODO -- fix this verses the API
  const deleteUniverse = async (uuid:string): Promise<null> => {

    try {
      const resp = await Universes.delete(getToken(), uuid);
      if (resp.status !== 200) {
        console.error('deleteUniverse api call status !== 200: ', resp.status);
      }
    } catch (err) {
      handleError(err);
      return null;
    }
    return null;
  };

  return {
    getAll,
    getInfo,
    getNetwork,
    getNetworkLocal,
    getImages,
    getImagesLocal,
    deleteUniverse
  };
};

export default useUniverses;
