import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import AdminUserManagement from '@subApps/admin/AdminUserManagement';
import AdminRepoManagement from '@subApps/admin/AdminRepoManagement';
import UserInsights from "@subApps/admin/UserInsights";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { isUserAnAdmin } from '@as_core/account/utils/admin';
import {useParams} from "react-router-dom";
import SettingsInfo from '@subApps/admin/SettingsInfo';

// ####################################################################
type actionT = {
  key: string;
  label: string;
  component?: React.ReactNode;
  icon?: React.ReactNode;
};

const menuActions: {[key: string]: actionT} = {
  'users': {
    key: 'users', label: 'User Management', icon: <ManageAccountsOutlinedIcon />, component: <AdminUserManagement />
  },
  'repos': {
    key: 'repos', label: 'Repository Management', icon: <GroupsOutlinedIcon />, component: <AdminRepoManagement />
  },
  'insights': {
    key: 'insights', label: 'User Insights', icon: <GroupsOutlinedIcon />, component: <UserInsights />
  },
  'settings': {
    key: 'settings', label: 'API Settings Information', icon: <GroupsOutlinedIcon />, component: <SettingsInfo />
  }
};

const debug = false;
const PgAdmin = () => {

  const params = useParams();
  const { tab } = params;
  const { user, setUser } = useContext(UserContext);

  const getComponent = (tab: string) => {
    if (debug) console.log('tab', tab);
    if (Object.hasOwn(menuActions, tab)) {
      return menuActions[tab].component;
    }
    return (
      <ErrorContainer>
        menu {tab} appears not to be implemented.
      </ErrorContainer>
    );
  }

  if (user.subApp !== 'Admin') {
    setUser((prev) => ({
      ...prev, subApp: 'Admin'
    }));
  }

  if (debug) console.log('PgAdmin | user:', user);

  return (
    <PageContainer title='Admin'>
      <AdminPanel>
        {isUserAnAdmin(user) ?
          getComponent(tab)
        :
          <ErrorContainer>
            It appears that you landed on a page you are not authorized to
            access.
          </ErrorContainer>
        }
      </AdminPanel>
    </PageContainer>
  );
};

export default PgAdmin;

const AdminPanel = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  width: calc(100vw - 67px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ErrorContainer = styled.div`
  align-items: center;
`;

