import React from 'react';
import { Cell } from '../../shared/RowElements';

interface ResultCellT {
  library: string;
  present: Record<string, boolean | string | boolean | undefined>;
}

const ResultCell = (props: ResultCellT) => {
  const { library, present } = props;

  return (
    <Cell>
      {
        library === 'user' ?
            present ?
              'Update' // Display for match in user library
            :
              'Create' // Display for NO match in user library
          : present ?
            'Link' // Display for match in external library
          :
            '-' // Display for NO match in external library
      }
    </Cell>
  );
};

export default ResultCell;
