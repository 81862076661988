import React, { memo, useContext } from 'react';
import {
  BarBase,
  BoxplotBase,
  DrcBase,
  HistogramBase,
  LineBase,
  PieBase,
  PlotBase,
  PlotConfig,
  PlotProps,
  ScatterBase,
  VolcanoBase,
} from '../plot.types';
import { TitleCard } from './cards/title-card/TitleCard';
import { ScatterCard } from './cards/scatter-card/ScatterCard';
import { BarCard } from './cards/bar-card/BarCard';
import { PlotContext } from '../plot-panel/plot.context';
import { BoxplotCard } from './cards/boxplot-card/BoxplotCard';
import { PieCard } from './cards/pie-card/PieCard';
import { Box } from '@mui/system';
import { VolcanoCard } from './cards/volcano-card/VolcanoCard';
import { LineCard } from './cards/line-card/LineCard';
import { HistogramCard } from './cards/histogram-card/HistogramCard';
import { DrcCard } from './cards/drc-card/DRCCard';

export const ConfigPanel = memo(({ onChange }: PlotProps<PlotBase>) => {
  const { plot } = useContext(PlotContext);

  const updateConfig = (update) => {
    onChange && onChange(update);
  };

  let editorCards;

  if (plot.config.base === 'boxplot') {
    editorCards = (
      <BoxplotCard onChange={onChange} plot={plot as PlotConfig<BoxplotBase>} />
    );
  } else if (plot.config.base === 'volcano') {
    editorCards = (
      <VolcanoCard onChange={onChange} plot={plot as PlotConfig<VolcanoBase>} />
    );
  } else if (plot.config.base === 'pie') {
    editorCards = (
      <PieCard onChange={onChange} plot={plot as PlotConfig<PieBase>} />
    );
  } else if (plot.config.base === 'bar') {
    editorCards = (
      <BarCard onChange={onChange} plot={plot as PlotConfig<BarBase>} />
    );
  } else if (plot.config.base === 'drc') {
    editorCards = (
      <DrcCard onChange={onChange} plot={plot as PlotConfig<DrcBase>} />
    );
  } else if (plot.config.base === 'histogram') {
    editorCards = (
      <HistogramCard
        onChange={onChange}
        plot={plot as PlotConfig<HistogramBase>}
      />
    );
  } else if (plot.config.base === 'line') {
    editorCards = (
      <LineCard onChange={onChange} plot={plot as PlotConfig<LineBase>} />
    );
  } else if (plot.config.base === 'scatter') {
    editorCards = (
      <ScatterCard onChange={onChange} plot={plot as PlotConfig<ScatterBase>} />
    );
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      width={'100%'}
      marginBottom={4}
      gap={2}
    >
      <TitleCard plot={plot} onChange={updateConfig} />
      {editorCards}
    </Box>
  );
});

// Adding display name for debugging and avoiding the error
ConfigPanel.displayName = 'ConfigPanel';
