import React from 'react';
import { segmentImages } from '@as_core/account/utils/images';
import classes from './SegmentImage.module.scss';
import { Tooltip } from '@mui/material';

interface PropsI {
  data: string;
}

const SegmentImage = (props:PropsI) => {
  const { data } = props;
  if (!data) return <></>;
  const values = data.split(';');

  return (
    <div className={classes['segment-images']}>
      {values.map((value) => (
        <Tooltip key={value} title={value} placement='top' arrow>
          <img
            key={value}
            className={classes['segment-images__element']}
            src={segmentImages[value]}
            height='50'
            alt={value}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default SegmentImage;
