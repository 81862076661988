import React from 'react';
import styled from 'styled-components/macro';
import {AddCircle, RemoveCircle} from "@mui/icons-material";

export type SelectBoxDetailsT = {xClick: number, yClick: number, x1: number, y1: number, x2: number, y2: number}

interface PropsI {
  open: boolean,
  position: SelectBoxDetailsT,
  state?: 'add' | 'remove',
}


const GraphSelector = (props:PropsI) => {
  const {
    open,
    position,
    state = 'add'
  } = props;

  if (!open) return(<></>);

  return (
    <div>
      <SelectBoxOutline position={position}>
        {state === 'add' ? <StyledAddIcon /> : <StyledRemoveIcon />}
      </SelectBoxOutline>
    </div>
  );
};
export default GraphSelector;

const SelectBoxOutline = styled.div<{position: {x1: number, y1: number, x2: number, y2: number}}>`
  z-index: 10;
  position: absolute;
  border: 2px dashed  ${(p) => p.theme.palette.accentSecondary};
  box-sizing: border-box;
  height: ${(p) => p.position.y2 - p.position.y1}px;
  width: ${(p) => p.position.x2 - p.position.x1}px;
  top: ${(p) => p.position.y1 - 100 - 10}px;
  left: ${(p) => p.position.x1 - 50 - 5}px;
`;

const StyledAddIcon = styled(AddCircle)`
  position: relative;
  top: -9px;
  left: -9px;
  color:  ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const StyledRemoveIcon = styled(RemoveCircle)`
  position: relative;
  top: -10px;
  left: -10px;
  color:  ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
