// generate the products as a carousel if > 3 -- otherwise single.
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {TextRegular, TextSmall, TextTiny, TextSpace } from '@as_core/elements/textStyles';
import { Logo } from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import TextButton from '@components/controls/TextButton/TextButton';
import {ProductT} from "@business/products/products.types";

interface PropsI {
  product: ProductT;
  onAddToCart: (product: ProductT) => void;
}

const debug = false;
const SubscriptionProductCard = (props:PropsI) => {
  const { product, onAddToCart } = props;
  const [quantity] = useState<number>(1);

  if (debug) console.log('ProductCard | product:', props.product);

  const handleAdd = () => {
    if (quantity) onAddToCart(product);
  }

  return(
   <CardContainer>
     <Row><Logo><img src={APP_CONFIG.logo} alt='logo' /></Logo></Row>
     <Row><TextSmall color={'primary'}>{product?.productName}</TextSmall></Row>
     <Row width={'80%'}>
       <TextTiny color={'secondary'}>{product?.productDescription}</TextTiny></Row>
     <Row>
       <TextTiny color={'accent'}>Catalog #:</TextTiny>
       <TextTiny>{product?.productCatalogNumber}</TextTiny>
     </Row>
     <Row>
       <TextRegular color={'accent'}>Price:</TextRegular>
       <TextSpace />
       <TextRegular color={'primary'}>{product?.currencyType}
         {product?.unitPrice}</TextRegular>
     </Row>
     <Row>
       <TextButton label={'Add to Cart'} height={28} onClick={handleAdd} />
     </Row>
   </CardContainer>
  )
}

export default SubscriptionProductCard;

const CardContainer = styled.div`
  display: flex;
  border-radius: 15px;
  width: 250px;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary };
  background-color: ${(p) => p.theme.palette.backgroundTertiary };
`;

const Row = styled.div<{width?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px 0;
  ${(p) => p.width && `width: ${p.width};`}
`;