import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import HoverOverlay from '../HoverOverlay';
import useFileImport from '../../useFileImport';
import { Cell, Row } from '../RowElements';
import { RootState } from "@src/store";
import { useSelector } from 'react-redux';

interface MatchedColumnRowT {
  columnIdx: number;
  fieldName: string;
  automaticMatch?: string;
  onClick: (idx: number) => void;
}

const MatchedColumnRow = ({ columnIdx, fieldName, automaticMatch, onClick }: MatchedColumnRowT) => {
  const { context: { columns } } = useFileImport();
  const { isGlobalChecked: isGlobalChecked } = useSelector((state: RootState) => state.globalCheckbox);
  const [, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(isGlobalChecked);
  }, [isGlobalChecked]);

  return (
    <Row key={columnIdx}>

      <StyledCell parent={"compoundSet"}>{columns[columnIdx].colName}</StyledCell>
      <StyledCell parent={"compoundSet"}>{automaticMatch}</StyledCell>
      <StyledCell>
        <HoverOverlay onClick={() => onClick(columnIdx)}>
          {columns[columnIdx].fieldFilter === 'Identity'
            ? fieldName + ' (Identity)'
            : fieldName}
        </HoverOverlay>
      </StyledCell>
      
    </Row>
  );
};

export default MatchedColumnRow;

const StyledCell = styled(Cell) <{ parent?: string }>`
  color: ${(p) => p.theme.palette.accentSecondary};
  font-weight: normal;
  cursor: pointer;
  max-width: 300px;
`;
