import React from 'react';
import styled from 'styled-components/macro';
import UserMessages, {MessageItem} from "@as_core/elements/UserMessages";
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";
import {FieldsTypeT} from "@subApps/admin/components/ObjectDetails";
import BasicTable from "@as_core/tables/BasicTable";
import {getTableRows} from "@subApps/admin/components/shared";
import {UserEventsItemT} from "@utils/useUserAppInfo";

const columns: FieldsTypeT[] = [
  { value: 'authId', label: 'Id', width: 260 },
  { value: 'authEmail', label: 'Email', width: 320 },
  { value: 'event', label: 'Event', width: 90 },
  { value: 'event_type', label: 'Event Type', width: 100 },
  { value: 'time', label: 'Time', type: 'datetime', width: 180 },
];


interface UserEventsDetailI {
  userEvents: UserEventsItemT[];
  backToList: ()=> void;
}

const debug = false;
const UserEventsDetail = (props: UserEventsDetailI) => {
  const { userEvents } = props;
  if (debug) console.log('RepoDetail | userEvents', userEvents);

  return(
    <UserEventsContainer>
      <Header>
        <MenuButton
          text={'Back to user list'}
          onClick={()=>props.backToList()}
          icon={<BackArrowIcon size={32} />}
          width={40}
          height={40}
        />
      </Header>

      { props.userEvents.length ?
        <ScrollContainer>
          <BasicTable
            key={'user-table'}
            fields={columns}
            rows={getTableRows(userEvents, columns)}
          />
        </ScrollContainer>
      :
      <UserMessages height='max-content' messages={[
        <MessageItem key={'no-user-events-message'}>
          no user events
        </MessageItem>
      ]}/>
    }
    </UserEventsContainer>
  );
};
export default UserEventsDetail;

const UserEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  min-width: 500px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 500px;
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 1030px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
`;
