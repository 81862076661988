import { AxiosRequestConfig } from 'axios';
import { handleError } from '@utils/misc';
import useCognito from '@as_core/account/useCognito';
import { authCoreClient, ResponseT } from '@utils/api/base';

export type UserRegistrationDataT = {
  email: string;
  name: {
    first: string;
    last: string;
    middle: string | null;
  };
  address: {
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    billing: boolean;
  };
  organization: {
    type: string;
    name: string;
  };
  system: string;
  cognito?: string;
  authId?: string;
  created: string;
  _id: string;
}

interface IUserRecord {
  _id: string;
  user: UserRegistrationDataT;
}

export const UserRegistration = {
  get: (token: string, config: AxiosRequestConfig) =>
    authCoreClient(token).get('/user/', config),
  create: (token: string, data: AxiosRequestConfig) =>
    authCoreClient(token).post('/user/create', data),
  update: (token: string, data: AxiosRequestConfig) =>
    authCoreClient(token).post('/user/update', data),
  delete: (token: string, data: AxiosRequestConfig) =>
    authCoreClient(token).post('/user/delete', data),
};

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
const useUserRegistrationInfo = () => {
  const { getToken } = useCognito();
  const createUserRegistrationInfo = async (userInfo: {
    [key: string]: string;
  }): Promise<ResponseT<IUserRecord[]>> => {
    const config: AxiosRequestConfig = userInfo;
    let resp;

    try {
      resp = await UserRegistration.create(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('createUserRegistration ERRORS', resp.data.errors);
    }

    console.log('useUserRegistrationInfo', resp.data);
    return resp.data;
  };

  // as this is used by AuthWrapper -- need to explicitly send token
  const getUserRegistrationInfo = async (
    token: string,
    params: Record<string, string>
  ): Promise<UserRegistrationDataT> => {
    const config: AxiosRequestConfig = { params };

    try {
      const resp = await UserRegistration.get(token, config);

      if (resp.status !== 200) {
        throw new Error(`${resp.status} ${resp.statusText}`);
      }

      const { data: apiResponse } = resp;

      if (debug)
        console.log('getUserRegistrationInfo | apiResponse', apiResponse);

      if (apiResponse.errors?.length) {
        if (
          apiResponse.errors.length === 1 &&
          apiResponse.errors[0].includes(
            'not registered in the database -- not authorized'
          )
        ) {
          throw new Error('user not registered');
        } else {
          console.error('getUserRegistration ERRORS', apiResponse.errors);
          throw new Error('API returned errors');
        }
      }

      if (apiResponse.data.length !== 1) {
        throw new Error('Unexpected API data array length');
      }

      return apiResponse.data[0];
    } catch (error) {
      console.error('getUserRegistrationInfo Error:', error);
      throw error;
    }
  };

  const deleteUserRegistrationInfo = async (
    params
  ): Promise<ResponseT<IUserRecord[]>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await UserRegistration.delete(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deleteUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const updateUserRegistrationInfo = async (
    userinfo
  ): Promise<ResponseT<IUserRecord[]>> => {
    const config: AxiosRequestConfig = userinfo;
    let resp;

    try {
      resp = await UserRegistration.update(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  return {
    getUserRegistrationInfo,
    createUserRegistrationInfo,
    updateUserRegistrationInfo,
    deleteUserRegistrationInfo,
  };
};
export default useUserRegistrationInfo;
