import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import SelectSmall from '@components/dataAnalytics/SelectSmall';
import AutocompleteCusto from '@components/dataAnalytics/AutocompleteCusto';
import AutocompleteCustoMulti from '@components/dataAnalytics/AutocompleteCustoMulti';
import SelectedDataShownAgg from '@components/dataAnalytics/SelectedDataShownAgg';
import './AggregateAndCalculate.css'; // Import the CSS file
import EnhancedTable from '@components/dataAnalytics/EnhancedTable';

type OptionType = { label: string; value: string };
import useCompounds from '@components/compounds/useCompounds';

type DynamicComponent =
  | { id: number; type: 'filter'; data?: DataItem[]; isSaved?: boolean }
  | { id: number; type: 'table'; data: DataItem[]; relatedFilters: string[] }
  | {
      id: number;
      type: 'allFilters';
      relatedFilters: string[];
      data?: DataItem[];
      agginfo?: Agginfo[];
    }
  | {
      id: number;
      type: 'aggregateAndCalculate';
      columns: Column[];
      relatedFilters: string[];
      data?: DataItem[];
      agginfo?: Agginfo[];
    };

interface AggregateAndCalculateProps {
  columns: Column[];
  handleClose: () => void;
  id: string;
  relatedFilters: string[];
  dynamicComponents: DynamicComponent[];
  dataa?: DataItem[];
  agginfo?: Agginfo;
}

const defaultColumn: Column = {
  id: 'casrn',
  numeric: false,
  disablePadding: false,
  label: 'CASRN',
};

interface DataItem {
  _id: Record<string, unknown> | unknown;
  [key: string]: unknown;
}

interface Agginfo {
  selectedSingleColumn: string;
  selectedColumnsForAggregation: string[];
  selectedFunction: string;
  id: string;
}

export interface Column {
  id?: string;
  numeric?: boolean;
  disablePadding?: boolean;
  label?: string;
  value?: string;
}

const aggregationFunctions = [
  { value: 'sum', label: 'Sum' },
  { value: 'avg', label: 'Average' },
  { value: 'min', label: 'Minimum' },
  { value: 'max', label: 'Maximum' },
  { value: 'count', label: 'Count' },
];

const AggregateAndCalculate: React.FC<AggregateAndCalculateProps> = ({
  columns,
  handleClose,
  id,
  relatedFilters,
  dynamicComponents,
  dataa,
  agginfo,
}) => {
  const [selectedFunction, setSelectedFunction] = useState<string>('avg');
  const [selectedSingleColumn, setSelectedSingleColumn] =
    useState<Column>(defaultColumn);
  const [selectedColumnsForAggregation, setSelectedColumnsForAggregation] =
    useState<Column[]>([]);
  const [data, setData] = useState<DataItem[]>([]);
  const [openSaveFilter, setOpenSaveFilter] = useState<boolean>(false);
  const [, setIsLoading] = useState<boolean>(false);
  const [, setIsSaved] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const { saveAggregate } = useCompounds();

  const handleEditAgg = () => {
    setEdit(true);
    setOpenSaveFilter(false); // Set to false to return to the filter setup view
    setIsLoading(false); // Ensure loading is not shown during edit
    setIsSaved(false); // Reset saved state
  };

  const handleSingleColumnSelect = (column: Column) => {
    setSelectedSingleColumn(column);
  };

  const handleColumnsForAggregationSelect = (selectedColumns: OptionType[]) => {
    const columnsForAggregation = selectedColumns.map((option) => {
      const column = columns.find((col) => col.id === option.value);
      return column || defaultColumn;
    });
    setSelectedColumnsForAggregation(columnsForAggregation);
  };

  useEffect(() => {
    if (dataa) {
      setData(dataa);
    }

    if (agginfo) {
      // Assuming agginfo.selectedSingleColumn is the 'id' of the column
      const columnObject =
        columns.find((column) => column.id === agginfo.selectedSingleColumn) ||
        defaultColumn;

      setSelectedSingleColumn(columnObject);
      setSelectedFunction(agginfo.selectedFunction);
      setSelectedColumnsForAggregation(
        agginfo.selectedColumnsForAggregation.map(
          (colId) =>
            columns.find((column) => column.id === colId) || defaultColumn
        )
      );
      setOpenSaveFilter(true);
    }
  }, [agginfo, columns, dataa]);

  const handleFunctionChange = (event: SelectChangeEvent) => {
    setSelectedFunction(event.target.value as string);
  };

  const [transformedData, setTransformedData] = useState<
    Record<string, unknown>[]
  >([]);
  const [generatedColumns, setGeneratedColumns] = useState<Column[]>([]);

  useEffect(() => {
    const newColumns = generateColumns(data);
    const newData = transformData(data);
    setGeneratedColumns(newColumns);
    setTransformedData(newData);
    setIsLoading(false); // Set loading to false after data is transformed
  }, [data]);

  const generateColumns = (data: DataItem[]): Column[] => {
    if (data.length === 0) return [];

    const firstItem = data[0];
    let columns: Column[] = [];

    Object.keys(firstItem).forEach((key) => {
      if (
        key === '_id' &&
        typeof firstItem[key] === 'object' &&
        firstItem[key] !== null
      ) {
        Object.keys(firstItem[key] as Record<string, unknown>).forEach(
          (nestedKey) => {
            columns.push({
              id: nestedKey,
              numeric: false,
              disablePadding: false,
              label: nestedKey.replace(/_/g, ' ').toUpperCase(),
            });
          }
        );
      } else {
        columns.push({
          id: key,
          numeric: typeof firstItem[key] === 'number',
          disablePadding: false,
          label: key.replace(/_/g, ' ').toUpperCase(),
        });
      }
    });

    return columns;
  };

  const transformData = (data: DataItem[]): Record<string, unknown>[] => {
    return data
      .map((item) => {
        let transformedItem: Record<string, unknown> = {};

        Object.keys(item).forEach((key) => {
          const value = item[key];

          // Check if the key is '_id' and the value is a non-null object
          if (key === '_id' && typeof value === 'object' && value !== null) {
            Object.keys(value as Record<string, unknown>).forEach(
              (nestedKey) => {
                // Only add the nestedKey to transformedItem if it's not null
                const nestedValue = value[nestedKey];
                if (nestedValue !== null) {
                  transformedItem[nestedKey] = nestedValue;
                }
              }
            );
          } else if (value !== null) {
            // For top-level keys, only add if the value is not null
            transformedItem[key] = value;
          }
        });

        return transformedItem;
      })
      .filter((item) => Object.keys(item).length > 0); // Filter out any items that ended up empty after removing nulls
  };

  const handleSave = () => {
    setOpenSaveFilter(true);
    setIsLoading(true);

    const payload = {
      selectedSingleColumn: selectedSingleColumn?.id || '',
      selectedColumnsForAggregation: selectedColumnsForAggregation.map(
        (c) => c.id
      ),
      selectedFunction: selectedFunction || '',
      id: id,
    };

    saveAggregate(payload)
      .then((data: DataItem[]) => {
        setData(data);
        setOpenSaveFilter(true);
        setEdit(false); // Reset edit state to false when saved
        setIsLoading(false);
      })
      .catch((error: Error) => {
        console.error('There was an error!', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Check if the necessary data for making the post request is complete and valid
    if (
      selectedSingleColumn?.id &&
      selectedColumnsForAggregation.length > 0 &&
      selectedFunction &&
      id
    ) {
      setIsLoading(true); // Set loading to true before the request

      const payload = {
        selectedSingleColumn: selectedSingleColumn.id,
        selectedColumnsForAggregation: selectedColumnsForAggregation.map(
          (c) => c.id
        ),
        selectedFunction: selectedFunction,
        id: id,
      };

      saveAggregate(payload)
        .then((data: DataItem[]) => {
          setData(data);
          setOpenSaveFilter(true);
          setEdit(false);
          setIsLoading(false);
        })
        .catch((error: Error) => {
          console.error(
            'There was an error performing the aggregation:',
            error
          );
          setIsLoading(false);
        });
    } else {
      console.log('Payload data is incomplete, skipping the aggregation call.');
    }
  }, [relatedFilters, dynamicComponents]);

  return (
    <div className='filtrecontainer'>
      <div className='flex-space-between'>
        <Typography variant='h6' component='h6' className='titletexte'>
          Aggregate And Calculate
        </Typography>
        <CloseIcon
          className='closeIcon'
          fontSize='small'
          style={{ color: '#F2F3F4' }}
          onClick={handleClose}
        />
      </div>
      {!openSaveFilter || edit ? (
        <div>
          <div className='columnflex'>
            <AutocompleteCusto
              options={columns.map((column) => ({
                label: column.label,
                value: column.id,
              }))}
              placeholder='Select a column'
              onColumnSelect={handleSingleColumnSelect}
              value={selectedSingleColumn}
            />
            <Tooltip title='Select the column on which the aggregation function will be applied.'>
              <InfoIcon className='info-icon' />
            </Tooltip>
            <SelectSmall
              menuItems={aggregationFunctions}
              value={selectedFunction}
              onChange={handleFunctionChange}
              size='small'
              style={{ minWidth: '200px', marginTop: '-30px' }}
              maxwidth='300px'
            />
            <Tooltip title='Choose an aggregation function like Sum, Average, Min, etc., to apply to the selected column.'>
              <InfoIcon className='info-icon' />
            </Tooltip>
            <AutocompleteCustoMulti
              options={columns.map((column) => ({
                label: column.label,
                value: column.id,
              }))}
              placeholder='Select columns for aggregation'
              onColumnSelect={handleColumnsForAggregationSelect}
              value={selectedColumnsForAggregation.map((column) => ({
                label: column.label,
                value: column.id,
              }))}
            />
            <AutocompleteCustoMulti
              options={columns.map((column) => ({
                label: column.label,
                value: column.id,
              }))}
              placeholder='Select columns for aggregation'
              onColumnSelect={handleColumnsForAggregationSelect}
              value={selectedColumnsForAggregation.map((column) => ({
                label: column.label,
                value: column.id,
              }))}
            />
            <Tooltip title='Select one or more columns to group the data. These are the columns by which the data will be aggregated.'>
              <InfoIcon className='info-icon' />
            </Tooltip>
          </div>

          <div className='save-cancel-buttons'>
            <Button
              variant='contained'
              className='button-cancel'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              className='button-save'
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <SelectedDataShownAgg
            selectedSingleColumn={selectedSingleColumn?.label || ''}
            selectedColumnsForAggregation={selectedColumnsForAggregation.map(
              (c) => c.label
            )}
            selectedFunction={selectedFunction}
            onEditFilter={handleEditAgg}
          />
          {transformedData.length === 0 ? (
            <div
              style={{ textAlign: 'center', marginTop: '20px', color: 'white' }}
            >
              No Result Found
            </div>
          ) : (
            <div className='array '>
              <EnhancedTable
                data={transformedData}
                columns={generatedColumns}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(AggregateAndCalculate);
