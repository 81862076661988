import React from 'react';
import { rgb, hsl } from 'd3-color';
import { ScatterPlotDatum, ScatterPlotTooltipProps } from '@nivo/scatterplot';
import { TooltipContainer } from '@dataviz/TooltipContainer';

interface GetScatterplotTooltipProps {
  innerWidth?: number;
  innerHeight?: number;
}

interface CustomScatterPlotDatum extends ScatterPlotDatum {
  size: number;
  xAxisName: string;
  yAxisName: string;
  name: string;
}


export const getScatterplotTooltip = ({
  innerWidth,
  innerHeight,
}: GetScatterplotTooltipProps) => {
  const ScatterplotTooltip: React.FC<
    ScatterPlotTooltipProps<CustomScatterPlotDatum>
  > = ({ node }) => {
    const { color, formattedX, formattedY, data } = node;
    const pointX = node.x;
    const pointY = node.y;
    // Calculate the center of the chart
    const centerX = innerWidth / 2;
    const centerY = innerHeight / 2;

    // Define tooltip position based on direction relative to center
    let tooltipStyle: React.CSSProperties = {};

    if (pointX < centerX && pointY < centerY) {
      tooltipStyle = { transform: 'translate(10%, 10%)', textAlign: 'left' };
    } else if (pointX >= centerX && pointY < centerY) {
      tooltipStyle = { transform: 'translate(-110%, 10%)', textAlign: 'right' };
    } else if (pointX < centerX && pointY >= centerY) {
      tooltipStyle = { transform: 'translate(10%, -120%)', textAlign: 'left' };
    } else if (pointX >= centerX && pointY >= centerY) {
      tooltipStyle = { transform: 'translate(-110%, -120%)', textAlign: 'right' };
    }

    const baseColor = hsl(rgb(color).formatHsl());
    baseColor.l = Math.max(0.7, baseColor.l + 0.2);
    return (
      <TooltipContainer color={baseColor.toString()} customStyle={tooltipStyle}>
        <p style={{ color: baseColor.toString(), fontSize: 14 }}>
          <b>{data.name || 'N/A'}</b>
        </p>
        <p>
          {`${data.xAxisName || 'X-Axis'}: ` +
            (isNaN(Number(formattedX)) ? formattedX : parseFloat(Number(formattedX).toFixed(3)))}
        </p>
        <p>
          {`${data.yAxisName || 'Y-Axis'}: ` +
            (isNaN(Number(formattedY)) ? formattedY : parseFloat(Number(formattedY).toFixed(3)))}
        </p>
        {node.data.size !== undefined && (
          <p>
            {'size: ' +
              (isNaN(Number(node.data.size)) ? node.data.size : parseFloat(Number(node.data.size).toFixed(3)))}
          </p>
        )}
      </TooltipContainer>
    );
  };

  return ScatterplotTooltip;
};
