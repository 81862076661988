import React from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import "./SelectedDataShownAgg.css"; // Adjust the CSS to match SelectedDataShown
import Button from "@mui/material/Button";
interface SelectedDataShownAggProps {
  selectedSingleColumn: string;
  selectedColumnsForAggregation: string[];
  selectedFunction: string;
  onEditFilter: () => void;
}

const SelectedDataShownAgg: React.FC<SelectedDataShownAggProps> = ({
  selectedSingleColumn,
  selectedColumnsForAggregation,
  selectedFunction,
  onEditFilter,
}) => {
  return (
    <Box>
      <div className="selected-data-shown-agg">
        <Box className="group-by-section">
          <span className="filter-item">select</span>

          {selectedColumnsForAggregation.map((column, index) => (
            <Chip
              key={index}
              label={column}
              className="date-chip"
              color="primary"
            />
          ))}
        </Box>

        <Box className="group-by-section">
          <Chip
            label={`${selectedFunction}(${selectedSingleColumn})`}
            color="primary"
            className="date-chip"
          />

          <span className="filter-item">from data</span>
        </Box>

        <Box className="group-by-section">
          <span className="filter-item"> Group by:</span>

          {selectedColumnsForAggregation.map((column, index) => (
            <Chip
              key={index}
              label={column}
              className="filter-icon"
              color="primary"
            />
          ))}
        </Box>
      </div>

      <div className="save-cancel-buttons">
        <Button
          variant="contained"
          className="button-save"
          onClick={() => onEditFilter()}
        >
          edit filter
        </Button>
         
      </div>
    </Box>
  );
};

export default SelectedDataShownAgg;
