import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const FulfillmentConsultingIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={colorHighlight} d="M11.4,15.2c-.3,0-.5,0-.7-.3-.4-.3-.5-.8-.3-1.2l.6-1.4c-.8-.4-1.3-1.2-1.3-2.1v-5.1c0-1.3,1-2.3,2.3-2.3h9.1c1.3,0,2.3,1,2.3,2.3v5.1c0,1.3-1,2.3-2.3,2.3h-4.9l-4.2,2.5c-.2.1-.4.2-.6.2ZM11.9,4.3c-.4,0-.8.4-.8.8v5.1c0,.4.4.8.8.8h1.1l-.8,2.1,3.5-2.1h5.3c.4,0,.8-.4.8-.8v-5.1c0-.4-.4-.8-.8-.8h-9.1Z"/>
      <path fill={color} d="M14.4,15.5v-1.4l-1.5.9v.5c0,.4-.4.8-.8.8h-1.1l.8,2.1-3.5-2.1H3c-.4,0-.8-.4-.8-.8v-5.1c0-.4.4-.8.8-.8h6.2v-1.5H3c-1.3,0-2.3,1-2.3,2.3v5.1c0,1.3,1,2.3,2.3,2.3h4.9l4.2,2.5c.2.1.4.2.6.2s.5,0,.7-.3c.4-.3.5-.8.3-1.2l-.6-1.4c.8-.4,1.3-1.2,1.3-2.1Z"/>
      <g>
        <circle fill={color} cx="3.9" cy="13" r=".7"/>
        <circle fill={color} cx="6.5" cy="13" r=".7"/>
        <circle fill={color} cx="9.1" cy="13" r=".7"/>
      </g>
      <g>
        <circle fill={colorHighlight} cx="14.2" cy="7.7" r=".8"/>
        <circle fill={colorHighlight} cx="16.7" cy="7.7" r=".8"/>
        <circle fill={colorHighlight} cx="19.3" cy="7.7" r=".8"/>
      </g>
    </svg>
  )
}

export default FulfillmentConsultingIcon;