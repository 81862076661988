import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import DashboardIcon from '@components/icons/dashboard.icon';
import CompoundsIcon from '@components/icons/compounds.icon';
import MyCompoundsIcon from '@components/icons/myCompounds.icon';
import FavoritesIcon from '@components/icons/favorites.icon';
import CompoundUniverseIcon from '@components/icons/compoundUniverse.icon';
import KinomeUniverseIcon from '@components/icons/kinomeUniverse.icon';
import UploadIcon from '@components/icons/upload.icon';
import GraphIcon from '@components/icons/graph.icon';
import EditIcon from '@components/icons/edit.icon';
import OurBiologyIcon from '@components/icons/ourBiology.icon';
import SubscriptionsIcon from '@components/icons/subscriptions.icon';
import IconInfo from '@components/icons/info.icon';

export const threeRndMenuItems: MenuItemsT[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    pathname: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    id: 'asedaCompounds',
    name: 'AsedaSciences Compounds',
    pathname: '/compounds/aseda',
    icon: <CompoundsIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'asedaCompoundsPublic',
    name: 'AsedaSciences Compounds (Public)',
    pathname: '/compounds/aseda_public',
    icon: <CompoundsIcon />,
    subscriptions: ['basic']
  },
  {
    id: 'userCompounds',
    name: 'My Compounds',
    pathname: '/compounds/user',
    icon: <MyCompoundsIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'favorites',
    name: 'My Favorites',
    pathname: '/compounds/favorites',
    icon: <FavoritesIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'compoundUniverse',
    name: 'Compound Universe',
    pathname: '/universe',
    icon: <CompoundUniverseIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'userUniverse',
    name: 'My Universes',
    pathname: '/user_universe',
    icon: <KinomeUniverseIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'upload',
    name: 'Upload Data',
    pathname: '/data_import',
    icon: <UploadIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'plots',
    name: 'My Plots',
    pathname: '/plots',
    icon: <GraphIcon />,
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+']
  },
  {
    id: 'fields',
    name: 'Fields Editor',
    pathname: '/fields',
    icon: <EditIcon />,
    subscriptions: ['disable']
  },
  {
    id: 'biology',
    name: 'Our Biology',
    pathname: '/biology',
    icon: <OurBiologyIcon />
  },
  {
    id: 'subscriptions',
    name: 'Subscriptions',
    pathname: '/subscriptions',
    icon: <SubscriptionsIcon />,
    subscriptions: ['basic']
  },
  {
    id: 'info',
    name: 'Info/FAQ',
    pathname: '/info',
    icon: <IconInfo />
  },
];

const debug = false;
export const get3rndMenus = (subscription: string) => {
  if (debug) console.log('get3rndMenus | props', subscription);
  return threeRndMenuItems
    .filter((m) => !m?.subscriptions || m.subscriptions.includes(subscription))
}


