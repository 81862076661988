import IconHome from '@components/icons/IconHome';
import React from 'react';
import { get3rndMenus } from '@subApps/3rnd/3rndMenus';
import { getAdminMenus } from '@subApps/admin/adminMenus';
import { getAccountMenus } from '@subApps/account/accountMenus';
import { getOrdersMenus } from '@subApps/orders/ordersMenus';
import { MenuItemsT } from '@as_core/menus/SideMenu';

export const homeMenu:MenuItemsT = {
  id: 'home',
  name: 'Home',
  pathname: '/home',
  icon: <IconHome />,
}

type getMenuT = (subscription: string) => MenuItemsT[];
export const menuFunctions: {[key: string]: getMenuT} = {
  '3RnD': get3rndMenus,
  'Admin': getAdminMenus,
  'Account': getAccountMenus,
  'Orders': getOrdersMenus
};