import {CompoundT} from '@src/type';
import {CompoundFieldsT} from '@stores/fields';
import {getValueByPath, setValueByPath} from '@utils/misc';

// add some functions so that there is error checking
export const getFieldName = (f, allFields) => {
  return getValueByPath(allFields[f], 'long_name', '');
}

export const getFieldDataKey = (f, allFields) => {
  return getValueByPath(allFields[f], 'data_key', '');
}

export const setCompoundFieldValue = (compound, f, v, allFields) => {
  const data_key=getFieldDataKey(f, allFields);
  // console.log('utils | setCompoundFieldValue | data_key, value:', data_key, v);
  if (data_key) {
    setValueByPath(compound, data_key, v);
  }
}

export const getCompoundFieldValue = (
  compound: CompoundT,
  f: string,
  allFields: CompoundFieldsT,
): string | number => {
  if (Object.hasOwn(allFields, f)) {
    const data_key = allFields[f].data_key;
    let value = getValueByPath(compound, data_key, '');
    if (f === 'exact_mol_wt' || f === 'crippen_log_p' || f === 'fs_p3' ||
        f === 'polar_surface_area' || f === 'c_sp3') {
      value = parseFloat(value).toFixed(2)
    }
    return value;
  } else {
    console.error('getCompoundFieldValue | ' + f + ' not in either dataFields');
    return '';
  }
}
