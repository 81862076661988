import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import { useEffect } from 'react';
import './SelectSmallcusto.css';
interface SelectSmallcustoProps {
  menuItems: { value: string; label: string; icon?: string }[][];
  value: string | undefined;
  onChange: (event: SelectChangeEvent) => void;
  size: 'small' | 'medium' | 'large' | undefined;
  style?: React.CSSProperties;
  minwidth?: string;
  maxwidth?: string;
  tabNames: Record<string, string>[];
  selectedTab?: number; 

}

export default function SelectSmallcusto({
  menuItems,
  value,
  onChange,
  style,
  minwidth,
  maxwidth,
  tabNames,
  selectedTab: externalSelectedTab, 
}: SelectSmallcustoProps) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const isMobile = windowWidth <= 900;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const tabIcons = [
    <TextSnippetIcon className="icon-selectsmall" key={"text_1"}/>,
    <DateRangeIcon className="icon-selectsmall" key={"date_1"}/>,
    <FormatListNumberedIcon className="icon-selectsmall" key={"format_number_1"}/>,
    <BorderStyleIcon className="icon-selectsmall" key={"border_style_1"}/>,
  ];
  useEffect(() => {
    // Adjust the internal state if the external prop changes
    if (externalSelectedTab !== undefined) {
      setSelectedTab(externalSelectedTab);
    }
  }, [externalSelectedTab]);


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation(); // Prevent event propagation

    setSelectedTab(newValue);
    event.preventDefault();
  };

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div style={style}>
      <FormControl sx={{
        m: 1, mt: 3, border: "none", color: "black",
        "& .css-1qb6xi6-MuiOutlinedInput-notchedOutline": {
          border: "white",
        }
      }}>
        <Select
          displayEmpty
          value={value || ''} // Ensure the value corresponds to available options
          onChange={onChange}
          input={<OutlinedInput />}
          sx={{
            backgroundColor: 'white',
            color: "Black",
            borderRadius: '5px',
            height: '38px',
            minWidth: isMobile ? minwidth : maxwidth,
            "& .css-1h20srk-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
              border: "0px",


            }
          }}
          inputProps={{
            fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
            fontWeight: 400, fontSize: '1rem', lineHeight: '1.4375em', letterSpacing: '0.00938em', color: 'rgba(0, 0, 0, 0.87)'
          }}
          size={'medium'}
        >


          <Tabs
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"


          >
            {tabNames.map((tab, index) => (
              <Tab
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                      color: selectedTab === index ? '#3d8bd9' : '#000000',
                      borderBottom: selectedTab === index ? '2px solid #3d8bd9' : 'none',
                    }}
                  >
                    {tabIcons[index]
                    }
                    {tab.label}
                  </div>
                }
                key={index}
              />
            ))}
          </Tabs>



          {menuItems[selectedTab].map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className='icon-selectsmall'>{item.icon}</span> {item.label}
              </div>
            </MenuItem>
          ))}
        </Select>

      </FormControl>
    </div>
  );
}