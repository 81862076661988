import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import SingleSelect from "@components/controls/SingleSelect";
import {FlexRow, FlexColumn, FlexItem} from "@components/layout/FlexStyles";
import {getSelectOptions} from "@as_core/controls/inputs/SingleSelect";
import Dropdown, {Label} from '@components/universe/elements/Dropdown';
import {GraphSettingsT} from "../types";
import {UpdateSettingsT} from "../CompoundUniverse";
import {Slider} from "@mui/material";

interface PropsI {
  settings: GraphSettingsT;
  updateSettings: (f: string, v: UpdateSettingsT) => void;
  edgeForceWeight?: number;
  setEdgeForceWeight?: (number) => void;
}

const SettingsPanel = (props:PropsI) => {
  const {settings, updateSettings, edgeForceWeight, setEdgeForceWeight} = props;
  const [selectOptions, setSelectOptions] =
      useState(getSelectOptions(settings.data_fields, 'field'));

  function handlePrimarySelect(e) {
    updateSettings('primary', e.target.value);
  }

  function handleSecondarySelect(id) {
    updateSettings('secondary', [...settings.secondary, id]);
  }

  function handleSecondaryReset() {
    updateSettings('secondary', []);
  }

  function handleSecondaryUnselect(id) {
    const newValues = settings.secondary.filter((v) => (v !== id));
    updateSettings('secondary', newValues);
  }

  const handleSlider = (e) => {
    setEdgeForceWeight(e.target.value);
  }

  useEffect(() => {
    setSelectOptions(getSelectOptions(settings.data_fields, 'field'));
  }, [settings]);

  return (
    <Container>
      <FlexRow v_centered>
        <FlexItem>
          <TextLabel>Force Weight:</TextLabel>
          <TextLabel color='accent'> {edgeForceWeight.toString()}</TextLabel>
        </FlexItem>
        <FlexItem width={'200px'}>
          <Slider
            value={edgeForceWeight}
            step={0.1}
            marks
            size='small'
            min={0.1}
            max={10.0}
            valueLabelDisplay='auto'
            onChange={handleSlider}
          />
        </FlexItem>
      </FlexRow>
      { settings.mode !== 'cell_health' ?
        <>
          <FlexRow v_centered>
            <FlexItem>
              <Pictogram
                src='/img/universe/node_primary_highlight.svg'
                alt='Secondary target'
              />
            </FlexItem>
            <FlexItem>
              <FlexColumn width={'100%'}>
                <FlexItem><Label>Primary Ring Color</Label></FlexItem>
                <FlexItem width={'100%'}>
                  <SingleSelect
                    id='primary'
                    label='Primary Ring Color'
                    value={settings.primary}
                    options={selectOptions}
                    onChange={handlePrimarySelect}
                    width={250}
                  />
                </FlexItem>
              </FlexColumn>
            </FlexItem>
          </FlexRow>
          <FlexRow>
            <FlexItem>
              <Pictogram
                src='/img/universe/node_secondary_highlight.svg'
                alt='Secondary target'
              />
            </FlexItem>
            <FlexItem>
              <Dropdown
                id='secondary'
                label='Secondary Ring Color(s)'
                values={selectOptions}
                selectedIds={settings.secondary}
                maxAllowed={8}
                onSelect={handleSecondarySelect}
                onUnselect={handleSecondaryUnselect}
                onReset={handleSecondaryReset}
              />
            </FlexItem>
          </FlexRow>
        </>
        : null }
    </Container>
  );
};

export default SettingsPanel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  font-size: 18px;
`;

const TextLabel = styled.div<{color?: string}>`
  display: flex;
  font-size: 12px;
  padding-right: 4px;
  color: ${(p) => p.color === 'accent' ? p.theme.palette.accentSecondary : p.theme.palette.textPrimary }
`;
TextLabel.defaultProps = {
  color: 'normal'
}

const Pictogram = styled.img`
  height: 50px;
  width: 50px;
  margin-right: ${(props) => props.theme.sizes.xsmall};
`;
