import React from 'react';
import { memo } from 'react';
import TextButtonBasic from './TextButtonBasic';
import TextButtonToolTip from './TextButtonToolTip';

interface TextButtonPropsT {
  tooltip?: string;
  tooltipPlacement?: 'right' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | 'top',
  text: string;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  isWarning?: boolean;
  isDisabled?: boolean;
  iconRight?: boolean;
  margin?: string;
  fontSize?: number;
  width?: number;
  height?: number;
  isLoading?: boolean;
}

const TextButton = (props: TextButtonPropsT) => {
  if (props?.tooltip) return <TextButtonToolTip {...props} />;
  return <TextButtonBasic {...props} />;
};
export default memo(TextButton);
