import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {Delaunay, select, zoom as d3zoom, zoomIdentity, ZoomTransform} from 'd3';
import {UseObjectArrayReturnType} from "@as_core/hooks/useObjectArray";
import {AesSettingsT, EdgeT, GraphSettingsT, PinnedVertexT, VertexT} from "../types";
import {UpdateSettingsT} from "../CompoundUniverse";
import {ZOOM_DURATION, MIN_ZOOM, MAX_ZOOM, ZOOM_SELECT_SCALE } from "../config/scale";
import {labelColors} from "../config/universe";
import {getClosestVertexByPosition, getNeighborVertices, getVerticesByIds, getVertexEdges} from "../utils/vertex";
import {isVisible, convertArrayToObject} from "../utils/vector";
import Vertex from '@components/universe/elements/Vertex';
import VertexPin from '@components/universe/elements/VertexPin';

interface PropsI {
  data: {vertices: VertexT[], edges: EdgeT[]};
  pinnedCompounds: UseObjectArrayReturnType;
  selectedCompounds: UseObjectArrayReturnType;
  settings?: GraphSettingsT;
  updateSettings: (f: string, v: UpdateSettingsT) => void;
  // eslint-disable-next-line
  delaunay: Delaunay<any>;
  aesSettings: { [key: string]: AesSettingsT };
  transform: ZoomTransform;
  zoomed: (v: ZoomTransform) => void;
  showVertices: boolean;
  setHoveredVertexLabel: (v: string) => void;
  cursor: string;
  selectInProcess: boolean;
}

const VectorPane = (props:PropsI) => {
  const {
    data,
    pinnedCompounds,
    selectedCompounds,
    settings,
    updateSettings,
    delaunay,
    aesSettings,
    transform,
    zoomed,
    showVertices,
    setHoveredVertexLabel,
    cursor,
    selectInProcess
  } = props;
  const { selectedVertex, dimensions } = settings;
  const { width, height } = dimensions;

  const zoomCatcherRef = useRef<SVGSVGElement>();
  const [ pinnedVertices, setPinnedVertices ] = useState<PinnedVertexT[]>([]);
  const [ selectedCompoundsVertices, setSelectedCompoundsVertices ] = useState<VertexT[]>([]);
  const [ selectedCompoundsVerticesId, setSelectedCompoundsVerticesId ] = useState<string[]>([]);
  const [ specialVertices, setSpecialVertices ] = useState<VertexT[]>([]);
  const [ specialVerticesId, setSpecialVerticesId ] = useState<string[]>([]);
  const [svgPos, setSvgPos] = useState({ left: 0, top: 0 });
  // eslint-disable-next-line
  const zoom = useRef<any>(
    d3zoom()
     .scaleExtent([MIN_ZOOM, MAX_ZOOM])
     .on('zoom', ({ transform }) => zoomed(transform))
  );
  function getPosition(e: {clientX: number, clientY: number}) {
    const scaledPos: [number, number] = [e.clientX - svgPos.left, e.clientY - svgPos.top];
    return transform.invert(scaledPos);
  }

  /**
   * handle the mouse movement that will select/set the hovered vertex and neighbors/edges that are drawn
   * in the Canvas2DPane and stored in the graphSettings (or settings)
   * @param e
   */
  function handleMouseMove(e) {
    const [x, y] = getPosition(e);
    if (!selectInProcess) {
      const newHoveredVertex = getClosestVertexByPosition(delaunay, x, y, transform.k, data.vertices);
      if (newHoveredVertex) {
        if (!settings.hoveredVertex ||
          (settings.hoveredVertex && newHoveredVertex.id !== settings.hoveredVertex?.id)) {
          setHoveredVertexLabel(newHoveredVertex?.name || newHoveredVertex?.id);
          updateSettings('hoveredVertex', newHoveredVertex);
          updateSettings('hoveredNeighborVertices', getNeighborVertices(newHoveredVertex.id, data.vertices, data.edges));
          updateSettings('hoveredEdges', getVertexEdges(newHoveredVertex.id, data.edges));
        }
      } else {
        if (settings.hoveredVertex) {
          setHoveredVertexLabel('');
          updateSettings('hoveredVertex', undefined);
          updateSettings('hoveredNeighborVertices', []);
          updateSettings('hoveredEdges', []);
        }
      }
    }
  }

  /**
   * handle the select/deselect of a node -- this also triggers the zoom
   * @param e
   */
  function handleClick(e) {
    if (!selectInProcess) {
      const [x, y] = getPosition(e);
      const newSelectedVertex = getClosestVertexByPosition(delaunay, x, y, transform.k, data.vertices);
      if (newSelectedVertex) {
        if (pinnedCompounds.data.includes(newSelectedVertex.id)) {
          pinnedCompounds.delete(newSelectedVertex.id);
        }
        updateSettings('selectedVertex', newSelectedVertex);
        updateSettings('selectedNeighborVertices', getNeighborVertices(newSelectedVertex.id, data.vertices, data.edges));
        updateSettings('selectedEdges', getVertexEdges(newSelectedVertex.id, data.edges));
      } else if (settings.selectedVertex) {
        updateSettings('selectedVertex', undefined);
        updateSettings('selectedNeighborVertices', []);
        updateSettings('selectedEdges', []);
      }
    }
  }

  useEffect(() => {
    // need to make sure that we get colors on right pins -- getVerticesByIds does not maintain id order
    const vertices = convertArrayToObject(getVerticesByIds(pinnedCompounds.data, data.vertices), 'id');
    const pins = pinnedCompounds.data.map((id, i)=>({v: vertices[id], color: labelColors['pinned'][i]}));
    if (settings?.selectedVertex) {
      pins.push({v: settings.selectedVertex, color: labelColors['selected']});
    }
    setPinnedVertices(pins)
  }, [pinnedCompounds]);

  useEffect(() => {
    if (zoom.current && !selectInProcess) {
      select(zoomCatcherRef.current).call(zoom.current);
    } else {
      select(zoomCatcherRef.current).on('.zoom', null);
    }
  }, [selectInProcess]);

  useEffect(() => {
    // handle the highlighted edges and vertices to the selectedVertex
    if (selectedVertex) {
      updateSettings('selectedNeighborVertices', getNeighborVertices(selectedVertex.id, data.vertices, data.edges));
      updateSettings('selectedEdges', getVertexEdges(selectedVertex.id, data.edges));
    }
    if (zoom.current) {
      const zoomCatcher = select(zoomCatcherRef.current);
      let updatedTransformation = zoomIdentity;
      if (selectedVertex && selectedVertex?.x && selectedVertex?.y) {
        const { x, y } = selectedVertex;
        updatedTransformation = updatedTransformation
          .translate(width / 2 + 125, height / 2)
          .scale(ZOOM_SELECT_SCALE)  // DHR was ZOOM_DETAIL_SCALE
          .translate(-x - width / 100, -y);
      }
      zoomCatcher
        .transition()
        .duration(ZOOM_DURATION)
        .call(zoom.current.transform, updatedTransformation);
    }
  }, [selectedVertex]);

  useEffect(() => {
    if (zoomCatcherRef && zoomCatcherRef.current) {
      const { left, top } = zoomCatcherRef.current.getBoundingClientRect();
      setSvgPos({ left, top });
    }
  }, [dimensions]);

  useEffect(() => {
    let newVertices = [];
    if (settings.selectedVertex !== undefined) {
      newVertices = [...newVertices, settings.selectedVertex, ...settings.selectedNeighborVertices];
    }
    if (settings.hoveredVertex !== undefined) {
      newVertices = [...newVertices, settings.hoveredVertex, ...settings.hoveredNeighborVertices];
    }
    const newVerticesIds = newVertices.map((v) => v.id);
    setSpecialVertices(newVertices);
    setSpecialVerticesId(newVerticesIds);
  }, [settings]);

  useEffect(() => {
    const newVertices = data.vertices.filter((v) => selectedCompounds.data.includes(v.id));
    const newVerticesIds = newVertices.map((v) => v.id);
    setSelectedCompoundsVertices(newVertices);
    setSelectedCompoundsVerticesId(newVerticesIds);
  }, [selectedCompounds]);

  const inViewVertices = data.vertices
    .filter((v) =>
      !specialVerticesId.includes(v.id) &&
      !selectedCompoundsVerticesId.includes(v.id) &&
      isVisible(transform, settings.dimensions, {x: v.x, y: v.y}));

  return (
    <Svg
      width={width}
      height={height}
      ref={zoomCatcherRef}
      onMouseMove={(e) => handleMouseMove(e)}
      onClick={(e) => handleClick(e)}
      cursor={cursor}
    >
      <Rect className='dummy-rect' x='0' y='0' width={width} height={height} />
      <g
        className='vertices'
        transform={'translate(' + transform.x + ' ' + transform.y + ') scale(' + transform.k + ')'}
      >
        {showVertices && inViewVertices
          .map((v) => {
            return (
              <Vertex
                key={v.id}
                vertex={v}
                radius={aesSettings[v.id]?.radius}
                vertexAesSettings={aesSettings[v.id]}
                settings={settings}
                hideValues={true}
              />
            );
        })}
        {specialVertices
          .map((v) => {
            return (
              <Vertex
                key={v.id}
                vertex={v}
                radius={aesSettings[v.id]?.radius}
                vertexAesSettings={aesSettings[v.id]}
                settings={settings}
                hideValues={true}
              />
            );
          })}
        {selectedCompoundsVertices
          .map((v) => {
            return (
              <Vertex
                key={v.id}
                vertex={v}
                radius={aesSettings[v.id]?.radius}
                vertexAesSettings={aesSettings[v.id]}
                settings={settings}
                hideValues={true}
              />
            );
          })}
      </g>
      <g className={'vertexPins'}
         transform={'translate(' + transform.x + ' ' + transform.y + ') scale(' + transform.k + ')'}
      >
        {pinnedVertices.map((pin, index) => {
          return(
            <>
              <Vertex
                key={pin.v.id}
                vertex={pin.v}
                radius={aesSettings[pin.v.id]?.radius}
                vertexAesSettings={aesSettings[pin.v.id]}
                settings={settings}
                hideValues={true}
              />
              <VertexPin
                key={`pin_${index}`}
                vertex={pin.v}
                color={pin.color}
                smallPin={showVertices}
              />
            </>
          )
        })}
      </g>
    </Svg>
  );
};

export default VectorPane;

const Svg = styled.svg<{cursor: string}>`
  cursor: ${(p) => p.cursor};
  pointer-events: all;
`;

const Rect = styled.rect`
  fill: none;
  stroke: none;
`;
