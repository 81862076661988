import React from 'react';
import { LayerCard } from '../LayerCard';
import { BoxplotBase, PlotConfig } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';

type BoxplotCardProps = {
  plot: PlotConfig<BoxplotBase>;
  onChange: (newPlotConfig: PlotConfig<BoxplotBase>) => void;
};

export const BoxplotCard: React.FC<BoxplotCardProps> = ({ plot, onChange }) => {

  const updateKey = (columnName: string) => {
    const newPlotConfig: PlotConfig<BoxplotBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<BoxplotBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateIsLogScale = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newPlotConfig: PlotConfig<BoxplotBase> = {
      ...plot,
      config: { ...plot.config, isLogScale: checked },
    };
    onChange(newPlotConfig);
  };

  const updateIsSorted = (event: React.ChangeEvent<HTMLInputElement>, isSorted: boolean) => {
    const newPlotConfig: PlotConfig<BoxplotBase> = {
      ...plot,
      config: { ...plot.config, isSorted: isSorted },
    };
    onChange(newPlotConfig);
  };

  return (
    <LayerCard title={'Box Plot'} disableExpand>
      <VariableSelect
        id='variable-key'
        label={'Grouping Variable'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateKey}
      />
      <VariableSelect
        id='variable-value'
        label={'Numeric Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValue}
      />
      {/* There is currently a bug in the NIVO boxplot component that prevents switching orientation
        I opened an issue on the topic: https://github.com/plouc/nivo/issues/2561 */}
      <br />
      <Box display='flex' alignItems='center' gap={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isLogScale}
              onChange={(e) => updateIsLogScale(e, e.target.checked)}  // Fixed event typing
            />
          }
          label={<Typography fontSize={12}>Use Log Scale</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isSorted}
              onChange={(e) => updateIsSorted(e, e.target.checked)}  // Fixed event typing
            />
          }
          label={<Typography fontSize={12}>Sort groups</Typography>}
        />
      </Box>
    </LayerCard>
  );
};
