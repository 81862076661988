import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FilterGenericI } from '../filter.types';
import MultiSelect from '@components/controls/MultiSelect';

const debug = false;
const FilterSelect = ({
  filterName,
  filter,
  options,
  handleUpdate,
}: FilterGenericI) => {
  const [newValue, setNewValue] = useState(filter.value);

  if (debug) {
    console.log(
      'FilterSelect | filterName:',
      filterName,
      ' filter: ',
      filter,
      ' options: ',
      options
    );
  }

  // utility function so that don't push when nothing is changed
  const equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter((e) => e === v).length;
      const bCount = b.filter((e) => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  };

  // just update locally while typing
  const handleLocalUpdate = (e) => {
    if (debug) console.log('handleLocalUpdate', e.target.value);
    setNewValue(e.target.value);
  };

  // check to update when lose focus by mouse leave
  const checkToUpdate = () => {
    if (debug) console.log('checkToUpdate');
    if (!equalsIgnoreOrder(newValue, filter?.value)) {
      if (debug) console.log('push update to refresh');
      handleUpdate('select', {
        uuid: filter?.uuid,
        value: newValue,
      });
    }
  };
  let field_options = [{ value: '', label: '' }];
  if (options !== undefined) {
    field_options = options.split(';').map((item) => ({
      label: item,
      value: item,
    }));
  }

  return (
    <FilterSelectItem>
      <Row>
        <Item>{filterName}</Item>
        <Item>
          <MultiSelect
            onChange={handleLocalUpdate}
            id='filter-compound-selector'
            label='Filter Compound Selector'
            value={newValue}
            options={field_options}
            onClose={checkToUpdate}
          />
        </Item>
      </Row>
    </FilterSelectItem>
  );
};

export default FilterSelect;

const FilterSelectItem = styled.label`
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  padding: 3px;
  display: flex;
  max-width: 60%;
  width: 60%;
`;
