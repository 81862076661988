import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import UserList from './components/UserList';
import UserDetail from './components/UserDetail';
import useUserAppInfo, {AppInfoT, UserListItemT} from '@utils/useUserAppInfo';

const debug = false;
const AdminUserManagement = () => {
  // API and CONTEXT
  const { user } = useContext(UserContext);
  const { getAllUsers, getUserByAdmin } = useUserAppInfo();
  const [selectedUser, setSelectedUser] = useState<AppInfoT>(null);

  // STATE VARIABLES
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState<UserListItemT[]>([]);
  if (debug) console.log('AdminUserManagement | user:', user);

  // HANDLERS
  const onSelect = (userId:string) => {
    if (debug) console.log('AdminUserManagement | onSelect userId', userId);
    setSelectedUserId(userId);
  };

  // DATA LOADING
  useEffect(() => {
    if (!loaded) {
      getAllUsers().then((response) => {
        if (debug) console.log('getAllUsers | response', response);
        setLoaded(true);
        setUsers(response);
      });
    }
  }, [loaded]);

  if (loaded) {
    if (debug) console.log('AdminUserManagement |users:', users);
  }

  if (debug) console.log('selectedUserId', selectedUserId);

  useEffect(() => {
    if (selectedUserId) {
      getUserByAdmin(selectedUserId).then((userInfo) => {
        if (debug) console.log('RESPONSE userInfo', userInfo);
        setSelectedUser(userInfo);
      });
    }
  }, [selectedUserId]);

  return (
    <Container>
      { selectedUserId ?
        <UserDetail
          user={selectedUser}
          backToList={() => setSelectedUserId(null)}
        />
      :
        <UserList
          users={users}
          handleSelect={onSelect}
        />
      }
    </Container>
  );
};

export default AdminUserManagement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
