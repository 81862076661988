import { DefaultTheme } from 'styled-components/macro';
import { PaletteT } from '../theme.type';

export const THEME = {
  name: 'light',
  textPrimary: 'rgb(3,2,38)',
  textSecondary: 'rgb(87,87,87)',
  accentTertiary: 'rgb(243,154,209)',
  accentSecondary: 'rgb(227,118,159)',
  accentPrimary: 'rgb(214,11,81)',
  backgroundPrimary: 'rgb(232,231,236)',
  backgroundSecondary: 'rgb(255,255,255)',
  backgroundTertiary: 'rgb(222,221,229)',
  backgroundQuaternary: 'rgb(210,210,217)',
  backgroundBlack: '#000129',
};

export const palette: PaletteT = {
  standout: '#FFFF00',
  accentPrimary: THEME.accentPrimary,
  accentSecondary: THEME.accentSecondary,
  accentTertiary: THEME.accentPrimary,
  backgroundPrimary: THEME.backgroundPrimary,
  backgroundBlack: THEME.backgroundBlack,
  backgroundSecondary: THEME.backgroundSecondary,
  backgroundTertiary: THEME.backgroundTertiary,
  backgroundQuaternary: THEME.backgroundQuaternary,
  titlePrimary: THEME.textPrimary,
  titleSecondary: THEME.textSecondary,
  textPrimary: THEME.textPrimary,
  textSecondary: THEME.textSecondary,
  textTertiary: THEME.textSecondary,
  textQuaternary: THEME.textSecondary,
  backgroundAlpha: 'rgba(0, 0, 0, 0.3)',
};

export default {
  name: 'light',
  palette,
  fonts: {
    main: 'Museo-Sans, Roboto, Verdana, sans-serif',
  },
  sizes: {
    xxsmall: '0.4rem',
    xsmall: '0.7rem',
    small: '1.0rem',
    default: '1.3rem',
    large: '1.6rem',
    xlarge: '2.0rem',
  },
  weights: {
    light: 100,
    default: 300,
    heavy: 500,
    heavy2: 700,
    heavy3: 900,
  },
  borders: {
    thinGreen: `1px solid ${palette.backgroundSecondary}`,
    default: `2px solid ${palette.backgroundSecondary}`,
    light: `2px solid ${palette.backgroundTertiary}`,
    accentPrimary: `2px solid ${palette.accentPrimary}`,
    accentSecondary: `2px solid ${palette.accentSecondary}`,
    thin: `1px solid ${palette.backgroundSecondary}`,
    thinLight: `1px solid ${palette.backgroundTertiary}`,
    thinDark: `1px solid ${palette.backgroundPrimary}`,
    thinAccentPrimary: `1px solid ${palette.accentPrimary}`,
    thinAccentSecondary: `1px solid ${palette.accentSecondary}`,
  },
  zIndex: {
    modal: 99,
    navbar: 100,
    sidebar: 101,
  },
  dimensions: {
    navHeight: 75,
    navWidth: 55,
    contentPaddingLeft: '1rem',
  },
  borderRadius: '3px',
  menuRadius: '6px',
  plots: {
    Aseda: {
      name: 'Aseda',
      scheme: [
        '#D60A50',
        '#DF487D',
        '#EA84A8',
        '#F6C2D4',
        '#D60A50',
        '#DF487D',
        '#EA84A8',
        '#F6C2D4',
        '#D60A50',
        '#DF487D',
        '#EA84A8',
        '#F6C2D4',
      ],
      type: 'discrete',
      unique: 9,
    },
  },
  menu: {
    active: {
      brightness: 1.0,
      contrast: 1.0,
    },
    disabled: {
      brightness: 1.0,
      contrast: 1.0,
    },
    inActive: {
      brightness: 1.5,
      contrast: 0.0,
    },
  },
  renderers: {
    circleBackground: '#BBBBBB',
  },
} as DefaultTheme;
