import React from 'react';
import Typography from '@mui/material/Typography';
import TableRowsIcon from '@mui/icons-material/TableRows';
import './TableDiv.css';
import EnhancedTable from '@components/dataAnalytics/EnhancedTable';
import CloseIcon from '@mui/icons-material/Close';

// TODO: Fix types here

const TableDiv: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any[];
  handleClose: (id: number) => void;
  id: number;
}> = ({ data, columns, handleClose, id }) => {
  if (columns === undefined && data !== undefined) {
    columns = Object.keys(data[0]);
  }
  return (
    <div className='Tablecontainer'>
      <div>
        <div className='flex-space-betweena'>
          <Typography variant='h6' component='h6' className='titletext'>
            <TableRowsIcon className='icon' fontSize='small' />
            TABLE
          </Typography>
          <CloseIcon
            className='closeIcon'
            onClick={() => handleClose(id)}
            style={{ color: '#F2F3F4' }}
          />
        </div>
        <div>
          <div className='normaltext'>
            Previewing {data.length} rows, {columns.length} of {columns.length}{' '}
            columns
          </div>
          <EnhancedTable data={data} columns={columns} />
        </div>
      </div>
    </div>
  );
};

export default TableDiv;
