import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}


const CompoundSmilesIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color} strokeWidth={10} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M150.9,338l168.7,97.3L488.2,338V143.4L319.5,46l-168.6,97.3V338z"/>
        <circle fill={color} cx="319.5" cy="45.8" r="22.4"/>
        <circle fill={color} cx="150.9" cy="144.1" r="22.4"/>
        <circle fill={color} cx="488.1" cy="141.2" r="22.4"/>
        <circle fill={color} cx="319.5" cy="435.5" r="22.4"/>
        <circle fill={color} cx="488.1" cy="337.8" r="22.4"/>
        <ellipse transform="matrix(3.327792e-02 -0.9994 0.9994 3.327792e-02 -191.7005 477.3648)" fill={color} cx="150.9" cy="337.8" rx="22.4" ry="22.4"/>
        <path stroke={colorHighlight} strokeWidth={10} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M18.8,322.1l141.7,81.7l141.7-81.7V158.6L160.4,76.8L18.8,158.5V322.1z"/>
        <circle fill={colorHighlight} cx="160.4" cy="76.6" r="18.8"/>
        <circle fill={colorHighlight} cx="18.8" cy="159.2" r="18.8"/>
        <circle fill={colorHighlight} cx="302.1" cy="156.8" r="18.8"/>
        <circle fill={colorHighlight} cx="160.4" cy="403.9" r="18.8"/>
        <circle fill={colorHighlight} cx="302.1" cy="321.8" r="18.8"/>
        <ellipse transform="matrix(3.327792e-02 -0.9994 0.9994 3.327792e-02 -303.4524 329.9403)" fill={colorHighlight} cx="18.8" cy="321.8" rx="18.8" ry="18.8"/>
        <text transform="matrix(1 0 0 1 74.5166 260.2396)" fill={colorHighlight} fontFamily={"MuseoSans-700"} fontSize={"51px"}>SMILES</text>
        <path fill={colorHighlight} d="M283.2,262.3h45.2v15.4c0,3.5,4.2,5.4,6.9,3.2l43.4-37.4c1.9-1.7,1.9-4.6,0-6.2l-43.4-37.5
		c-2.7-2.3-6.9-0.4-6.9,3.2v14.7h-45.2V262.3z"/>
      </svg>
    </>
  )
}

export default CompoundSmilesIcon;