import React from 'react';
import styled from "styled-components/macro";

interface PropsI {
  message: string;
}
const LoadingMessage = ({message}:PropsI) => {
  return (
      <Center>
        <StatusMessage>{ message }</StatusMessage>
      </Center>
  );
};

export default LoadingMessage;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const StatusMessage = styled.div`
  display: flex;
  min-width: 550px;
  padding: 50px;
  font-size: 24px;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary };
  border-radius: 30px;
  justify-content: center;
  align-items: center;
`;
