import React, {useContext} from "react";
import styled from "styled-components/macro";
import {UserContext} from "@stores/UserContext";
import SubscriptionByCoupon from "@business/subscriptions/SubscriptionByCoupon";
import {FlexItem} from "@components/layout/FlexStyles";
import {TextSmall} from "@as_core/elements/textStyles";
import {useParams} from "react-router-dom";
import SubscriptionOrderCreate from "@business/subscriptions/SubscriptionsOrderCreate";
import {FlexColumn} from "@as_core/elements/flexStyles";

const debug = false;
const SubscriptionOrder = () => {
  const { user } = useContext(UserContext);
  let { action,  state } = useParams();
  const orgType = user?.regInfo?.organization?.type ? user.regInfo.organization.type : 'commercial' ;
  if (debug) console.log('SubscriptionCurrent | user: ', user, 'orgType: ', orgType, 'action:', action, 'state:', state);
  return(
    <SubscriptionOrderContainer>
      { state === 'coupon' ?
        <SubscriptionByCoupon />
        :
        <FlexColumn h_centered>
          <FlexItem><TextSmall color={'accentSecondary'}>3RnD is currently in pilot-phase testing.</TextSmall></FlexItem>
          <FlexItem><TextSmall color={'primary'}>If you would like to be a pilot tester, contact us at info@asedasciences.com.</TextSmall></FlexItem>
          <SubscriptionOrderCreate />
        </FlexColumn>

      }
    </SubscriptionOrderContainer>
  )
}

export default SubscriptionOrder;

const SubscriptionOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;