import React, { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import useAggregation from './useAggregation';
import { FlexItem, FlexColumn } from '@components/layout/FlexStyles';
import useCognito from "@as_core/account/useCognito";

interface DashboardCategoryT {
  name: string;
  count: number;
}

export type CategoryProps = {
  field_id: string;
  onClick?: (item: string) => void;
};

const mapRequestData = ({ result }): DashboardCategoryT[] => {
  const { values } = result;

  return values
    .filter((item) => item._id.toString() !== '')
    .map((item) => ({
      name: item._id,
      count: item.count,
    }));
};

export const CategoryTiles = ({ field_id }: CategoryProps) => {
  const { getBucketAnnotationsCategory, getCancelSource } = useAggregation();
  const { getToken } = useCognito();
  const cancelSource = getCancelSource();
  const [data, setData] = React.useState<DashboardCategoryT[]>([]);
  const [loading, setLoading] = React.useState(false);

  const get_name = (name) => {
    if (name === 'Agricultural Chemical') {
      return 'Ag. Chem.';
    }
    if (name === 'Cosmetic Chemical') {
      return 'Cosmetic Chem.';
    }
    if (name === 'Industrial Chemical') {
      return 'Indust. Chem.';
    }
    if (name === 'Natural Product') {
      return 'Natural Prod.';
    }
    if (name === 'Vitamin/Supplement') {
      return 'Vitamin';
    }
    return name;
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      const config = {
        cancelToken: cancelSource.token,
        params: {
          library: 'aseda',
        },
      };

      getBucketAnnotationsCategory(getToken(), field_id, config).then((result) => {
        if (_.hasIn(result, 'values')) {
          setData(mapRequestData({ result }));
          setLoading(false);
        }
      });
    }
    return () => {
      cancelSource.cancel('exit');
      setLoading(false);
    };
  }, [field_id]);

  const cards = data.map((datum, index) => {
    const url =
      '/compounds/aseda/table?filter=market_segment&value=' + datum.name;
    return (
      <Card key={index}>
        <Tooltip
          title={`Click for Table View`}
          arrow
        >
          <StyledLink to={url} key={datum.name}>
            <FlexColumn h_centered>
              <FlexItem>
                <CategoryNumber>{datum.count.toLocaleString()}</CategoryNumber>
              </FlexItem>
              <FlexItem>
                <CategoryLabel>{get_name(datum.name)}</CategoryLabel>
              </FlexItem>
            </FlexColumn>
          </StyledLink>
        </Tooltip>
      </Card>
    );
  });

  return (
    <CategoryTilesContainer>
      <CategoryTilesScroll>
        {cards}
      </CategoryTilesScroll>
    </CategoryTilesContainer>
  );
};

const StyledLink = styled(Link)`
  margin: 0;
`;

const CategoryTilesContainer = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  height: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
`;

const CategoryTilesScroll = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: max-content;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 115px;
  margin: 5px;
  padding: 0;
  border-radius: 15px;
  color: ${(p) => p.theme.palette.textPrimary};
  background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  border: 1px solid ${(p) => p.theme.palette.backgroundPrimary};
  :hover {
    background-color: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.textSecondary};
  }
`;

const CategoryNumber = styled.div`
  display: flex;
  margin-top: 8px;
  font-size: 20px;
`;

const CategoryLabel = styled.div`
  display: flex;
  font-size: 14px;
`;
