import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const TableIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M3.81818 6.5H20.1818C20.3988 6.5 20.6069 6.42098 20.7604 6.28033C20.9138 6.13968
          21 5.94891 21 5.75C21 5.55109 20.9138 5.36032 20.7604 5.21967C20.6069 5.07902 20.3988 5
          20.1818 5H3.81818C3.60119 5 3.39308 5.07902 3.23964 5.21967C3.0862 5.36032 3 5.55109 3
          5.75C3 5.94891 3.0862 6.13968 3.23964 6.28033C3.39308 6.42098 3.60119 6.5 3.81818 6.5Z" fill={color}/>
          <path d="M20.1818 11H3.81818C3.60119 11 3.39308 11.079 3.23964 11.2197C3.0862 11.3603 3
          11.5511 3 11.75C3 11.9489 3.0862 12.1397 3.23964 12.2803C3.39308 12.421 3.60119 12.5
          3.81818 12.5H20.1818C20.3988 12.5 20.6069 12.421 20.7604 12.2803C20.9138 12.1397 21
          11.9489 21 11.75C21 11.5511 20.9138 11.3603 20.7604 11.2197C20.6069 11.079 20.3988 11
          20.1818 11Z" fill={color}/>
          <path d="M20.1818 17H3.81818C3.60119 17 3.39308 17.079 3.23964 17.2197C3.0862 17.3603 3
          17.5511 3 17.75C3 17.9489 3.0862 18.1397 3.23964 18.2803C3.39308 18.421 3.60119 18.5
          3.81818 18.5H20.1818C20.3988 18.5 20.6069 18.421 20.7604 18.2803C20.9138 18.1397 21
          17.9489 21 17.75C21 17.5511 20.9138 17.3603 20.7604 17.2197C20.6069 17.079 20.3988 17
          20.1818 17Z" fill={color}/>
        </g>
      </svg>
    </>
  )
}

export default TableIcon;