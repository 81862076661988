export const roundCapJoinGeometry = (regl, resolution = 16) => {
  // the rectangular section vertices
  const instanceRoundRound = [
    [0, -0.5, 0],
    [0, -0.5, 1],
    [0, 0.5, 1],
    [0, -0.5, 0],
    [0, 0.5, 1],
    [0, 0.5, 0],
  ];

  // left semicircle vertices
  for (let step = 0; step < resolution; step++) {
    const theta0 = Math.PI / 2 + (step * Math.PI) / resolution;
    const theta1 = Math.PI / 2 + ((step + 1) * Math.PI) / resolution;
    instanceRoundRound.push([0, 0, 0]);
    instanceRoundRound.push([
      0.5 * Math.cos(theta0),
      0.5 * Math.sin(theta0),
      0,
    ]);
    instanceRoundRound.push([
      0.5 * Math.cos(theta1),
      0.5 * Math.sin(theta1),
      0,
    ]);
  }

  // right semicircle vertices
  for (let step = 0; step < resolution; step++) {
    const theta0 = (3 * Math.PI) / 2 + (step * Math.PI) / resolution;
    const theta1 = (3 * Math.PI) / 2 + ((step + 1) * Math.PI) / resolution;
    instanceRoundRound.push([0, 0, 1]);
    instanceRoundRound.push([
      0.5 * Math.cos(theta0),
      0.5 * Math.sin(theta0),
      1,
    ]);
    instanceRoundRound.push([
      0.5 * Math.cos(theta1),
      0.5 * Math.sin(theta1),
      1,
    ]);
  }

  return {
    buffer: regl.buffer(instanceRoundRound),
    count: instanceRoundRound.length,
  };
};

export const createDrawLine = (regl, width, height) => {
  const roundCapJoin = roundCapJoinGeometry(regl, 16);

  return regl({
    vert: `
      precision highp float;
      attribute vec3 position;
      attribute vec2 pointA, pointB;
      attribute float width;
      uniform float transformK;
      uniform mat3 zoomMatrix;

      vec2 normalizeCoords(vec3 pos) {
        float x = pos[0];
        float y = pos[1];
  
        return vec2(
          2.0 * ((x / ${width.toFixed(2)}) - 0.5),
          -(2.0 * ((y / ${height.toFixed(2)}) - 0.5))
        );
      }

      void main() {
        vec2 pointANorm = normalizeCoords(vec3(pointA, 1.0) * zoomMatrix);
        vec2 pointBNorm = normalizeCoords(vec3(pointB, 1.0) * zoomMatrix);

        vec2 xBasis = normalize(pointBNorm - pointANorm);
        vec2 yBasis = vec2(-xBasis.y, xBasis.x);

        float transformedWidth = width * (exp(log(transformK) * 0.5));;

        vec2 offsetA = pointANorm + transformedWidth * (position.x * xBasis + position.y * yBasis);
        vec2 offsetB = pointBNorm + transformedWidth * (position.x * xBasis + position.y * yBasis);

        vec2 point = mix(offsetA, offsetB, position.z);
        gl_Position = vec4(point, 0, 1);
      }
    `,

    frag: `
      precision highp float;
      uniform vec4 color;
      void main() {
        gl_FragColor = color;
        gl_FragColor.rgb *= gl_FragColor.a;
      }
    `,

    attributes: {
      position: {
        buffer: roundCapJoin.buffer,
        divisor: 0,
      },
      pointA: {
        buffer: (context, props) =>
          regl.buffer(
            props.edges
              .map((e) => [e.source.x, e.source.y, e.target.x, e.target.y])
              .flat()
          ),
        divisor: 1,
        offset: 0,
        stride: Float32Array.BYTES_PER_ELEMENT * 4,
      },
      pointB: {
        buffer: (context, props) =>
          regl.buffer(
            props.edges
              .map((e) => [e.source.x, e.source.y, e.target.x, e.target.y])
              .flat()
          ),
        divisor: 1,
        offset: Float32Array.BYTES_PER_ELEMENT * 2,
        stride: Float32Array.BYTES_PER_ELEMENT * 4,
      },
      width: {
        buffer: (context, props) => regl.buffer(props.edgeWeights),
        divisor: 1,
        offset: 0,
        stride: Float32Array.BYTES_PER_ELEMENT,
      },
    },

    uniforms: {
      color: (context, props) => props.color,
      transformK: (context, props) => props.transform.k,
      zoomMatrix: (context, props) => {
        return [
          [props.transform.k, 0, props.transform.x],
          [0, props.transform.k, props.transform.y],
          [0, 0, 1],
        ].flat();
      },
    },

    depth: {
      enable: false,
    },

    cull: {
      enable: true,
      face: 'back',
    },

    blend: {
      enable: true,
      func: {
        srcRGB: 'one',
        srcAlpha: 'one',
        dstRGB: 'one minus src alpha',
        dstAlpha: 'one minus src alpha',
      },
    },

    count: roundCapJoin.count,
    instances: (context, props) => props.segments,
  });
};
