import React from 'react';
import styled from 'styled-components/macro';

import useFileImport from '../useFileImport';
import { Header, Row } from '../shared/RowElements';
import GenericColumnRow from './GenericColumnRow';

interface ImportGenericTableT {
  width: number;
}
const ImportGenericTable = (props: ImportGenericTableT) => {
  const { width } = props;
  const { context: { columns } } = useFileImport();

  return (
    <Container>
      <ScrollContainer>
        <Table width={width}>
          <tbody>
            <Row border>
              <Header>Columns</Header>
              <Header>Column Type</Header>
              <Header>Data Preview</Header>
            </Row>
            {columns.map((column, columnIdx) => {
              return (
                <GenericColumnRow
                  key={columnIdx}
                  column={column}
                  colIdx={columnIdx}
                />
              );
            })}
          </tbody>
        </Table>
      </ScrollContainer>
    </Container>
  );
};

export default ImportGenericTable;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
`;

const ScrollContainer = styled.div`
  display: flex;
  height: max-content;
  max-height: calc(100vh - 330px);
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table<{ height?: number; width?: number }>`
  display: flex;
  table-layout: auto;
  width: 100%;

`;
