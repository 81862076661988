import { AxiosRequestConfig } from "axios";
import { UserT} from "@stores/UserContext";
import {authClient, ResponseT} from "@utils/api/base";
import { handleError } from "@utils/misc";
import useCognito from "@as_core/account/useCognito";

export const Subscriptions = {
  add: (token, data) => authClient(token).post('/user/add-subscription', data),
};

const useSubscriptions = () => {
  const {getToken} = useCognito();
  const addSubscription = async (data): Promise<ResponseT<UserT>> => {
    const config: AxiosRequestConfig = data;
    let resp;

    try {
      resp = await Subscriptions.add(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };


  return {
    addSubscription,
  };
};

export default useSubscriptions;
