import React, { useState } from 'react';
import styled from 'styled-components/macro';
import RadioButton from '@components/elements/RadioButton';
import { FilterGenericI } from '../filter.types';

const validValues = [
  { value: 'exists', label: 'Value Exists' },
  { value: 'not_exists', label: 'No Values' },
];

const debug = false;
const FilterLogical = ({
  filterName,
  filter,
  handleUpdate,
}: FilterGenericI) => {
  if (debug)
    console.log('FilterLogical | filterName:', filterName, ' filter: ', filter);
  const [newComparator, setNewComparator] = useState(filter.comparator);

  const handleLocalUpdate = (e) => {
    const v = e.target.value;
    if (debug) console.log('FilterLogical | handleLocalUpdate | v:', v);
    setNewComparator(v);
    if (debug)
      console.log('FilterLogical | handleLocalUpdate | push update to refresh');
    handleUpdate('logical', { uuid: filter.uuid, comparator: v });
  };

  if (debug) console.log('FilterLogical | newComparator:', newComparator);
  return (
    <FilterExistsItem>
      <Title>{filterName}</Title>
      <Selections>
        <Container onChange={handleLocalUpdate}>
          {validValues.map((v, i) => (
            <RadioButton
              key={`radiobutton-${i}`}
              id={`radiobutton-${i}`}
              value={v.value}
              checked={v.value === newComparator}
              onClick={() => handleLocalUpdate(v.value)}
              label={v.label}
            />
          ))}
        </Container>
      </Selections>
    </FilterExistsItem>
  );
};

export default FilterLogical;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const FilterExistsItem = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 500px;
`;

const Title = styled.div`
  display: flex;
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
`;

const Selections = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 450px;
  margin-left: 1rem;
  font-size: ${(props) => props.theme.sizes.small};
`;
