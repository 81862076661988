import React from "react";
import {Tooltip} from "@mui/material";
import {truncateString} from "@utils/misc";

type PropsT = {
  text: string
  characters?: number;
  showRear?: boolean;
}
const HoverTextTruncate = (props:PropsT) => {
  const {text, characters=20, showRear=true} = props;
  let halfCharacters = Math.floor(characters/2);
  if (!showRear) halfCharacters = 0;
  return(
    <>
      { text.length < characters ?
        text
      :
        <Tooltip title={text} arrow={true}>
          {truncateString(text, characters, halfCharacters)}
        </Tooltip>
      }
    </>
  )
}

export default HoverTextTruncate;
