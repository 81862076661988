import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const SelectIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M19.4,11c-0.4,0-0.7,0.3-0.7,0.7v7.1c0,0.4-0.3,0.7-0.7,0.7H5.1c-0.4,0-0.7-0.3-0.7-0.7V4.6
	c0-0.4,0.3-0.7,0.7-0.7h8.6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7H5.1C4,2.4,3,3.4,3,4.6v14.3C3,20,4,21,5.1,21H18
	c1.2,0,2.1-1,2.1-2.1v-7.1C20.1,11.3,19.8,11,19.4,11z"/>
          <path fill={colorHighlight} d="M11.5,18c-1.8,0-3.2-2.3-4.5-4.3c-0.4-0.6-0.8-1.3-1-1.5c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.8,1,1.3,1.8
	c0.4,0.6,1.2,1.9,1.9,2.7c0.4,0.4,1,0.3,1.2-0.2c0.3-0.6,0.6-1.5,0.9-2.2c1.5-4.1,3.8-10.2,9.5-11.4c0.5-0.1,1.1,0.2,1.2,0.8
	c0.1,0.5-0.2,1.1-0.8,1.2c-4.6,1-6.6,6.3-8,10.2C13.7,16.3,13,18,11.5,18z"/>
        </g>
      </svg>
    </>
  )
}

export default SelectIcon;