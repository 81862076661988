import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const NewFieldIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M19.4,11c-0.4,0-0.7,0.3-0.7,0.7v7.1c0,0.4-0.3,0.7-0.7,0.7H5.1c-0.4,0-0.7-0.3-0.7-0.7V4.6
	c0-0.4,0.3-0.7,0.7-0.7h8.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H5.1C4,2.4,3,3.4,3,4.6v14.3C3,20,4,21,5.1,21H18
	c1.2,0,2.1-1,2.1-2.1v-7.1C20.1,11.3,19.8,11,19.4,11z" />
          <path fill={colorHighlight} d="M19.8,6.2h-2.1v-2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2.1H14c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1V10
	c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V7.9h2.1c0.4,0,0.8-0.3,0.8-0.8S20.2,6.2,19.8,6.2z" />
        </g>
      </svg>
    </>
  )
}

export default NewFieldIcon;