import { BaseLayerType } from "@plotting/single-plot-view/plot.types";

export type PlotType = {
  base: BaseLayerType;
  text: string;
  icon: string;
  subtext?: string;
  disabled?: boolean;
};

export const PLOT_TYPE_LIST: Array<PlotType> = [
  {
    text: 'Scatter Plot',
    base: 'scatter',
    icon: '/img/plots/ScatterPlot_White.svg',
  },
  {
    text: 'Bar Chart',
    base: 'bar',
    icon: '/img/plots/BarGraph_White.svg',
  },
  {
    text: 'Pie Chart',
    base: 'pie',
    icon: '/img/plots/PieChart_White.svg',
  },
  {
    text: 'Line Chart',
    base: 'line',
    icon: '/img/plots/LineChart_White.svg',
  },
  {
    text: 'Box Plot',
    base: 'boxplot',
    icon: '/img/plots/BoxWhiskerPlot_White.svg',
  },
  {
    text: 'Dose Response',
    base: 'drc',
    icon: '/img/plots/DoseCurve_White.svg',
  },
  {
    text: 'Volcano',
    base: 'volcano',
    icon: '/img/plots/VolcanoPlot_White.svg',
  },
  {
    text: 'Histogram',
    base: 'histogram',
    icon: '/img/plots/Histogram_White.svg',
  },
];
