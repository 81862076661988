import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {getFieldName, getCompoundFieldValue, setCompoundFieldValue} from '@components/elements/compound/utils';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { FieldSliceT } from '@stores/fields';
import { CompoundT } from '@src/type';
import InputField from "@as_core/components/inputs/InputField";

interface PropertiesEditableTilePropsT {
  compound: CompoundT;
  fields: string[];
}

const debug = false;
const NewValuesTile = (props: PropertiesEditableTilePropsT) => {
  const { compound, fields } = props;
  const [localValue, setLocalValue] = useState<{[key: string]: string | number}>({});
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  const getValue = (f: string) => {
    if (!Object.hasOwn(localValue, f)) {
      // check if already in compound
      const newLocalValue = getCompoundFieldValue(compound, f, allFields);
      setLocalValue(prev => ({...prev, [f]: newLocalValue}));
      return newLocalValue;
    }
    return localValue[f];
  }

  const onChange = (f: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (debug) console.log(f, e.target.value);
    setLocalValue(prev => ({...prev, [f]: e.target.value}));
    setCompoundFieldValue(compound, f, e.target.value, allFields);
  }

  if (debug) console.log('PropertiesTile | compound:', compound, ' dataFields:', fields);
  return (
    <PropertiesContainer>
      {fields.map((f, i) => (
        <Item key={i}>
          <InputField
            value={getValue(f)}
            onChange={(e) => onChange(f, e)}
            name={getFieldName(f, allFields)}
            label={getFieldName(f, allFields)}
            size='small'
          />
        </Item>
      ))}
    </PropertiesContainer>
  );
};

export default NewValuesTile;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
`;

const Item = styled.div`
  padding: 0;
  margin: 2px;
  display: flex;
  flex-direction: row;
`;

