import React from 'react';
import { LayerCard } from '../LayerCard';
import { ScatterBase, PlotConfig, KeyColor } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Checkbox, FormControlLabel, Slider, Typography, Divider } from '@mui/material';
import { DEFAULT_CIRCLE_SIZE } from '@dataviz/scatterplot/ScatterPlot';
import { Stack } from '@mui/system';
import { DiscreteColorPaletteSelect } from '@plotting/controls/ColorPaletteSelect';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';
import Alert from '@components/elements/Alert';
import InputField from '@as_core/components/inputs/InputField';

type ScatterCardProps = {
  plot: PlotConfig<ScatterBase>;
  onChange: (newPlotConfig: PlotConfig<ScatterBase>) => void;
};

export const ScatterCard: React.FC<ScatterCardProps> = ({ plot, onChange }) => {
  const [isGroupDefined, setIsGroupDefined] = React.useState(plot.config.groupByColumn !== '');
  const [showAdvancedControls, setShowAdvancedControls] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('Something went wrong');

  const closeAlert = () => {
    setErrorMessage("Something went wrong")
    setAlertOpen(false);
  }

  const updateXColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, xColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateYColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, yColumn: columnName },  // Fixing the update to `yColumn`
    };
    onChange(newPlotConfig);
  };

  const updateGroupColumn = (columnName: string) => {
    columnName !== "" ? setIsGroupDefined(true) : setIsGroupDefined(false);
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateSizeColumn = (columnName: string) => {
    let newCircleSize = plot.config.circleSize;
    if (!plot.config.sizeColumn && columnName !== '') {
      newCircleSize = [DEFAULT_CIRCLE_SIZE, DEFAULT_CIRCLE_SIZE + 6];
    }
    if (plot.config.sizeColumn && columnName === '') {
      newCircleSize = DEFAULT_CIRCLE_SIZE;
    }
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        sizeColumn: columnName,
        circleSize: newCircleSize,
      },
    };
    onChange(newPlotConfig);
  };

  const updateCircleSize = (e: Event, newSize: number | [number, number]) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, circleSize: newSize },
    };
    onChange(newPlotConfig);
  };

  const updateIsLinearRegressionEnabled = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, isLinearRegressionEnabled: checked },
    };
    onChange(newPlotConfig);
  };

  const updateXAxisName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        xAxisName: event.target.value,
      },
    };
    onChange(newPlotConfig);
  };

  const updateYAxisName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        yAxisName: event.target.value,
      },
    };
    onChange(newPlotConfig);
  };

  const updateIsLogScaleXAxis = (
    event: React.ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    const xColumn = plot?.config?.xColumn;
    let invalidDataFound = false;
  
    plot?.data.forEach((el) => {
      if (
        isChecked &&
        (el[xColumn]!=null && typeof el[xColumn] !== 'number')
      ) {
        setErrorMessage(
          `A logarithmic scale cannot be applied to the non-numeric value ${el[xColumn]} found in the column ${xColumn}.`
        );
        setAlertOpen(true);
        invalidDataFound = true;
      }
    });
  
    let newXAxisScaleType: 'linear' | 'log';
  
    if (isChecked) {
      if (invalidDataFound) {
        // Keep the existing xAxisScale.type if invalid data is found
        newXAxisScaleType = plot?.config?.xAxisScale?.type;
      } else {
        // Set xAxisScale.type to 'log' if no invalid data is found
        newXAxisScaleType = 'log';
      }
    } 
  
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        xAxisScale: {
          ...plot.config.xAxisScale,
          type: newXAxisScaleType,
        },
      },
    };
    onChange(newPlotConfig);
  };
  

  const updateIsLogScaleYAxis = (
    event: React.ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    const yColumn = plot?.config?.yColumn;
    let invalidDataFound = false;
  
    plot?.data.forEach((el) => {
      if (
        isChecked &&
        (el[yColumn]!=null && typeof el[yColumn] !== 'number')
      ) {
        setErrorMessage(
          `A logarithmic scale cannot be applied to the non-numeric value ${el[yColumn]} found in the column ${yColumn}.`
        );
        setAlertOpen(true);
        invalidDataFound = true;
      }
    });
  
    let newYAxisScaleType: 'linear' | 'log';
  
    if (isChecked) {
      if (invalidDataFound) {
        // Keep the existing yAxisScale.type if invalid data is found
        newYAxisScaleType = plot?.config?.yAxisScale?.type;
      } else {
        // Set yAxisScale.type to 'log' if no invalid data is found
        newYAxisScaleType = 'log';
      }
    }   
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: {
        ...plot.config,
        yAxisScale: {
          ...plot.config.yAxisScale,
          type: newYAxisScaleType,
        },
      },
    };
    onChange(newPlotConfig);
  };

  const updateStyle = (style: KeyColor[], colorName?: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, style , colorName},
    };
    onChange(newPlotConfig);
  };

  const updateColumnName = (columnName: string) => {
    const newPlotConfig: PlotConfig<ScatterBase> = {
      ...plot,
      config: { ...plot.config, name: columnName },
    };
    onChange(newPlotConfig);
  }
  const updateFontSizeAxisLabel = (event) => {
    const size = Number.parseInt(event.target.value);
    const newPlot = {
      ...plot,
      config: { ...plot.config, axisLabelFontSize: size },
    };
    onChange(newPlot);
  }
  const updateFontSizeTickMarks = (event) => {
    const size = Number.parseInt(event.target.value);
    const newPlot = {
      ...plot,
      config: { ...plot.config, tickLabelFontSize: size },
    };
    onChange(newPlot);
  }

  const groups: string[] = [...new Set(plot.data.map((d) => d[plot.config.groupByColumn]))];
  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors?.scheme || []);

  const defaultKeyColor = groups.map((group) => {
    return {
      id: group,
      color: defaultColorScale(group),
    };
  });

  const defaultSizeSlideValue =
    plot.config.sizeColumn && plot.config.sizeColumn !== ''
      ? [DEFAULT_CIRCLE_SIZE, DEFAULT_CIRCLE_SIZE + 6]
      : DEFAULT_CIRCLE_SIZE;

  const circleSizeSliderValue = plot.config.circleSize || defaultSizeSlideValue;

  const toggleAdvancedControls = (expanded: boolean) => {
    setShowAdvancedControls(expanded);
  };

  const displayAdvancedControls = () => {
    return(
      <>
        <Divider sx={{width: '100%'}}/>
        <Stack direction={"column"} spacing={2} sx={{marginTop: '10px'}}>
          <Typography
            color={'textPrimary'}
            sx={{marginBottom: '0', fontSize: 14, marginTop: 1}}
          >
            Use Log Scale:
          </Typography>
          <Stack direction="row" spacing={2} sx={{marginTop: '0'}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={plot.config.xAxisScale?.type === 'log'}
                  onChange={updateIsLogScaleXAxis}
                />
              }
              label={<Typography fontSize={12}>x-Axis</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={plot.config.yAxisScale?.type === 'log'}
                  onChange={updateIsLogScaleYAxis}
                />
              }
              label={<Typography fontSize={12}>y-Axis</Typography>}
            />
          </Stack>
          <Typography
            color={'textPrimary'}
            sx={{marginBottom: '0', fontSize: 14, marginTop: 1}}
          >
            Font Sizes:
          </Typography>
          <Stack direction="row" spacing={2}>
            <InputField
              value={plot.config.axisLabelFontSize}
              onChange={updateFontSizeAxisLabel}
              type="number"
              margin="dense"
              label="Axis Labels"
              size="small"
            />
            <InputField
              value={plot.config.tickLabelFontSize}
              onChange={updateFontSizeTickMarks}
              type="number"
              margin="dense"
              label="Tick Mark Labels"
              size="small"
            />
          </Stack>
        </Stack>
      </>
    )
  };



  return (
    <>
      <Alert
        type={'general'}
        title={'Error Logarithmic Scale'}
        message={errorMessage}
        alertOpen={alertOpen}
        closeAlert={closeAlert}
      />
      <LayerCard title={'Variables'} disableExpand>
        <VariableSelect
          id='variable-value'
          label={'x-Axis Variable'}
          value={plot.config.xColumn}
          options={plot.columns}
          onChange={updateXColumn}
        />
        <VariableSelect
          id='variable-value'
          label={'y-Axis Variable'}
          value={plot.config.yColumn}
          options={plot.columns}
          onChange={updateYColumn}
        />
        <VariableSelect
          id='variable-name'
          label={'Data Label'}
          value={plot.config.name}
          options={plot.columns}
          onChange={updateColumnName}
        />
        <VariableSelect
          id='variable-group'
          label={'Group By'}
          value={plot.config.groupByColumn}
          options={plot.columns}
          onChange={updateGroupColumn}
        />
        <VariableSelect
          id='variable-size'
          label={'Size By'}
          value={plot.config.sizeColumn}
          options={plot.columns}
          onChange={updateSizeColumn}
        />
      </LayerCard>

      <LayerCard title={'Customization'} disableExpand>
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isLinearRegressionEnabled}
              onChange={updateIsLinearRegressionEnabled}
            />
          }
          label={<Typography fontSize={12}>Add linear regression</Typography>}
        />
        <DiscreteColorPaletteSelect
          colorName={plot.config?.colorName}
          values={isGroupDefined ? defaultKeyColor : plot.config.style }
          onChange={updateStyle}
        />
        <Stack style={{ width: '100%' }}>
          <Typography
            color={'textSecondary'}
            sx={{ marginBottom: '2px', fontSize: 14, marginTop: 1 }}
          >
            Circle Size
          </Typography>
          <Slider
            value={circleSizeSliderValue}
            step={1}
            marks
            size='small'
            min={3}
            max={50}
            valueLabelDisplay='auto'
            onChange={updateCircleSize}
          />
        </Stack>
      </LayerCard>

      <LayerCard title={'Axes'} disableExpand={false} onChange={toggleAdvancedControls}>
        <InputField
          value={plot.config.xAxisName}
          onChange={updateXAxisName}
          label='x-Axis Label'
          fontSize={plot?.config?.axisLabelFontSize || 12}
        />
        <InputField
          value={plot.config.yAxisName}
          onChange={updateYAxisName}
          label='y-Axis Label'
          fontSize={plot?.config?.axisLabelFontSize || 12}
        />
        {showAdvancedControls && displayAdvancedControls()}
      </LayerCard>
    </>
  );
};
