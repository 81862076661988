import React, { useState } from 'react';
import MenuButton from '@components/elements/MenuButton';
import SettingsIcon from '@components/icons/settings.icon';
import ContinueIcon from '@components/icons/continue.icon';
import DraggableModal from '@components/elements/DraggableModal';
import UniverseLibrarySelector from './UniverseLibrarySelector';
import SettingsPanel from './SettingsPanel';
import ColorLegend from '@components/universe/elements/ColorLegend';

import {
  FlexItem,
  FlexColumn,
  FlexBanner,
  FlexBannerGroup,
  FlexSpacer,
} from '@as_core/elements/flexStyles';
import {GraphSettingsT, SelectorT, SettingsT} from '../types';
import {
  inhibitionScale,
  uniScoresColorScale,
  chiScoresColorScale,
  zebrafishScale,
} from '@components/universe/utils/compoundUniverse';
import SelectorDropdown from '@as_core/controls/inputs/SelectorDropdown';
import SelectIcon from '@components/icons/select.icon';
import SelectedCompoundsPanel from '@components/universe/controls/SelectedCompoundsPanel';
import SearchFieldAutocomplete from '@as_core/components/inputs/SearchFieldAutocomplete';
import {UseObjectArrayReturnType} from "@as_core/hooks/useObjectArray";
import {UpdateSettingsT} from "@components/universe/CompoundUniverse";
import SelectionIcon from "@as_core/icons/selection.icon";
import DeSelectionIcon from "@as_core/icons/deSelection.icon";

// TODO - set this based on graph network dataset -- it may have alternate forms in future
const edgeOptions: {value: string, label: string}[] = [
  { value: 'biol', label: 'Biological' },
  { value: 'chem', label: 'Chemical' },
];

interface PropsI {
  universeSelector?: SelectorT;
  datasets: { datasetId: string, label: string}[];
  settings: GraphSettingsT;
  updateSettings: (f: string, v: UpdateSettingsT) => void;
  edgeForceWeight: number;
  setEdgeForceWeight: (number) => void;
  setContinueSimulation: () => void;
  getSearchResponse: (v: string) => { id: string, label: string}[];
  onSearchItemSelect: (v: string) => void;
  selectedCompounds: UseObjectArrayReturnType;
}

const UniverseBanner = (props: PropsI) => {
  const {
    universeSelector,
    datasets,
    setContinueSimulation,
    settings,
    updateSettings,
    edgeForceWeight,
    setEdgeForceWeight,
    getSearchResponse,
    onSearchItemSelect,
    selectedCompounds,
  } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<{ id: string, label: string}[]>([]);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
  const [isSelectedModalOpen, setIsSelectedModalOpen] = useState<boolean>(false);

  const handleUniverseSelect = (v: string) => {
    universeSelector.setValue(v);
    setIsSettingsModalOpen(false);
    setIsSelectedModalOpen(false);
  };

  const handleEdgeTypeSelect = (v: string) => {
    updateSettings('edgeType', v);
  };
  const handleColorByChange = (v: string) => {
    updateSettings('nodeColorBy', v);
  };

  const handleSearchTextChange = (v:string) => {
    setSearchText(v);
    const values = getSearchResponse(v);
    setSearchResults(values);
  };

  const handleSearchSelect = (v: string) => {
    onSearchItemSelect(v);
    setSearchText('');
    setSearchResults([]);
  };

  /**
   * Get the appropriate color legends to display at right side
   * @param settings
   */
  const getLegend = (settings: SettingsT) => {
    if (settings.response_type === '%') {
      return (
        <FlexItem>
          <ColorLegend
            scale={inhibitionScale}
            title={'Inhibition'}
            lowText={'0%'}
            highText={'100%'}
          />
        </FlexItem>
      );
    } else if (settings.response_type === '%_inverse') {
      return (
        <FlexItem>
          <ColorLegend
            scale={inhibitionScale}
            title={'-Response'}
            lowText={'100%'}
            highText={'0%'}
          />
        </FlexItem>
      );
    } else if (settings.response_type === 'zebrafish') {
      return (
        <FlexItem>
          <ColorLegend
            scale={zebrafishScale}
            title={'BMD'}
            lowText={settings.limits[0].toString() + 'uM'}
            highText={settings.limits[1].toString() + 'uM'}
          />
        </FlexItem>
      );
    } else {
      return (
        <FlexColumn>
          <FlexItem>
            <ColorLegend
              scale={chiScoresColorScale}
              title={'cell health index'}
            />
          </FlexItem>
          <FlexItem>
            <ColorLegend
              scale={uniScoresColorScale}
              title={'other cell measures'}
            />
          </FlexItem>
        </FlexColumn>
      );
    }
  };

  return (
    <FlexBanner>
      <FlexBannerGroup>
        <FlexItem>
          <MenuButton
            onClick={() =>
              isSettingsModalOpen
                ? setIsSettingsModalOpen(false)
                : setIsSettingsModalOpen(true)
            }
            icon={<SettingsIcon />}
            text={'Open/Close controls'}
            tooltipPlacement={'bottom'}
            margin={'0'}
          />
        </FlexItem>
        <FlexItem>
          <MenuButton
            onClick={setContinueSimulation}
            icon={<ContinueIcon />}
            text={'Continue Expanding Graph'}
            tooltipPlacement={'bottom'}
            margin={'0'}
          />
        </FlexItem>
        {universeSelector ? (
          <FlexItem>
            <SelectorDropdown
              label={'Universe'}
              value={universeSelector.value}
              options={universeSelector.options}
              setValue={handleUniverseSelect}
            />
          </FlexItem>
        ) : null}
        <FlexItem>
          <SelectorDropdown
            label={'Similarity'}
            value={settings.edgeType}
            options={edgeOptions}
            setValue={handleEdgeTypeSelect}
          />
        </FlexItem>
        <FlexItem>
          <SelectorDropdown
            label={'Colored by'}
            value={settings.nodeColorBy}
            optionKey={'field'}
            options={settings.data_fields}
            setValue={handleColorByChange}
          />
        </FlexItem>
        <FlexItem>
          { settings.mouseSelectionType === 'none' ?
            <MenuButton
              onClick={() => updateSettings('mouseSelectionType', 'add')}
              icon={<SelectionIcon />}
              isActive={false}
              text={'Mouse Selection - Enable'}
              tooltipPlacement={'bottom'}
              margin={'0'}
            />
          : settings.mouseSelectionType === 'add' ?
            <MenuButton
              onClick={() => updateSettings('mouseSelectionType', 'remove')}
              icon={<SelectionIcon />}
              isActive={true}
              text={'Mouse Selection - Change to Remove'}
              tooltipPlacement={'bottom'}
              margin={'0'}
            />
          :
            <MenuButton
              onClick={() => updateSettings('mouseSelectionType', 'none')}
              icon={<DeSelectionIcon />}
              isActive={true}
              text={'Mouse Selection - Turn Off'}
              tooltipPlacement={'bottom'}
              margin={'0'}
            />
          }
          <MenuButton
            onClick={() => setIsSelectedModalOpen((prev) => !prev)}
            notificationCount={selectedCompounds?.data.length}
            icon={<SelectIcon />}
            isActive={isSettingsModalOpen}
            text={'Show/Edit Selected Compounds'}
            tooltipPlacement={'bottom'}
            margin={'0'}
          />
        </FlexItem>
        {datasets && (
          <FlexItem>
            <UniverseLibrarySelector
              datasets={datasets}
              settings={settings}
              updateSettings={updateSettings}
            />
          </FlexItem>
        )}
        <SearchFieldAutocomplete
          searchText={searchText}
          searchResults={searchResults.map((v) => (v.label))}
          onChange={handleSearchTextChange}
          onSelect={handleSearchSelect}
          width={250}
          hideIcon={true}
          hasShowButton
        />
      </FlexBannerGroup>
      <FlexBannerGroup></FlexBannerGroup>
      <FlexBannerGroup>
        {getLegend(settings)}
        <FlexSpacer width={5} />
      </FlexBannerGroup>
      <DraggableModal
        title={'Graph Settings'}
        modalOpen={isSettingsModalOpen}
        onCloseClick={() => setIsSettingsModalOpen(false)}
        width={350}
        height={ settings.mode === 'cell_health' ? 90 : 310}
      >
        <SettingsPanel
          settings={settings}
          updateSettings={updateSettings}
          edgeForceWeight={edgeForceWeight}
          setEdgeForceWeight={setEdgeForceWeight}
        />
      </DraggableModal>
      <DraggableModal
        title={'Selected Compounds'}
        modalOpen={isSelectedModalOpen}
        onCloseClick={() => setIsSelectedModalOpen(false)}
        width={280}
        height={300}
      >
        <SelectedCompoundsPanel
          selectedCompounds={selectedCompounds}
        />
      </DraggableModal>
    </FlexBanner>
  );
};

export default UniverseBanner;
