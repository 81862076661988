import React, { useState } from "react";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import {RequestT} from "../hooks/useRequests";
import RequestFormFiles from "../files/RequestFormFiles";
import { RequestFormsContainer, RequestFormsHeader } from "../common/flexStyles";

const UserRequestInfo:string = 'For this request to be fulfilled by AsedaSciences and its partners '
 + 'you must perform the actions in the Steps To Complete list.'

interface PropsI {
  viewMode: string;
  request: RequestT;
}

const debug = false;
const AssayDataForms = (props: PropsI) => {
  const {viewMode, request} = props;
  const [numberDataFiles, setNumberDataFiles] = useState<number>(0);

  function getHeader() {
    return viewMode === 'user' ? 'User Actions Required' : 'Partner Supplied Information';
  }

  if (debug) console.log('AssayRequestForms | request:', request, 'numberDataFiles:', numberDataFiles);

  return (
    <RequestFormsContainer>
      <RequestFormsHeader>{getHeader()} {viewMode === 'user'? <HoverInfoText text={UserRequestInfo}/> : null }</RequestFormsHeader>
        <RequestFormFiles
          viewMode={viewMode}
          formType='assay_data'
          formName='Assay_Data'
          allowEdit={false}
          request={request}
          setNumberForms={setNumberDataFiles}
        />
    </RequestFormsContainer>
  );
};

export default AssayDataForms;
