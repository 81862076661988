import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}


const CompoundDrawIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color} strokeWidth="15" d="M255,31.6l-194,112v224.1l194.1,112l57.3-148.1" />
        <circle fill={color} cx="255" cy="31.4" r="25.8" />
        <circle fill={color} cx="61" cy="144.5" r="25.8" />
        <circle fill={color}  cx="255" cy="479.9" r="25.8" />
        <circle fill={color}  cx="306.6" cy="327.1" r="25.8" />
        <ellipse transform="matrix(6.652053e-02 -0.9978 0.9978 6.652053e-02 -309.6626 403.8109)" className="st2"
                 cx="61" cy="367.4" rx="25.8" ry="25.8" />
        <path fill={colorHighlight} d="M448.5,85.3c-13.6-8.3-31.3-4.2-39.7,9.3l-7.6,12.4l-80.9,130.5l-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.1
		c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.2-0.1,0.3-0.2,0.6c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.2,0,0.3-0.1,0.5
		c0,0.1-0.1,0.1-0.1,0.1l-6.9,76.1c-0.2,2.2,0.8,4.2,2.7,5.4c1.3,0.8,2.9,1.1,4.4,0.7c0.6-0.1,1.1-0.4,1.7-0.7l64.9-40.1
		c0.1,0,0.1,0,0.1-0.1c0.2-0.1,0.4-0.2,0.4-0.3c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.2-0.4,0.4-0.5
		c0.1-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.1-0.2l88.5-142.8c8.3-13.6,4.2-31.3-9.3-39.7L448.5,85.3z M381.4,268.5l-48.2-29.9
		L408,118l48.2,29.9L381.4,268.5z M330.1,250.4l42.2,26.1l-47.2,29.1L330.1,250.4z M466.8,130.5l-4.5,7.4L414,108.1l4.6-7.5
		c5.1-8.1,15.7-10.6,23.8-5.6l18.9,11.7C469.3,111.9,471.8,122.5,466.8,130.5z" />
      </svg>

    </>
  )
}

export default CompoundDrawIcon;