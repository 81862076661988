import React from 'react';
import styled from 'styled-components/macro';
import { ButtonBase, CircularProgress } from '@mui/material';

interface TextButtonPropsT {
  text: string;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  isDisabled?: boolean;
  isWarning?: boolean;
  iconRight?: boolean;
  margin?: string;
  fontSize?: number;
  width?: number;
  height?: number;
  isLoading?: boolean;
}

const TextButtonBasic = (props: TextButtonPropsT) => {
  const {
    text,
    icon,
    onClick,
    isActive = false,
    isWarning = false,
    iconRight = false,
    fontSize = 14,
    margin = '2px 4px',
    width = 100,
    height = 40,
  } = props;

  const getButtonContents = () => {
    if (iconRight) {
      return (
        <>
          <LabelContainer fontSize={fontSize}>{text}</LabelContainer>
          <IconContainer>{icon}</IconContainer>
        </>
      );
    } else {
      return (
        <>
          <IconContainer>{icon}</IconContainer>
          <LabelContainer fontSize={fontSize}>{text}</LabelContainer>
        </>
      );
    }
  };
  if (props?.isDisabled) {
    return (
      <TextButtonContainer margin={margin} width={width} height={height}>
        <DisabledIconButton onClick={() => {}} className={''}>
          {getButtonContents()}
        </DisabledIconButton>
      </TextButtonContainer>
    );
  }

  if (props?.isLoading) {
    return (
      <TextButtonContainer margin={margin} width={width} height={height}>
        <DisabledIconButton onClick={() => {}} className={''}>
          <CircularProgress color='error' size={24} />
        </DisabledIconButton>
      </TextButtonContainer>
    );
  }

  return (
    <TextButtonContainer
      margin={margin}
      width={width}
      height={height}
      isWarning={isWarning}
    >
      <IconButton onClick={onClick} className={isActive ? 'active' : ''}>
        {getButtonContents()}
      </IconButton>
    </TextButtonContainer>
  );
};
export default React.memo(TextButtonBasic);

const TextButtonContainer = styled.div<{
  margin: string;
  height: number;
  width: number;
  isWarning?: boolean;
}>`
  display: flex;
  margin: ${(p) => p.margin};
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  opacity: 1;
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.palette.textSecondary};
  color: ${(p) => (p.isWarning ? 'white' : p.theme.palette.textPrimary)};
  background-color: ${(p) =>
    p.isWarning
      ? p.theme.palette.accentPrimary
      : p.theme.palette.backgroundQuaternary};
  :hover {
    border: 1px solid ${(p) => p.theme.palette.textPrimary};
    background-color: ${(p) =>
      p.isWarning
        ? p.theme.palette.accentPrimary
        : p.theme.palette.backgroundTertiary};
  }
`;
TextButtonContainer.defaultProps = {
  isWarning: false,
};

const IconButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  filter: grayscale(1) brightness(${(p) => p.theme.menu.inActive.brightness})
    contrast(${(p) => p.theme.menu.inActive.contrast});
  :hover {
    filter: grayscale(0) brightness(${(p) => p.theme.menu.active.brightness})
      contrast(${(p) => p.theme.menu.active.contrast});
    border-radius: 10px;
  }
  &.active {
    filter: grayscale(0) brightness(${(p) => p.theme.menu.active.brightness})
      contrast(${(p) => p.theme.menu.active.contrast});
    //background-color: ${(p) => p.theme.palette.backgroundPrimary};
  }
`;

const DisabledIconButton = styled(IconButton)`
  filter: grayscale(1) brightness(${(p) => p.theme.menu.disabled.brightness})
    contrast(${(p) => p.theme.menu.disabled.contrast});
  :hover {
    filter: grayscale(1) brightness(${(p) => p.theme.menu.disabled.brightness})
      contrast(${(p) => p.theme.menu.disabled.contrast});
  }
  &.MuiButtonBase-root {
    color: #444444;
    background-color: #9999;
    border-radius: 5px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const LabelContainer = styled.div<{ fontSize: number }>`
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  font-size: ${(p) => p.fontSize}px;
  white-space: nowrap;
`;
