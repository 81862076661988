import React from 'react';
import styled from 'styled-components/macro';
import { ButtonBase } from '@mui/material';

interface PropsI {
  id: string,
  name: string,
  onClick: ()=>void;
}

const Chip = (props:PropsI) => {
  const { id, name, onClick } = props;
  return (
    <Label key={id} onClick={onClick}>{name}</Label>
  );
};

export default Chip;

const Label = styled(ButtonBase)`
  position: relative;
  font-size: 12px;
  height: 20px;
  width: max-content;
  color: ${(p) => p.theme.palette.textPrimary };

  &.MuiButtonBase-root {
    border: none;
    border-radius: 4px;
    padding: 1px 4px;
    margin: 2px;
    background-color: ${(props) => props.theme.palette.accentPrimary};
  }
`;

