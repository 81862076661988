import React, { useContext } from 'react';
import { HistogramBase, PlotConfig } from '../../plot.types';
import { PlotContext } from '../plot.context';

import { Theme } from '@nivo/core';
import Histogram from '@dataviz/histogram/Histogram';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';

type HistogramWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<HistogramBase>;
  isSmallChart?: boolean;
};

export const HistogramWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: HistogramWrapperProps) => {
  const plot =
    providedPlot ||
    (
      useContext(PlotContext) as {
        plot: PlotConfig<HistogramBase>;
      }
    ).plot;

  const data: number[] = plot.data.map((d) => d[plot.config.valueColumn]);

  return (
    <Histogram
      data={data}
      margin={isSmallChart ? DEFAULT_SMALL_GRAPH_MARGIN : undefined}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      datavizTheme={datavizTheme}
      binNumber={plot.config.binNumber}
      xAxis={{
        name: plot.config.valueColumn,
      }}
    />
  );
};
