import React, {useContext} from 'react';
import styled from "styled-components/macro";
import {UserContext} from '@stores/UserContext';
import SubscriptionOrderButton from "@business/subscriptions/components/SubscriptionOrderButton";
import BasicTable from '@as_core/tables/BasicTable';

import { SubscriptionsData } from './subscriptions.data';

const debug = false;
const SubscriptionCurrent = () => {
  const { user } = useContext(UserContext);
  if (debug) console.log('SubscriptionCurrent | user: ', user);
  return(
    <SubscriptionCurrentContent>
      <BasicTable
        fields={SubscriptionsData['columns']}
        rows={SubscriptionsData['rows']}
        debug={debug}/>
      <SubscriptionOrderButton />
    </SubscriptionCurrentContent>
  )
}

export default SubscriptionCurrent;

const SubscriptionCurrentContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
