import React from 'react';
import {Cell, Row} from "../../RowElements";
import HoverOverlay from "../../HoverOverlay";
import styled from "styled-components/macro";
import {Button} from "@mui/material";
import {FlexRow, FlexItem} from "@components/layout/FlexStyles";
import {TextSmallBold, TextDataItalics} from "@components/elements/TextStyles";
import {truncateString} from "@utils/misc";

interface ColumnField {
  label: string;
  required?: boolean;
  matched_column: string | string[];
  unMatched?: boolean;
  multiple?: boolean;
}

interface RowBuilderRequiredProps {
  columnFields: ColumnField[];
  onSelect: (index: number) => void;
  onDeselect: (index: number) => void;
  shouldAddStyledCell?: boolean;
}


const debug: boolean = process.env.REACT_APP_HOST_TYPE === 'dev';
const RowBuilderRequired = (props: RowBuilderRequiredProps) => {
  const { columnFields, onSelect, onDeselect, shouldAddStyledCell } = props;
  if (debug) console.log("RowBuilderRequired | columnFields", columnFields);
  let label = "Assign to";
  function showValues(value: string | string[]) {
    if (typeof value === 'string') return value;
    const values = value.join(', ');
    return truncateString(values, 60) + ' [' + value.length + ']';
  }

  function showUnMapped(value: string | string[]) {
    if (value.length === 0 || typeof value === 'string') return 'Automatically Assigned';

    const values = value.join(', ');
    return truncateString(values, 60) + ' [' + value.length + ']';      
    

  }

  return (
    <>
      {columnFields.map((column, columnIdx) => {
        return (
            <StyledRow key={columnIdx}>
              <StyledCell>
                <FlexRow width={'100%'} h_centered>
                  <FlexItem>
                    <TextSmallBold color={'accentSecondary'}>{column.required ? '*' : ''}</TextSmallBold>
                  </FlexItem>
                  <FlexItem>{column.label}</FlexItem>
                </FlexRow>
              </StyledCell>
              <StyledCell>
                { column?.unMatched ?
                    <TextDataItalics>{showUnMapped(column?.matched_column)}</TextDataItalics>
                    : column.matched_column === '' || (column?.multiple && column.matched_column.length === 0) ?
                    <StyledButton
                        onClick={() => {
                          onSelect(columnIdx);
                        }}
                    >{label}</StyledButton>
                    : !column?.unMatched ?
                    <HoverOverlay onClick={()=>onDeselect(columnIdx)}>
                      {showValues(column.matched_column)}
                    </HoverOverlay>
                        :
                        <>{showValues(column.matched_column)}</>
                }
              </StyledCell>
              {shouldAddStyledCell && (
                <StyledCell>
                  
                </StyledCell>
              )}

            </StyledRow>
        )
      })}
    </>
  );
};

RowBuilderRequired.defaultProps = {
  shouldAddStyledCell: false,
};

export default RowBuilderRequired;

const StyledRow = styled(Row)`
  height: 35px;
`;

const StyledCell = styled(Cell)<{color?: string}>`
  color: ${(p) => p.theme.palette.textPrimary };
  font-weight: normal;
`;

const StyledButton = styled(Button)`
  height: 35px;
  color: white;
  background: ${(p) => p.theme.palette.backgroundTertiary };
`;