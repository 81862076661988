import React, { RefObject, useContext } from 'react';
import { ScatterWrapper } from './vizWrappers/ScatterWrapper';
import { BarWrapper } from './vizWrappers/BarWrapper';
import { PlotContext } from './plot.context';
import { Box } from '@mui/material';
import { PieWrapper } from './vizWrappers/PieWrapper';
import { BoxplotWrapper } from './vizWrappers/BoxplotWrapper';
import { VolcanoplotWrapper } from './vizWrappers/VolcanoplotWrapper';
import { Theme } from '@nivo/core';
import { LineWrapper } from './vizWrappers/LineWrapper';
import { DoseResponseCurveWrapper } from './vizWrappers/DoseResponseCurveWrapper';
import { HistogramWrapper } from './vizWrappers/HistogramWrapper';

interface PlotPanelProps {
  datavizTheme?: Theme;
  reference?: RefObject<HTMLDivElement>;
}

// Note on datavizTheme
// It provides details for plot styles (grid, axes, labels...)
// Individual viz component know how to define their theme. But it can also be defined here
// for the snapshotting feature which passes a specific setup for the screenshot! (no margin, tiny font size...)

export const PlotPanel = ({ datavizTheme, reference }: PlotPanelProps) => {
  const { plot } = useContext(PlotContext);

  return (
    <Box ref={reference} style={{ height: '100%', width: '100%' }}>
      {plot?.config?.base === 'pie' && (
        <PieWrapper datavizTheme={datavizTheme} />
      )}

      {plot?.config?.base === 'line' && (
        <LineWrapper datavizTheme={datavizTheme} />
      )}

      {plot?.config?.base === 'bar' && (
        <BarWrapper datavizTheme={datavizTheme} />
      )}

      {plot?.config?.base === 'histogram' && (
        <HistogramWrapper datavizTheme={datavizTheme} />
      )}

      {plot?.config?.base === 'boxplot' && (
        <BoxplotWrapper datavizTheme={datavizTheme} />
      )}

      {plot?.config?.base === 'volcano' && (
        <VolcanoplotWrapper datavizTheme={datavizTheme} />
      )}

      {plot?.config?.base === 'drc' && (
        <DoseResponseCurveWrapper datavizTheme={datavizTheme} />
      )}

      {(plot?.config?.base === 'scatter' || plot?.config?.base === null) && (
        <ScatterWrapper datavizTheme={datavizTheme} />
      )}
    </Box>
  );
};
