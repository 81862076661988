import { ProductCategoryT} from "@business/products/products.types";

export const ProductsCategoriesFull:ProductCategoryT[] = [
  {
    categoryCode: "AS-SYS",
    categoryType: "Screen",
    categoryName: "SYSTEMETRIC\u00ae Cell Health Screen"
  },
  {
    categoryCode: "AS-ZBE",
    categoryType: "Screen",
    categoryName: "ZBEscreen\u2122 Zebrafish Screen"
  },
  {
    categoryCode: "AS-BMVK",
    categoryType: "Screen",
    categoryName: "Biomimetic Valko Screen"
  },
  {
    categoryCode: "AS-CQC-SST",
    categoryType: "Screen",
    categoryName: "Compound QC - Solubility and Stability"
  }
]