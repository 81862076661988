import React, { useContext, useEffect, useState } from 'react';
import { PlotPanel } from './plot-panel/PlotPanel';
import { Drawer } from '@mui/material';
import {
  CameraAlt,
  Done,
  Settings,
  Insights,
  Print,
  ViewList,
} from '@mui/icons-material';
import { ConfigPanel } from './config-panel/ConfigPanel';
import { PlotConfigModal } from '../plot-config-modal/PlotConfigModal';
import {
  ScreenshotContext,
  ScreenshotProvider,
} from '@components/screenshot/screenshot.context';
import { PlotAction, PlotBase, PlotConfig, PlotProps } from './plot.types';
import { PlotProvider } from './plot-panel/plot.context';
import { ScreenshotFrame } from './ScreenshotFrame';
import { ActionButtons } from './ActionButtons';
import { ViewDataPanel } from './view-data-panel/ViewDataPanel';

interface SinglePlotViewProps extends PlotProps<PlotBase> {
  plot: PlotConfig<PlotBase>;
  modalOpen?: boolean;
  isDrawerEditorOpen?: boolean;
}

enum View {
  PLOT,
  GRID,
}

/**
 * Wrapper to add the screenshot context
 * @param props
 * @constructor
 */
export const SinglePlotView = (props: SinglePlotViewProps) => {
  return (
    <ScreenshotProvider>
      <PlotMedia {...props} />
    </ScreenshotProvider>
  );
};

export const PlotMedia = ({
  plot: initialPlot,
  onChange,
  modalOpen: initialModal,
  isDrawerEditorOpen,
}: SinglePlotViewProps) => {
  const [plot, updatePlot] = useState(initialPlot);
  const [modalOpen, setModalOpen] = useState(initialModal);
  const [view, setView] = useState(View.PLOT);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const screenshotContext = useContext(ScreenshotContext);

  if (!plot.config) {
    plot.config = {
      base: 'scatter',
      authors: ['Test User'],
    };
    updatePlot({ ...plot });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isDrawerEditorOpen) {
        setIsEditModeEnabled(true);
      }
    }, 300);
    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const editButtonHandler = () => {
    const open = !isEditModeEnabled;
    if (open && view === View.GRID) setView(View.PLOT);
    setIsEditModeEnabled(open);
  };

  const updateConfig = (config: PlotConfig<PlotBase>) => {
    updatePlot({ ...config });
    onChange && onChange(config);
  };

  const openExport = () => screenshotContext.openModal();

  const openPrint = () => screenshotContext.print();

  const toggleView = () => {
    if (view === View.PLOT) {
      setView(View.GRID);
    } else if (view === View.GRID) {
      setView(View.PLOT);
    }
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const editIcon = isEditModeEnabled ? <Done /> : <Settings />;
  const viewIcon = view === View.PLOT ? <ViewList /> : <Insights />;
  const viewName = view === View.PLOT ? 'View Data' : 'View Plot';

  const actions = [
    { icon: viewIcon, name: viewName, onClick: toggleView },
    // { icon: <Description />, name: 'Download', onClick: download },
    { icon: <Print />, name: 'Print', onClick: openPrint },
    { icon: <CameraAlt />, name: 'Export Image', onClick: openExport },
    // { icon: <NoteAlt />, name: 'Plot Wizard', onClick: openWizard },
  ] as Array<PlotAction>;

  return (
    <PlotProvider plot={plot}>
      <>
        {view === View.PLOT && <PlotPanel />}
        {view === View.GRID && <ViewDataPanel />}
        <ScreenshotFrame />
      </>
      <ActionButtons
        icon={editIcon}
        actions={actions}
        onClick={editButtonHandler}
      />

      <PlotConfigModal
        plot={plot}
        open={modalOpen}
        onSave={updateConfig}
        onClose={onModalClose}
      />
      <Drawer
        anchor={'right'}
        open={isEditModeEnabled}
        onClose={() => {
          setIsEditModeEnabled(false);
        }}
        PaperProps={{
          sx: { width: 300, padding: '25px' },
        }}
      >
        <ConfigPanel onChange={updateConfig} />
      </Drawer>
    </PlotProvider>
  );
};
