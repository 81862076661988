import React from 'react';
import styled from 'styled-components/macro';
import {Warning} from "@mui/icons-material";

interface propsT {
  message: string;
}

const ErrorMessage = (props: propsT) => {
  return (
    <ErrorMessageContainer>
      <Warning style={{fontSize: "16px", color: "yellow"}} />
      <Message>{props.message}</Message>
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-bottom: 5px;
`;

const Message = styled.div`
  font-size: 12px;
  padding-left: 5px;
  color: #F44336;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

