import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {zoomIdentity, ZoomTransform} from 'd3';
import {SHOW_NODE_DETAIL_ZOOM_LEVEL} from "@components/universe/config/scale";
import {UseObjectArrayReturnType} from "@as_core/hooks/useObjectArray";
import {GraphSettingsT, XYCoordinate, VertexT, EdgeT, DataSetT, AesSettingsT} from "./types";
import {UpdateSettingsT} from "./CompoundUniverse";
import { createSimulation, updateSimulation } from './utils/force';
import { createDelaunay } from './utils/delaunay';
import VectorPane from '@components/universe/graph-layers/VectorPane';
import EdgeWebGLPane from '@components/universe/graph-layers/EdgeWebGLPane';
import Canvas2DPane from '@components/universe/graph-layers/Canvas2DPane';
import DetailHovered from '@components/universe/graph-layers/DetailHovered';
import DetailSelectedPinned from '@components/universe/graph-layers/DetailSelectedPinned';
import ContextMenu from "@components/universe/elements/ContextMenu/ContextMenu";
import GraphSelector from "@components/universe/graph-layers/GraphSelector";
import {SelectBoxDetailsT} from "@components/universe/graph-layers/GraphSelector";
import {isInScreenRegion} from "@components/universe/utils/vector";

export const deepCopy = (arr) => arr.map((item) => Object.assign({}, item));

const SelectBoxInitial: SelectBoxDetailsT = {xClick: -10, yClick: -10, x1: -10, y1: -10, x2: -10, y2: -10};

interface GraphPropsI {
  input: DataSetT;
  settings: GraphSettingsT;
  updateSettings: (f: string, v: UpdateSettingsT) => void;
  aesSettings: { vertex?: {[key: string]: AesSettingsT}, edges?: {[key:string]: EdgeT[]}, };
  edgeForceFactor: number;
  continueSimulation: boolean;
  selectedCompounds: UseObjectArrayReturnType;
  pinnedCompounds: UseObjectArrayReturnType;
}

const Graph = (props:GraphPropsI) => {
  const {
    input,
    settings,
    updateSettings,
    aesSettings,
    edgeForceFactor,
    continueSimulation,
    selectedCompounds,
    pinnedCompounds
  } = props;
  // eslint-disable-next-line
  const simulation = useRef<any>();
  const [transform, setTransform] = useState<ZoomTransform>(zoomIdentity);
  const [showVectorVertices, setShowVectorVertices] = useState<boolean>(false);
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<XYCoordinate>({x: null, y: null});
  const [selectInProcess, setSelectInProcess] = useState<boolean>(false);
  const [selectBoxVisible, setSelectBoxVisible] = useState<boolean>(false);
  const [selectBoxState, setBoxSelectState] = useState<'add' | 'remove'>('add');
  const [selectBoxDetails, setSelectBoxDetails] = useState<SelectBoxDetailsT>(SelectBoxInitial);
  const [selectBoxVertices, setSelectBoxVertices] = useState<VertexT[]>([]);
  // eslint-disable-next-line
  const [delaunay, setDelaunay] = useState<any>(createDelaunay(input.vertices));
  const [cursor, setCursor] = useState<string>('pointer');
  const [hoveredVertexLabel, setHoveredVertexLabel] = useState<string>('');
  const [iteration, setIteration] = useState(0);
  const data = useRef<{ vertices: VertexT[], edges: EdgeT[] }>({ vertices: [], edges: [] });
  const { mouseSelectionType, selectedVertex} = settings;

  const edgeType = settings.edgeType;

  function handleZoom(transform: ZoomTransform) {
      setTransform(transform);
  }

  // context menu moving off menu
  const handleMouseMove = (event) => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    if (selectBoxVisible) {
      if (mouseX < selectBoxDetails.xClick) {
        if (mouseY < selectBoxDetails.yClick) {
          setSelectBoxDetails((prev) => ({...prev, x1: mouseX, y1: mouseY}));
        } else {
          setSelectBoxDetails((prev) => ({...prev, x1: mouseX, y2: mouseY}));
        }
      } else if (mouseY < selectBoxDetails.yClick) {
        setSelectBoxDetails((prev) => ({...prev, x2: mouseX, y1: mouseY}));
      } else {
        setSelectBoxDetails((prev) => ({...prev, x2: mouseX, y2: mouseY}));
      }

      const includedVertices = data.current.vertices
        .filter((v) => isInScreenRegion(transform, selectBoxDetails, {x: v.x, y: v.y}))
      setSelectBoxVertices(includedVertices);
    }
    // Access mouse coordinates
    if (contextMenuOpen) {
      if (mouseX - contextMenuPosition.x < -10 || mouseX - contextMenuPosition.x > 75 ||
          mouseY - contextMenuPosition.y < -10 || mouseY - contextMenuPosition.y > 100) {
        setContextMenuOpen(false);
      }
    }
  };

  const resetSelected = () => {
    updateSettings('selectedVertex', undefined);
    updateSettings('selectedNeighborVertices', []);
    updateSettings('selectedEdges', []);
    // pinnedCompounds.reset();
  }

  // reset and set selection and zoom when edge type changes
  useEffect(() => {
    resetSelected();
  }, [edgeType]);

  // set the data depending on edge type
  useEffect(() => {
    data.current = {
      vertices: deepCopy(input.vertices),
      edges: deepCopy(input.edges[edgeType]),
    };
    // setTransform(zoomIdentity);
  }, [input, edgeType]);

  // in the case that the search/select function from the banner is used, the vertices list at the CompoundUniverse level
  // does not have the x,y positions, so need to reset to the one from data useRef -- and make sure does not pan/zoom
  // if there is not the x,y in the VectorPane.tsx
  useEffect(() => {
    if (selectedVertex && !selectedVertex?.x) {
      const newSelected = data.current.vertices.find((v) => v.id === selectedVertex.id);
      updateSettings('selectedVertex', newSelected);
    }
  }, [selectedVertex]);

  // initialise the force simulation
  useEffect(() => {
    simulation.current = createSimulation();
    simulation.current.stop().on('tick', () => {
      setIteration((i) => (i > 5000 ? 0 : ++i));
    });

    const currentSimulation = simulation.current;
    return () => currentSimulation.stop();
  }, []);

  // handle the selectBox state change
  const applySelection = () => {
    const ids = selectBoxVertices.map((v) => v.id);
    if (selectBoxState === 'add') {
      selectedCompounds.addList(ids);
    } else {
      selectedCompounds.deleteList(ids);
    }
    setSelectBoxVertices([]);
    setSelectBoxDetails(SelectBoxInitial);
  }

  const captureKeyboardChanges = (e) => {
    if (e.key.toUpperCase() !== 'A' && e.key.toUpperCase() !== 'D') return;
    if (e.type === 'keydown') {
      setCursor('crosshair');
      setSelectInProcess(true);
      if (e.key.toUpperCase() === 'A') {
        setBoxSelectState('add');
        updateSettings('mouseSelectionType', 'add');
      } else {
        setBoxSelectState('remove');
        updateSettings('mouseSelectionType', 'remove');
      }
    } else {
      setSelectInProcess(false);
      updateSettings('mouseSelectionType', 'none');
      setCursor('pointer');
    }
  };

  const captureMouseClickChanges = (e) => {
    if (selectInProcess && e.button === 0) {
      if (e.type === 'mouseup') {
        setSelectBoxVisible(false);
        applySelection();
      } else if (e.type === 'mousedown') {
        setSelectBoxVisible(true);
        setSelectBoxDetails({
          xClick: e.clientX, yClick: e.clientY,
          x1: e.clientX, y1: e.clientY,
          x2: e.clientX, y2: e.clientY
        });
      }
    }
  };

  useEffect(() => {
    if (mouseSelectionType === 'none' && selectInProcess) {
      setSelectInProcess(false);
      setCursor('pointer');
    } else if (mouseSelectionType === 'add') {
      setSelectInProcess(true);
      setCursor('crosshair');
      setBoxSelectState('add');
    } else if (mouseSelectionType === 'remove' && selectBoxState !== 'remove') {
      setSelectInProcess(true);
      setCursor('crosshair');
      setBoxSelectState('remove');
    }
  }, [mouseSelectionType]);

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('keyup', captureKeyboardChanges);
    window.addEventListener('keydown', captureKeyboardChanges);
    window.addEventListener('mousedown', captureMouseClickChanges);
    window.addEventListener('mouseup', captureMouseClickChanges);

    // Make sure to clean up event listeners
    return () => {
      window.removeEventListener('keyup', captureKeyboardChanges);
      window.removeEventListener('keydown', captureKeyboardChanges);
      window.removeEventListener('mouseup', captureMouseClickChanges);
      window.removeEventListener('mousedown', captureMouseClickChanges);
    }
  }, [captureKeyboardChanges, captureMouseClickChanges]);

  useEffect(() => {
    simulation.current.stop();
    simulation.current = updateSimulation(
      simulation.current,
      settings,
      edgeForceFactor,
      data.current.vertices,
      data.current.edges,
    );
    simulation.current.alpha(1).restart();
  }, [data.current.edges, continueSimulation]);

  useEffect(() => {
    simulation.current.stop();
    simulation.current = updateSimulation(
      simulation.current,
      settings,
      edgeForceFactor,
      data.current.vertices,
      data.current.edges,
    );
    simulation.current.alpha(1).restart();
  }, [edgeForceFactor]);

  // change from the circles (distant) to the corona (close)
  useEffect(() => {
    setShowVectorVertices(transform.k > SHOW_NODE_DETAIL_ZOOM_LEVEL);
  }, [transform, selectedVertex, settings.selectedVertex]);

  useEffect(() => {
    setDelaunay(createDelaunay(data.current.vertices));
  }, [iteration, data]);

  // console.log('iteration', iteration, continueSimulation);

  return (
    <UniverseContainer key={'UniverseContainer'}
      onContextMenu={(e) => {
        e.preventDefault(); // prevent the default behavior
        setContextMenuOpen(prev => !prev);
        setContextMenuPosition({x: e.pageX, y: e.pageY});
      }}
      onMouseMove={(e)=>handleMouseMove(e)}
    >
      <ContextMenu
        open={contextMenuOpen}
        setOpen={setContextMenuOpen}
        position={contextMenuPosition}
        label={hoveredVertexLabel}
        settings={settings}
        selectedCompounds={selectedCompounds}
        pinnedCompounds={pinnedCompounds}
      />
      <GraphSelector
        open={selectBoxVisible}
        position={selectBoxDetails}
        state={selectBoxState}
      />
      <DetailHovered
        transform={transform}
        settings={settings}
        pinnedCompounds={pinnedCompounds}
        aesSettings={aesSettings.vertex}
        contextMenuOpen={contextMenuOpen}
        hoveredVertexLabel = {hoveredVertexLabel}
      />
      <DetailSelectedPinned
        key={'detailProperties'}
        vertices={data.current.vertices}
        pinnedCompounds={pinnedCompounds}
        settings={settings}
        resetSelected={resetSelected}
        aesSettings={aesSettings.vertex}
      />
      <WebGLWrapper>
        <EdgeWebGLPane
          iteration={iteration}
          data={data.current}
          settings={settings}
          aesSettings={aesSettings.edges}
          transform={transform}
        />
      </WebGLWrapper>
      <CanvasWrapper>
        <Canvas2DPane
          iteration={iteration}
          data={data.current}
          settings={settings}
          pinnedCompounds={pinnedCompounds}
          aesSettings={aesSettings.vertex}
          transform={transform}
          showVertices={!showVectorVertices}
          selectedCompounds={selectedCompounds}
          inSelectBoxVertices={selectBoxVertices}
        />
      </CanvasWrapper>
      <VectorWrapper>
        <VectorPane
          data={data.current}
          pinnedCompounds={pinnedCompounds}
          selectedCompounds={selectedCompounds}
          settings={settings}
          updateSettings={updateSettings}
          delaunay={delaunay}
          aesSettings={aesSettings.vertex}
          transform={transform}
          zoomed={handleZoom}
          showVertices={showVectorVertices}
          setHoveredVertexLabel = {setHoveredVertexLabel}
          cursor={cursor}
          selectInProcess={selectInProcess}
        />
      </VectorWrapper>
    </UniverseContainer>
  );
};

export default Graph;

const UniverseContainer = styled.div`
`;

const WebGLWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 2;
`;

const VectorWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 3;
`;
