import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Step, Stepper, StepLabel } from '@mui/material';
import useFileImport from '../useFileImport';
import MenuButton from '@components/elements/MenuButton';
import BackArrowIcon from '@components/icons/backArrow.icon';
import DataUploadIcon from '@components/icons/dataUpload.icon';
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';

interface UploadDataStepperProps {
  steps: string[];
  hideNextSteps?: number[];
  handleBack: () => void;
  handleNext: () => void;
  handleReset: () => void;
  nextAvailable?: boolean;
  hideUpload?: boolean;
  uploadStep?: number;
  className?: string;
  noJustify?: boolean; // Add the noJustify prop here
  hideStepsArrow?: number[];
}

const debug = false;
const UploadDataStepper = (props: UploadDataStepperProps) => {
  const {
    steps,
    hideNextSteps = [],
    handleBack,
    handleNext,
    nextAvailable = true,
    hideUpload = false,
    uploadStep = -1,
  } = props;
  const { context } = useFileImport();

  useEffect(() => {
    if (debug) console.log(context.activeStep);
  }, [context.activeStep]);

  return (
    <Container className={props.className} noJustify={props.noJustify}>
      <ButtonContainer>
        {context.activeStep < uploadStep ? (
          <MenuButton
            text={'Back'}
            tooltipPlacement={'bottom'}
            onClick={handleBack}
            icon={<BackArrowIcon size={32} />}
            width={220}
            height={40}
            margin={'10px'}
          />
        ) : (
          <></>
        )}
      </ButtonContainer>
      <StepperContainer>
        <StyledStepper activeStep={context.activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </StyledStepper>
      </StepperContainer>
      {context.activeStep === uploadStep &&
      !hideUpload &&
      !hideNextSteps.includes(context.activeStep) ? (
        <ButtonContainer>
          <MenuButton
            text={'Upload Data'}
            tooltipPlacement={'bottom'}
            onClick={handleNext}
            icon={<DataUploadIcon size={32} />}
            width={220}
            height={40}
            margin={'0'}
          />
        </ButtonContainer>
      ) : context.activeStep < steps.length - 1 &&
        nextAvailable &&
        !hideNextSteps.includes(context.activeStep) ? (
        <ButtonContainer>
          <MenuButton
            tooltipPlacement={'bottom'}
            text={'Next'}
            onClick={handleNext}
            icon={<ForwardArrowIcon size={32} />}
            width={220}
            height={40}
            margin={'0'}
          />
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Blank>&nbsp;</Blank>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default UploadDataStepper;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props: { noJustify?: boolean }) =>
    props.noJustify ? 'normal' : 'space-between'};
  min-width: 600px;
  width: 99%;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
`;

const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 99%;
`;

const StyledStepper = styled(Stepper)`
  && {
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
    width: inherit;
  }
  .MuiStepConnector-line {
    border-color: ${(p) => p.theme.palette.accentSecondary};
  }
  .MuiSvgIcon-root {
    color: ${(p) => p.theme.palette.backgroundPrimary};
    fill: ${(p) => p.theme.palette.textSecondary};
  }
  .MuiStepLabel-label {
    color: ${(p) => p.theme.palette.textSecondary};
  }

  .MuiStepLabel-label.Mui-active {
    color: ${(p) => p.theme.palette.accentSecondary};
  }
  .MuiStepIcon-root.Mui-active {
    fill: ${(p) => p.theme.palette.accentSecondary};
  }

  .MuiStepLabel-label.Mui-completed {
    color: ${(p) => p.theme.palette.accentPrimary};
  }
  .MuiStepIcon-root.Mui-completed {
    fill: ${(p) => p.theme.palette.accentPrimary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  width: 50px;
`;

const Blank = styled.div`
  display: flex;
  min-width: 220px;
  max-width: 220px;
`;
