import React, { useRef, useEffect, useState, useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
import {UseObjectArrayReturnType} from "@as_core/hooks/useObjectArray";
import {ZoomTransform} from "d3";
import { initCanvas } from '../utils/graphics';
import {drawVertices, drawShadowVertices} from '../utils/vertex';
import {AesSettingsT, EdgeT, GraphSettingsT, VertexT} from "../types";

interface PropsI {
  iteration: number;
  data: {vertices: VertexT[], edges: EdgeT[]};
  settings: GraphSettingsT;
  pinnedCompounds: UseObjectArrayReturnType;
  aesSettings: {[key: string]: AesSettingsT};
  transform: ZoomTransform;
  showVertices: boolean;
  selectedCompounds: UseObjectArrayReturnType;
  inSelectBoxVertices: VertexT[];
}

const Canvas2DPane = (props:PropsI) => {
  const {
    iteration,
    data,
    settings,
    pinnedCompounds,
    aesSettings,
    transform,
    showVertices,
    selectedCompounds,
    inSelectBoxVertices
  } = props;
  const [appStyle] = useContext(StyleContext);

  // convenience to set shorter parameter names -- still need the useEffect to trigger off settings
  const { width, height } = settings.dimensions;

  const canvasRef = useRef<HTMLCanvasElement>();
  const [ctx, setCtx] = useState(null);

  useEffect(() => {
    initCanvas(canvasRef.current, width, height);
    const context = canvasRef.current.getContext('2d');
    setCtx(context);
  }, [settings.dimensions]);

  useEffect(() => {
    if (ctx) {
      ctx.save();
      ctx.clearRect(0, 0, width, height);
      ctx.translate(transform.x, transform.y);
      ctx.scale(transform.k, transform.k);
      drawShadowVertices(
        ctx,
        data.vertices,
        pinnedCompounds.data,
        settings.hoveredVertex,
        settings.selectedVertex,
        settings.hoveredNeighborVertices,
        settings.selectedNeighborVertices,
        settings.radiusFactor,
        settings.selectedDatasetId,
        aesSettings,
        appStyle.palette,
        selectedCompounds,
        inSelectBoxVertices
      );
      if (showVertices) {
        drawVertices(
          ctx,
          data.vertices,
          settings.hoveredVertex,
          settings.selectedVertex,
          settings.hoveredNeighborVertices,
          settings.selectedNeighborVertices,
          settings.nodeColorBy,
          settings.radiusFactor,
          aesSettings
        );
      }
      ctx.restore();
    }
  }, [
    iteration,
    ctx,
    data,
    settings,
    transform,
    showVertices,
    inSelectBoxVertices
  ]);

  return <canvas ref={canvasRef} />;
};

export default Canvas2DPane;
