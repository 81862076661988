import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { UserContext } from '@stores/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import AccountDetails from '@as_core/account/AccountDetails';
import Preferences from './accountManagement/Preferences';
import RepoManagement from '@subApps/account/repositoryManagement/RepoManagement';
import SubscriptionManagement from './subscriptionManagement/SubscriptionManagement';

const Titles = {
  repositories: 'Manage My Repositories',
  subscriptions: 'Manage My Team',
  user: 'Manage My Profile',
  preferences: 'Manage My Preferences',
};

function getTitle(view: string) {
  if (Object.hasOwn(Titles, view)) return Titles[view];
  return view;
}
const debug = false;

const PgUserAccount = () => {
  const { view } = useParams();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user.subApp !== 'Account') {
      setUser((prev) => ({ ...prev, subApp: 'Account' }));
    }

    if (debug) {
      console.log('PgUserAccount - Current View:', view, 'User:', user);
    }
  }, [view, user, setUser]);

  const renderContent = useMemo(() => {
    switch (view) {
      case 'user':
        return <AccountDetails key='account-details' />;
      case 'subscriptions':
        return <SubscriptionManagement key='subscription-management' />;
      case 'repositories':
        return <RepoManagement key='repo-management' />;
      case 'preferences':
        return <Preferences key='preferences' />;
      default:
        return <div>Invalid View</div>;
    }
  }, [view]);
  return (
    <PageContainer title={getTitle(view)}>
      <UserAccount>{renderContent}</UserAccount>
    </PageContainer>
  );
};

export default PgUserAccount;

const UserAccount = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px;
  width: calc(100vw - 76px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
`;
