import React from 'react';
import styled from 'styled-components/macro';

interface Node {
  data: {
    name: string;
    label?: string;
    count: number;
  };
}

export const BarChartTooltip = () => {
  // eslint-disable-next-line react/display-name
  return (node: Node) => {
    if (node.data?.label) {
      return (
        <Panel>
          <div>{node.data.label}</div>
          <div>{node.data.count}</div>
        </Panel>
      );
    } else {
      return (
        <Panel>
          <div>{node.data.name}</div>
          <div>{node.data.count}</div>
        </Panel>
      );
    }
  };
};

const Panel = styled.div`
  background-color: rgba(1, 0, 1, 0.8);
  color: #fff;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
`;
