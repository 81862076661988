export const faqs = {
  'General Questions': [
    {
      question: 'General Q1',
      response: 'General A1'
    },
    {
      question: 'General Q2',
      response: 'General A2'
    }
  ],
  'Search': [
    {
      question: 'Search Q1',
      response: 'Search A1'
    },
    {
      question: 'Search Q2',
      response: 'Search A2'
    }
  ],
  'Subscriptions': [
    {
      question: 'Search Q1',
      response: 'Search A1'
    },
    {
      question: 'Search Q2',
      response: 'Search A2'
    }
  ]
}