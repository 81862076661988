import React, { createContext } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import {AppInfoT} from "@utils/useUserAppInfo";
import {UserRegistrationDataT} from "@utils/useUserRegistrationInfo";

export type RegionT = 'OUS' | 'US';

export interface UserT {
  isLoading: boolean;              // make sure that AuthWrapper has time to check for user authentication session and data
  isAuthenticated: boolean;        // first level account creation (Cognito)
  isEmailVerified: boolean;        // second level email verification (Cognito)
  isRegistered: boolean;           // user registration -- done at create account and stored in AsedaSciences Core API (global)
  isAppAuthorized: boolean;        // anything related to the particular app
  authId: string;                  // holds the Cognito ID
  authEmail: string;               // holds the email used with the cognito ID
  authSession: CognitoUserSession, // when authenticated holds all the cognito authentication information
  authRoles: Array<string>;        // holds the user roles as assigned through Cognito Groups
  regInfo?: UserRegistrationDataT; // holds the user registration retrieved after authentication
  userRegion: RegionT;             // holds the region used for stripe
  appInfo: AppInfoT;               // holds the user-specific application information retrieved after authentication
  subApp: string;                  // hold the subApp context for the left side menus
}

interface UserContextProps {
  user: UserT | null;
  setUser: React.Dispatch<React.SetStateAction<UserT | null>>;
}

export const UserEmpty:UserT = {
  isLoading: true,
  isAuthenticated: false,
  isEmailVerified: false,
  isRegistered: false,
  isAppAuthorized: false,
  authId: null,
  authEmail: null,
  authSession: null,
  authRoles: [],
  regInfo: null,
  userRegion: null,
  appInfo: {
    subscription: {
      uuid: '',
      type: 'basic',
      ownerAuthId: '',
      ownerAuthEmail: '',
      allowedInvites: 0,
      allowedRepositories: 0,
    },
    repositories: {
      current: {uuid: '', repo: null, accessType: 'readOnly'},
      owned: [],
      shared: []
    }
  },
  subApp: '3RnD'
}

export const UserContext = createContext<UserContextProps>({user: UserEmpty, setUser: null});
