import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MarkT {
  value: number;
  label: string;
}

export interface FilterSliderT {
  type: string;
  uuid: string;
  field: string;
  title: string;
  value: number[];
  step: number;
  min: number;
  max: number;
  marks: MarkT[];
}

export interface FilterStringT {
  type: string;
  uuid: string;
  field: string;
  comparator: string;
  value: string;
}

export interface FilterExistsT {
  type: string;
  uuid: string;
  field: string;
  comparator: string;
}

export interface FilterSelectT {
  type: string;
  uuid: string;
  field: string;
  value: string[];
}

export interface ExtentT {
  [propName: string]: {
    max: number | null;
    min: number | null;
    step?: number | null;
  };
}

export interface FilterStateT {
  filters: Array<FilterExistsT | FilterSliderT | FilterStringT | FilterSelectT>;
  filterExtents: ExtentT;
}

const initialState: FilterStateT = {
  filters: [],
  filterExtents: {
    exact_mol_wt: {
      min: 10,
      max: 1500,
      step: 10,
    },
    heavy_atom_mol_wt: {
      min: 10,
      max: 1000,
      step: 10,
    },
    lipinski_hbd: {
      min: 0,
      max: 42,
      step: 1,
    },
    lipinski_hba: {
      min: 0,
      max: 67,
      step: 1,
    },
    rotatable_bonds: {
      min: 0,
      max: 90,
      step: 1,
    },
    aromatic_rings: {
      min: 0,
      max: 15,
      step: 1,
    },
    polar_surface_area: {
      min: 0,
      max: 1250,
      step: 10,
    },
    heavy_atom_count: {
      min: 1,
      max: 200,
      step: 1,
    },
    qed_weighted: {
      min: 0.0,
      max: 1.0,
      step: 0.02,
    },
    crippen_log_p: {
      min: -20.0,
      max: 35.0,
    },
    fs_p3: {
      min: 0,
      max: 1,
      step: 0.1,
    },
    c_sp3: {
      min: 0,
      max: 1,
      step: 0.1,
    },
  },
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilter: (
      state,
      { payload }: PayloadAction<{ value: string[]|number[]; uuid: string }>
    ) => {
      const idx = state.filters.findIndex((x) => x.uuid === payload.uuid);
      console.log('idx: ', idx, 'payload', payload, 'filters', state.filters);
      if (idx !== -1) state.filters[idx]['value'] = payload.value;
    },
    updateLogicalFilter: (
      state,
      { payload }: PayloadAction<{ comparator: string; uuid: string }>
    ) => {
      const idx = state.filters.findIndex((x) => x.uuid === payload.uuid);
      state.filters[idx]['comparator'] = payload.comparator;
    },
    addFilter: (
      state,
      { payload }: PayloadAction< FilterSliderT | FilterStringT | FilterSelectT | FilterExistsT >
    ) => {
      // console.log('filterSlice.addFilter {payload}', payload);
      state.filters.push(payload);
    },
    removeFilter: (state, { payload }: PayloadAction<{ uuid: string }>) => {
      const idx = state.filters.findIndex((x) => x.uuid === payload.uuid);
      if (idx > -1) {
        state.filters.splice(idx, 1);
      }
    },
    clearAllFilters: (state) => {
      state.filters = [];
    },
  },
});

export const {
  updateFilter,
  updateLogicalFilter,
  addFilter,
  removeFilter,
  clearAllFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
