import React from 'react';
import styled from 'styled-components/macro';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  id: string;
  label?: string;
  value: string[];
  options: Option[];
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  onClose?: (event: React.SyntheticEvent) => void;
}

const MultiSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  onClose,
}: MultiSelectProps) => {
  return (
    <StyledMultiSelect
      id={id}
      variant='filled'
      label={label}
      value={value}
      disableUnderline
      multiple
      onChange={onChange}
      onClose={onClose}
    >
      {options.map((item, index) => (
        <MenuItem key={`${item.value}-${index}`} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </StyledMultiSelect>
  );
};

export default MultiSelect;

const StyledMultiSelect = styled(Select)`
  display: flex;
  width: 100%;

  & .MuiSelect-select {
    padding: 0;
  }
  & .hover {
    border: 0;
  }
  & .MuiSelect-select:before {
    border: 0;
  }
  & .MuiFilledInput-input.MuiSelect-select {
    color: ${(p) => p.theme.palette.textSecondary};
  }
`;
