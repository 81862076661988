import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const DataFolderSharedIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={colorHighlight} d="M23.7,20.2h-9.4c-.2-.6-.7-1-1.3-1h-1.5c-.6,0-1.1.4-1.3,1H.7v.8h9.5c.2.5.7.9,1.3.9h1.5c.6,0,1.1-.4,1.3-.9h9.4v-.8Z"/>
      <rect fill={color} x="11.8" y="16.3" width=".9" height="2.1"/>
      <path fill={color} d="M20.7,1.8h-9l.9.9c.1.1.3.3.6.3h8.6c0-.7-.5-1.2-1-1.2h-.1Z"/>
      <path fill={color} d="M21.7,3.8h-8.6c-.3,0-.8-.1-1.2-.4l-2.2-2c-.3-.3-.7-.4-1-.4H2.2c-.7,0-1.2.5-1.2,1.3v13.4c0,.8.5,1.3,1.2,1.3h19.7c.7,0,1.2-.5,1.2-1.3V5.1c0-.8-.5-1.3-1.2-1.3h-.1ZM15.4,16.1h-7.3c-.3,0-.7-.5-.6-1,.2-2.4.7-4.3,2.5-5-.5-.5-.8-1.2-.8-2,0-1.4,1-2.6,2.3-2.6s2.3,1.2,2.3,2.6-.3,1.4-.8,2c1.8.7,2.6,2.6,3,5.1,0,.5-.2,1-.6,1h-.1Z"/>
    </svg>
  )
}

export default DataFolderSharedIcon;