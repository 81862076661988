import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import useRequests, { RequestT, RequestUpdateT } from '../hooks/useRequests';
import BasicTable from '@as_core/tables/BasicTable';
import RequestDetails from "../common/RequestDetails";
import {getRequestsTableRows} from "../utils/renderValues";
import RequestsProcessMap from '../common/RequestsProcessMap';
import RequestProcessMap from '../common/RequestProcessMap';
import useVendors from '../hooks/useVendors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const tableFields: BasicTableFieldsT[] = [
  { value: 'view', label: '', type: 'view', width: 40 },
  { value: 'status', label: 'Fulfilment Status', type: 'status', width: 150},
  { value: 'prod_name', label: 'Request Type', width: 240 },
  { value: 'name', label: 'Name', type: 'shortString', width: 200 },
  { value: 'created_at', label: 'Created', type: 'date', width: 100 },
  { value: 'quantity', label: 'Quantity', type: 'quantity', width: 100 },
  { value: 'updated_at', label: 'Last Updated', type: 'datetime', width: 150 },
];

type PropsT = {
  requestType: string;
  requests: RequestT[];
  showProcessMap?: boolean;
}

const debug = false;
const RequestsTable = (props:PropsT) => {
  const { requests, requestType, showProcessMap=true } = props;
  const queryClient = useQueryClient();
  const { updateRequest } = useRequests();
  const { getRequestType } = useVendors();
  const [selectedRequest, setSelectedRequest] = useState<RequestT>(null);
  const [requestsRows, setRequestsRows] = useState<RequestT[]>([]);

  if (debug) console.log('user/RequestsTable | {requests.length, requestType}:',
        requests.length, requestType);

  const mutation = useMutation({
    mutationFn: (param: {id: string, updates: RequestUpdateT}) => updateRequest(param.id, param.updates),
    onSuccess: (data) => {
      if (debug) console.log('user/RequestsTable | mutation | OnSuccess | data:', data);
      setSelectedRequest(data);
      queryClient.invalidateQueries({ queryKey: ['requests', 'user'] }).then();
      queryClient.invalidateQueries({ queryKey: ['requests', 'order', selectedRequest.id] }).then();
    }
  });

  const handleRequestUpdate = async (id: string, updates: RequestUpdateT) => {
    if (debug) console.log('user/RequestsTable | handleRequestUpdate {id, updates}:', id, updates);
    mutation.mutate({id: id, updates: updates})
  }

  const handleClick = (clickType: string, requestId: string) => {
    if (debug) console.log('user/RequestsTable | handleClick: ', requestId);
    const matched:RequestT = requests.find((o) => o.id === requestId);
    if (matched) {
      setSelectedRequest(matched);
    } else {
      console.error(`user/RequestsTable | unable to locate request by id ${requestId})`);
    }
  };
  requests.sort((a,b) => a.name < b.name ? -1 : 1);

  useEffect(() => {
    if (requests !== undefined) {
      const updatedRequests = requests.map((r) => ({
        ...r, itemType: getRequestType(r), userEmail: ''
      }))
      setRequestsRows(getRequestsTableRows('open', 'user', updatedRequests, tableFields, handleClick));
    }
  }, [requests]);

  useEffect(() => {
    setSelectedRequest(null);
  }, [requestType])

  if (debug) console.log('user/RequestsTable | requests:', requests);
  return (
    <>
      { requests.length ?
        <RequestTableContainer>
          { selectedRequest !== null ?
          <>
            { showProcessMap && (
            <>
              <RequestProcessMap
                requestType={requestType}
                request={selectedRequest}
              />
            </>
            )}
            <RequestDetails
              action={'open'}
              viewMode='user'
              request={selectedRequest}
              handleRequestUpdate={handleRequestUpdate}
              userEmails={{}}
              handleBack={()=>setSelectedRequest(null)}
            />
          </>
          :
          <>
            { showProcessMap && (
              <>
                <RequestsProcessMap
                requestType={requestType}
                requests={requests}
                />
              </>
            )}
            <BasicTable
              fields={tableFields}
              rows={requestsRows}
              border={false}
            />
          </>
          }
        </RequestTableContainer>
      :
          <ErrorMessages messages={[
            <MessageItem key='emi_no_requests'>You do not have any {requestType} requests</MessageItem>
          ]}/>
      }
    </>
  );
};

export default RequestsTable;

const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
