import { CompoundSearch } from '@utils/api';
import { handleError } from '@utils/misc';
import { ResponseT } from '@utils/api/base';
import { AxiosRequestConfig } from 'axios';

export interface ICompoundSearchData {
  uuid: string;
  compoundName: string;
}

export interface ICompoundSearchAll {
  errors: Array<object | string>;
  data: Array<ICompoundSearchData>;
}

const useCompoundSearch = () => {
  const getAll = async (
    token: string,
    library: string
  ): Promise<ResponseT<Array<ICompoundSearchData>>> => {
    const params = { library: library };
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await CompoundSearch.getAll(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getAll ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  return {
    getAll,
  };
};

export default useCompoundSearch;
