import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MagnifiedUrlImageStateT {
  visible: boolean;
  x: number;
  y: number;
  imgurl: string;
}

const initialState: MagnifiedUrlImageStateT = {
  visible: false,
  x: 150,
  y: 150,
  imgurl: 'bd',
};

const magnifiedUrlImageSlice = createSlice({
  name: 'magnifiedUrlImage',
  initialState,
  reducers: {
    setMagnifiedUrlImage: (
      state,
      { payload }: PayloadAction<MagnifiedUrlImageStateT>
    ) => {
      //console.log('setMagnifiedImage');
      return payload;
    },
    hideMagnifiedUrlImage: (state) => {
      //console.log('hideMagnifiedImage');
      state.visible = false;
    },
  },
});

export const { setMagnifiedUrlImage, hideMagnifiedUrlImage } =
  magnifiedUrlImageSlice.actions;

export default magnifiedUrlImageSlice.reducer;
