import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const ForwardArrowIcon = ({
  size = 24,
}: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path stroke={color} strokeMiterlimit={"10"}
            d="M2.6,10.8l0,3.1c0,0.8,0.6,1.4,1.4,1.4h8.4v2c0,0.9,1.1,1.5,1.9,0.9l6.9-5.2c0.6-0.5,0.6-1.4,0-1.9l-6.9-5.2
	c-0.8-0.6-1.9-0.1-1.9,0.9v2.4H3.9C3.1,9.2,2.5,9.9,2.6,10.8z" fill={colorHighlight} />
        </g>
      </svg>
    </>
  )
}

export default ForwardArrowIcon;