import { UserSettings } from '@utils/api/userSettings.api';
import { handleError } from '@utils/misc';
import { ResponseT } from '@utils/api/base';

export interface ITileData {
  _id: string;
  pinnedTiles: string[];
  type: string;
  uuid: string;
  modifiedOn: string; // ISO date format
  createdOn: string; // ISO date format
  modifiedBy: string;
  pinned: string[];
}

export interface IUserSettings {
  errors: Array<object | string>;
  data: ITileData;
}

const useUserSettings = () => {
  const getUserSettings = async (
    token: string,
    settingType: string
  ): Promise<ResponseT<ITileData>> => {
    let resp;

    try {
      resp = await UserSettings.get(token, settingType, null);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUserSettings ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const updateUserSettings = async (
    token: string,
    settingType: string,
    updates: object
  ): Promise<ResponseT<ITileData>> => {
    let resp;

    try {
      resp = await UserSettings.update(token, settingType, updates);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('updateUserSettings ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  return {
    getUserSettings,
    updateUserSettings,
  };
};

export default useUserSettings;
