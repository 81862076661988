import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import {
  TableContents,
  HeaderRow,
  HeaderCell,
  BodyRow,
  BodyCell
} from '../BasicTable/BasicTable';
import { dataRenderer } from "@components/dataRenderer";

interface PropsI {
  fields: {[key: string]: string | number}[];
  columns: {[key: string]: string | number}[];
  colWidth: number;
  border?: boolean;
  fullPage?: boolean;
  pillRows?: boolean;
  debug?: boolean;
}

const BasicColumnTable = (props: PropsI) => {
  const { fields, columns } = props;
  if (props.debug) console.log('BasicColumnTable | {fields, rows}', fields, columns);

  const renderValue = (col, field, index) => {
    let value;
    if (field?.renderer === 'mol_svg') {
      value = _.get(col, 'mol_svg', null);
    } else {
      value = _.get(col, field.value, null);
    }
    if (field.renderer === 'mol_svg') console.log('renderValue: ', value, field);
    if (value === null) return ('');
    if (field?.renderer) return dataRenderer(value, field.renderer, index);
    return (value);
  }

  const getRow = (field, fieldIndex, isHeader) => {
    // props.if (debug) console.log('BasicColumnTable | {field, fieldIndex, isHeader}', field, fieldIndex, isHeader);
    if (isHeader) {
      return (
        <HeaderRow key={`${fieldIndex}`}>
          <HeaderCell key={`basicColumnTable_HeaderCell_${fieldIndex}_label`} width={props.colWidth}>
            {field.label}
          </HeaderCell>
          {columns.map((col, colIndex) =>
            <BodyCell
              key={`basicColumnTable_BodyCell_${fieldIndex}_${colIndex}`}
              width={props.colWidth}
              leftBorder={true}
            >
              {renderValue(col, field, colIndex)}
            </BodyCell>
          )}
        </HeaderRow>
      )
    }
    return (
      <BodyRow key={`${fieldIndex}`} index={fieldIndex}>
        <HeaderCell key={`basicColumnTable_HeaderCell_${fieldIndex}_label`} width={props.colWidth}>
          {field.label}
        </HeaderCell>
        {columns.map((col, colIndex) =>
          <BodyCell
            key={`basicColumnTable_BodyCell_${fieldIndex}_${colIndex}`}
            width={props.colWidth}
            leftBorder={true}
          >
            {renderValue(col, field, colIndex)}
          </BodyCell>
        )}
      </BodyRow>
    )
  }

  return (
    <BasicColumnTableContents key={'basicColumnTable'} fullPage={props.fullPage}>
      <TableContents key={'basicColumnTable_contents'}>
        {fields.map((field, fieldIndex) => getRow(field, fieldIndex, (fieldIndex === 0)))}
      </TableContents>
    </BasicColumnTableContents>
  );
}

BasicColumnTable.defaultProps = {
  debug: false,
  border: false,
  pillRows: false,
  colWidth: 100,
  fullPage: false,
}

export default BasicColumnTable;

const BasicColumnTableContents = styled.div<{ fullPage?: boolean }>`
  display: flex;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  ${p => p.fullPage ?
    `height: calc(100vh - 116px);
   width: calc(100vw - 62px);` :
    `
   height: max-content;
   width: max-content;
   `};
  overflow-y: auto;
  overflow-x: auto;
`;
BasicColumnTableContents.defaultProps = {
  fullPage: false
}

