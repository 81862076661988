import { useMemo } from 'react';
/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components/macro';
import { PlotEdit } from './plot-edit/PlotEdit';
import { PlotCreate } from './plot-create/PlotCreate';
import { useLocation, useParams } from 'react-router-dom';

import { PlotList } from './plot-list/PlotList';

const PgPlots = (props: { className?: string }) => {
  const { className } = props;
  const { plotId } = useParams<'plotId'>();
  const location = useLocation();

  const isDrawerEditorOpen = location?.state?.isDrawerEditorOpen;

  const dataId = location?.state?.dataId;
  const dataType = location?.state?.dataType;
  const columns = location?.state?.columns;

  const view = useMemo(() => {
    if (plotId === 'create' && dataId) {
      return (
        <PlotCreate dataId={dataId} dataType={dataType} columns={columns} />
      );
    } else if (plotId) {
      return (
        <PlotEdit plotId={plotId} isDrawerEditorOpen={isDrawerEditorOpen} />
      );
    } else {
      return <PlotList />;
    }
  }, [plotId, dataId]);

  return <Container className={className}>{view}</Container>;
};

export default PgPlots;

const Container = styled.div`
  height: 100vh;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
