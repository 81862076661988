import React from 'react';
import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import {
  HistogramBase,
  PlotConfig,
} from '@plotting/single-plot-view/plot.types';

interface HistogramModalEditorProps {
  plot?: PlotConfig<HistogramBase>;
  onSave?: (config: PlotConfig<HistogramBase>) => void;
}

export const HistogramModalEditor = ({
  plot,
  onSave,
}: HistogramModalEditorProps) => {
  const updateValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<HistogramBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onSave(newPlotConfig);
  };


  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-value'
        label={'Numeric Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValue}
      />
    </Stack>
  );
};
