import React from "react";
import styled from "styled-components/macro";
import { ITab } from "@pages/PgOurBiology";

interface ExtraProps {
  isActive?: boolean;
}

const Tab = (props: ITab) => {
  const { title, slug, subsections, onClick, section, subsection } = props;

  return (
    <TabContainer>
      <TabTitle
        onClick={() => onClick(slug, subsections[0].slug)}
        isActive={section === slug}
      >
        {title}
      </TabTitle>
      {(section === slug ) && subsections.map(localSubsection => 
        <TabSubtitle
          key={localSubsection.slug}
          onClick={() => onClick(section, localSubsection.slug)}
          isActive={localSubsection.slug === subsection}
        >
          {localSubsection.text}
        </TabSubtitle>
      )}
    </TabContainer>
  )
}

const TabTitle = styled.div<ExtraProps>`
  display: flex;
  font-size: 20px;
  line-height: 110%;
  border-radius: 8px;
  padding: 18px;
  text-align: center;
  transition: .5s;
  margin-bottom: 8px;
  background-color: ${(p) =>
    p.isActive
      ? p.theme.palette.accentPrimary
      : p.theme.palette.backgroundQuaternary};
  &:hover {
    background-color: ${(p) => p.theme.palette.accentPrimary};
    cursor: pointer;
  }
`

const TabSubtitle = styled(TabTitle)`
  display: flex;
  font-size: 16px;
  line-height: 110%;
  padding: 6px;
  margin-left: 30px;
  text-align: left;
`

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 16px;
  width: calc(100% - 20px);
  min-width: calc(100% - 20px);
`;

export default Tab;