import React, { useState } from 'react';
import styled from 'styled-components';

import UniverseListItem from './UniverseListItem';
import Alert from '@components/elements/Alert';
import { FlexItem } from '@components/layout/FlexStyles';
import { TextRegularBold } from '@components/elements/TextStyles';
import DataLoading from '@as_core/elements/DataLoading';
import useUniverses, {UniverseT} from '@components/universe/useUniverses';
import SearchField from '@as_core/components/inputs/SearchField';
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";


export const UniverseList = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const [universes, setUniverses] = useState<UniverseT[]>([]);
  const [universeToDelete, setUniverseToDelete] = useState<string>('');
  const { getAll, deleteUniverse } = useUniverses();

  // HANDLERS

  const updateSearch = (event: { target: { value: string; }; }) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Escape') {
      setSearchText('');
    }
  };

  const handleDeleteClick = (uuid: string) => {
    setUniverseToDelete(uuid);
    setIsDeleteAlertOpen(true);
  };

  const _handleCancel = () => {
    setUniverseToDelete('');
    setIsDeleteAlertOpen(false);
  };

  const _handleDeleteConfirm = () => {
    deleteUniverse(universeToDelete).then();
    setUniverses((prev) => prev.filter((u) => u.uuid !== universeToDelete));
  };

  if (!loaded) {
    getAll().then((universes) => {
      setLoaded(true);
      setUniverses(universes);
    });
  }

  if (!loaded) {
    return (
      <DocumentListContainer>
        <DataLoading />
      </DocumentListContainer>
    );
  }
  let filteredList = universes;
  if (searchText.length > 2) {
    filteredList = universes.filter(
      (d) =>
        d.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  return (
    <DocumentListContainer>
      <FlexItem>
        <TextRegularBold color={'accent'}>
          Your Available Universes
        </TextRegularBold>
      </FlexItem>
      <FlexItem width={'50%'}>
        <SearchField
          placeholder='Search ...'
          value={searchText}
          onChange={updateSearch}
          onKeyDown={handleKeyDown}
        />
      </FlexItem>
      <ScrollContainer>
        { searchText && filteredList.length === 0 ?
          <ErrorMessages
           messages={[
             <MessageItem key={'error_message_1'}>No universes from search filter. Change filter to see matching universes.</MessageItem>
           ]}
          />
          :
          <DocumentListItemContainer>
            {filteredList.map((data, index) => (
              <UniverseListItem
                universe={data}
                handleDeleteClick={handleDeleteClick}
                key={`document-list-item-${index}`}
              />
            ))}
          </DocumentListItemContainer>
        }
      </ScrollContainer>
      <Alert
        type={'deleteConfirm'}
        title={'Delete Universe'}
        message={'Confirm delete by typing DELETE below to Enable Delete'}
        alertOpen={isDeleteAlertOpen}
        onConfirm={_handleDeleteConfirm}
        closeAlert={_handleCancel}
      />
    </DocumentListContainer>
  );
};

const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 84%;
  width: calc(100% - 20px);
`;

const ScrollContainer = styled.div`
  display: flex;
  width: calc(100% - 10px);
  height: 100%;
  padding: 5px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const DocumentListItemContainer = styled.div`
  display: flex;
  flex-grow: initial;
  flex-direction: column;
  width: 100%;
`;
