import React from 'react';
import { Cell, Row } from '../shared/RowElements';
import { truncateString } from '@utils/misc';

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

interface ImportTargetPanelReviewRowProps {
  data: Record<string, string | number | undefined>;
  dataIdx: number;
}


const ImportTargetPanelReviewRow = (props: ImportTargetPanelReviewRowProps) => {
  const { data, dataIdx } = props;
  debug &&
    console.log(
      'ImportTargetPanelReviewRow | data:',
      data,
      ' dataIdx:',
      dataIdx
    );

  function getPreview(data, numAssays) {
    let previewLen = Math.min(4, numAssays);
    let preview = '';
    let i = 0;
    for (const target of Object.keys(data)) {
      if (!['compound name', 'compound', 'smiles'].includes(target)) {
        // console.log(target, data[target]);
        if (i < previewLen) {
          if (i > 0) {
            preview = preview + ', ';
          }
          if (data[target] === undefined) {
            preview += '(' + target + ': NA)';
          } else {
            let targetData = typeof (data[target])!=="number" && data[target].includes("-") ? data[target] : data[target]?.toFixed(2)
            preview += '(' + target + ':' + targetData + ')';
          }
        }
        i++;
      }
    }
    return preview;
  }

  const numAssays = Object.keys(data).length - 2;
  const preview = getPreview(data, numAssays);
  if (debug) console.log('ImportTargetPanelReviewRow | preview:', preview);

  return (
    <Row key={dataIdx}>
      <Cell>{data?.compound}</Cell>
      <Cell>{truncateString(data?.smiles, 20)}</Cell>
      <Cell>{numAssays}</Cell>
      <Cell>{preview}</Cell>
    </Row>
  );
};

export default ImportTargetPanelReviewRow;
