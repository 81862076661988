import React from 'react';
import PageContainer from '@as_core/pages/PageContainer';
import UiHuman from "@components/ui-human/UiHuman";

const PgUiHuman = () => {

  return (
    <PageContainer title='UI-Human'>
      <UiHuman />
    </PageContainer>
  );
};

export default PgUiHuman;
