import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { getBasicPlotConfig } from '@plotting/plot-config-modal/build-plot-config';
import { PlotBase, PlotConfig } from '../single-plot-view/plot.types';
import { SinglePlotView } from '../single-plot-view/SinglePlotView';
import { ScrollBox } from '@components/scrollbox/scrollbox';
import PageContainer from '@as_core/pages/PageContainer';
import useData from '@components/dataSets/useData';
import useCognito from '@as_core/account/useCognito';
import usePlots from '../usePlots';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import useCompounds from '@components/compounds/useCompounds';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { FieldSliceT } from '@stores/fields';
import { viewToColumns } from '../../../components/compounds/view/views.service';


interface Props {
  dataId?: Array<string> | string;
  dataType?: string;
  columns?: Array<string>;
}

const favoriteColumns = [
  {
    label: 'Target',
    value: 'Target',
    type: 'string',
  },
  {
    label: 'Compound Name',
    value: 'Compound Name',
    type: 'string',
  },
  {
    label: 'Ki/IC50 (nM)',
    value: 'Ki/IC50 (nM)',
    type: 'number',
  },
  {
    label: 'Selective Entropy',
    value: 'Selective Entropy',
    type: 'number',
  },
  {
    label: 'Cellular Activity(nm)',
    value: 'Cellular Activity(nm)',
    type: 'string',
  },
  {
    label: 'References',
    value: 'References',
    type: 'string',
  },
  {
    label: 'CAS# (if available)',
    value: 'CAS# (if available)',
    type: 'string',
  },
  {
    label: 'smiles',
    value: 'smiles',
    type: 'string',
  },
  {
    label: 'source',
    value: 'source',
    type: 'string',
  },
  {
    label: 'source_id_symbol',
    value: 'source_id_symbol',
    type: 'string',
  },
  {
    label: 'source_id',
    value: 'source_id',
    type: 'number',
  },
];

const debug = false;
export const PlotCreate = ({ dataId = null, dataType = 'data', columns = [] }: Props) => {
  if (debug) console.log('PlotCreate | dataId:', dataId);
  const [plot, setPlot] = useState({} as PlotConfig<PlotBase>);

  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { getData } = useData();
  const { getToken } = useCognito();
  const { updatePlot } = usePlots();
  const { getCompoundsFinite } = useCompounds();

  const compoundViews = useSelector((state: RootState) => state.compoundViews);
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  const savePlot = async (config) => {
    const update = { ...config };

    setPlot(update);
    // setAltered(false);
    return updatePlot(getToken(), update);
  };

  // create a new plot -- user will need to select data set
  if (!dataId) {
    console.error('You must provide dataId to this component!');
  }

  const getFavorites = async () => {
    const userFieldIds = columns || [];
    const allColumns = viewToColumns(userFieldIds, allFields)
    const plotColumns = allColumns.map((column) => ({
      label: column.title,
      value: column.title,
      type: column.type,
    }));
    function getValueByPath(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    await getCompoundsFinite(
      {},
      // @ts-expect-error -- TSCONVERSION
      compoundViews?.views[dataId]?.compoundIds
    )
      .then((res) => {
        const data = res.data.map(item =>
          allColumns.reduce((acc, column) => {
            acc[column.title] = getValueByPath(item, column.dataKey);
            return acc;
          }, {})
        );
        const userFavoritesColumns = plotColumns.length ? plotColumns : favoriteColumns;
        const initial = getBasicPlotConfig([], [], data, userFavoritesColumns);
        if (debug) console.log('initial:', initial);
        setPlot(initial);
        setDataLoading(false);
        setDataLoaded(true);
        setModalOpen(true);
      });
  };

  if (!dataLoaded && !dataLoading) {
    if (dataId) {
      if (dataType === 'favorites') {
        setDataLoading(true);
        debug &&
          console.log(`PlotCreate | plot from favorite data set ${dataId}`);

        getFavorites();
        setDataLoading(false);
        setDataLoaded(true);
      } else {
        setDataLoading(true);
        if (debug) console.log(`PlotCreate | plot from data set ${dataId}`);
        setErrorMessage('');
        getData(getToken(), dataId)
          .then((response) => {
            if (debug) console.log('Response: ', response);
            let dataSet = response.data;
            let columns = response.columns;
            if (debug) console.log('columns', columns);
            if (debug) console.log('data: ', dataSet);
            const initial = getBasicPlotConfig(
              [],
              [],
              dataSet,
              columns,
              response.dataFormatted
            );
            if (debug) console.log('initial:', initial);
            setPlot(initial);
            setDataLoading(false);
            setDataLoaded(true);
            setModalOpen(true);
          })
          .catch(console.error);
      }
    }
  }

  const onChange = (config: PlotConfig<PlotBase>) => {
    config.data_uuid = typeof dataId === 'string' ? dataId : Array.isArray(dataId) ? dataId[0] : '';
    setPlot({ ...config });
    savePlot(config).then();
  };

  if (debug) console.log('plot', plot);
  if (debug) console.log('plot.data', plot?.data);
  if (debug) console.log('dataLoaded', dataLoaded);
  if (debug) console.log('modalOpen', modalOpen);
  if (debug) console.log('errorMessage', errorMessage);

  return (
    <PageContainer title='Create Plot'>
      <Container>
        <Wrapper>
          {dataLoaded && plot?.data ? (
            <SinglePlotView
              plot={plot}
              onChange={onChange}
              modalOpen={modalOpen}
            />
          ) : errorMessage !== '' ? (
            <ErrorMessages
              messages={[<MessageItem key={0}>{errorMessage}</MessageItem>]}
            />
          ) : null}
        </Wrapper>
      </Container>
    </PageContainer>
  );
};

const Container = styled(ScrollBox)`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 10px);
`;
