import { CompoundT } from "@src/type";

export function getLibraryCompoundIds(selected: Array<string>) {
  let selectedDict = {};
  console.log(selected);
  selected.forEach((item) => {
    const parts = item.split('_');
    if (Object.hasOwn(selectedDict, parts[0])) {
      selectedDict[parts[0]].push(parts[1]);
    } else {
      selectedDict[parts[0]] = [parts[1]];
    }
  });
  console.log(selectedDict);
  let selectedArray = [];
  for (const library of Object.keys(selectedDict)) {
    selectedArray.push({ libraryId: library, compoundIds: selectedDict[library] })
  }
  return selectedArray
}

export function checkDuplicatedRows(data: CompoundT[]) {
  const debug = false;
  let searchKeyCount = {};
  for (const row of data) {
    if (Object.hasOwn(searchKeyCount, row.searchKey)) {
      searchKeyCount[row.searchKey] = searchKeyCount[row.searchKey] + 1;
    } else {
      searchKeyCount[row.searchKey] = 1
    }
  }
  if (debug) console.log('searchKeyCount', searchKeyCount);
  const allKeys = Object.keys(searchKeyCount);
  const duplicatedKeys = allKeys.filter((k) => searchKeyCount[k] > 1);
  if (debug) console.log('allKeys.length', allKeys.length, 'duplicatedKeys.length', duplicatedKeys.length);
}