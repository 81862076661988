import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { PieBase, PlotConfig } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { KeyColorTable } from '@plotting/controls/KeyColorTable';
import { LayerCard } from '../LayerCard';
import { Box } from '@mui/system';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';
import { DiscreteColorPaletteSelect } from '@plotting/controls/ColorPaletteSelect';

type PieCardProps = {
  plot: PlotConfig<PieBase>;
  onChange: (newPlotConfig: PlotConfig<PieBase>) => void;
};

export const PieCard: React.FC<PieCardProps> = ({ plot, onChange }) => {
  const [colorControlType, setColorControltype] = useState<'palette' | 'manual'>('palette');

  const updateGroupByColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateValueColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateIsSorted = (e: React.ChangeEvent<HTMLInputElement>, isSorted: boolean) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, isSorted },
    };
    onChange(newPlotConfig);
  };

  const updateIsDonut = (e: React.ChangeEvent<HTMLInputElement>, isDonut: boolean) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, isDonut },
    };
    onChange(newPlotConfig);
  };

  const updateStyle = (style) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, style },
    };
    onChange(newPlotConfig);
  };

  const groups: string[] = [...new Set(plot.data.map((d) => d[plot.config.groupByColumn]))];
  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors?.scheme || []);

  const defaultKeyColor = groups.map((group) => {
    return {
      id: group,
      color: defaultColorScale(group),
    };
  });

  const updateColorConfigType = (
    event: React.MouseEvent<HTMLElement>,
    type: 'palette' | 'manual'
  ) => {
    if (type !== null) {
      setColorControltype(type);
    }
  };
  const simpleControls = (
    <>
      <VariableSelect
        id='variable-key'
        label={'Grouping Variable'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateGroupByColumn}
      />
      <VariableSelect
        id='variable-value'
        label={'Numeric Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValueColumn}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isSorted}
              onChange={(e) => updateIsSorted(e, e.target.checked)}
            />
          }
          label={<Typography fontSize={12}>Sorted</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox checked={plot.config.isDonut} onChange={(e) => updateIsDonut(e, e.target.checked)} />
          }
          label={<Typography fontSize={12}>Donut</Typography>}
        />
      </Box>
    </>
  );

  const colorControls = (
    <>
      <ToggleButtonGroup
        size='small'
        color='standard'
        value={colorControlType}
        exclusive
        onChange={updateColorConfigType}
        aria-label='color control type'
      >
        <ToggleButton value='palette'>Palette</ToggleButton>
        <ToggleButton value='manual'>Manual</ToggleButton>
      </ToggleButtonGroup>
      {colorControlType === 'palette' && (
        <DiscreteColorPaletteSelect
          values={plot.config.style || defaultKeyColor}
          onChange={updateStyle}
        />
      )}
      {colorControlType === 'manual' && (
        <KeyColorTable
          values={plot.config.style || defaultKeyColor}
          onChange={updateStyle}
        />
      )}
    </>
  );

  return (
    <>
      <LayerCard title={'Pie Chart'} disableExpand>
        {simpleControls}
      </LayerCard>
      <LayerCard title={'Color Control'} disableExpand>
        {colorControls}
      </LayerCard>
    </>
  );
};
