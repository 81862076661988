import React, { useState, useEffect } from 'react';
import SelectSmallcusto from './SelectSmallcusto';
import './ColumnFilter.css';
import InputDate from '@components/dataAnalytics/InputDate';
import DeleteIcon from '@mui/icons-material/Delete';
import AutocompleteCusto from '@components/dataAnalytics/AutocompleteCusto';
import CustomInput from './CustomInput'; // Import your custom input component
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SelectChangeEvent } from '@mui/material/Select';

import SingleInputDateRangePicker from './SingleInputDateRangePicker';
import { Column } from './AggregateAndCalculate';

const menuItems = [
  [
    { value: '1', label: 'contains' },
    { value: '3', label: 'is' },
    { value: '5', label: 'matches' },
  ],
  [
    { value: '6', label: 'after' },
    { value: '7', label: 'on or after' },
    { value: '8', label: 'before' },
    { value: '9', label: 'on or before' },

    { value: '10', label: 'on' },
    { value: '11', label: 'between' },
  ],

  [
    { value: '12', label: 'equal to' },
    { value: '13', label: 'less than' },
    { value: '14', label: 'greater than' },
    { value: '15', label: 'greater than or equal' },
    { value: '16', label: 'between numbers' },
  ],

  [
    { value: '17', label: 'is null' },
    { value: '18', label: 'is not null' },
  ],
];

export interface SelectedDataProps {
  id: number;
  selectedColumn: Column;
  selectedDate?: string;
  value: string;
  AndOR: 'and' | 'or'; // This assumes "AndOR" will only be "and" or "or"
  title: string;
  inputValue?: string;
  minValue?: string;
  selectedLabel: string;
  selectedDatestart?: string;
  selectedDatend?: string;
}

interface ColumnFilterProps {
  columns: Column[];
  selectedData: SelectedDataProps;
  onSelectedDataChange: (data: SelectedDataProps) => void;
  onDeleteFilter: () => void;
  canDelete: boolean;
  title: string;
}

const ColumnFilter = (props: ColumnFilterProps) => {
  const {
    columns,
    selectedData,
    onSelectedDataChange,
    onDeleteFilter,
    canDelete,
    title,
  } = props;
  const [currentInputType, setCurrentInputType] = useState('Text'); // Default to Text
  const [isCaseSensitive, setIsCaseSensitive] = useState(false); // State for checkbox
  const [selectedDateRange] = useState();

  function determineTabFromLabel(label: string) {
    // Here you would match the label to the tab index based on your application's logic
    // For example, if label is 'contains', 'is', 'matches', it would return the Text tab index, which might be 0
    const textLabels = ['contains', 'is', 'matches'];
    const dateLabels = [
      'after',
      'on or after',
      'before',
      'on or before',
      'on',
      'between',
    ];
    const numberLabels = [
      'equal to',
      'less than',
      'greater than',
      'greater than or equal',
    ];
    const nullLabels = ['is null', 'is not null'];

    if (textLabels.includes(label)) return 0;
    if (dateLabels.includes(label)) return 1;
    if (numberLabels.includes(label)) return 2;
    if (nullLabels.includes(label)) return 3;

    return 0; // default to the first tab if not found
  }

  function determineValueForSelectSmall(selectedData: { value: string }) {
    // This function needs to return the value that matches the `value` property of the `menuItems`
    // Assuming `selectedData.value` holds the value that corresponds to the options, you can directly return it
    return selectedData.value;
  }

  const currentTab = determineTabFromLabel(selectedData.selectedLabel);

  // Determine the value for the SelectSmallcusto component based on the selectedData.value
  const selectSmallValue = determineValueForSelectSmall(selectedData);

  useEffect(() => {
    // Update the input type based on the selected tab
    const value = parseInt(selectedData.value); // Convert value to a number

    if (value >= 1 && value <= 5) {
      setCurrentInputType('Text');
    } else if (value >= 6 && value < 11) {
      setCurrentInputType('Date');
    } else if (value === 11) {
      setCurrentInputType('between');
    } else if (value >= 12 && value < 16) {
      setCurrentInputType('Number');
    } else if (value === 16) {
      setCurrentInputType('between number');
    } else if (value >= 17 && value <= 18) {
      setCurrentInputType('Null');
    }
  }, [selectedData.value]);

  const handleDateSelect = (date) => {
    const updatedData = {
      ...selectedData,
      selectedDate: date.$d.toISOString(),
    };
    onSelectedDataChange(updatedData);
  };

  const handleColumnSelect = (column: Column) => {
    const updatedData = { ...selectedData, selectedColumn: column };
    onSelectedDataChange(updatedData);
  };
  const handleDateRangeSelect = (dateRange) => {
    let startDate = dateRange[0];
    let endDate = dateRange[1];
    if (startDate !== null) {
      startDate = startDate.$d.toISOString();
    }
    if (endDate !== null) {
      endDate = endDate.$d.toISOString();
    }

    const updatedData = {
      ...selectedData,
      selectedDatestart: startDate,
      selectedDatend: endDate,
    };

    onSelectedDataChange(updatedData);
  };

  const handleChange = (event: SelectChangeEvent) => {
    let selectedLabel = '';
    for (const menuItemGroup of menuItems) {
      for (const menuItem of menuItemGroup) {
        if (menuItem.value === event.target.value) {
          selectedLabel = menuItem.label;
          break; // Exit the loop once a match is found
        }
      }
      if (selectedLabel) {
        break; // Exit the outer loop if selectedLabel is found
      }
      console.log(selectedLabel);
    }

    const updatedData = {
      ...selectedData,
      value: event.target.value,
      selectedLabel: selectedLabel,
    };
    onSelectedDataChange(updatedData);
  };

  const handleInputTextOrNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedData = { ...selectedData, inputValue: event.target.value };
    onSelectedDataChange(updatedData);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCaseSensitive(event.target.checked);
  };
  const placeholderTitle = 'Select Column';

  return (
    <div className='containerColumn'>
      <div className='columnflex'>
        <div className='filter-title'>{title}</div>
        <AutocompleteCusto
          options={columns}
          placeholder={placeholderTitle}
          onColumnSelect={handleColumnSelect}
          value={selectedData.selectedColumn} // Pass the selected column here
        />

        <SelectSmallcusto
          tabNames={[
            { value: '1', label: 'Text', icon: 'icon-text' },
            { value: '2', label: 'Date', icon: 'icon-date' },
            { value: '3', label: 'Number', icon: 'icon-number' },
            { value: '4', label: 'Null', icon: 'icon-null' },
          ]}
          style={{ marginTop: '-30px' }}
          menuItems={menuItems}
          value={selectSmallValue}
          onChange={handleChange}
          size='small'
          maxwidth='300px'
          minwidth='100px'
          // Pass the selectedTab to ensure we're showing the correct tab during edit mode
          selectedTab={currentTab}
        />

        {currentInputType === 'Text' && (
          <div className='text'>
            <CustomInput
              placeholder='Enter Text Filter...'
              onInput={handleInputTextOrNumberChange}
              value={selectedData.inputValue} // Pass the existing value here
              width={297}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isCaseSensitive}
                  onChange={handleCheckboxChange}
                />
              }
              label='Case Sensitive'
              style={{ color: 'white', marginLeft: '10px' }}
              className='checkbox'
            />
          </div>
        )}

        {currentInputType === 'Date' && (
          <div className='date'>
            <InputDate
              onDateSelect={handleDateSelect}
              selectedDate={selectedData.selectedDate}
            />
          </div>
        )}
        {currentInputType === 'between' && (
          <div className='datebetween'>
            <SingleInputDateRangePicker
              onDateSelect={handleDateRangeSelect}
              selectedDateRange={selectedDateRange}
            />
          </div>
        )}
        {currentInputType === 'Number' && (
          <div className='number'>
            <CustomInput
              placeholder='Enter Text Filter...'
              onInput={handleInputTextOrNumberChange}
              value={selectedData.inputValue} // Pass the existing value here
            />
          </div>
        )}
        {currentInputType === 'between number' && (
          <div>
            <CustomInput
              placeholder='Min Value'
              value={selectedData.minValue}
              onInput={(event) => {
                onSelectedDataChange({
                  ...selectedData,
                  minValue: event.target.value,
                });
              }}
            />
            <CustomInput
              placeholder='Max Value'
              value={selectedData.inputValue}
              onInput={(event) => {
                onSelectedDataChange({
                  ...selectedData,
                  inputValue: event.target.value,
                });
              }}
            />
          </div>
        )}

        <div className='icondelete-container'>
          {canDelete && (
            <DeleteIcon onClick={onDeleteFilter} className='icondelete' />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ColumnFilter);
