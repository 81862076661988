import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import BasicTable from "@as_core/tables/BasicTable";
import IconButton from "@ui-components/controls/IconButton";
import { Cancel } from "@mui/icons-material";
import { Center, Title } from "@subApps/account/shared/flexStyles";
import { UserContext } from "@stores/UserContext";
import { getMaxInvites, SubscriptionSharesT } from "@utils/useUserAppInfo";
import { getObjectValueByKey } from "@utils/misc";
import Alert from "@components/elements/Alert";

const columns = [
  { label: 'Email', value: 'authEmail', type: 'string', width: 280 },
  { label: 'Joined', value: 'sharedOn', type: 'date', width: 90 },
  { label: '', value: 'remove', type: 'action', width: 40 }
];

function getTableRows(subscriptionUsers: SubscriptionSharesT[], handleRemoveUser: (id: string) => void) {
  const rows = [];
  subscriptionUsers.forEach((user) => {
    const row = {};
    columns.forEach((c) => {
      if (c.type === 'date') {
        const value = getObjectValueByKey(user, c.value, '');
        if (value === '') return value;
        row[c.value] = new Date(value).toLocaleDateString();
      } else if (c.type === 'action') {
        if (c.value === 'remove') {
          row[c.value] =
            <IconButton
              tooltip={'Remove from Subscription'}
              icon={<Cancel />}
              onClick={() => handleRemoveUser(user.authId)}
            />
        }
      } else {
        row[c.value] = getObjectValueByKey(user, c.value, '');
      }
    })
    rows.push(row);
  })
  return rows;
}

const debug = false;
const SubscriptionUsers = () => {
  const { user } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const { subscription } = user.appInfo;
  const isOwner = subscription.ownerAuthId === user.authId;
  const maxInvites = getMaxInvites(user);
  const [alertOpen, setAlertOpen] = useState(false);

  if (debug) console.log('SubscriptionUsers | user', user, 'isOwner:', isOwner, 'maxInvites:', maxInvites);
  if (debug) console.log('SubscriptionUsers | subscription', subscription);

  useEffect(() => {
    if (subscription.invitedUsers.length > 0) {
      setRows(getTableRows(subscription.invitedUsers, handleRemoveUser));
    } else {
      setRows([]);
    }
  }, [subscription])

  const handleRemoveUser = (user_id: string) => {
    console.log('handleRemoveUser', user_id);
    setAlertOpen(true);
    alert('')
    // deleteUser(getToken(), orgId, user_id).then((org) => {
    // setOrgs(updateOrg(org, orgs));
    //});
  };

  return (
    <SubscriptionUsersContainer>
      {isOwner ?
        <>
          {!subscription.allowedInvites ?
            <Center>
              <MessageLarge>
                Your Subscription does not allow subscription sharing. However, you can still share your repository with
                other subscribers.
              </MessageLarge>
            </Center>
            :
            <>
              <Title>Users Sharing My Subscription
                ({subscription.invitedUsers.length} of {maxInvites})</Title>
              {subscription.invitedUsers.length < maxInvites ?
                null
                :
                <Center>
                  <Message>At Maximum Invitations: Remove User to invite someone else</Message>
                </Center>
              }
              <Center>
                {rows.length > 0 ?
                  <BasicTable fields={columns} rows={rows} />
                  :
                  <Message>No shared users of this subscription</Message>
                }
              </Center>
            </>
          }
        </>
        :
        <></>
      }
      <Alert
        type={'general'}
        title={'Remove User'}
        message={'Not Implemented Yet.'}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </SubscriptionUsersContainer>
  );
};

export default SubscriptionUsers;

const SubscriptionUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  display: flex;
  font-size: 13px;
  font-style: italic;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const MessageLarge = styled(Message)`
  font-size: 15px;
`;
