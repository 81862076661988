import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import useCompounds from "@components/compounds/useCompounds";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@components/elements/Alert';

import '../components-style/SelectableTable.scss';
import { UserContext } from '@stores/UserContext';


const AbbvieUpload = () => {

  const { user } = useContext(UserContext);
  const user_id = user.authId;
  const [files, setFiles] = useState([
    { name: 'Centred', size: '0 MB', status: 'waiting for upload', type: 'centred' },
    { name: 'Raw', size: '0 MB', status: 'waiting for upload', type: 'raw' }
  ]);
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>([null, null]);
  const [, setUploadStatus] = useState<Record<string, string>>({});
  const { uploadFile, finilizeUploadFile } = useCompounds();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const handleFileSelect = (index: number) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files[0]) {
        const file = target.files[0];
        const updatedFiles = [...selectedFiles];
        updatedFiles[index] = file;
        setSelectedFiles(updatedFiles);
        const updatedFileDetails = files.map(f => {
          if (f.type === (index === 0 ? 'centred' : 'raw')) {
            return {
              name: file.name,
              size: (file.size / (1024 * 1024)).toFixed(2) + ' MB',
              status: 'ready to upload',
              type: f.type
            };
          }
          return f;
        });
        setFiles(updatedFileDetails);
      }
    };
    input.click();
  };
  const handleFileDelete = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles[index] = null;
    setSelectedFiles(updatedFiles);
    const updatedFileDetails = files.map(f => {
      if (f.type === (index === 0 ? 'centred' : 'raw')) {
        return {
          name: f.type,
          size: '0 MB',
          status: 'waiting for upload',
          type: f.type
        };
      }
      return f;
    });
    setFiles(updatedFileDetails);
  };
  const sendFileInChunks = async (file1: Blob, file2: Blob, filename1: string, filename2: string) => {
    const CHUNK_SIZE = 1048576; // 1MB
    const uploadChunks = async (file: Blob, filename: string) => {
      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      console.log(`Total chunks to upload for ${filename}: ${totalChunks}`);
      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('file', chunk, `${user_id}_${filename}_chunk${i}`);
        formData.append('filename', `${user_id}_${filename}`);
        formData.append('start', start.toString());
        formData.append('chunkNumber', i.toString());
        try {
          await uploadFile(formData);
          setUploadStatus(prevStatus => ({
            ...prevStatus,
            [filename]: `Chunk ${i + 1}/${totalChunks} uploaded successfully.`
          }));
          updateFileStatus(filename, `Chunk ${i + 1}/${totalChunks} uploaded successfully.`);
        } catch (error) {
          console.error(`Error uploading chunk ${i + 1} for ${filename}:`, error);
          setUploadStatus(prevStatus => ({
            ...prevStatus,
            [filename]: `Error in uploading chunk ${i + 1}.`
          }));
          updateFileStatus(filename, `Error in uploading chunk ${i + 1}.`);
          return;
        }
      }
    };
    await uploadChunks(file1, filename1);
    await uploadChunks(file2, filename2);
    try {
      console.log('Finalizing upload');
      const formData = new FormData();
      formData.append('filename1', `${user_id}_${filename1}`);
      formData.append('filename2', `${user_id}_${filename2}`);
      const finalizeResponse = await finilizeUploadFile(formData);
      setUploadStatus(prevStatus => ({
        ...prevStatus,
        [filename1]: finalizeResponse.data.message,
        [filename2]: finalizeResponse.data.message
      }));
      if (finalizeResponse.status === 200) {
        updateFileStatus(filename1, 'File started uploading to S3.');
        updateFileStatus(filename2, 'File started uploading to S3.');
      }
    } catch (error) {
      console.error('Error finalizing file upload:', error);
      setUploadStatus(prevStatus => ({
        ...prevStatus,
        [filename1]: 'Error in finalizing file upload.',
        [filename2]: 'Error in finalizing file upload.'
      }));
      updateFileStatus(filename1, 'Error in finalizing file upload.');
      updateFileStatus(filename2, 'Error in finalizing file upload.');
    }
  };
  const handleSubmit = () => {
    if (selectedFiles[0] && selectedFiles[1]) {
      const [file1, file2] = selectedFiles;
      setUploadStatus(prevStatus => ({
        ...prevStatus,
        [file1.name]: 'Compressing file...',
        [file2.name]: 'Compressing file...'
      }));
      updateFileStatus(file1.name, 'Compressing file...');
      updateFileStatus(file2.name, 'Compressing file...');
      const worker = new Worker(new URL('./fileWorker.js', import.meta.url));
      worker.onmessage = async (e) => {
        if (e.data.error) {
          setUploadStatus(prevStatus => ({
            ...prevStatus,
            [file1.name]: e.data.error,
            [file2.name]: e.data.error
          }));
          updateFileStatus(file1.name, e.data.error);
          updateFileStatus(file2.name, e.data.error);
        } else {
          setUploadStatus(prevStatus => ({
            ...prevStatus,
            [file1.name]: 'Starting upload...',
            [file2.name]: 'Starting upload...'
          }));
          updateFileStatus(file1.name, 'Starting upload...');
          updateFileStatus(file2.name, 'Starting upload...');
          await sendFileInChunks(e.data.compressedFile1, e.data.compressedFile2, e.data.originalFileName1, e.data.originalFileName2);
        }
      };
      worker.postMessage({
        file1,
        file2,
        chunkSize: 1048576 // 1MB
      });
    } else {
      setAlertOpen(true);
    }
  };
  const updateFileStatus = (filename: string, status: string) => {
    setFiles(prevFiles =>
      prevFiles.map(file =>
        file.name === filename ? { ...file, status } : file
      )
    );
  };
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Name</th>
            <th style={{ width: '25%' }}>Size</th>
            <th style={{ width: '35%' }}>Status</th>
            <th style={{ width: '20%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index}>
              <td>{file.name}</td>
              <td>{file.size}</td>
              <td>{file.status}</td>
              <td>
                {selectedFiles[index] ? (
                  <DeleteButton onClick={() => handleFileDelete(index)}>
                    <DeleteIcon style={
                      {
                        color: 'white',
                      }
                    } />
                  </DeleteButton>
                ) : (
                  <button onClick={() => handleFileSelect(index)}>Select File</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <FlexCenter>
        <UploadButton
          onClick={handleSubmit}
          isReady={Boolean(selectedFiles[0] && selectedFiles[1])}
        >
          <FileUploadIcon style={{ marginRight: '8px' }} />
          Upload Files
        </UploadButton>
      </FlexCenter>
      <Alert
        type={'general'}
        title={'Alert files selection'}
        message={'Please select exactly two files.'}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </Container>
  );
};
export default AbbvieUpload;
const Table = styled.table`
  width: 580px;
  border-collapse: collapse;
  margin-top: 10px;
  th, td {
    padding: 8px;
    text-align: left;
  }
  th {
    border-bottom: 2px solid #D60B51;
  }
`;
const UploadButton = styled.button<{ isReady: boolean }>`
  background-color: ${(p) => (p.isReady ? '#28A745' : p.theme.palette.accentPrimary)};
  color: ${(p) => p.theme.palette.textPrimary};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${(p) => (p.isReady ? '#218838' : p.theme.palette.accentSecondary)};
  }
  margin-top: 20px;
`;
const Container = styled.div`
  display: grid;
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
