import  { css } from 'styled-components/macro';


// Define a CSS variable with the theme value
const cssVariables = css`
  --background-primary: ${(p) => p.theme.palette.backgroundPrimary};
  --background-secondary: ${(p) => p.theme.palette.backgroundSecondary};
  --background-tertiary: ${(p) => p.theme.palette.backgroundTertiary};
  --accent-primary: ${(p) => p.theme.palette.accentPrimary};
  --accent-secondary: ${(p) => p.theme.palette.accentPrimary};
  --text-primary: ${(p) => p.theme.palette.textPrimary};
  --text-secondary: ${(p) => p.theme.palette.textSecondary};

`;

export default cssVariables;