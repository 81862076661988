import React, { useEffect, useState } from 'react';
import styled,  { css } from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import styles from '../components-style/UploadComponents.module.css';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import cssVariables from '../components/constant_css';
import '../components-style/SelectableTable.scss';

// const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

type onDropType = (files: File[]) => void;

interface UploadComponentV2Props {
    onDrop: onDropType;
}

const UploadComponentV2 = ({ onDrop }: UploadComponentV2Props) => {
    const { acceptedFiles, getRootProps } = useDropzone();

    useEffect(() => {
        if (acceptedFiles.length) {
            onDrop(acceptedFiles);
        }
    }, [acceptedFiles]);
    const choices = [
        {
            label: 'Compound Set w/ Data',
            value: 'compound_set',
        },
        {
            label: 'Target Panel (kinome)',
            value: 'target_panel',
        },
        {
            label: 'Dose Response Curve',
            value: 'dose_response',
        },
        {
            label: 'Generic Plotting Data',
            value: 'generic',
        },
    ];

    const [activeChoice, setActiveChoice] = useState(choices[0].value);

    const handleChoiceClick = (value) => {
        setActiveChoice(value);
    };
    return (
        <Container className='containerInUploadComponentV2'>
            <UploadLeftDiv>
                {choices.map((choice) => (
                    <ChoicesButton
                        key={choice.value}
                        active={activeChoice === choice.value}
                        onClick={() => handleChoiceClick(choice.value)}
                    >
                        {choice.label}
                    </ChoicesButton>
                ))}
            </UploadLeftDiv>
            <UploadRightDiv>
                <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
                    <div className={styles.iconUploadFolder}>
                        <DriveFolderUploadIcon />
                    </div>
                    <TextUploadParagraph>
                        Drag and drop files
                        <br />
                        -OR-
                        <br />
                    </TextUploadParagraph>
                    <ButtonUpload>Click to Select files</ButtonUpload>
                </StyledDropZone>
            </UploadRightDiv>
        </Container>
    );
};

export default UploadComponentV2;
const Container = styled.div`
  ${cssVariables};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
const UploadLeftDiv = styled.div`
${cssVariables};
display: flex;
flex-direction: column;
justify-content: space-evenly;
width: 35%;
height: 70%;
margin-top: 10%;
`;
const UploadRightDiv = styled.div`
${cssVariables};
display: flex;
flex-direction: column;
width: 65%;
height: 70%;
margin-top: 10%;
`;
const StyledDropZone = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;
const TextUploadParagraph = styled.div`
display: flex;
min-height: 50px;
align-items: center;
justify-items: center;
justify-content: center;
border-radius: 20px;
cursor: pointer;
text-align: center;

`;

const ButtonUpload = styled.button`
    width: 55%;
    align-self: center;
    align-items: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    height: 35px;
`;

const activeChoiceStyle = css`
    background-color: rgb(214, 11, 81);
    color: white;
`;

// Define a CSS style for the inactive choices
const inactiveChoiceStyle = css`
    background-color: rgb(148, 148, 148);
    color: black;
`;

interface ChoicesButtonProps {
    active: boolean;
}

const ChoicesButton = styled.button<ChoicesButtonProps>`
    width: 100%;
    align-items: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 30px;
    height: 15%;

    ${(props) => (props.active ? activeChoiceStyle : inactiveChoiceStyle)}
`;

