import React from 'react';
import styled from 'styled-components/macro';
import {useQuery} from '@tanstack/react-query';
import DataLoading from '@as_core/elements/DataLoading';
import {RequestT} from '../hooks/useRequests';
import useRequestStatus from '../hooks/useRequestStatus';
import AssayProcessDetails from '../assay/AssayProcessDetails';
import ConsultingProcessDetails from '../consulting/ConsultingProcessDetails';
import useVendors from "../hooks/useVendors";

type PropsT = {
  view: string;
  request: RequestT;
}

const debug = false;
const RequestProcessDetails = (props:PropsT) => {
  const {view, request} = props;
  const { getRequestStatus } = useRequestStatus();
  const { getRequestType } = useVendors();

  const { isPending, isError, error, data: requestStatus } = useQuery({
    queryKey: ['requestStatus', request.id],
    queryFn: () => getRequestStatus(request),
  })
  if (isError) console.error(error.message);

  if (debug) console.log('RequestProcessDetails | request.id', request.id,
    'react-query: ', isPending, error, requestStatus);

  return (
    <RequestProcessDetailsContainer>
      { isPending ?
        <DataLoading />
      :
        getRequestType(request) === 'consulting' ?
          <ConsultingProcessDetails
            view={view}
            requestStatus={requestStatus}
          />
        :
          <AssayProcessDetails
            view={view}
            requestStatus={requestStatus}
          />
      }
    </RequestProcessDetailsContainer>
  )
}

export default RequestProcessDetails;

const RequestProcessDetailsContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.width};
`;
RequestProcessDetailsContainer.defaultProps = {
  width: 'max-content'
}
