import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import useRepos, {RepositoryDetailsT} from '@subApps/account/repositoryManagement/useRepos';
import RepoDetail from './components/RepoDetail';
import RepoList from "./components/RepoList";
import UserMessages, {MessageItem} from "@as_core/elements/UserMessages";

const debug = false;

const AdminRepoManagement = () => {
  const { user } = useContext(UserContext);
  const { getAllRepositories, getRepository } = useRepos();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [orgs, setOrgs] = useState<RepositoryDetailsT[]>([]);
  const [selectedRepoId, setSelectedRepoId] = useState<string>(null);
  const [selectedRepo, setSelectedRepo] = useState<RepositoryDetailsT>(null);

  if (debug) console.log('AdminRepoManagement | user:', user);
  if (debug) console.log('AdminRepoManagement | orgs:', orgs);

  // HANDLERS
  const onSelect = (repoId: string) => {
    if (debug) console.log('AdminRepoManagement | onSelect repoId', repoId);
    setSelectedRepoId(repoId);
  };

  // DATA LOADING
  useEffect(() => {
    if (!loaded) {
      getAllRepositories().then((repos) => {
        setOrgs(repos);
      });
    }
    setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (selectedRepoId) {
      getRepository(selectedRepoId).then((repoDetails) => {
        if (debug) console.log('RESPONSE repoDetails', repoDetails);
        setSelectedRepo(repoDetails);
      });
    }
  }, [selectedRepoId]);

  if (debug) console.log('AdminRepoManagement | orgs', orgs);
  return (
    <Container>
    { selectedRepoId ?
      <>
        { selectedRepo !== null ?
          <RepoDetail
            key={selectedRepoId}
            repo={selectedRepo}
            backToList={()=>setSelectedRepoId(null)}
          />
          :
          <UserMessages key={'repo-detail-error'} messages={[
            <MessageItem key={'selected-repo-empty'}>
              Selected Repository is empty -- issue with API?
            </MessageItem>
          ]}/>
        }
      </>
    :
      <RepoList
        repos={orgs}
        handleSelect={onSelect}
      />
    }
    </Container>
  );
};

export default AdminRepoManagement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
