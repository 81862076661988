import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const CompareIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "#F2F2F2" : "#8F8F8F";
  return (
    <>
      <svg width={size} height={size} viewBox="-2 -2 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M22.1,6.9c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.2-0.1-0.5-0.3-0.7l-5.3-4.4c-0.4-0.3-0.9-0.3-1.2,0.1
	c-0.3,0.4-0.3,0.9,0.1,1.2l3.5,3H5.8C5.4,5.9,5,6.2,5,6.7c0,0.5,0.4,0.9,0.9,0.9H19l-3.5,2.9c-0.4,0.3-0.4,0.8-0.1,1.2
	c0.3,0.4,0.8,0.4,1.2,0.1l5.3-4.4c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1C22.1,7,22.1,7,22.1,6.9z"/>
          <path fill={color} d="M2.3,17.6c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.2,0.1-0.5,0.3-0.7l5.3-4.4c0.4-0.3,0.9-0.3,1.2,0.1
	c0.3,0.4,0.3,0.9-0.1,1.2l-3.5,3h13.2c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H5.4l3.5,2.9C9.3,21.4,9.3,22,9,22.3
	c-0.3,0.4-0.8,0.4-1.2,0.1L2.5,18c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1C2.3,17.7,2.3,17.6,2.3,17.6z"/>
        </g>
      </svg>
    </>
  )
}

export default CompareIcon;