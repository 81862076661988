import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Fields } from '@utils/api/fields.api';
import { RootState } from "@src/store";

// ###########################################################################
// TYPES
// ###########################################################################

export enum ValueRendererT {
  string = 'string',
  float = 'float',
  integer = 'integer',
  zebrafish = 'zebrafish',
  circle = 'circle',
  mol_svg = 'mol_svg',
  multiselect = 'multiselect',
  singleselect = 'singleselect',
  objectlist = 'objectlist',
  boolean = 'boolean',
}

export interface TableColumnT {
  width: number;
  minWidth: number;
  resizable?: boolean;
  format?: string;
  align?: string;
  sortable?: boolean;
}

export interface ColumnField {
  label: string;
  required?: boolean;
  matched_column: string | string[];
  unMatched?: boolean;
  multiple?: boolean;
}
export interface MatchedColumnsInt {
  asedaColumns?: string;
  asedaValues?: string;
  id?: string;
  inputColumns?: string;
  percentMatch?: number;
}

export interface MatchFieldT {
  data: {
    columns_with_examples: Record<string, any>;
    matched_objects: MatchedColumnsInt[];
    alternative_match: MatchedColumnsInt[];
  };
}

export interface CompoundFieldT {
  _id?: string;
  _source?: string;
  field_id: string;
  group_id: string;
  data_key: string;
  group_name: string;
  group_order: number;
  cpdset_filter?: boolean;
  cfields?: boolean;
  filter?: boolean;
  value_renderer?: ValueRendererT;
  field_options?: string;
  animal_tox_general?: boolean;
  animal_tox_pharma?: boolean;
  table?: TableColumnT;
  category?: string;
  symbol: string;
  short_name: string;
  long_name?: string;
  short_description?: string;
  long_description?: string;
  hidden?: boolean;
}

export interface CompoundFieldsT {
  [propName: string]: CompoundFieldT;
}
export interface FieldSliceT {
  loading: boolean;
  currentRequestId: string | undefined;
  errors: string[];
  fieldIds: string[];
  fields: CompoundFieldsT;
}

const initialState: FieldSliceT = {
  loading: false,
  currentRequestId: undefined,
  errors: [],
  fieldIds: [],
  fields: {},
};

// ###########################################################################
// ACTIONS
// ###########################################################################
export const getFields = createAsyncThunk<
  { fields; fieldIds } & Partial<FieldSliceT>,
  { token: string },
  {
    state: RootState;
    requestId: string;
  }
>('dataFields/getFields', async ({ token }, thunkAPI) => {
  const { loading, currentRequestId } = thunkAPI.getState()
    .fields as FieldSliceT;
  if (loading !== true || currentRequestId !== thunkAPI.requestId) {
    return;
  }
  //console.log('FIELDS3 GET dataFields/getFields');
  const apiResponse = await Fields.all(token);
  const apiPayload = apiResponse.data;
  const payload = {
    fieldIds: [],
    fields: {},
  };
  apiPayload.data.forEach((payloadItem) => {
    payload.fields[payloadItem.field_id] = payloadItem;
    payload.fieldIds.push(payloadItem.field_id);
  });
  return payload;
});

export const updateField = createAsyncThunk(
  'dataFields/updateField',
  async (
    { token, field }: { token: string; field: CompoundFieldT },
    thunkAPI
  ) => {
    const apiResponse = await Fields.update(field, token);
    return apiResponse.data.data[0];
  }
);

export const addField = createAsyncThunk(
  'dataFields/addField',
  async (
    { token, field }: { token: string; field: CompoundFieldT },
    thunkAPI
  ) => {
    const apiResponse = await Fields.add(field, token);
    return apiResponse.data.data[0];
  }
);

export const removeField = createAsyncThunk(
    'dataFields/removeField',
    async (
        { token, id }: { token: string; id: string },
        thunkAPI
    ) => {
      const apiResponse = await Fields.remove(id, token);
      return apiResponse.data.data[0];
    }
);

// ###########################################################################
// SLICE
// ###########################################################################
const fieldSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    clear: (state) => {
      state.fields = {};
      state.fieldIds = [];
    },
    add: (state, { payload }: PayloadAction<CompoundFieldT>) => {
      state.fields[payload.field_id] = payload;
      state.fieldIds.push(payload.field_id);
    },
    update: (state, { payload }: PayloadAction<CompoundFieldT>) => {
      state.fields[payload.field_id] = payload;
    },
    remove: (state, { payload }: PayloadAction<{ id: string }>) => {
      const idx = state.fieldIds.indexOf(payload.id);
      delete state.fields[payload.id];
      state.fieldIds.splice(idx, 1);
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFields.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(getFields.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      //console.log('dataFields requestId', requestId);
      if (state.loading === true && state.currentRequestId === requestId) {
        state.fields = action.payload.fields;
        state.fieldIds = action.payload.fieldIds;
        state.currentRequestId = undefined;
        state.loading = false;
      }
    });

    builder.addCase(updateField.fulfilled, (state, { payload }) => {
      state.fields[payload.field_id] = payload;
    });

    builder.addCase(addField.fulfilled, (state, { payload }) => {
      state.fields[payload.field_id] = payload;
      state.fieldIds.push(payload.field_id);
    });
  },
});
export const { clear, add, update, remove, setLoading } = fieldSlice.actions;
export default fieldSlice.reducer;
