import React from 'react';
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';

type Props = {
  id: string;
  label: string;
  value?: string;
  options: Array<{ label: string; value: string; type?: string }>;
  onChange: (value: string) => void;
  className?: string;
};

export const VariableSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  className,
}: Props) => {
  const handleUpdate = (event) => {
    const value = event.target.value;
    onChange && onChange(value);
  };
  return (
    <FormControl
      id={id}
      fullWidth
      sx={{ maxWidth: 250 }}
      size='small'
      className={className}
    >
      <Typography
        color={'textSecondary'}
        sx={{ marginBottom: '2px', fontSize: 14 }}
      >
        {label}
      </Typography>
      <Select
        value={value}
        onChange={handleUpdate}
        input={<OutlinedInput notched={false} />}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.2)', // TODO: this should be light mode compliant
          },
        }}
      >
        {options.map((opt, i) => (
          <MenuItem key={i} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
