import React, { useContext } from 'react';
import { PlotConfig, ScatterBase } from '../../plot.types';
import { PlotContext } from '../plot.context';
import { ScatterPlot } from 'src/dataviz/scatterplot/ScatterPlot';
import { getScatterplotSeries } from './data-wrangling-utils/get-scatterplot-series';
import { Margin, Theme } from '@nivo/core';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';

type ScatterWrapperProps = {
  datavizTheme?: Theme;
  margin?: Margin;
  providedPlot?: PlotConfig<ScatterBase>;
  isSmallChart?: boolean;
};

export const ScatterWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: ScatterWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<ScatterBase> }).plot;

  const { config } = plot;
  const { xColumn, yColumn, groupByColumn, sizeColumn, name, style } = config;

  const series = getScatterplotSeries(
    plot.data,
    xColumn,
    yColumn,
    groupByColumn,
    sizeColumn,
    name,
  );

  return (
    <ScatterPlot
      data={series}
      margin={isSmallChart ? DEFAULT_SMALL_GRAPH_MARGIN : undefined}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      tickLabelFontSize={plot.config?.tickLabelFontSize}
      axisLabelFontSize={plot.config?.axisLabelFontSize}
      datavizTheme={datavizTheme}
      xAxisName={plot.config.xAxisName ?? xColumn}
      yAxisName={plot.config.yAxisName ?? yColumn}
      xAxisScale={plot.config.xAxisScale}
      yAxisScale={plot.config.yAxisScale}
      circleSize={plot.config.circleSize}
      isLinearRegressionEnabled={plot.config.isLinearRegressionEnabled}
      isLegendEnabled={!isSmallChart}
      colorConfig={style}
    />
  );
};
