import React, { ReactNode } from 'react';
import styled from 'styled-components';

type TooltipContainerProps = {
  children: ReactNode;
  color?: string;
  customStyle?: React.CSSProperties;
};

export const TooltipContainer = ({
  children,
  color,
  customStyle,
}: TooltipContainerProps) => {
  return (
    <TooltipDiv
      data-html2canvas-ignore
      style={{
        border: `solid 1px ${color}`,
        minWidth: 160,
        position: 'absolute',
        ...customStyle,
      }}
    >
      {children}
    </TooltipDiv>
  );
};

const TooltipDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: left;
  border-radius: 2px;
  font-size: 12px;
  padding: 8px;
  z-index: 10;
  pointer-events: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;
