import React, {useContext} from "react";
import styled from "styled-components/macro";
import SubscriptionCard from "./SubscriptionCard";
import TextButton from "@components/controls/TextButton/TextButton";
import {useNavigate} from "react-router-dom";
import {UserContext} from "@stores/UserContext";
import {getSubscriptionDetails} from "@business/products/Subscriptions";
import {getUserSubscriptionType} from "@utils/useUserAppInfo";

const debug = false;
const SubscriptionView = () => {
  const { user } = useContext(UserContext);
  if (debug) console.log('SubscriptionView | user:', user);
  const userSubscription = getUserSubscriptionType(user.appInfo);
  const subscriptionDetails = getSubscriptionDetails(userSubscription);
  const navigate = useNavigate();

  return(
    <SubscriptionViewContents>
      <SubscriptionCard />
      <ActionButtons>
        { !['basic', 'pilot'].includes(subscriptionDetails.level) ?
          <TextButton
            label={'Extend my Subscription'}
            height={28}
            width={200}
            onClick={()=>navigate('/subscriptions/order/extend')}
          />
          : null
        }
        { !['pilot'].includes(subscriptionDetails.level) ?
          <TextButton
            label={'Upgrade my Subscription'}
            height={28}
            width={200}
            onClick={()=>navigate('/subscriptions/order/upgrade')} />
          : null
        }
      </ActionButtons>
    </SubscriptionViewContents>
  )
}

export default SubscriptionView;

const SubscriptionViewContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;