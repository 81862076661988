import React, { useContext } from 'react';
import { PlotConfig, LineBase } from '../../plot.types';
import { PlotContext } from '../plot.context';
import { Theme } from '@nivo/core';
import LineChart from '@dataviz/linechart/LineChart';
import {
  getLineSeries,
  getLineSeriesWithGroupBy,
} from './data-wrangling-utils/get-line-series';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';

type LineWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<LineBase>;
  isSmallChart?: boolean;
};

export const LineWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: LineWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<LineBase> }).plot;

  const { timeColumn, valueColumn, groupByColumn } = plot.config;

  const series = groupByColumn
    ? getLineSeriesWithGroupBy(
        plot.data,
        timeColumn,
        valueColumn,
        groupByColumn,
        plot.config.style
      )
    : getLineSeries(plot.data, timeColumn, valueColumn);

  return (
    <LineChart
      data={series}
      margin={isSmallChart ? DEFAULT_SMALL_GRAPH_MARGIN : undefined}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      datavizTheme={datavizTheme}
      yAxis={{
        name: valueColumn,
        isLogScale: plot.config.isLogScale,
      }}
      xAxis={{ name: timeColumn }}
      isLegendEnabled={!isSmallChart}
    />
  );
};
