import React from "react";
import styled from "styled-components/macro";
import InvitationCards from "@subApps/account/userLoginAlerts/components/InvitationCards";
import Title from "@ui-components/elements/Title";
import {InvitationT} from "@src/type";

interface ShowInvitationsProps {
  invitations: InvitationT[];
  setInvitationsUpdated: (b:boolean)=>void;
}

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
const ShowInvitations = ({invitations, setInvitationsUpdated}: ShowInvitationsProps) => {

  if (debug) console.log('ShowInvitations | invitations: ', invitations);
  if (!invitations.length) {
    return null;
  }
  return(
    <InvitationsContainer>
      <Title variant={'normal'} text={'My Pending Invitations'}/>
      <Cards>
        { invitations.length ?
          <InvitationCards
            invitations={invitations}
            setInvitationsUpdated={setInvitationsUpdated}
          />
          : null}
      </Cards>
    </InvitationsContainer>
  )
}
export default ShowInvitations;

const InvitationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  margin: 5px 5px;
  padding: 5px;
  border-radius: 15px;
`;

const Cards = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;