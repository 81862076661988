import React from 'react';
import styled from 'styled-components/macro';
import { Line } from 'rc-progress';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const ProgressBar = (props: { percentage: number | number[] }) => {
  const { percentage } = props;
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <Line
        percent={percentage}
        strokeWidth={1}
        strokeColor={themeContext.palette.accentPrimary}
      />
    </Container>
  );
};

export default React.memo(ProgressBar);

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  align-items: center;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  margin-bottom: 10px;
`;
