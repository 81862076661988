import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const PinIcon = () => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';
  const colorHighlight = style.palette.accentPrimary;

  return (
    <>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 512 512'
      >
        <g>
          <path
            fill={colorHighlight}
            d='M131.6,129.8l-75.7,44.7c-12.3,7.2-1.3,25.9,11,18.6l75.7-44.7C154.8,141.2,144,122.6,131.6,129.8z'
          />
          <path
            fill={colorHighlight}
            d='M142.6,310.5l-75.7-44.7c-12.4-7.3-23.3,11.4-11,18.6l75.7,44.7C143.8,336.3,155,317.8,142.6,310.5z'
          />
          <path
            fill={colorHighlight}
            d='M223.6,275.1l0-91.2c0-14.3-21.6-14.3-21.6,0l0,91.2C201.9,289.4,223.6,289.4,223.6,275.1z'
          />
          <path
            fill={color}
            d='M385.7,376.2L499.4,309c3.3-1.9,5.3-5.5,5.3-9.3V159.2c0-3.8-2-7.4-5.3-9.3h0L380.4,79.6c-3.4-2-7.6-2-11,0
		l-113.4,67L147.9,82.7l-21.6,0L12.6,149.9c-3.3,1.9-5.3,5.5-5.3,9.3v135.2l11.5,18.4l112.7,66.6c3.4,2,7.6,2,11,0L256,312.3
		l108.1,63.9L385.7,376.2z M245.2,293.6l-108.1,63.9L29,293.6V165.4l108.1-63.9l108.1,63.9L245.2,293.6z M483,293.6l-108.1,63.9
		l-108.1-63.9l0-128.2l108.1-63.9L483,165.4V293.6z'
          />
          <path
            fill={colorHighlight}
            d='M369.4,148.4l75.7,44.7c12.3,7.2,23.4-11.3,11-18.6l-75.7-44.7C368.1,122.6,357.1,141.2,369.4,148.4z'
          />
        </g>
      </svg>
    </>
  );
};

export default PinIcon;
