import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

interface OptionType {
  label: string;
  value: string;
}

interface AutocompleteCustoProps {
  options: OptionType[];
  placeholder: string;
  onColumnSelect: (selectedColumns: OptionType[]) => void;
  value: OptionType[];
}

export default function AutocompleteCustoMulti({
  options,
  placeholder,
  onColumnSelect,
  value: externalValue,
}: AutocompleteCustoProps) {
  const [value, setValue] = useState<OptionType[]>([]);

  useEffect(() => {
    setValue(externalValue || []);
  }, [externalValue]);

  const handleSelection = (
    _: React.SyntheticEvent,
    newValues: OptionType[]
  ) => {
    onColumnSelect(newValues);
    setValue(newValues);
  };

  return (
    <Autocomplete
      multiple
      id='combo-box-demo-multi'
      options={options}
      getOptionLabel={(option) => option.label}
      value={value}
      onChange={handleSelection}
      renderTags={(tagValue, getTagProps) => (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'auto',
            maxHeight: '96px',
          }}
        >
          {tagValue.map((option, index) => (
            <Chip
              key={option.value + index}
              variant='outlined'
              label={option.label.slice(0, 2) + '...'} // Show only the first two letters followed by ellipsis
              {...getTagProps({ index })}
              style={{
                maxWidth: '100px', // Set a maximum width for each tag
                overflow: 'hidden', // Hide overflowed content
                textOverflow: 'ellipsis', // Add ellipsis to overflowed content
                whiteSpace: 'nowrap', // Prevent text from wrapping to a new line
              }}
            />
          ))}
        </div>
      )}
      style={{ minWidth: 300, marginTop: '-18px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          placeholder={placeholder}
          fullWidth // Use the full width of the container
          sx={{
            backgroundColor: 'white',
            borderRadius: '7px',
            '& .MuiAutocomplete-inputRoot': {
              '& input::placeholder': {
                fontSize: '13px',
                color: 'black',
                opacity: 1,
                fontWeight: 400,
              },
              '&.Mui-focused .MuiAutocomplete-inputRoot': {
                borderColor: 'green',
              },
            },
          }}
        />
      )}
    />
  );
}
