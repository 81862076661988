import React, {useEffect, useRef} from "react";
import PageContainer from "@as_core/pages/PageContainer";
import Organ from "./organ";
import styled from "styled-components/macro";
import {bodyComponents} from "@components/ui-human/config";

const UiHuman = () => {
  const circleGraphRef = useRef(null);

  useEffect(() => {
    const setCircularLayout = () => {
      const circles = circleGraphRef?.current.querySelectorAll('#circle');
      let angle = 360 - 90,
        dangle = 360 / circles.length;

      circles.forEach((circle, index) => {
        angle += dangle;
        // console.log('UiHuman', bodyComponents[index].title, (angle - 270));
        bodyComponents[index].titlePosition = (angle - 270).toFixed(1);
        circle.style.transform = `rotate(${angle}deg) translate(${
          circleGraphRef.current.clientWidth / 2
        }px) rotate(-${angle}deg)`;
      });
    };

    setCircularLayout();
  }, []);

  return (
    <PageContainer title='UI-Human'>
      <OrgansContainer ref={circleGraphRef}>
        {bodyComponents.map((component, i) => (
          <Organ {...component} id='circle' key={i}/>
        ))}
        <CenterElement src='/img/interactive-organs/body.svg' />
      </OrgansContainer>
    </PageContainer>
  );
};

export default UiHuman;

const OrgansContainer = styled.div`
  position: relative;
  width: 600px;
  height: 600px;
  //   transform: scale(0.75);
  margin: auto;
`;

const CenterElement = styled.img`
  height: 75%;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;