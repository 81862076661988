import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useAggregation, {
  BucketCfieldsNumericT,
} from './useAggregation';
import { BarChart, DistributionEntry } from './plots/BarChart';
import { LineGraph, LineGraphT } from './plots/LineGraph';
import ToTableViewButton from '@pages/dashboard/ToTableViewButton';
import { StyleContext } from '@theme/AppStyles';
import useCognito from '@as_core/account/useCognito';
import PieChart from 'src/dataviz/piechart/PieChart';

const mapBarChartData = (values): DistributionEntry[] => {
  return values
    .filter((value) => value._id !== '')
    .map((value) => ({
      name: value._id,
      count: value.count,
    }));
};

interface PieChartDataT {
  id: string;
  label: string;
  value: number;
  color: string;
}

const mapPieChartData = ({ result, colors }): PieChartDataT[] => {
  const { values } = result;
  return values
    .filter(
      (value) =>
        value._id !== '' &&
        value._id !== 'Pesticide' &&
        value._id !== 'Agricultural Chemical'
    )
    .map((value, i) => ({
      id: i,
      label: value._id,
      value: value.count,
      color: colors[i],
    }));
};

type mapRequestDataProps = {
  result: BucketCfieldsNumericT;
  class_name: string;
};

const mapRequestData = ({
  result,
  class_name,
}: mapRequestDataProps): LineGraphT => {
  const { values } = result;
  return {
    id: class_name + 'xz',
    data: values
      .filter((item) => item._id.toString() !== 'other')
      .map((item) => ({
        x: item._id,
        y: item.count,
      })),
  };
};

interface CategoryExplorerPropsT {
  categoryGraphType: string;
  categoryField: string;
  initialState: string;
  distributionField: string;
  distributionLabel: string;
  barChartScale?: string;
}

const debug = false;
const CategoryExplorer = (props: CategoryExplorerPropsT) => {
  const {
    categoryGraphType,
    categoryField,
    initialState,
    distributionField,
    distributionLabel,
    barChartScale = 'linear',
  } = props;

  // PARAMETERS
  const library = 'aseda';
  const [style] = useContext(StyleContext);
  const colors = style.plots.Aseda.scheme;

  // STATES
  const [selectedClass, setSelectedClass] = useState<string>(initialState);
  const [categoryDataLoading, setCategoryDataLoading] = useState(false);
  const [distributionDataLoading, setDistributionDataLoading] = useState(false);
  const [yMax, setYMax] = useState(5);
  const [chartData, setChartData] = useState([]);
  const [lineGraphData, setLineGraphData] = useState<LineGraphT[]>([]);
  const navigate = useNavigate();

  // API
  const {
    getBucketAnnotationsCategory,
    getBucketAnnotationsCategoryDistribution,
    getCancelSource,
  } = useAggregation();
  const { getToken } = useCognito();
  const cancelSource = getCancelSource();
  debug &&
    console.log(
      'CategoryExplorer | initial_state selectedClass',
      initialState,
      selectedClass
    );

  // HANDLERS
  const categoryChartHandler = (selected) => {
    if (debug) console.log('CategoryExplorer | selected:', selected);
    if (categoryGraphType === 'barChart') {
      setSelectedClass(selected.name);
    } else {
      setSelectedClass(selected.label);
    }
  };

  const handleClick = () => {
    console.log('handleClick', selectedClass);
    const url =
      '/compounds/aseda/table?filter=' +
      categoryField +
      '&value=' +
      selectedClass +
      '&from=dashboard';
    navigate(url);
  };

  // EFFECTS -- DATA LOADING
  // category data
  useEffect(() => {
    if (!categoryDataLoading) {
      setCategoryDataLoading(true);
      const config = {
        cancelToken: cancelSource.token,
        params: { library: library, consolidate: 4 },
      };

      getBucketAnnotationsCategory(getToken(), categoryField, config).then(
        (result) => {
          if (result!==null) {
            if (categoryGraphType === 'barChart') {
              const chartData = mapBarChartData(result.values);
              setChartData(chartData);
            } else {
              const chartData = mapPieChartData({ result, colors });
              setChartData(chartData);
              debug &&
                console.log('CategoryExplorer | pieCharData: ', chartData);
            }
          }
          setCategoryDataLoading(false);
        }
      );
    }
    return () => {
      setCategoryDataLoading(false);
      cancelSource.cancel('exit');
    };
  }, [library]);

  // distribution data
  useEffect(() => {
    if (!distributionDataLoading && selectedClass !== null) {
      setDistributionDataLoading(true);

      const params = new URLSearchParams();
      params.append('ann_field_id', categoryField);
      params.append('ann_name', selectedClass);
      params.append('dist_field_id', distributionField);
      params.append('library', library);

      const config = {
        cancelToken: cancelSource.token,
        params,
      };
      getBucketAnnotationsCategoryDistribution(getToken(), config).then(
        (result) => {
          if (debug) console.log('distribution {result}', result);
          if (result!==null) {
            const class_name = selectedClass;
            setLineGraphData([mapRequestData({ result, class_name })]);
            let max = 5;
            result.values.map((item) => {
              if (item?.count > max) {
                max = item.count;
              }
            });
            if (max > 5) {
              max = 5 * (Math.floor(max / 5.0) + 1);
            }
            setYMax(max);
          }
          setDistributionDataLoading(false);
        }
      );
    }
    return () => {
      setDistributionDataLoading(false);
      cancelSource.cancel('exit');
    };
  }, [selectedClass, library]);

  // if (debug) console.log("lineGraphData ", lineGraphData);
  return (
    <Row>
      <Item>
        {categoryGraphType === 'barChart' ? (
          <BarChart
            data={chartData}
            selectedClass={selectedClass}
            onClick={categoryChartHandler}
            linear={barChartScale === 'linear'}
            marginBottom={85}
          />
        ) : (
          <PieChart data={chartData} onClick={categoryChartHandler} />
        )}
      </Item>
      <Item>
        <ItemPartsTop>
          <ToTableViewButton
            text={distributionLabel + ' for ' + selectedClass + ' compounds'}
            tooltip={'View compounds in table'}
            tooltipPlacement={'bottom'}
            onClick={handleClick}
          />
        </ItemPartsTop>
        <ItemPartsBottom>
          <GraphContainer>
            {!distributionDataLoading ? (
              <LineGraph
                data={lineGraphData}
                xAxisLabel={distributionLabel}
                yAxisLabel={'Number of Compounds'}
                yMax={yMax}
                onClick={() => {}}
              />
            ) : (
              <div>Loading</div>
            )}
          </GraphContainer>
        </ItemPartsBottom>
      </Item>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  height: 510px;
  justify-content: center;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  overflow-x: hidden;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 50%;
  height: 510px;
  align-items: center;
  overflow-x: hidden;
`;

const ItemPartsTop = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
`;

const ItemPartsBottom = styled.div`
  display: flex;
  width: 100%;
`;

const GraphContainer = styled.div`
  align-items: center;
  height: 440px;
  width: 100%;
`;

export default CategoryExplorer;
