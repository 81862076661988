import React from 'react';
import styled from 'styled-components/macro';
import { faqs } from '@components/pages/infoFaq/info.faq';
import { FlexRow } from '@as_core/elements/flexStyles';
import FaqItem from '@components/pages/infoFaq/FaqItem';

const Faq = () => {
  const returnCategoryFaqs = (cat: string, cat_index: number) => {
    return (
      <React.Fragment key={cat_index}>
        <FlexRow width={'100%'}>
          <CategoryTitle>{cat}</CategoryTitle>
        </FlexRow>
        {faqs[cat].map((faq, index) => (
          <FaqItem
            key={`${cat_index}_${index}`}
            id={`${cat_index}_${index}`}
            question={faq.question}
            response={faq.response}
          />
        ))}
      </React.Fragment>
    );
  };

  return (
    <FaqContainer>
      {Object.keys(faqs).map((cat, cat_index) =>
        returnCategoryFaqs(cat, cat_index)
      )}
    </FaqContainer>
  );
};

export default Faq;

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const CategoryTitle = styled.div`
  display: flex;
  font-size: 16px;
  margin-top: 10px;
`;
