import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import './Filter.css';
import SelectSmall from '@components/dataAnalytics/SelectSmall';
import ColumnFilter, {
  SelectedDataProps,
} from '@components/dataAnalytics/ColumnFilter';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import ErrorModal from '@components/dataAnalytics/ErrorModal';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useEffect } from 'react';
import SelectedDataShown from './Selecteddatashown'; // Import your SelectedDataShown component
import { Condition } from './SaveFilter';
import { IFilter } from '@pages/PgDataAnalytics';
interface FilterCondition {
  selectedColumn: string;
  selectedDate: string;
  value: string;
  AndOR: 'AND' | 'OR';
  title: string;
  inputValue: string;
  selectedLabel: string;
  selectedDatend: string;
  selectedDatestart: string;
  minValue: string;
}

interface FilterData {
  AndOR: 'AND' | 'OR';
  conditions: FilterCondition[];
}

interface Column {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

interface FilterProps {
  columns: Column[];
  handleClose: () => void;
  onSaved: (
    data: FilterData[],
    AndOR: 'AND' | 'OR',
    filterid: string | number
  ) => void;
  datalist: IFilter[];
  data?: FilterData[];
  id: string | number;
  saved?: boolean;
}

const Filter: React.FC<FilterProps> = (props) => {
  const [, setidFilter] = useState<string | number>('');
  const initialFilterList = [
    {
      id: 1,

      selectedColumn: {
        id: 'compound_status',
        numeric: false,
        disablePadding: false,
        label: 'Compound Status',
      },
      selectedDate: 'todayAtNoon',
      value: '1',
      AndOR: 'and' as 'and' | 'or',
      minValue: '',

      title: 'Filter 1',
      inputValue: '',
      selectedLabel: 'contains',
      selectedDatend: 'todayAtNoon',
      selectedDatestart: 'todayAtNoon',
    },
  ];
  const { columns, datalist, id, data, saved } = props;
  const handleCancel = () => {
    // Reset the filterList to its initial state
    setFilterList([...initialFilterList]);
    // Reset other states if needed
    setError(null);
    setIsSaved(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  const [value, setvalue] = useState(
    data ? data[0].conditions[0].value : 'keep'
  );
  const [AndOR, setAndOR] = useState<'AND' | 'OR'>(
    data ? data[0].AndOR : 'AND'
  );
  const [resetKey, setResetKey] = useState(0);

  const [filterList, setFilterList] = useState<SelectedDataProps[]>([
    {
      id: 1,
      selectedColumn: {
        id: 'compound_status',
        numeric: false,
        disablePadding: false,
        label: 'Compound Status',
      },
      selectedDate: 'todayAtNoon',
      value: '1',
      AndOR: 'and' as 'and' | 'or',
      title: 'Filter 1',
      inputValue: '',
      minValue: '',

      selectedLabel: 'contains',
      selectedDatend: 'todayAtNoon',
      selectedDatestart: 'todayAtNoon',
    },
  ]);

  const [error, setError] = useState<string | null>(null);
  const [isSaved, setIsSaved] = useState(false);
  const [, setEdit] = useState(false);
  const [listData, setListData] = useState<{ conditions: Condition[] }>();
  const handleSave = () => {
    const isSaveAllowed = filterList.every((filter) => {
      if (
        filter.selectedLabel === 'is null' ||
        filter.selectedLabel === 'is not null'
      ) {
        return true; // 'null' and 'is not null' do not need inputValue
      }

      if (
        filter.selectedLabel === 'contains' ||
        filter.selectedLabel === 'is' ||
        filter.selectedLabel === 'matches'
      ) {
        if (
          filter.selectedColumn.numeric === false &&
          typeof filter.inputValue === 'string' &&
          filter.inputValue !== ''
        ) {
          return true;
        } else {
          setError(
            'Cannot save the filter because the input value is empty or the type of column is not string.'
          );
          return false;
        }
      } else if (
        filter.selectedLabel === 'equal to' ||
        filter.selectedLabel === 'less than' ||
        filter.selectedLabel === 'greater than' ||
        filter.selectedLabel === 'greater than or equal' ||
        filter.selectedLabel === 'between numbers'
      ) {
        if (
          filter.selectedColumn.numeric === true &&
          !isNaN(parseFloat(filter.inputValue)) &&
          filter.inputValue !== ''
        ) {
          return true;
        } else {
          setError(
            'Cannot save the filter because the input value is empty or the type of column is not number.'
          );
          return false;
        }
      } else if (
        filter.selectedLabel === 'after' ||
        filter.selectedLabel === 'on or after' ||
        filter.selectedLabel === 'before' ||
        filter.selectedLabel === 'on'
      ) {
        if (
          filter.selectedColumn.numeric === false &&
          filter.selectedDate !== 'todayAtNoon'
        ) {
          return true;
        } else {
          setError(
            'Cannot save the filter because the input value is empty or the type of column is not date.'
          );
          return false;
        }
      }
    });

    console.log('isSaveAllowed:', isSaveAllowed);

    if (isSaveAllowed) {
      const allFilterData = filterList.map((filter) => ({
        selectedColumn: filter.selectedColumn.id,
        selectedDate: filter.selectedDate,
        value: filter.value,
        AndOR: filter.AndOR,
        title: filter.title,
        inputValue: filter.inputValue,
        minValue: filter.minValue,
        selectedLabel: filter.selectedLabel,
        selectedDatend: filter.selectedDatend,
        selectedDatestart: filter.selectedDatestart,
      }));

      const data: FilterData[] = [
        {
          AndOR: AndOR,
          conditions: allFilterData.map((filter) => ({
            ...filter,
            value: value,
            AndOR: AndOR,
          })),
        },
      ];
      const datalist = {
        conditions: allFilterData.map((filter) => ({
          ...filter,
          value: value,
          AndOR: AndOR,
        })),
      };

      props.onSaved(data, AndOR, id);
      setListData(datalist);

      setIsSaved(true); // Set the state to indicate that the user has clicked "Save"
    }
  };
  useEffect(() => {
    if (data && saved && data[0] && data[0].conditions) {
      // Map each condition to the expected shape
      const newFilterList = data[0].conditions.map((condition, index) => ({
        id: index, // or some other unique identifier
        selectedColumn:
          columns.find((col) => col.id === condition.selectedColumn) ||
          columns[0],
        selectedDate: condition.selectedDate,
        value: condition.value,
        AndOR: condition.AndOR.toLowerCase() as 'and' | 'or',
        title: condition.title || `Filter ${index + 1}`,
        inputValue: condition.inputValue,
        minValue: condition.minValue,
        selectedLabel: condition.selectedLabel,
        selectedDatend: condition.selectedDatend,
        selectedDatestart: condition.selectedDatestart,
      }));
      setFilterList(newFilterList);

      setIsSaved(true);
    }
  }, [data]);

  const handleEditFilter = (filterid: string) => {
    setIsSaved(false);

    setEdit(false);
    setidFilter(filterid);
  };

  const handleAddFilter = () => {
    const newFilter = {
      id: Date.now(),
      selectedColumn: {
        id: 'compound_status',
        numeric: false,
        disablePadding: false,
        label: 'Compound Status',
      },

      selectedDate: 'todayAtNoon',
      value: 'optionA',
      AndOR: 'and',
      inputValue: '',
      minValue: '',
      title: `Filter ${filterList.length + 1}`,
      selectedLabel: 'contains',
      selectedDatend: 'todayAtNoon',
      selectedDatestart: 'todayAtNoon',
    };
    setFilterList((prevFilterList) => [
      ...prevFilterList,
      { ...newFilter, AndOR: newFilter.AndOR as 'and' | 'or' },
    ]);
  };

  const handleDeleteFilter = (idToDelete: number) => {
    if (filterList.length === 1) {
      setError('Cannot delete the last filter.');
    } else {
      setFilterList((prevFilterList) => {
        return prevFilterList
          .filter((filter) => filter.id !== idToDelete)
          .map((filter, index) => ({
            ...filter,
            title: `Filter ${index + 1}`,
          }));
      });
    }
  };

  return (
    <div className='filtrecontainer'>
      <div className='flex-space-between'>
        <Typography variant='h6' component='h6' className='titletexte'>
          <FilterAltIcon className='icon' fontSize='small' />
          FILTER
        </Typography>
        <CloseIcon
          className='closeIcon'
          fontSize='small'
          onClick={props.handleClose}
          style={{ color: '#F2F3F4' }}
        />
      </div>

      {isSaved && filterList.length > 0 ? (
        <SelectedDataShown
          selectedData={filterList}
          SelectedValue={value}
          listData={listData}
          AndOR={AndOR}
          onEditFilter={handleEditFilter}
          datalist={datalist}
          filterid={id.toString()}
        />
      ) : (
        <div>
          {/* Content to be displayed when "Save" is not clicked */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
              marginBottom: '30px',
              borderBottom: '1px solid #D9A43A',
            }}
          >
            <SelectSmall
              style={{ marginTop: '-17px', marginLeft: '-8px' }}
              menuItems={[
                {
                  value: 'keep',
                  label: 'Keep',
                  icon: <FilterAltIcon fontSize='small' />,
                },
                {
                  value: 'remove',
                  label: 'remove',
                  icon: <FilterAltOffIcon fontSize='small' />,
                },
              ]}
              value={value}
              onChange={(event) => setvalue(event.target.value)}
              size='small'
              maxwidth='120px'
            />

            <span style={{ margin: ' 0 10px', color: 'white' }}>
              rows that match using logical operators
            </span>

            <SelectSmall
              style={{ marginTop: '-17px' }}
              menuItems={[
                { value: 'AND', label: 'AND' },
                { value: 'OR', label: 'OR' },
              ]}
              value={AndOR}
              onChange={(event) => {
                setAndOR(event.target.value as 'AND' | 'OR');
              }}
              size='small'
            />
          </div>

          {filterList.map((filter, index) => (
            <ColumnFilter
              key={`${filter.id}-${resetKey}`} // use resetKey in the key to force re-render
              columns={columns}
              selectedData={filter}
              title={filter.title}
              onSelectedDataChange={(newFilter: SelectedDataProps) => {
                const updatedFilters = [...filterList];
                updatedFilters[index] = newFilter;
                setFilterList(updatedFilters);
              }}
              onDeleteFilter={() => handleDeleteFilter(filter.id)}
              canDelete={filterList.length > 1}
            />
          ))}

          <div className='addfilter'>
            <Button
              variant='text'
              startIcon={<AddCircleIcon style={{ color: 'white' }} />}
              style={{ color: 'white' }}
              onClick={handleAddFilter}
              className='addfilter-button'
            >
              Add Filter
            </Button>
          </div>

          <div className='save-cancel-buttons'>
            <Button
              variant='contained'
              className='button-cancel'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              className='button-save'
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      )}

      {error && (
        <ErrorModal
          message={error}
          open={true}
          onClose={() => setError(null)}
        />
      )}
    </div>
  );
};

export default React.memo(Filter);
