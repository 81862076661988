import { BoxPlotDatum } from "@nivo/boxplot";
import { group, median } from "d3-array";


export const getBoxplotRankedData = (data: BoxPlotDatum[], groupByColumn: string, valueColumn: string) => {

    const groupedData = group(data, (d) => d[groupByColumn]);

    const medians = Array.from(groupedData, ([group, values]) => {
        return {
            group: group,
            median: median(values, (d) => Number(d[valueColumn])),
        };
    });

    medians.sort((a, b) => a.median - b.median);

    // Reorder the original dataset based on sorted groups
    const sortedData = medians.flatMap((medianGroup) =>
        groupedData.get(medianGroup.group)
    );

    return sortedData

}
