import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Dialog } from '@mui/material';
import TextButton from '@components/controls/TextButton';
import InputField from '@as_core/components/inputs/InputField';

import { Logo, Name, Row, Title } from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';

type Props = {
  type: 'logout' | 'general' | 'deleteConfirm';
  alertOpen: boolean;
  closeAlert?: () => void;
  title?: string;
  message?: string;
  width?: number;
  height?: number;
  opacity?: number;
  onConfirm?: () => void;
  tooltip?: string;
  buttonText?: string;
};

interface AppDialogHeaderProps {
  message: string;
  tooltip?: string;
}

// Définissez le composant AppDialogHeader
const AppDialogHeader = ({ message, tooltip = '' }: AppDialogHeaderProps) => {
  return (
    <>
      <Row height={'50px'}>
        <Logo>
          <img src={APP_CONFIG.logo} alt='logo' />
        </Logo>
        <Name>{APP_CONFIG.name}</Name>
      </Row>
      <Row height={'50px'}>
        <Title>
          {message}
          {tooltip !== '' ? (
            <TooltipContainer>
              <HoverInfoText text={tooltip} />
            </TooltipContainer>
          ) : null}
        </Title>
      </Row>
    </>
  );
};

const Alert = (props: Props) => {
  const {
    type,
    alertOpen,
    closeAlert,
    title,
    message,
    width = 500,
    height = 200,
    onConfirm,
    tooltip = '',
    buttonText = 'Close Alert',
  } = props;

  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [internalAlertOpen, setInternalAlertOpen] = useState(alertOpen);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setInternalAlertOpen(alertOpen);
  }, [alertOpen]);

  const handleCloseAlert = () => {
    if (closeAlert) {
      closeAlert();
    } else {
      setInternalAlertOpen(false);
    }
  };

  const handleInputChange = (event) => {
    setDeleteConfirm(event.target.value.toUpperCase());
    setErrorMessage(''); // Reset error message on input change
  };

  const handleCancel = () => {
    setDeleteConfirm('');
    handleCloseAlert();
  };

  const handleDelete = () => {
    if (deleteConfirm !== 'DELETE') {
      setErrorMessage('You must enter DELETE to confirm.');
      return;
    }
    if (onConfirm) {
      onConfirm();
    }
    handleCloseAlert(); // Close the alert after confirming
  };

  const renderContent = () => {
    switch (type) {
      case 'logout':
        return (
          <>
            <AppDialogHeader message={'Session Timeout'} tooltip={tooltip} />
            <Message height={height} width={width}>
              <TextItem color={'primary'}>Your session has timed out</TextItem>
              <TextItem>
                due to session expiration or inactivity (1 hour)
              </TextItem>
              <TextItem>{`at ${new Date().toLocaleString()}`}</TextItem>
              <TextItem color={'accent'}>
                You will need to re-login to continue.
              </TextItem>
            </Message>
          </>
        );
      case 'general':
        return (
          <>
            <AppDialogHeader message={title || 'Alert'} tooltip={tooltip} />
            <Message height={height} width={width} color={'primary'}>
              {message}
            </Message>
          </>
        );
      case 'deleteConfirm':
        return (
          <>
            <AppDialogHeader
              message={title || 'Confirm Delete'}
              tooltip={tooltip}
            />
            <Message height={height} width={width}>
              {message}
            </Message>
            <DeleteConfirm>
              <InputField
                value={deleteConfirm}
                label='Confirm Delete'
                onChange={handleInputChange}
                size='small'
              />
              {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            </DeleteConfirm>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <StyledDialog open={internalAlertOpen}>
      <Content>
        {renderContent()}
        <ActionButtons>
          {type === 'deleteConfirm' ? (
            <>
              <TextButton text={'CANCEL'} onClick={handleCancel} height={24} />
              <TextButton
                text={'DELETE'}
                onClick={handleDelete}
                isWarning={true}
                height={24}
              />
            </>
          ) : (
            <>
              {onConfirm && (
                <TextButton text={'Confirm'} onClick={onConfirm} height={24} />
              )}
              <TextButton
                text={buttonText}
                height={25}
                onClick={handleCloseAlert}
              />
            </>
          )}
        </ActionButtons>
      </Content>
    </StyledDialog>
  );
};

export default Alert;

const StyledDialog = styled(Dialog)`
  border-radius: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-width: 400px;
  border-radius: 15px;
  align-items: center;
  align-content: space-between;
`;

const Message = styled.div<{ height: number; width: number; color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;
  width: calc(${(p) => p.width}px);
  height: calc(${(p) => p.height})px;
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  color: ${(p) =>
    p.color === 'primary'
      ? p.theme.palette.textPrimary
      : p.color === 'accent'
      ? p.theme.palette.accentPrimary
      : p.theme.palette.textSecondary};
`;

const TextItem = styled.div<{ color?: string }>`
  display: flex;
  color: ${(p) =>
    p.color === 'primary'
      ? p.theme.palette.textPrimary
      : p.color === 'accent'
      ? p.theme.palette.accentPrimary
      : p.theme.palette.textSecondary};
`;
TextItem.defaultProps = {
  color: 'secondary',
};

const DeleteConfirm = styled.div`
  margin-top: 10px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const TooltipContainer = styled.div`
  margin-left: 16px;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
