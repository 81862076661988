import { BarDatum } from '@nivo/bar';
import { PlotData } from '@plotting/single-plot-view/plot.types';

// User picks 1 numeric value and 1 categoric value from his dataset
// We must aggregate the numeric value for each item of the grouping variable
export const getBarSeriesOneGroupingLevel = (
  data: PlotData,
  valueColumn: string,
  groupByColumn: string,
  isSorted: boolean
): BarDatum[] => {
  // Create a map to aggregate values by group
  const aggregateMap = new Map();

  // Iterate through the data
  data.forEach((item) => {
    const groupName = item[groupByColumn];
    const numericValue = parseFloat(item[valueColumn]);

    if (isNaN(numericValue)) {
      return; // Skip invalid numeric values
    }

    if (!aggregateMap.has(groupName)) {
      aggregateMap.set(groupName, {
        group: groupName,
        count: 0,
        sum: 0,
      });
    }

    const entry = aggregateMap.get(groupName);
    entry.sum += numericValue;
    entry.count++;
  });

  // Convert aggregateMap to an array of datapoints
  const barSeries = Array.from(aggregateMap.values()).map((entry) => {
    const meanValue = entry.sum / entry.count;
    return {
      [groupByColumn]: entry.group,
      [valueColumn]: meanValue,
    };
  });

  // Sort the barSeries array by the mean values
  if (isSorted) {
    barSeries.sort((a, b) => a[valueColumn] - b[valueColumn])
  }

  return barSeries;
};

// User picks 1 numeric value and TWO categoric value from his dataset
// This will create a grouped or stacked bar chart
// We must aggregate the numeric value for each combination of group and subgroups
export const getBarSeriesTwoGroupingLevels = (
  data: PlotData,
  valueColumn: string,
  groupByColumns: string[],
  isSorted
): BarDatum[] => {
  // Validate input
  if (
    typeof valueColumn !== 'string' ||
    !Array.isArray(groupByColumns) ||
    groupByColumns.length !== 2
  ) {
    throw new Error('Invalid input parameters.');
  }

  const [groupColumn, subgroupColumn] = groupByColumns;

  // Create a map to aggregate values by group and subgroup
  const aggregateMap = new Map();
  const groupSums = new Map();

  // Iterate through the data
  data.forEach((item) => {
    const groupName = item[groupColumn];
    const subgroupName = item[subgroupColumn];
    const numericValue = parseFloat(item[valueColumn]);

    if (isNaN(numericValue)) {
      return; // Skip invalid numeric values
    }

    const key = `${groupName}-${subgroupName}`;
    if (!aggregateMap.has(key)) {
      aggregateMap.set(key, {
        group: groupName,
        subgroup: subgroupName,
        count: 0,
        sum: 0,
      });
    }

    const entry = aggregateMap.get(key);
    entry.sum += numericValue;
    entry.count++;

    // Aggregate the total sum per group
    if (!groupSums.has(groupName)) {
      groupSums.set(groupName, 0);
    }
    groupSums.set(groupName, groupSums.get(groupName) + numericValue);
  });

  // Prepare the result array
  const barSeries: BarDatum[] = [];

  // Iterate over the aggregateMap to calculate means and construct result items
  aggregateMap.forEach((value, key) => {
    const mean = value.sum / value.count;

    // Find or create the result item for the group
    let groupItem = barSeries.find((item) => item[groupColumn] === value.group);
    if (!groupItem) {
      groupItem = { [groupColumn]: value.group };
      barSeries.push(groupItem);
    }

    // Add the subgroup mean to the group item
    groupItem[value.subgroup] = mean;
  });

  // Sort the barSeries array by the total sum of each group
  if (isSorted) {
    barSeries.sort((a, b) => {
      const totalSumA = groupSums.get(a[groupColumn]);
      const totalSumB = groupSums.get(b[groupColumn]);
      return totalSumB - totalSumA; // Sort in descending order
    });
  }
  return barSeries;
};
