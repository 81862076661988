import React, { useState, useEffect, useCallback } from 'react';
import useCompoundsNew from '@utils/useCompoundsNew';
import { CompoundT } from '../../../type';
import CompoundDetail from '@components/compounds/view/CompoundDetail';
import DataLoading from '@as_core/elements/DataLoading';
import useCognito from '@as_core/account/useCognito';

interface CompoundDetailDataT {
  searchKey: string;
}

const debug = false;

let libraryId = 'aseda';

const CompoundsDetailData = (props: CompoundDetailDataT) => {
  const { searchKey } = props;
  if (debug) console.log('CompoundDetailData | searchKey: ', searchKey);

  const { getCompound } = useCompoundsNew();
  const { getToken } = useCognito();
  const [neighbors] = useState([]); // TODO add neighbors loading here as well

  // ###########################################################################
  // Compound Loading
  // ###########################################################################
  const [compound, setCompound] = useState<CompoundT>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // default
  let compoundId = searchKey;
  if (searchKey.includes('_')) {
    // new format
    const splitString = searchKey.split('_');
    libraryId = splitString[0];
    compoundId = splitString[1];
  }

  if (debug) {
    console.log(
      'CompoundDetailData | {libraryId, compoundId',
      libraryId,
      compoundId
    );
  }

  const loadData = useCallback(async () => {
    const res = await getCompound(getToken(), compoundId, libraryId);
    setCompound(res.data);
  }, [compoundId]);

  useEffect(() => {
    setLoading(true);
    loadData().then(() => setLoading(false));
  }, [compoundId, loadData]);

  if (debug) {
    console.log(
      'CompoundDetailData | compound: ',
      compound,
      'neighbors: ',
      neighbors
    );
  }

  if (loading) return <DataLoading />;

  return <CompoundDetail compound={compound} neighbors={neighbors} />;
};

export default CompoundsDetailData;
