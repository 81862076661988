import _ from 'lodash';
import { Data } from '@utils/api';
import { handleError } from '@utils/misc';
import { AxiosRequestConfig } from 'axios';
import { DataQueryParamsT, DataT } from '../../type';
import { ResponseT } from '@utils/api/base';
import { DataSetT } from '@components/dataSets/dataSet.types';

export interface Dictionary<T = unknown> {
  [key: string]: T;
}

const useData = () => {
  const getAllData = async (
    token: string,
    params: DataQueryParamsT
  ): Promise<ResponseT<[DataSetT]>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Data.all(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getAllData ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getData = async (token: string, dataId): Promise<DataSetT> => {
    let resp;

    try {
      resp = await Data.get(token, dataId);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getData ERRORS', resp.data.errors);
    }

    // unpack
    let dataSet = resp.data.data;
    dataSet.data = JSON.parse(decodeURIComponent(_.get(dataSet, 'data')));
    dataSet.dataFormatted = JSON.parse(
      decodeURIComponent(_.get(dataSet, 'dataFormatted'))
    );

    return dataSet;
  };

  const createData = async (
    token: string,
    plot: DataT<object>
  ): Promise<DataT<null>> => {
    let resp;
    const plotPacked = {
      ...plot,
      data: encodeURIComponent(JSON.stringify(plot.data)),
    };

    try {
      resp = await Data.create(token, plotPacked);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('createData ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const deleteData = async (
    token: string,
    dataSetId: string
  ): Promise<DataT<null>> => {
    let resp;

    try {
      resp = await Data.delete(token, dataSetId);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deleteDataSett ERRORS', resp.data.errors);
    }
  };

  return {
    getData,
    getAllData,
    createData,
    deleteData,
  };
};

export default useData;
