import _ from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { FieldSliceT } from '@stores/fields';
import { assayFields } from '@components/compounds/view/detail/assays.config';
import { FlexItem, FlexRow } from '@components/layout/FlexStyles';
import { Tooltip } from '@mui/material';
import zebraRenderer from '@components/valueRenderers/zebraRenderer';
import { CompoundT } from '@src/type';

const ZFScorecardTile = (props: { compound: CompoundT }) => {
  const { compound } = props;
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  const fields = assayFields['zf'];

  return (
    <Container>
      {fields.map((f, i) => (
        <FlexRow key={'coltab_row_' + f} height={'20px'} v_centered>
          <FlexItem>
            <Tooltip
              title={allFields[f].short_description}
              placement={'left-end'}
              arrow
            >
              <LabelCell key={'lab_cell_' + f}>{allFields[f].symbol}</LabelCell>
            </Tooltip>
          </FlexItem>
          <FlexItem width={'max-content'}>
            <BarChart>
              {zebraRenderer({
                className: 'zf_legend' + i,
                width: 200,
                cellData: _.get(compound, allFields[f].data_key, 0),
                column: { barsHorizontal: true },
              })}
            </BarChart>
          </FlexItem>
        </FlexRow>
      ))}
    </Container>
  );
};

export default ZFScorecardTile;

const Container = styled.div`
  width: 290px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LabelCell = styled.div`
  width: 35px;
  text-align: right;
  font-size: ${(p) => p.theme.sizes.xsmall};
  font-weight: ${(p) => p.theme.weights.light};
  padding-right: 0;
  :hover {
    cursor: pointer;
  }
`;

const BarChart = styled.div`
  display: flex;
  align-items: flex-start;
  width: max-content;
  margin-left: 5px;
  transform: translateX(100px);
`;
