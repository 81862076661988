import React from 'react';
import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { BoxplotBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface BoxplotModalEditorProps {
  plot?: PlotConfig<BoxplotBase>;
  onSave?: (config: PlotConfig<BoxplotBase>) => void;
}

export const BoxplotModalEditor = ({
  plot,
  onSave,
}: BoxplotModalEditorProps) => {
  const updateKey = (columnName: string) => {
    const newPlotConfig: PlotConfig<BoxplotBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<BoxplotBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-key'
        label={'Grouping Variable'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateKey}
      />
      <br />
      <VariableSelect
        id='variable-value'
        label={'Numeric Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValue}
      />
    </Stack>
  );
};
