import {ZoomTransform} from "d3";

export const getScreenCoords = (pos, transform) => {
  const x = transform.x + pos[0] * transform.k;
  const y = transform.y + pos[1] * transform.k;
  return [x, y];
};

export const getDistance = (x1, y1, x2, y2) => {
  return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
};

export function isVisible(
  transform: ZoomTransform,
  dimensions: {width: number, height: number},
  d: {x: number, y: number})
{
  const offset = 100;
  const pos = getScreenCoords([d.x, d.y], transform);
  if (pos[0] + offset < 0 || pos[0] - offset > dimensions.width ||
    pos[1] + offset < 0 || pos[1] - offset > dimensions.height) return false;
  return true;
}

export function isInScreenRegion(
  transform: ZoomTransform,
  region: {x1: number, y1: number, x2: number, y2: number},
  d: {x: number, y: number})
{
  const offset = { x:50, y: 100};
  const pos = getScreenCoords([d.x, d.y], transform);
  if (pos[0]  < region.x1 - offset.x || pos[0] > region.x2 - offset.x ||
    pos[1] < region.y1 - offset.y || pos[1] > region.y2 - offset.y) return false;
  return true;
}

// eslint-disable-next-line
export const convertArrayToObject = (array: any[], key: string) => {
  const initialValue = {};
  // eslint-disable-next-line
  return array.reduce((obj:any, item:any) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};