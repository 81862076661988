import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import useInvitations from '@utils/api/useInvitations';
import InvitesTable from '../../shared/InvitesTable';
import Button from '@ui-components/controls/Button';
import {
  AccountDetailContainer,
  Title,
  Center,
  Message, HorizontalLine,
} from '../../shared/flexStyles';
import { isValidEmail } from '@as_core/account/utils';
import { errorMessages } from '@as_core/account/forms/config';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import {BasicTextInput} from "@as_core/account/forms/FormStyles";
import {isInvitationActive} from "@utils/api/useInvitations";
import {getMaxInvites, SubscriptionT} from "@utils/useUserAppInfo";
import {InvitationT} from "@src/type";

// build list of active repository invitees and users to exclude duplicate invitations.
function getActiveUsers(subscription:SubscriptionT, invitations:InvitationT[]): string[] {
  return [...subscription.invitedUsers.map((u) => u.authEmail), ...invitations.map((i) => i.invitee.authEmail)];
}

type PropsT = {
  subscription: SubscriptionT;
};

const debug = false;
const SubscriptionInvites = (props: PropsT) => {
  const { user } = useContext(UserContext);
  const { subscription } = props;
  const { getBySubscription } = useInvitations();
  const [invitations, setInvitations] = useState([]);
  const [message, setMessage] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [,setInvitationsUpdated] = useState<boolean>(false);
  const { inviteUser, cancelInvite } = useInvitations();
  const [invitedEmail, setInvitedEmail] = useState('');
  const [errorInvite, setErrorInvite] = useState<string>();
  const totalActive = invitations.length + subscription.invitedUsers.length;
  const maxAllowedInvites = getMaxInvites(user);
  if (debug) console.log('SubscriptionInvites | props', props);

  const getActiveInvitations = async(): Promise<InvitationT[]> => {
    const response = await getBySubscription(subscription.uuid);
    const activeInvitations =  response.filter((i:InvitationT) => isInvitationActive(i));
    if (!activeInvitations.length)
      setMessage('No current active invitations');
    return activeInvitations;
  }

  useEffect(() => {
    getActiveInvitations().then((updatedInvitations) => {
      setInvitations(updatedInvitations);
    })
  }, []);

  // create a new invitation for this repo
  const sendInvite = () => {
    const activeUserInvites = getActiveUsers(subscription, invitations);
    setIsProcessing(true);
    if (invitedEmail && !isValidEmail(invitedEmail)) {
      setErrorInvite(errorMessages.invalidEmailFormat);
      setIsProcessing(false);
    } if (invitedEmail === user.authEmail) {
      setErrorInvite('Cannot invite yourself');
      setIsProcessing(false);
    } if (activeUserInvites.includes(invitedEmail)) {
      setErrorInvite('Invitee already has an active invitation or in subscription user list.');
      setIsProcessing(false);
    } else {
      inviteUser(
        user,
        invitedEmail,
        'Share Subscription',
        {'subscriptionId': subscription.uuid}).then(() => {
          getActiveInvitations().then((activeInvitations) => {
            setMessage(`Invitation sent to ${invitedEmail}`);
            setInvitedEmail('');
            setInvitations(activeInvitations);
            setIsProcessing(false);
          })
      });
    }

  };

  const handleCancelInvite = (inviteId: string) => {
    setIsProcessing(true);
    cancelInvite(inviteId).then(async () => {
      setInvitationsUpdated(prev => !prev);
      setMessage(`Invitation Cancelled`);
      setIsProcessing(false);
    });
  };
  if (debug) console.log('SubscriptionInvites | invitations', invitations);
  if (debug) console.log('maxAllowedInvites', maxAllowedInvites, totalActive)

  return (
    <AccountDetailContainer key={'repo-invites'}>
      { totalActive < maxAllowedInvites ?
        <>
          <Center>
            <InviteBox>
              <BasicTextInput
                key={'newInvitee'}
                value={invitedEmail}
                placeholder={'Enter email to share subscription ...'}
                width={'375px'}
                onChange={(e)=>setInvitedEmail(e.target.value)}
              />
              <Button
                label={'Invite User'}
                disabled={invitedEmail === ''}
                width={150}
                onClick={sendInvite}
                inProcess={isProcessing}
              />
            </InviteBox>
          </Center>
          <Center>
            {errorInvite ? (
              <ErrorMessage message={errorInvite} />
            ) : (
              <Message>{message}</Message>
            )}
          </Center>
          <HorizontalLine />
        </>
        : null }
      {invitations.length ? (
        <>
          <Title>Active Invitations</Title>
          <InvitesTable
            inviteType={'Share Subscription'}
            invitations={invitations}
            handleCancelInvite={handleCancelInvite}
          />
        </>
      ) : null}
    </AccountDetailContainer>
  );
};

export default SubscriptionInvites;

const InviteBox = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 575px;
  height: 20px;
  margin: 8px 0;
  gap: 8px;
`;


