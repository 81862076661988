import styled, { keyframes } from "styled-components/macro";

export const ListContainer = styled.div`
  height: max-content;
  width: calc(100vw - 80px);
  padding: 5px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

export const ListError = styled.div`
  padding: 10px;
  color: ${(p) => p.theme.palette.titleSecondary};
  font-size: calc(0.8 * ${(props) => props.theme.sizes.default});
`;

export const rotate = keyframes`
  from { transform: rotate(0deg); } to { transform: rotate(360deg); }
`;

interface StyledExtraProps {
  small?: boolean;
}
export const ListLoader = styled.div<StyledExtraProps>`
  display: inline-block;
  border-radius: 100%;
  margin: 2px;
  border: ${(p) => p.theme.borders.accentPrimary};
  border-bottom-color: transparent;
  width: ${(props) => (props.small ? 12 : 22)}px;
  height: ${(props) => (props.small ? 12 : 22)}px;
  animation: ${rotate} 0.75s linear infinite;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
