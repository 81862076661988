export const bodyComponents = [
  {
    title: 'Nose',
    subtitle: 'Olfactory',
    key: 'olfactory',
    src: '/img/interactive-organs/nose.svg',
    titlePosition: '',
    url: 'https://www.google.com',
    tooltip: 'Test Nose',
  },
  {
    title: 'Ear',
    subtitle: 'Ototoxicity',
    key: 'Ototoxicity',
    src: '/img/interactive-organs/ear.svg',
    titlePosition: '',
    url: '/dashboard',
    tooltip: 'Test Ear',
  },
  {
    title: 'Brain',
    subtitle: 'Neurotoxicity',
    key: 'neurotoxicity',
    src: '/img/interactive-organs/brain.svg',
    titlePosition: '',
  },
  {
    title: 'Heart',
    subtitle: 'Cardiotoxicity',
    key: 'cardiotoxicity',
    src: '/img/interactive-organs/heart.svg',
    titlePosition: '',
  },
  {
    title: 'Stomach & intestines',
    subtitle: 'Gastrointestinal',
    key: 'gastrointestinal',
    src: '/img/interactive-organs/gastro.svg',
    titlePosition: '',
  },
  {
    title: 'Uterus / Fallopian tubes',
    subtitle: 'Reproductive',
    key: 'reproductive',
    src: '/img/interactive-organs/reproductive.svg',
    titlePosition: '',
  },
  {
    title: 'Circulatory',
    subtitle: 'Coagulation \n Hematological Immunotoxicity',
    key: 'body',
    src: '/img/interactive-organs/circulatory.svg',
    titlePosition: '',
  },
  {
    title: 'Skeletal Muscle',
    subtitle: 'Skeletal Muscle',
    key: 'skeletal-muscle',
    src: '/img/interactive-organs/skeletal-muscle.svg',
    titlePosition: '',
  },
  {
    title: 'Skin',
    subtitle: 'Dermal',
    key: 'dermal',
    src: '/img/interactive-organs/dermal.svg',
    titlePosition: '',
  },
  {
    title: 'Kidneys',
    subtitle: 'Nephrotoxicity',
    key: 'nephrotoxicity',
    src: '/img/interactive-organs/kidney.svg',
    titlePosition: '',
  },
  {
    title: 'Liver',
    subtitle: 'Hepatotoxicity',
    key: 'hepatotoxicity',
    src: '/img/interactive-organs/liver.svg',
    titlePosition: '',
  },
  {
    title: 'Lungs',
    subtitle: 'Pulmonary',
    key: 'pulmonary',
    src: '/img/interactive-organs/lungs.svg',
    titlePosition: '',
  },
  {
    title: 'Thyroid',
    subtitle: 'Endocrine',
    key: 'Endocrine',
    src: '/img/interactive-organs/thyroid.svg',
    titlePosition: '',
  },
  {
    title: 'Eye',
    subtitle: 'Ocular',
    key: 'ocular',
    src: '/img/interactive-organs/eye.svg',
    titlePosition: '',
  },
];