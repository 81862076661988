import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {RequestT} from '../hooks/useRequests';
import useVendors from "../hooks/useVendors";
import { getStatusCounts } from '../utils/processMaps';
import AssayProcessMap from '../assay/AssayProcessMap';
import ConsultingProcessMap from '../consulting/ConsultingProcessMap';

type PropsT = {
  requestType: string;
  requests: RequestT[];
}

const debug = false;
const RequestsProcessMap = (props:PropsT) => {
  const {requestType, requests} = props;
  const [counts, setCounts] = useState<{[key:string]: number}>({});
  const {getRequestType} = useVendors();

  if (debug) console.log('common/RequestsProcessMap | requestType:', requestType);

  const getComponent = () => {
    switch (requestType) {
      case 'all':
        return null;
      case 'screen':
        return <AssayProcessMap counts={counts}/>
      case 'consulting':
        return <ConsultingProcessMap counts={counts}/>
      default:
        return <Error>{`unknown view type ${requestType}`}</Error>;
    }
  }

  useEffect(() => {
    if (requests !== undefined) {
      const assayRequests = requests.filter((r) => getRequestType(r) === requestType);
      setCounts(getStatusCounts(assayRequests));
    }
  }, [requests, requestType]);

  if (debug) console.log('counts', counts);

  return (
    <>
      {getComponent()}
    </>
  )
}

export default RequestsProcessMap;

const RequestProcessDetailsContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.width};
`;
RequestProcessDetailsContainer.defaultProps = {
  width: 'max-content'
}

const Error = styled.div`
    display: flex;
`;
