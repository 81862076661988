import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@src/store';
import useFileImport from '../../useFileImport';
import TextButton from '@components/controls/TextButton';
import NewFieldIcon from '@components/icons/newField.icon';
import { CompoundFieldT, updateField, ValueRendererT } from '@stores/fields';
import useCognito from '@as_core/account/useCognito';
import { FlexBanner } from '@as_core/elements/flexStyles';
import HeaderMenu from '@as_core/controls/HeaderMenu';
import SearchField from '@as_core/components/inputs/SearchField';

const columns = [
  { display: 'Symbol', dataId: 'symbol' },
  { display: 'Short Name', dataId: 'short_name' },
  { display: 'Short Description', dataId: 'short_description' },
];

type actionT = {
  key: string;
  label: string;
};

const menuActions: Array<actionT> = [
  { key: 'reference', label: 'Standardized Fields' },
  { key: 'user', label: 'User Defined Fields' },
];

export function generateSymbol(text: string) {
  const maxLength = 8;
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength);
  }
}

function createNewField(fieldId: string, columnName: string): CompoundFieldT {
  const newField: CompoundFieldT = {
    _source: 'user',
    field_id: fieldId,
    group_id: '96730156-6a8c-46f5-8ad6-803b60cb5465',
    data_key: ['user', fieldId, 'value'].join('.'),
    group_name: '',
    group_order: 0,
    value_renderer: ValueRendererT.string,
    field_options: '',
    cfields: true,
    animal_tox_general: false,
    animal_tox_pharma: false,
    table: {
      resizable: true,
      sortable: false,
      align: 'left',
      width: 220,
      minWidth: 80,
    },
    category: 'User Data',
    symbol: generateSymbol(columnName),
    short_name: columnName,
    long_name: columnName,
    short_description: columnName,
    long_description: columnName,
  };
  return newField;
}

const debug = false;
const ColumnFieldPickerTable = (props) => {
  // eslint-disable-next-line react/prop-types
  const { columnName, width, selectedColumns, onColumnPicked } = props;
  const { getActiveColumn } = useFileImport();
  const dispatch = useDispatch();
  const { getToken } = useCognito();
  const [localFieldFilter, setLocalFieldFilter] = useState<string>('reference');
  const { fields: fieldDescriptions } = useSelector(
    (state: RootState) => state.fields
  );
  debug &&
    console.log(
      'ColumnFieldPicker | columnName:',
      columnName,
      'selectedColumns:',
      selectedColumns
    );

  // ###########################################################################
  // Filter Results
  // ###########################################################################
  const [searchTerm, setSearchTerm] = useState('');

  const handleNewField = () => {
    if (debug) console.log('ColumnFieldPicker | handleNewField');
    const uuid = uuidv4();
    const field_id = 'user_' + uuid.split('-')[0];
    const token = getToken();
    const newField = createNewField(field_id, columnName);
    dispatch(updateField({ token, field: newField }));
    onColumnPicked(field_id);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setSearchTerm('');
    }
  };


  const activeColumnFieldFilter = getActiveColumn().fieldFilter;
  const getRowIds = React.useCallback(() => {
    if (_.isUndefined(fieldDescriptions)) {
      return [];
    }
    let rowIds = Object.keys(fieldDescriptions).filter(
      (f) =>
        // eslint-disable-next-line react/prop-types
        !selectedColumns.includes(f) &&
        !fieldDescriptions[f]?.hidden &&
        (fieldDescriptions[f]?.short_name?.toLowerCase().includes(searchTerm) ||
          fieldDescriptions[f]?.long_name
            ?.toLowerCase()
            .includes(searchTerm) ||
          fieldDescriptions[f]?.group_name?.toLowerCase().includes(searchTerm))
    );
    if (activeColumnFieldFilter !== 'all') {
      rowIds = rowIds.filter(
        (f) => fieldDescriptions[f]?.group_name === activeColumnFieldFilter
      );
    }
    if (localFieldFilter === 'reference') {
      rowIds = rowIds.filter((f) => !f.startsWith('user_'));
    } else {
      rowIds = rowIds.filter((f) => f.startsWith('user_'));
    }
    return rowIds;
  }, [
    fieldDescriptions,
    searchTerm,
    localFieldFilter,
    activeColumnFieldFilter,
  ]);

  const handleOnColumnPicked = ({ field_id }) => {
    onColumnPicked({ field_id });
  };

  if (debug) console.log('rowIds', getRowIds(), getActiveColumn().fieldFilter);

  // ###########################################################################
  // RENDER
  // ###########################################################################
  return (
    <Container width={width}>
      {getActiveColumn().fieldFilter !== 'Identity' ? (
        <FlexBanner>
          {menuActions.map((action) => (
            <HeaderMenu
              key={`user_data_${action.key}`}
              label={action.label}
              isActive={action.key === localFieldFilter}
              onClick={() => setLocalFieldFilter(action.key)}
            />
          ))}
        </FlexBanner>
      ) : null}
      <SearchPane>
        <SearchField
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          onKeyDown={handleKeyDown}
        />
      </SearchPane>
      <ScrollContainer
        height={getActiveColumn().fieldFilter !== 'Identity' ? 249 : 343}
      >
        <Table>
          <tbody>
            <Row>
              {columns.map(({ display, dataId }) => {
                return <Header key={dataId}>{display}</Header>;
              })}
            </Row>
            {getRowIds().map((field_id) => {
              return (
                <Row
                  key={'v' + field_id}
                  onClick={() => handleOnColumnPicked({ field_id })}
                >
                  {columns.map(({ dataId }, colIndx) => {
                    
                    return (
                      <Cell key={dataId} index={colIndx}>
                        {fieldDescriptions[field_id]["short_name"]}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </tbody>
        </Table>
      </ScrollContainer>
      {getActiveColumn().fieldFilter !== 'Identity' ? (
        <NewFieldPane>
          <TextButton
            text={'Create Column As New Field'}
            height={30}
            width={250}
            onClick={() => handleNewField()}
            icon={<NewFieldIcon />}
          />
        </NewFieldPane>
      ) : null}
    </Container>
  );
};

export default ColumnFieldPickerTable;

const Container = styled.div<{ width: number }>`
  width: calc(${(p) => p.width}px);
  max-width: 700px;
  display: block;
  position: relative;
  border-radius: 15px;
  padding-bottom: 5px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const SearchPane = styled.div`
  display: flex;
  padding: 5px;
`;

const NewFieldPane = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
`;

const ScrollContainer = styled.div<{ height: number }>`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  overflow-y: scroll;
  border-radius: 15px;
  width: calc(100% - 5px);
  padding-bottom: 15px;
  height: ${(p) => p.height}px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;

const Table = styled.table`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  table-layout: auto;
  max-width: 750px;
  border-radius: 15px;
`;

const Header = styled.th`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  font-size: 14px;
  padding: ${(p) => p.theme.sizes.small};
  position: sticky;
  top: -1px;
  /* padding-left: ${(p) => p.theme.sizes.small}; */
  /* padding-bottom: ${(p) => p.theme.sizes.xsmall}; */
  text-align: left;
`;

interface ExtraProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compound?: any;
  field_id?: string;
  fieldSrc?: string;
}

const Row = styled.tr<ExtraProps>`
  margin-bottom: 3px;
  border-radius: 15px;

  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
  }

  background-color: ${(p) => p.theme.palette.backgroundPrimary};

  &:hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary} !important;
    cursor: pointer;
  }
`;

const Cell = styled.td<{ index: number }>`
  height: ${(p) => p.theme.sizes.xlarge};
  padding-left: ${(p) => p.theme.sizes.small};
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  font-weight: ${(p) => p.theme.weights.light};
  width: ${(p) =>
    p.index === 0 ? '100px' : p.index === 1 ? '150px' : '450px'};
`;
