import React from "react";
import styled from "styled-components/macro";
import DataSaveIcon from "@components/icons/dataSave.icon";
import {Tooltip} from "@mui/material";

interface PropsI {
  path: string;
  file: string;
}

const debug = false;
const DownloadFile = ({path, file}:PropsI) => {
  const handleClick = (path:string, file:string) => {
    const filePath = path + file;
    if (debug) console.log('filePath', filePath);
    let link=document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
    link.remove();
  }

  return(
    <Tooltip
      title={`Download ${file}`}
      arrow
      placement={'bottom-end'}
      >
     <DownloadLink onClick={()=>handleClick(path, file)}>
       <DataSaveIcon />
     </DownloadLink>
    </Tooltip>
  )
}

export default DownloadFile;

const DownloadLink = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  filter: grayscale(0.75);
  :hover {
    filter: grayscale(0.0);
  }
`;