import React, { createContext } from 'react';
import {PlotBase, PlotConfig, PlotTheme} from '../plot.types';

export interface IPlotSettings {
  plot?: PlotConfig<PlotBase>,
  theme?: PlotTheme,
  switches?: {
    showCurveSampling?: boolean
  }
}

const PlotContext = createContext({
  plot: {},
  theme: {},
  switches: {
    showCurveSampling: false,
  },
} as IPlotSettings);

const PlotProvider = ({
  plot,
  theme,
  switches,
  children,
}: React.PropsWithChildren<IPlotSettings>) => {
  const settings = {} as IPlotSettings;
  settings.plot = plot;
  settings.theme = theme || plot?.config?.settings?.theme || {};
  settings.switches = switches || {};
  return (
    <PlotContext.Provider value={settings}>{children}</PlotContext.Provider>
  );
};

export { PlotProvider, PlotContext };
