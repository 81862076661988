import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
      size?: number;
}


const DataSetIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M17.7,2H6.3C3.9,2,2,4.1,2,6.5v11.1C2,20.1,4,22,6.3,22h11.3c2.4,0,4.3-2.1,4.3-4.5V6.5C22,3.9,20,2,17.7,2z M20.5,17.8
	c0,1.5-1.2,2.7-2.7,2.7H6.2c-1.5,0-2.7-1.2-2.7-2.7V6.2c0-1.5,1.2-2.7,2.7-2.7h11.6c1.5,0,2.7,1.2,2.7,2.7V17.8z"/>
        <path fill={colorHighlight} d="M6.7,10.2h0.7c0.2,0,0.3,0,0.4,0.1S8,10.5,8,10.6c0,0.2,0,0.3-0.1,0.4s-0.2,0.1-0.4,0.1H4.8
  c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4s0.2-0.1,0.4-0.1h0.8V6.4L5.1,6.6c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0
  c-0.1,0-0.2,0-0.3-0.1C4.5,6.4,4.5,6.3,4.5,6.2c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.2L6,5.5c0.1,0,0.2,0,0.2-0.1s0.1,0,0.1,0
  c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3V10.2z" />
        <path fill={colorHighlight} d="M10,5.4c0.7,0,1.2,0.3,1.6,0.8c0.4,0.5,0.6,1.2,0.6,2.1c0,0.9-0.2,1.6-0.6,2.1c-0.4,0.5-0.9,0.8-1.6,0.8
  c-0.7,0-1.2-0.3-1.6-0.8C8,9.9,7.8,9.2,7.8,8.3S8,6.7,8.4,6.2C8.8,5.7,9.3,5.4,10,5.4z M10,6.4c-0.3,0-0.6,0.2-0.7,0.5
  C9.1,7.2,9,7.7,9,8.3c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.4,0.5,0.7,0.5s0.6-0.2,0.7-0.5C10.9,9.4,11,8.9,11,8.3
  c0-0.6-0.1-1.1-0.3-1.4C10.6,6.6,10.3,6.4,10,6.4z" />
        <path fill={colorHighlight} d="M14.6,10.2h0.7c0.2,0,0.3,0,0.4,0.1s0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4s-0.2,0.1-0.4,0.1h-2.6
  c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4s0.2-0.1,0.4-0.1h0.8V6.4l-0.5,0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0
  c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.2L14,5.5c0.1,0,0.2,0,0.2-0.1s0.1,0,0.1,0
  c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3V10.2z" />
        <path fill={colorHighlight} d="M18.6,10.2h0.7c0.2,0,0.3,0,0.4,0.1s0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4s-0.2,0.1-0.4,0.1h-2.6
  c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1h0.8V6.4l-0.5,0.1c-0.1,0-0.1,0-0.2,0
  s-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.2l1.1-0.3c0.1,0,0.2,0,0.2-0.1
  s0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1s0.1,0.2,0.1,0.3V10.2z" />
        <path fill={colorHighlight} d="M6.7,17.7h0.7c0.2,0,0.3,0,0.4,0.1S8,18,8,18.1c0,0.2,0,0.3-0.1,0.4s-0.2,0.1-0.4,0.1H4.8
  c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4s0.2-0.1,0.4-0.1h0.8v-3.7l-0.5,0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0
  c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.2L6,13c0.1,0,0.2,0,0.2-0.1s0.1,0,0.1,0
  c0.1,0,0.2,0,0.3,0.1c0,0.1,0.1,0.2,0.1,0.3V17.7z" />
        <path fill={colorHighlight} d="M10.7,17.7h0.7c0.2,0,0.3,0,0.4,0.1s0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4s-0.2,0.1-0.4,0.1H8.8
  c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4s0.2-0.1,0.4-0.1h0.8v-3.7l-0.5,0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0
  c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.2L10,13c0.1,0,0.2,0,0.2-0.1s0.1,0,0.1,0
  c0.1,0,0.2,0,0.3,0.1c0,0.1,0.1,0.2,0.1,0.3V17.7z" />
        <path fill={colorHighlight} d="M14,12.9c0.7,0,1.2,0.3,1.6,0.8c0.4,0.5,0.6,1.2,0.6,2.1c0,0.9-0.2,1.6-0.6,2.1c-0.4,0.5-0.9,0.8-1.6,0.8
  c-0.7,0-1.2-0.3-1.6-0.8c-0.4-0.5-0.6-1.2-0.6-2.1s0.2-1.6,0.6-2.1C12.8,13.2,13.3,12.9,14,12.9z M14,13.9c-0.3,0-0.6,0.2-0.7,0.5
  c-0.2,0.3-0.3,0.8-0.3,1.4c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.4,0.5,0.7,0.5s0.6-0.2,0.7-0.5c0.2-0.3,0.3-0.8,0.3-1.4
  c0-0.6-0.1-1.1-0.3-1.4C14.5,14.1,14.3,13.9,14,13.9z" />
        <path fill={colorHighlight} d="M18.6,17.7h0.7c0.2,0,0.3,0,0.4,0.1s0.1,0.2,0.1,0.4c0,0.2,0,0.3-0.1,0.4s-0.2,0.1-0.4,0.1h-2.6
  c-0.2,0-0.3,0-0.4-0.1s-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1h0.8v-3.7l-0.5,0.1c-0.1,0-0.1,0-0.2,0
  s-0.1,0-0.1,0c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.2l1.1-0.3c0.1,0,0.2,0,0.2-0.1
  s0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3V17.7z" />
      </svg>
    </>
  )
}

export default DataSetIcon;