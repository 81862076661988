import React from 'react';
import styled from 'styled-components/macro';
import Draggable from 'react-draggable';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

type Props = {
  children: React.ReactNode;
  title: string;
  modalOpen: boolean;
  onCloseClick: () => void;
  width?: number;
  height?: number;
  opacity?: number;
  className?: string;
};
export type Ref = {
  openModal: () => void;
  closeModal: () => void;
};
const DraggableModal = (props: Props) => {
  const {
    title,
    modalOpen,
    onCloseClick,
    width = 600,
    height = 400,
    opacity = 0.9,
  } = props;

  const MyDraggable = Draggable;

  return (
    <MyDraggable handle={'.handle'}>
      <StyledModal
        open={modalOpen}
        hideBackdrop={true}
        width={width}
        height={height}
        opacity={opacity}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        disableAutoFocus={true}
      >
        <ModalContent>
          <ModalHeader className={'handle'}>
            <FlexItem>{title}</FlexItem>
            <FlexItem>
              <Close onClick={onCloseClick} />
            </FlexItem>
          </ModalHeader>
          <ModalContent>{props.children}</ModalContent>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </StyledModal>
    </MyDraggable>
  );
};

export default DraggableModal;

const StyledModal = styled(Modal)<{
  width: number;
  height: number;
  opacity: number;
}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  opacity: ${(p) => p.opacity};
  border-radius: 15px;
  border: 1px solid #636363;
  box-shadow: 20px 20px 10px ${(p) => p.theme.palette.backgroundSecondary};
  padding: 0;
  
  &.MuiModal-root {
    position: absolute;
    left: calc(50vw - 0.5 * ${(p) => p.width}px);
    top: calc(50vh - 0.5 * ${(p) => p.height}px);
  }
`;

const ModalHeader = styled.div`
  display: flex;
  font-size: 18px;
  height: 20px;
  padding: 5px 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: 15px 15px 0 0;
  width: 100%;
`;

const FlexItem = styled.div`
  display: flex;
  padding: 0 10px;
  cursor: pointer;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  height: calc(100% - 15px);

  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: transparent;
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;
