import { UseCartObjectReturnType } from '@as_core/hooks/useShoppingCart';
import React, { ReactNode } from 'react';
import OrderCreate from '@business/orders/OrderCreate';
import OrderConfirm from '@business/orders/components/OrderConfirm';
import OrdersStripe from '@business/orders/OrdersStripe';
import RequestsList from './RequestsList';
import OrdersList from '../orders/OrdersList';

export const getTitle = (
  action: string
): string => {
  switch(action) {
    case 'create':
      return 'Create New Order';
    case 'confirm':
      return 'Confirm Order';
    case 'active':
      return 'In Process Orders';
    case 'requests':
      return 'Open Requests';
    case 'consulting':
      return 'Open Consulting Requests';
    case 'stripe':
      return 'Processing Order';
    case 'complete':
      return 'Completed Orders';
  }
  return 'Title';
}

export const getComponent = (
  action: string,
  ordersCart:UseCartObjectReturnType
): ReactNode => {
  switch(action) {
    case 'create':
      return <OrderCreate ordersCart={ordersCart}/>
    case 'confirm':
      return <OrderConfirm ordersCart={ordersCart}/>
    case 'stripe':
      return <OrdersStripe />
    case 'requests':
      return <RequestsList requestType={'screen'} />
    case 'consulting':
      return <RequestsList requestType={'consulting'} />
    case 'active':
      return <OrdersList orderType={'active'}/>
    case 'complete':
      return <OrdersList orderType={'complete'}/>
  }
  return <div>Unknown Action {action}</div>
}