const angleBreak1 = Math.PI / 6;
const angleBreak2 = 2 * Math.PI - angleBreak1;
const angleOffset = Math.PI / 120;

function getSymbol(fieldsInfo, id) {
  const fieldInfo = fieldsInfo.find((v) => v.field === id);
  if (fieldInfo?.symbol) return fieldInfo.symbol;
  return id
}

export const createFieldCoronaAngles = (
  fieldTypes,
  primary = null,
  secondaries = [],
  fieldsInfo = []
) => {
  if (fieldTypes.length === 0) return [];

  // secondary dataFields
  let secondaryFieldTypes = fieldTypes.filter((d) => d.status === 'secondary');
  if (secondaries && secondaries.length) {
    secondaryFieldTypes = secondaries.map((d) => ({ type: d, name: d }));
  }
  const numFields = secondaryFieldTypes.length;
  const fieldAngleSpan = (angleBreak2 - angleBreak1) / numFields;

  let angle = angleBreak1;
  let angles = secondaryFieldTypes.map((d) => {
    angle += fieldAngleSpan;
    return {
      fieldType: d.type,
      fieldName: d.name,
      fieldSymbol: getSymbol(fieldsInfo, d.name),
      startAngle: angle - fieldAngleSpan + angleOffset,
      endAngle: angle - angleOffset,
    };
  });

  // primary field
  let { type: fieldType, name: fieldName } =
    fieldTypes.find((d) => d.status === 'primary') || {};
  if (primary) {
    fieldType = fieldName = primary;
  }

  angles = [
    {
      fieldType,
      fieldName,
      fieldSymbol: getSymbol(fieldsInfo, fieldName),
      startAngle: angleBreak2 + angleOffset,
      endAngle: 2 * Math.PI + angleBreak1 - angleOffset,
    },
    ...angles,
  ];

  return angles;
};
