import React from 'react';

import styled from 'styled-components/macro';
import SearchCompound from '@components/compoundEditor/SearchCompound';
import DataLoading from '@as_core/elements/DataLoading';

const PgSearch = () => {
  const [searchText, setText] = React.useState<string>('');
  const [searchSmiles, setSearchSmiles] = React.useState<string>('');
  const [searchType, setSearchType] = React.useState<string>('text');
  const [similarityThreshold, setSimilarityThreshold] = React.useState<number>(0.75);
  const [isSearching, setIsSearching] = React.useState(false);

  const handleSearch = () => {
    setIsSearching(true);
  }

  return (
    <Container>
      <Header>Test page for structure and similarity search</Header>
      { isSearching ?
        <DataLoading />
      :
        <SearchCompound
          smiles = {searchSmiles}
          onSmilesChange={(smiles) => setSearchSmiles(smiles)}
          text = {searchText}
          onTextChange={(text) => setText(text)}
          searchType = {searchType}
          onSearchTypeChange={(e) => setSearchType(e.target.value)}
          similarityThreshold = {similarityThreshold}
          onSimilarityThresholdChange={(v) => setSimilarityThreshold(v)}
          onSubmit={() => handleSearch()}
        />
      }
    </Container>
  );
};

export default PgSearch;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: calc(100vw - 50px);
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`
