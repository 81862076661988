import React, { useEffect, ReactNode, useState } from 'react';
import { ColumnDataT, DataSetT } from '@components/dataSets/dataSet.types';
import BasicTable from '@as_core/tables/BasicTable';
import DataLoading from '@as_core/elements/DataLoading';
import HoverTextTruncate from '@as_core/elements/HoverTextTruncate';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

interface propsT {
  dataSet: DataSetT;
}

function transformColumns(dataColumns: ColumnDataT[]) {
  const newColumns: BasicTableFieldsT[] = [];
  dataColumns.forEach(col => {
    const updatedColumn: BasicTableFieldsT = col as BasicTableFieldsT;
    updatedColumn.width = 100;
    updatedColumn.align = 'center';
    if (['compound', 'smiles'].includes(col.value)) {
      updatedColumn.width = 200;
    } else {
      if (col.label.length > 6) {
        updatedColumn.label = <HoverTextTruncate text={col.label} characters={6} showRear={false}/>;
      }
    }
    newColumns.push(updatedColumn);
  })
  return newColumns;
}

function transformRows(dataRows: { [key: string]: number | string}[]): {[key: string]: number | string | ReactNode}[] {
  const newRows: {[key: string]: number | string | ReactNode }[] = [];
  dataRows.forEach((row) => {
    const updatedRow = {};
    for (const key in row) {
      let value: string | number | Element | ReactNode = row[key];
      if (typeof value === 'string') {
        if (value.length > 20) {
          value = <HoverTextTruncate text={value} characters={20} showRear={false}/>;
        }
      } else if (typeof value === 'number') {
        value = value.toFixed(2).toString();
      }
      updatedRow[key] = value;
    }
    newRows.push(updatedRow);
  })
  return newRows;
}

const debug = false;
const PreviewTargetPanel = (props: propsT) => {
  const { dataSet } = props;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  if (debug) console.log('PreviewTargetPanel | dataSet:', dataSet);

  useEffect(() => {
    if (dataSet.columns.length > 0) {
      setColumns(transformColumns(dataSet.columns));
      setRows(transformRows(dataSet.data));
    }
  }, [dataSet]);

  if (debug) console.log('PreviewTargetPanel | props', props);
  if (debug) console.log('PreviewTargetPanel | (columns, rows): ', columns, rows);
  return(
    <>
      { !columns.length ?
        <DataLoading /> :
        <BasicTable
          fields={columns}
          rows={rows}
        />
      }
    </>
  );
};

export default PreviewTargetPanel;
