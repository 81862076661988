import { ScatterPlotDatum, ScatterPlotRawSerie } from '@nivo/scatterplot';
import { KeyColor, PlotData } from '@plotting/single-plot-view/plot.types';
import { getDefaultColorScale } from '@utils/scales/color/ColorScale.service';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';

export const getScatterplotSeries = (
  data: PlotData,
  xcolumn: string,
  yColumn: string,
  groupByColumn: string,
  sizeColumn: string,
  name: string,
): ScatterPlotRawSerie<ScatterPlotDatum & { size: number }>[] => {
  // Step 1: Group data by unique values in the groupColumn
  const groupedData: { [key: string]: PlotData[] } = {};

  data.forEach((point) => {
    const groupValue = point[groupByColumn];

    if (!groupedData[groupValue]) {
      groupedData[groupValue] = [];
    }

    groupedData[groupValue].push(point as any);
  });

  // // Step 2: create a default color scale in case no colors are provided
  // const allGroups = Object.keys(groupedData)
  // const defaultColors = COLORS.find((col) => col.id === 'aseda');
  // const defaultColorScale = scaleOrdinal<string>()
  //   .domain(allGroups.map((g) => String(g)))
  //   .range(defaultColors.scheme);

  // Step 3: Transform grouped data into series format
  const series = [];

  Object.keys(groupedData).forEach((group) => {

    // let color = defaultColorScale(group)
    // if (colorConfig) {
    //   const selectedColorItem = colorConfig.find((item) => item.id === group);
    //   if (selectedColorItem) {
    //     color = selectedColorItem.color; // Use the color from selected item if found
    //   }
    // }

    const seriesData = groupedData[group].map((point, index) => ({
      x: point[xcolumn],
      y: point[yColumn],
      size: point[sizeColumn],
      name: point[name] ? `${point[name]}-${index}` : `compound-${index}`,
      "xAxisName": xcolumn || "x",
      "yAxisName": yColumn || "y",
    }));

    series.push({
      id: group,
      data: seriesData,
    });
  });

  return series;
};
