import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const ListIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M6.4,6.5h13.9c0.2,0,0.4-0.1,0.5-0.2C20.9,6.1,21,5.9,21,5.8s-0.1-0.4-0.2-0.5C20.7,5.1,20.5,5,20.3,5H6.4
	C6.2,5,6,5.1,5.9,5.2C5.7,5.4,5.7,5.6,5.7,5.8s0.1,0.4,0.2,0.5C6,6.4,6.2,6.5,6.4,6.5z"/>
          <path fill={color} d="M20.3,11H6.4c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2h13.9
	c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5C20.7,11.1,20.5,11,20.3,11z"/>
          <path fill={color} d="M20.3,17H6.4c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2h13.9
	c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5C20.7,17.1,20.5,17,20.3,17z"/>
          <circle fill={colorHighlight} cx="3.5" cy="5.8" r="0.8"/>
          <circle fill={colorHighlight} cx="3.5" cy="11.8" r="0.8"/>
          <circle fill={colorHighlight} cx="3.5" cy="17.8" r="0.8"/>
        </g>
      </svg>
    </>
  )
}

export default ListIcon;