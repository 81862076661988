import React from 'react';
import styled from 'styled-components/macro';

interface TileProps {
  info: Record<string, string>;
}

function getRef(label, value) {
  if (value) {
    return (
      <Item>
        {label}: {value}
      </Item>
    );
  }
  return <Item></Item>;
}

const Tile = ({ info }: TileProps) => {
  return (
    <TileContainer>
      <Header>
        <Types>{info.type}</Types>
        <RowCenter>
          <Title>{info.name}</Title>
        </RowCenter>
      </Header>
      <DescriptionCenter>
        <Description>{info.description}</Description>
      </DescriptionCenter>
      <Data>
        {getRef('DOI', info?.doi)}
        {getRef('Tutorial', info?.document)}
        {getRef('Data', info?.file)}
      </Data>
    </TileContainer>
  );
};

export default Tile;

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  height: 150px;
  margin: 5px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const BaseItem = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
`;

const RowCenter = styled(BaseItem)`
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Header = styled(BaseItem)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 32px;
`;

const Types = styled(BaseItem)`
  width: 70px;
  margin-bottom: 0;
`;

const Title = styled(BaseItem)`
  color: ${(p) => p.theme.palette.textPrimary};
  margin-bottom: 0;
`;

const DescriptionCenter = styled(BaseItem)`
  height: 70px;
  align-items: center;
`;

const Description = styled(BaseItem)`
  text-align: center;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Data = styled(BaseItem)`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  min-width: 50px;
  font-size: 12px;
`;
