import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface DropdownProps {
  value: string;
  handleChangedrop: (event: SelectChangeEvent<string>) => void;
  menuItems: { value: string; label: string; icon: React.ReactNode }[];
}

const SimpleDropdown: React.FC<DropdownProps> = ({ value, handleChangedrop, menuItems }) => {
  return (
    <FormControl sx={{ 
      '& .MuiInputBase-root': {
        color: 'white',
        borderColor: '#8B9A46',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '100px',
        minWidth: '120px',
        justifyContent: 'center',
        height: '30px',
        marginTop: '4px',
       },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingRight: '0px',
      },
      width: 'auto',
      fontSize: '12px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    }}>
      <Select
        value={value}
        onChange={handleChangedrop}
        IconComponent={ExpandMoreRoundedIcon}
        sx={{
          width: 'auto',
          fontSize: '12px',
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: '0px',
          },
          icon: {
            position: 'relative',
            color: '#8B9A46',
            fontSize: '10px',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
         {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SimpleDropdown;
