import React, { memo, useState } from 'react';
import styled from 'styled-components/macro';
import { Button, ButtonGroup, Modal, Typography } from '@mui/material';
import {
  BoxplotBase,
  PieBase,
  LineBase,
  PlotBase,
  PlotConfig,
  VolcanoBase,
  BarBase,
  DrcBase,
  HistogramBase,
  ScatterBase,
} from '../single-plot-view/plot.types';
import { buildPlotConfig } from './build-plot-config';
import { BaseLayerType } from '@plotting/single-plot-view/plot.types';
import { PlotTypeSelectionTray } from './PlotTypeSelectionTray';
import { ScrollBox } from '@components/scrollbox/scrollbox';
import NewGraphIcon from '@components/icons/newGraph.icon';

import { ScatterModalEditor } from './base/ScatterModalEditor';
import { BarModalEditor } from './base/BarModalEditor';
import { PieModalEditor } from './base/PieModalEditor';
import { BoxplotModalEditor } from './base/BoxplotModalEditor';
import { VolcanoModalEditor } from './base/VolcanoModalEditor';
import { LineModalEditor } from './base/LineModalEditor';
import { DrcModalEditor } from './base/DrcModalEditor';
import { HistogramModalEditor } from './base/HistogramModalEditor';
import InputField from '@as_core/components/inputs/InputField';

interface PlotConfigModalProps {
  plot?: PlotConfig<PlotBase>;
  open?: boolean;
  onSave?: (config: PlotConfig<PlotBase>) => void;
  onClose?: () => void;
}

export const PlotConfigModal = memo(
  ({
    plot,
    onSave,
    onClose,
    open: defaultOpen = false,
  }: PlotConfigModalProps) => {
    const [open, setOpen] = useState(defaultOpen);

    const handleCancel = () => {
      setOpen(false);
      onClose && onClose();
    };

    const handleSave = () => {
      setOpen(false);
      onClose && onClose();
    };

    const handleTitleChange = (newTitle: string) => {
      const newPlot = { ...plot, name: newTitle };
      onSave(newPlot);
    };

    const handleChartTypeChange = (newType: BaseLayerType) => {
      const newPlotConfig = buildPlotConfig(plot, newType);
      onSave(newPlotConfig);
    };

    const BaseControls = {
      scatter: (
        <ScatterModalEditor
          plot={plot as PlotConfig<ScatterBase>}
          onSave={onSave}
        />
      ),
      bar: (
        <BarModalEditor plot={plot as PlotConfig<BarBase>} onSave={onSave} />
      ),
      histogram: (
        <HistogramModalEditor
          plot={plot as PlotConfig<HistogramBase>}
          onSave={onSave}
        />
      ),
      pie: (
        <PieModalEditor plot={plot as PlotConfig<PieBase>} onSave={onSave} />
      ),
      line: (
        <LineModalEditor plot={plot as PlotConfig<LineBase>} onSave={onSave} />
      ),
      drc: (
        <DrcModalEditor plot={plot as PlotConfig<DrcBase>} onSave={onSave} />
      ),
      boxplot: (
        <BoxplotModalEditor
          plot={plot as PlotConfig<BoxplotBase>}
          onSave={onSave}
        />
      ),
      volcano: (
        <VolcanoModalEditor
          plot={plot as PlotConfig<VolcanoBase>}
          onSave={onSave}
        />
      ),
    };

    return (
      <Modal
        open={open}
        onClose={handleCancel}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <InnerPanel>
          <ModalScroll scrollX={false}>
            <ModalTitle>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Create Plot
                <TitleIcon>
                  <NewGraphIcon />
                </TitleIcon>
              </Typography>
            </ModalTitle>
            <PanelBody>
              <InputField
                id='standard-basic'
                label='Title'
                variant='filled'
                fullWidth
                value={plot.name}
                onChange={(e) => handleTitleChange(e.target.value)}
              />
              <PlotTypeSelectionTray
                currentPlotType={plot.config.base as BaseLayerType} // TODO: in the plot config type, base should NOT be a string!
                onChange={(newChartType) => handleChartTypeChange(newChartType)}
              />
              <MetaPanel>{BaseControls[plot.config.base]}</MetaPanel>
              <ActionBar>
                <ButtonGroup aria-label='outlined button group' size={'small'}>
                  <StyledButton variant='outlined' onClick={handleCancel}>
                    Cancel
                  </StyledButton>
                  <StyledButton variant='contained' onClick={handleSave}>
                    Save
                  </StyledButton>
                </ButtonGroup>
              </ActionBar>
            </PanelBody>
          </ModalScroll>
        </InnerPanel>
      </Modal>
    );
  }
);
PlotConfigModal.displayName = 'PlotConfigModal';

const InnerPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
  border: 2px solid ${(p) => p.theme.palette.backgroundQuaternary};
  box-shadow: #0d2550;
  padding: 0;
`;

const MetaPanel = styled.div`
  min-height: 200px;
`;

const ModalScroll = styled(ScrollBox)`
  max-height: 100vh;
`;

const ModalTitle = styled.div`
  position: relative;
  padding: 8px 16px;
`;

const TitleIcon = styled.div`
  position: relative;
  float: right;
`;

const PanelBody = styled.div`
  position: relative;
  padding: 8px 16px;
  min-height: 300px;
  border-top: 1px solid ${(p) => p.theme.palette.textPrimary};
`;

const ActionBar = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
`;

const StyledButton = styled(Button)`
  width: 60px;
`;
