import React from 'react';
import styled from 'styled-components/macro';

import { TextSmallItalics } from '@as_core/elements/textStyles';
import BasicTable from '@as_core/tables/BasicTable';
import IconCart from '@components/icons/IconCart';

import {UseCartObjectReturnType} from "@as_core/hooks/useShoppingCart";
import {getCartTableRows, getCartTotalCost} from "@business/orders/components/utils";

export const ordersCartTableFields = [
  { value: 'delete', label: '', width: 50 },
  { value: 'info', label: '', width: 50 },
  { value: 'name', label: 'Product Name', width: 250, align: 'left' },
  { value: 'metadata.catalogNumber', label: 'Catalog Number', width: 150, align: 'left' },
  { value: 'price', label: 'Unit Price', width: 100, align: 'right' },
  { value: 'quantity', label: 'Quantity', width: 100 },
  { value: 'totalCost', label: 'Line Item Cost', width: 100, align: 'right' },
]

export type OrdersCartPropsI = {
  cart: UseCartObjectReturnType;
  noQuantityChanges?: boolean;
  debug?: boolean;
};

const OrdersCart = (props: OrdersCartPropsI) => {
  const {cart} = props;
  if (props.debug) console.log('OrderCart | {props}:', props);

  // empty carts
  if (!Object.keys(cart.data).length) {
    return(
      <Contents>
        <EmptyCartContainer>
          <TextSmallItalics color={'accentSecondary'}>Empty Cart</TextSmallItalics>
        </EmptyCartContainer>
      </Contents>
    )
  }

  // cart with items
  const showFields = props.noQuantityChanges ?
    ordersCartTableFields.filter((f)=> f.value !== 'delete') : ordersCartTableFields;
  return (
    <Contents>
      <OrdersCartTitle>
        <IconCart/><Space/>
        Orders Cart - Total Cost {getCartTotalCost(cart.data)}
      </OrdersCartTitle>
      <BasicTable
        fields={showFields}
        rows={getCartTableRows(cart, props)}
      />
    </Contents>
  );
};

OrdersCart.defaultProps = {
  noQuantityChanges: false,
  debug: false
}

export default OrdersCart;

const Contents = styled.div`
  display: flex;
  width:  100%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: ${(p) => p.theme.sizes.small};
`;

const EmptyCartContainer = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;

const Space = styled.div`
  display: flex;
  width: 10px;
`;

const OrdersCartTitle = styled.div`
  display: flex;
  font-size: 18px;
  margin-top: 12px;
`;
