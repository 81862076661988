import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import MolImgTile from '../images/MolImgTile';
import ScoreTile from './ScoreTile';

export interface INeighbor {
  mol_svg: object;
  sim: number;
  source: string;
  id: string | number;
}

const debug = false;
const SimilarityCard: React.FC<{ neighbor: INeighbor | null }> = ({
  neighbor,
}) => {
  if (debug) console.log('SimilarityCard | neighbor:', neighbor);

  return (
    <>
      {_.isEmpty(neighbor) || neighbor === null ? (
        <NeighborContainer />
      ) : (
        <NeighborContainer>
          <MolImgTile width={125} mol_svg={neighbor?.mol_svg} hover={true} />
          <ScoreTileContainer score={neighbor?.sim} />
          <DetailTileContainer>
            <DetailTile>
              <DetailTitle>Source</DetailTitle>
              <DetailValue>{neighbor?.source}</DetailValue>
            </DetailTile>
            <DetailTile>
              <DetailTitle>ID</DetailTitle>
              <DetailValue>{neighbor?.id}</DetailValue>
            </DetailTile>
          </DetailTileContainer>
        </NeighborContainer>
      )}
    </>
  );
};

export default SimilarityCard;

const NeighborContainer = styled.div`
  display: flex;
  margin: 5px;
  max-width: min-content;
  grid-template-columns: 100px 100px 1fr;
  justify-content: center;
  align-content: center;
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  transition: 0.2s ease;
  /* & :hover {
    border: ${(p) => p.theme.borders.thinAccentPrimary};
  } */
`;

const ScoreTileContainer = styled(ScoreTile)`
  margin: 5px;
`;

const DetailTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.sizes.xsmall};
`;

const DetailTile = styled.div`
  display: flex;
  margin: 2px;
  font-size: ${(p) => p.theme.sizes.small};
  flex-direction: row;
`;

const DetailTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  min-width: 80px;
  color: ${(p) => p.theme.palette.titleSecondary};
`;

const DetailValue = styled.div`
  display: flex;

  color: ${(p) => p.theme.palette.textPrimary};
`;
