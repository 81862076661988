import React, { useState } from 'react';
import { KeyColor } from '@plotting/single-plot-view/plot.types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ColorPicker } from '@components/controls/ColorPicker';

type KeyColorTableProps = {
  values: Array<KeyColor>;
  onChange?: (colors: Array<KeyColor>) => void;
};

// Allow to change the color of 1 specific group on the chart.
export const KeyColorTable = ({ values, onChange }: KeyColorTableProps) => {
  const [rows, setRows] = useState(values || []);

  const updateKeyColor = (node, color) => {
    node.color = color.hex;
    setRows([...rows]);
    const update = [...rows];
    setRows(update);
    onChange && onChange(update);
  };

  return (
    <TableContainer
      component={Paper}
      variant='outlined'
      style={{ height: '180px' }}
    >
      <Table padding={'none'} size={'medium'} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component='th' scope='row' style={{ padding: '0 8px' }}>
              <Typography color={'textSecondary'} variant={'caption'}>
                Group
              </Typography>
            </TableCell>
            <TableCell
              component='th'
              scope='row'
              width={70}
              style={{ padding: '0 8px' }}
              align={'center'}
            >
              <Typography color={'textSecondary'} variant={'caption'}>
                Color
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((key) => (
            <TableRow key={key.id}>
              <TableCell
                component='th'
                scope='row'
                style={{
                  maxWidth: 80,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'auto',
                  paddingLeft: 8,
                  fontSize: 12,
                }}
              >
                {key.id}
              </TableCell>
              <TableCell component='th' scope='row' align={'center'}>
                <ColorPicker
                  color={key.color}
                  onChange={(color) => updateKeyColor(key, color)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
