import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import * as d3 from 'd3';
import * as d3Zoom from 'd3-zoom';

const height = 800;
const width = 800;

const data = [
  { id: 1, cx: 50, cy: 50, r: 25, color: 'red' },
  { id: 2, cx: 150, cy: 150, r: 50, color: 'blue' },
  { id: 3, cx: 300, cy: 300, r: 75, color: 'white' },
  { id: 4, cx: 700, cy: 500, r: 50, color: 'yellow' },
  { id: 5, cx: 700, cy: 100, r: 25, color: 'green' },
  { id: 6, cx: 150, cy: 550, r: 50, color: 'orange' },
  { id: 7, cx: 400, cy: 550, r: 75, color: 'cyan' },
  // ... Add more circles if desired
];

function zoomToCircle(svg, zoom, d) {
  const x = d.cx;
  const y = d.cy;
  const width = +svg.attr("width");
  const height = +svg.attr("height");
  const scale = 2;

  const translateX = 0.5*width - x;
  const translateY = 0.5*height - y;
  //console.log('tX, tY, s', translateX, translateY, scale);

  svg.transition()
      .duration(500)
      .call(
          zoom.transform,
          d3Zoom.zoomIdentity
              .scale(scale)
              .translate(translateX, translateY)

      );
}

const ZoomCircle = () => {
  const svgRef = useRef(null);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const svg = d3.select(svgRef.current);

  useEffect(() => {
    // Setup Zoom behavior
    const zoom = d3Zoom.zoom()
        .scaleExtent([1, 8])
        .filter(() => false)  // Disable all zooming/panning interactions
        .on('zoom', (event) => {
          svg.attr('transform', event.transform);
        });

    svg.call(zoom);

    if (selectedCircle !== null) {
      const circleData = data.find(d => d.id === selectedCircle);
      zoomToCircle(svg, zoom, circleData);
    } else {
      svg.transition()
          .duration(750)
          .call(
              zoom.transform,
              d3Zoom.zoomIdentity
          )
    }
    //console.log('useEffect', d3Zoom.zoomTransform(svg.node()));
  }, [selectedCircle]);

  useEffect(() => {
    console.log('useEffect | draw circles')
    const zoom = d3Zoom.zoom()
        .scaleExtent([1, 8])
        .filter(() => false)  // Disable all zooming/panning interactions
        .on('zoom', (event) => {
          svg.attr('transform', event.transform);
        });
    // Create circles
    svg.selectAll('circle')
        .data(data)
        .join('circle')
        .attr('cx', d => d.cx)
        .attr('cy', d => d.cy)
        .attr('r', d => d.r)
        .attr('fill', d => d.color)
        .on('click', (event, d) => {
          setSelectedCircle(d.id);
          zoomToCircle(svg, zoom, d);
        });
  }, []);
  //console.log('ZoomCircle', d3Zoom.zoomTransform(svg.node()));

  return (
    <>
      <ZoomCircleContainer width={width} height={height}>
        <svg ref={svgRef} width={width} height={height}>
          <g></g>
        </svg>
      </ZoomCircleContainer>
      <ButtonContainer width={width}>
        <StyledButton onClick={() => setSelectedCircle(null)}>Reset View</StyledButton>
      </ButtonContainer>
    </>
  );
}

export default ZoomCircle;

const ZoomCircleContainer = styled.div<{width: number, height: number}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px; 
  border: 1px solid yellow;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const ButtonContainer = styled.div<{width: number}>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: ${(p) => p.width}px;
`;

const StyledButton = styled.button`
  display: flex;
  width: 200px;
  z-index: 10;
`;