import { BarDatum } from "@nivo/bar";
import { bin, scaleLinear } from "d3";

export const binDataForHistogram = (data: number[], binNumber: number): BarDatum[] => {

    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);

    const xScale = scaleLinear()
        .domain([minValue, maxValue])

    const bucketGenerator = bin()
        .value((d) => d)
        .domain([minValue, maxValue])
        .thresholds(xScale.ticks(binNumber));

    const buckets = bucketGenerator(data);

    const nivoBuckets: BarDatum[] = buckets.map(bucket => {
        return ({
            bin: "[" + bucket.x0 + " - " + bucket.x1 + "]",
            count: bucket.length
        })
    })

    return nivoBuckets

};
