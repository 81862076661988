import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiClient } from '@utils/api/base';
import { AppThunk } from '@src/store';
import { CompoundT } from '@src/type';

export interface ActiveCompoundT {
  loading: boolean;
  compound: CompoundT | undefined;
}

let initialState: ActiveCompoundT = {
  compound: null,
  loading: false,
};

const activeCompoundSlice = createSlice({
  name: 'activeCompound',
  initialState: initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setActiveCompound: (state, { payload }: PayloadAction<CompoundT>) => {
      state.compound = payload;
    },
    clearActiveCompound: (state) => {
      state.compound = null;
      state.loading = false;
    },
  },
});

export const { setLoading, setActiveCompound, clearActiveCompound } =
  activeCompoundSlice.actions;

export default activeCompoundSlice.reducer;

export const getCompoundDetail =
  (
    id: string
    // compounds: Compounds
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    // const { data } = compounds;
    const data = [];
    const res_cached = await data.filter((cmp) => cmp.uuid === id);

    if (res_cached.length === 1) {
      dispatch(setActiveCompound(res_cached[0]));
    } else {
      try {
        const { data } = await apiClient.get(`/compounds/${id}`);
        dispatch(setActiveCompound(data));
      } catch (err) {
        console.error('getCompoundDetail Failed: ', err);
      }
    }
    dispatch(setLoading(false));
  };
