import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { Button, Alert } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { PlotConfigModal } from '../plot-config-modal/PlotConfigModal';
import { PlotBase, PlotConfig } from '../single-plot-view/plot.types';
import { SinglePlotView } from '../single-plot-view/SinglePlotView';
import { ScrollBox } from '@components/scrollbox/scrollbox';
import PageContainer from '@as_core/pages/PageContainer';
import usePlots from '../usePlots';
import useCognito from '@as_core/account/useCognito';

interface PlotEditProps {
  plotId: string;
  isDrawerEditorOpen?: boolean;

}

export const PlotEdit = ({ plotId, isDrawerEditorOpen }: PlotEditProps) => {
  const [plot, setPlot] = useState({} as PlotConfig<PlotBase>);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [altered, setAltered] = useState(false);

  const { getToken } = useCognito();
  const { getPlot, updatePlot } = usePlots();
  const navigate = useNavigate();

  if (!plotId) {
    console.error('You must provide plotId to this component!');
  }

  // load data
  if (plotId && !dataLoaded && !dataLoading) {
    setErrorMessage('');
    setDataLoading(true);
    getPlot(getToken(), plotId).then((initial) => {
      setDataLoaded(true);
      setDataLoading(false);
      if (initial !== null) {
        const userFieldIds = Object.keys(initial.data[0]) || [];
        const allColumns = userFieldIds.map((column) => ({
          label: column,
          value: column,
        }));
        initial.columns = allColumns;
        setPlot(initial);
      } else {
        setErrorMessage(
          `Unable to load data from ${plotId}. Not found. API error`
        );
      }
    });
  }

  const savePlot = async (config) => {
    const update = { ...config };
    setPlot(update);
    setAltered(false);
    return updatePlot(getToken(), update);
  };

  const savePlotAs = (config: PlotConfig<PlotBase>) => {
    config.uuid = uuidv4();
    config.name = `${config.name} (copy)`;
    savePlot({ ...config }).then(() => {
      const loc = `/plots/${config.uuid}`;
      navigate(loc);
    });
  };

  const saveRevert = () => {
    getPlot(getToken(), plotId).then((initial) => {
      setPlot(initial);
      setAltered(false);
      setDataLoaded(true);
    });
  };

  const onModalSave = (config) => {
    setPlot({ ...config });
    setDataLoaded(true);
    savePlot(config).then(() => {
      const loc = `/plots/${config.uuid}`;
      navigate(loc);
    });
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onChange = (config) => {
    setAltered(true);
    setPlot({ ...config });
  };

  const alert = (
    <ChangeAlert
      variant='outlined'
      severity='warning'
      action={
        <div>
          <Button size='small' onClick={() => saveRevert()}>
            Revert
          </Button>
          <Button
            size='small'
            variant='outlined'
            onClick={() => savePlotAs(plot)}
          >
            Save As
          </Button>
          <Button
            size='small'
            variant='contained'
            color='primary'
            onClick={() => savePlot(plot)}
          >
            Save
          </Button>
        </div>
      }
    >
      Plot has unsaved changes.
    </ChangeAlert>
  );
  return (
    <PageContainer title='Edit Plot'>
      <Container>
        {dataLoading ? (
          <>Data Loading</>
        ) : dataLoaded && plot?.data ? (
          <Wrapper>
            <SinglePlotView
              plot={plot}
              onChange={onChange}
              isDrawerEditorOpen={isDrawerEditorOpen}
            />
          </Wrapper>
        ) : errorMessage !== '' ? (
          <CenterMessage>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </CenterMessage>
        ) : (
          <CenterMessage>
            <ErrorMessage>Unknown error submit a bug report</ErrorMessage>
          </CenterMessage>
        )}
        {dataLoaded && plot?.data && (
          <PlotConfigModal
            plot={plot}
            open={modalOpen}
            onSave={onModalSave}
            onClose={onModalClose}
          />
        )}
      </Container>
      {altered && !modalOpen && alert}
    </PageContainer>
  );
};

const Container = styled(ScrollBox)`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ChangeAlert = styled(Alert)`
  background-color: ${(p) => p.theme.palette.backgroundPrimary} !important;
  position: absolute;
  right: 0;
  top: 10px;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 10px;
  width: 100%;
`;

const CenterMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ErrorMessage = styled.div`
  display: flex;
  width: max-content;
  color: ${(p) => p.theme.palette.accentSecondary};
  font-size: 16px;
  font-style: italic;
`;
