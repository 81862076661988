import React, { ReactNode } from "react";
import styled from "styled-components/macro";

type PropsT = {
  messages: string[] | ReactNode[];
  border?: boolean;
  width?: string;
  height?: string;
  padding?: string;
}
const ErrorsMessages = (props:PropsT) => {
  return(
    <CenterContainer
      width={props?.width ? props.width : '100%' }
      height = {props?.height ? props.height : '50%'}
    >
      <UserMessages
        border={props.border}
        padding = {props?.padding ? props.padding: '15px'}
      >
        {props.messages.map((item, index) =>
          <Message key={`error_message_${index}`}>{item}</Message>
        )}
      </UserMessages>
    </CenterContainer>
  )
}

export default ErrorsMessages;

const CenterContainer = styled.div<{width: string, height: string}>`
  display: flex;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UserMessages = styled.div<{border: boolean, padding: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding: ${(p) => p.padding};
  border: ${(p) => p.border ? 1: 0}px solid ${(p) => p.theme.palette.accentSecondary};
`;
UserMessages.defaultProps = {
  border: true
}


const Message = styled.div`
  display: flex;
`;

type colorType = 'primary' | 'secondary' | 'accentPrimary' | 'accentSecondary';
type fontStyleType = 'normal' | 'italic' | 'oblique';
export const MessageItem = styled.div<{fontSize?: number, fontStyle?: fontStyleType, color?: colorType}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: ${(p) => p.fontStyle};
  font-size: ${(p) => p.fontSize}px;
  color: ${(p) => p.color === 'primary' ? p.theme.palette.textPrimary :
          p.color === 'accentPrimary' ? p.theme.palette.accentPrimary :
          p.color === 'accentSecondary'? p.theme.palette.accentSecondary : p.theme.palette.textSecondary};
`;
MessageItem.defaultProps = {
  fontSize: 14,
  fontStyle: 'normal',
  color: 'secondary'
}