import React, { useState, useMemo } from 'react';
import UserEmailPreferences from '@pages/userManagement/UserEmailPreferences';
import SaveIcon from '@components/icons/upload.icon';
import EditIcon from '@components/icons/edit.icon';
import PagePanel from "@components/pages/PagePanel";
import {FlexItem, FlexRow} from "@components/layout/FlexStyles";
import MenuButton from "@components/elements/MenuButton";

const Preferences = () => {
  const [isEditEmailPreferences, setIsEditEmailPreference] = useState(false);

  const togglePreferences = () => {
    setIsEditEmailPreference((previous) => !previous);
  };

  const textEditPreferences = useMemo(() => {
    return (isEditEmailPreferences ? 'Save' : 'Edit') + ' Email Preferences';
  }, [isEditEmailPreferences]);

  const iconPreferences = useMemo(() => {
    return isEditEmailPreferences ? <SaveIcon /> : <EditIcon />;
  }, [isEditEmailPreferences]);

  return (
    <PagePanel title={'My Email Preferences'}>
      <FlexRow>
        <FlexItem>
          <MenuButton
            onClick={togglePreferences}
            tooltipPlacement={'bottom'}
            icon={iconPreferences}
            text={textEditPreferences}
          />
        </FlexItem>
        <FlexItem>
          <UserEmailPreferences isEditMode={isEditEmailPreferences} />
        </FlexItem>
      </FlexRow>
    </PagePanel>
  );
};

export default Preferences;