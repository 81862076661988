import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import { TextSmall } from '@as_core/elements/textStyles';
import { Logo } from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import {getSubscriptionDetails} from "@business/products/Subscriptions";
import _ from "lodash";
import {UserContext} from "@stores/UserContext";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import {getUserSubscriptionType} from "@utils/useUserAppInfo";

const availableFields: string[] = [ 'duration', 'organization', 'invitees', 'repositories' ];

function getDisplayDate(date) {
  const dateUTC = new Date(date);
  return dateUTC.toLocaleDateString() + ' ' + dateUTC.toLocaleTimeString();
}

function getCardRow(name, subscriptionDetails) {
  if (name === 'organization') {
    const orgName = _.get(subscriptionDetails, 'subscription.orgName', '');
    const orgId = _.get(subscriptionDetails, 'subscription.orgId', '');
    return (
      <Row key={`sv_row_${name}`}>
        <Label>Secure Data Area:</Label>
        <Value>{orgName} <HoverInfoText text={orgId}/></Value>
      </Row>
    )
  }
  if (name === 'duration') {
    const start = _.get(subscriptionDetails, 'subscription.createdOn', '');
    const end = _.get(subscriptionDetails, 'subscription.expiresOn', '');
    let value = getDisplayDate(start);
    let label = end !== '' ? 'Duration:' : 'Created:';
    if (end !== '') {
      value = value + ' - ' + getDisplayDate(end);
    }
    return (
      <Row key={`sv_row_${name}`}>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Row>
    )
  }
  if (name === 'invitees') {
    const number = _.get(subscriptionDetails, 'subscription.allowedInvites', 0);
    return (
      <Row key={`sv_row_${name}`}>
        <Label>Total Invitees:</Label>
        <Value>{number}</Value>
      </Row>
    )
  }
  if (name === 'repositories') {
      const repo_number = _.get(subscriptionDetails, 'subscription.allowedRepositories', 0);
      return(
        <Row key={`sv_row_${name}`}>
          <Label>Owned Repositories:</Label>
          <Value>{repo_number}</Value>
        </Row>
      )
  }
}


const debug = false;
const SubscriptionCard = () => {
  const { user } = useContext(UserContext);
  if (debug) console.log('SubscriptionCard | user:', user);
  const userSubscriptionType = getUserSubscriptionType(user.appInfo);
  const isOwner = user.appInfo.subscription.ownerAuthId === user.authId;
  const subscriptionDetails = getSubscriptionDetails(userSubscriptionType);

  if (debug) console.log('ProductCard | user:', user, 'subscriptionDetails:', subscriptionDetails);

  let showFields: string[] = availableFields;
  if (subscriptionDetails.level === 'basic') {
    showFields = availableFields.filter((f) => ['duration', 'invitees', 'repositories'].includes(f));
  }

  return(
   <CardContainer>
     <Row><Logo><img src={APP_CONFIG.logo} alt='logo' /></Logo></Row>
     <Row><TextSmall color={'primary'}>{subscriptionDetails?.name}</TextSmall></Row>
     <Row width={'80%'}><Value color={'accentSecondary'}>{subscriptionDetails?.description}</Value></Row>
     <Row>
       <Label color={'accent'}>Catalog #:</Label>
       <Value>{subscriptionDetails?.catalogNumber}</Value>
     </Row>
       {showFields.map((f) => (getCardRow(f, user?.appInfo)))}
     { ! isOwner ?
       <Row>
         <Label color={'accent'}>Subscription Owner:</Label>
         <Value>{ user.appInfo.subscription.ownerAuthEmail ? user.appInfo.subscription.ownerAuthEmail
           : 'unknown'}</Value>
       </Row>
     : null}
   </CardContainer>
  )
}

export default SubscriptionCard;

const CardContainer = styled.div`
  display: flex;
  border-radius: 15px;
  width: 550px;
  min-height: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary };
  background-color: ${(p) => p.theme.palette.backgroundTertiary };
`;

const Row = styled.div<{width?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px 0;
  ${(p) => p.width && `width: ${p.width};`}
`;

const Label = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: end;
  width: 250px;
  color: ${(p) => p.theme.palette.textPrimary };
`;

const Value = styled(Label)<{color?: string}>`
  width: 375px;
  padding-left: 5px;
  justify-content: start;
  color: ${(p) => p.color === 'accentSecondary' ? p.theme.palette.accentSecondary : 
          p.theme.palette.textSecondary };
`;