import React from 'react';
import styled, { keyframes } from 'styled-components';

interface ToastProps {
  message: string;
  type?: 'info' | 'success' | 'error';
  duration?: number;
  onClose?: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, duration = 10000, onClose }) => {
  const [resetKey, setResetKey] = React.useState(0);

  React.useEffect(() => {
    setResetKey((prevKey) => prevKey + 1); // Reset progress bar animation

    const timer = setTimeout(() => {
      if (onClose) onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <ToastContainer hasMessage={!!message}>
      <Message>{message}</Message>
      <ProgressBar key={resetKey} duration={duration} />
    </ToastContainer>
  );
};

export default Toast;

const ToastContainer = styled.div<{ hasMessage: boolean }>`
  padding: 1rem 2rem;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  color: ${(p) => p.theme.palette.textPrimary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  width: 60%;
  position: relative;
  overflow: hidden;
  height: ${(p) => (p.hasMessage ? 'auto' : '10px')};
`;

const Message = styled.div`
  font-size: 1rem;
  text-align: center;
`;

const progressBarAnimation = (duration: number) => keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const ProgressBar = styled.div<{ duration: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: ${(p) => p.theme.palette.accentPrimary};
  animation: ${(p) => progressBarAnimation(p.duration)} linear ${(p) => p.duration}ms;
  animation-fill-mode: forwards;
`;
