import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';

import { useOnClickOutside } from '../../../hooks';
import { FilterSearchI } from './filter.types';
import {
  LeftSection,
  RightSection,
  RightSectionList,
  RightSectionListItem,
  DraggableContainer,
  DraggableModalPanel,
} from '@components/modals/common';
import InputField from '@as_core/components/inputs/InputField';


const debug = false;
const FilterSearch = ({
  id,
  label,
  values,
  idInfo,
  onSelect,
}: FilterSearchI) => {
  const containerRef = useRef();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [hoveredSuggestion, setHoveredSuggestion] = useState(null);
  const [inputValue, setInputValue] = useState('');

  function handleSuggestionSelect(id) {
    onSelect(id);
    setShowSuggestions(false);
    setInputValue('');
  }

  useOnClickOutside(containerRef, () => setShowSuggestions(false));

  if (debug) console.log('idInfo ', idInfo);
  useEffect(() => {
    const matches = values.filter(
      (d) =>
        (_.get(idInfo[d], 'long_name', ' ')
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
          _.get(idInfo[d], 'group_name', ' ')
            .toLowerCase()
            .includes(inputValue.toLowerCase())) &&
        idInfo[d].filter
    );
    setSuggestions(matches);
  }, [inputValue]);

  return (
    <DraggableContainer>
      <DraggableModalPanel>
        <LeftSection>
          <InputField
            label={label}
            id={id}
            type='text'
            placeholder='Search...'
            autoComplete='off'
            value={inputValue}
            onFocus={() => setShowSuggestions(true)}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </LeftSection>

        {showSuggestions && (
          <RightSection ref={containerRef}>
            <RightSectionList>
              {suggestions.map((d, i) => (
                <RightSectionListItem
                  key={i}
                  active={hoveredSuggestion === i}
                  onMouseEnter={() => setHoveredSuggestion(i)}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => handleSuggestionSelect(d)}
                >
                  {idInfo[d].long_name} ({idInfo[d].value_renderer})
                </RightSectionListItem>
              ))}
            </RightSectionList>
          </RightSection>
        )}
      </DraggableModalPanel>
    </DraggableContainer>
  );
};

export default FilterSearch;
