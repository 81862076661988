import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface IconT {
  size?: number,
}

const NearNeighborsIcon = ({ size = 24 }: IconT) => {
  const [style] = useContext(StyleContext);
  let color = style.palette.textPrimary;
  let colorHighlight = style.palette.accentPrimary;

  return (
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <path
            fill={color}
            d='M13.4,9.6l1.1,0.6l2.8-2.8c0.2,0.1,0.5,0.2,0.8,0.2c1,0,1.8-0.8,1.8-1.8S19,4,18,4s-1.8,0.8-1.8,1.8
		c0,0.2,0.1,0.5,0.2,0.8L13.4,9.6z M18,5.2c0.3,0,0.6,0.2,0.6,0.6S18.4,6.3,18,6.3S17.5,6,17.5,5.7S17.7,5.2,18,5.2z'
          />
          <path
            fill={color}
            d='M10.6,14l-1.1-0.6l-3.3,3.3c-0.4-0.2-0.8-0.3-1.2-0.3c-1.4,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4
		c0-0.4-0.1-0.8-0.3-1.2L10.6,14z M5,19.9c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S5.7,19.9,5,19.9z'
          />
          <path
            fill={color}
            d='M19,10.7c-0.9,0-1.8,0.6-2.1,1.3l-2.3-0.4l0,1.2l2,0.4c0.1,1.2,1.1,2.2,2.4,2.2c1.3,0,2.4-1.1,2.4-2.4
		S20.3,10.7,19,10.7z M19,14.2c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S19.6,14.2,19,14.2z'
          />
          <path
            fill={color}
            d='M7.9,11C7.6,10.3,7,9.8,6.2,9.8c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8c0.8,0,1.4-0.5,1.7-1.2h1.5l0-1.3H7.9z
		 M6.2,12.2c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6S6.5,12.2,6.2,12.2z'
          />
          <path
            fill={color}
            d='M5.3,7.2c0.4,0,0.8-0.1,1.1-0.3l3.2,3.2l1.1-0.7L7.4,6c0.2-0.4,0.4-0.8,0.4-1.2c0-1.3-1.1-2.4-2.4-2.4
		S2.9,3.5,2.9,4.8S4,7.2,5.3,7.2z M5.3,3.7c0.6,0,1.1,0.5,1.1,1.1S5.9,6,5.3,6S4.2,5.5,4.2,4.8C4.2,4.2,4.7,3.7,5.3,3.7z'
          />
          <path
            fill={color}
            d='M14.4,17l-0.9-3.1l-1.2,0.7l0.9,2.8c-0.4,0.3-0.6,0.8-0.6,1.4c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8
		S15.4,17,14.4,17z M14.5,19.3c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6S14.8,19.3,14.5,19.3z'
          />
          <path
            fill={colorHighlight}
            d='M11.9,15.3l-3.1-1.8l0-3.6l3.1-1.8L15,10l0,3.6L11.9,15.3z M9.8,13l2.1,1.2L14,13l0-2.4l-2.1-1.2l-2.1,1.2
		L9.8,13z'
          />
        </g>
      </svg>
  );
};

export default NearNeighborsIcon;


