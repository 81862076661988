import {COLORS} from '@utils/scales/color/ColorSchemes';
import {SettingsT} from "../types";

export const labelColors = {
  'selected': COLORS[0].scheme[0],
  'hovered': COLORS[0].scheme[1],
  'pinned': COLORS[0].scheme.slice(2, COLORS[0].unique)
}

export const cellHealthSettings:SettingsT = {
  "mode": 'cell_health',
  "response_type": "scores",
  "limits": [0, 1],
  "primary": "cell_health_index",
  "secondary": [
    "calcein",
    "cell_morphology",
    "jc9",
    "mbbr",
    "mitosox",
    "pi",
    "sytox",
    "violet"
  ],
  "data_fields":
      [
        { field: "cell_health_index", symbol: "CHI", label: "Cell Health Index"},
        { field: "cell_morphology", symbol: "MORPH", label: "Cell Morphology"},
        { field: "calcein", symbol: "CMI", label: "Cell Membrane Integrity"},
        { field: "mitosox", symbol: "ROS", label: "Reactive Oxygen Species"},
        { field: "mbbr", symbol: "GSH", label: "Glutathione"},
        { field: "violet", symbol: "CC", label: "Cell Cycle"},
        { field: "sytox", symbol: "NMI1", label: "Nuc. Membrane Integrity 1"},
        { field: "pi", symbol: "NMI2", label: "Nuc. Membrane Integrity 2"},
        { field: "jc9", symbol: "MMP", label: "Mito. Membrane Polarization"},
      ]
}

export const zebrafishSettings:SettingsT = {
  "mode": "zebrafish",
  "response_type": "zebrafish",
  "limits": [0.005, 100],
  "primary": "zf_mort",
  "secondary": [
    "zf_cran",
    "zf_edem",
    "zf_mo24",
    "zf_sm24",
    "zf_epr",
    "zf_lpr_d",
    "zf_lpr_l",
    "zf_any_effect",
  ],
  data_fields: [
    { field: "zf_mort", label: "Mortality (120hr)", symbol: "MORT" },
    { field: "zf_mo24", label: "Mortality (24hr)", symbol: "MO24" },
    { field: "zf_dp24", label: "Delayed Development (24hr)", symbol: "DP24" },
    { field: "zf_sm24", label: "No Spontaneous Movement (24hr)", symbol: "SM24" },
    { field: "zf_cran", label: "Craniofacial Defect (120hr)", symbol: "CRAN" },
    { field: "zf_axis", label: "Axial Bend/Defect (120hr)", symbol: "AXIS" },
    { field: "zf_edem", label: "Edema Defects (120hr)", symbol: "EDEM" },
    { field: "zf_musc", label: "Muscle Defect (120hr)", symbol: "MUSC" },
    { field: "zf_ltrk", label: "Lower Trunk Defect (120hr)", symbol: "LTRK" },
    { field: "zf_brn", label: "Brain Region Defect (120hr)", symbol: "BRN" },
    { field: "zf_skin", label: "Skin Defect (120hr)", symbol: "SKIN" },
    { field: "zf_nc", label: "Notochord Defect (120hr)", symbol: "NC" },
    { field: "zf_tchr", label: "Touch Unresponsive (120hr)", symbol: "TCHR" },
    { field: "zf_any_effect", label: "Any Effect", symbol: "ANY.E" },
    { field: "zf_epr", label: "Embryonic Photomotor Response (24hr)", symbol: "EPR" },
    { field: "zf_lpr_d", label: "Larval Photomotor Response - Dark (120hr)", symbol: "LPR_D" },
    { field: "zf_lpr_l", label: "Larval Photomotor Response - Light (120hr)", symbol: "LPR_L" },
  ]
}

export const kinomeSettings:SettingsT = {
  "mode": "target_panel",
  "response_type": "%_inverse",
  "limits": [0, 100],
  "primary": "CDK2/cyclin A",
  "secondary": [
    "CDK6/cyclin D1",
    "KDR/VEGFR2",
    "P38a/MAPK14",
    "ABL1",
    "EGFR",
    "FLT1/VEGFR1",
    "IKKb/IKBKB"
  ],
  data_fields: [
    {"field": "ABL1", "label": "ABL1", "symbol": "ABL1"},
    {"field": "ABL2/ARG", "label": "ABL2/ARG", "symbol": "ABL2/ARG"},
    {"field": "ACK1", "label": "ACK1", "symbol": "ACK1"},
    {"field": "AKT1", "label": "AKT1", "symbol": "AKT1"},
    {"field": "AKT2", "label": "AKT2", "symbol": "AKT2"},
    {"field": "AKT3", "label": "AKT3", "symbol": "AKT3"},
    {"field": "ALK", "label": "ALK", "symbol": "ALK"},
    {"field": "ALK1/ACVRL1", "label": "ALK1/ACVRL1", "symbol": "ALK1/ACVRL1"},
    {"field": "ALK2/ACVR1", "label": "ALK2/ACVR1", "symbol": "ALK2/ACVR1"},
    {"field": "ALK4/ACVR1B", "label": "ALK4/ACVR1B", "symbol": "ALK4/ACVR1B"},
    {"field": "ALK5/TGFBR1", "label": "ALK5/TGFBR1", "symbol": "ALK5/TGFBR1"},
    {"field": "ARAF", "label": "ARAF", "symbol": "ARAF"},
    {"field": "ARK5/NUAK1", "label": "ARK5/NUAK1", "symbol": "ARK5/NUAK1"},
    {"field": "ASK1/MAP3K5", "label": "ASK1/MAP3K5", "symbol": "ASK1/MAP3K5"},
    {"field": "Aurora A", "label": "Aurora A", "symbol": "Aurora A"},
    {"field": "Aurora B", "label": "Aurora B", "symbol": "Aurora B"},
    {"field": "Aurora C", "label": "Aurora C", "symbol": "Aurora C"},
    {"field": "AXL", "label": "AXL", "symbol": "AXL"},
    {"field": "BLK", "label": "BLK", "symbol": "BLK"},
    {"field": "BMX/ETK", "label": "BMX/ETK", "symbol": "BMX/ETK"},
    {"field": "BRAF", "label": "BRAF", "symbol": "BRAF"},
    {"field": "BRK", "label": "BRK", "symbol": "BRK"},
    {"field": "BRSK1", "label": "BRSK1", "symbol": "BRSK1"},
    {"field": "BRSK2", "label": "BRSK2", "symbol": "BRSK2"},
    {"field": "BTK", "label": "BTK", "symbol": "BTK"},
    {"field": "c-Kit", "label": "c-Kit", "symbol": "c-Kit"},
    {"field": "c-MER", "label": "c-MER", "symbol": "c-MER"},
    {"field": "c-MET", "label": "c-MET", "symbol": "c-MET"},
    {"field": "c-SRC", "label": "c-SRC", "symbol": "c-SRC"},
    {"field": "CAMK1a", "label": "CAMK1a", "symbol": "CAMK1a"},
    {"field": "CAMK1b", "label": "CAMK1b", "symbol": "CAMK1b"},
    {"field": "CAMK1d", "label": "CAMK1d", "symbol": "CAMK1d"},
    {"field": "CAMK1g", "label": "CAMK1g", "symbol": "CAMK1g"},
    {"field": "CAMK2a", "label": "CAMK2a", "symbol": "CAMK2a"},
    {"field": "CAMK2b", "label": "CAMK2b", "symbol": "CAMK2b"},
    {"field": "CAMK2d", "label": "CAMK2d", "symbol": "CAMK2d"},
    {"field": "CAMK2g", "label": "CAMK2g", "symbol": "CAMK2g"},
    {"field": "CAMK4", "label": "CAMK4", "symbol": "CAMK4"},
    {"field": "CAMKK1", "label": "CAMKK1", "symbol": "CAMKK1"},
    {"field": "CAMKK2", "label": "CAMKK2", "symbol": "CAMKK2"},
    {"field": "CDK1/cyclin A", "label": "CDK1/cyclin A", "symbol": "CDK1/cyclin A"},
    {"field": "CDK1/cyclin B", "label": "CDK1/cyclin B", "symbol": "CDK1/cyclin B"},
    {"field": "CDK2/cyclin A", "label": "CDK2/cyclin A", "symbol": "CDK2/cyclin A"},
    {"field": "CDK2/cyclin E", "label": "CDK2/cyclin E", "symbol": "CDK2/cyclin E"},
    {"field": "CDK3/cyclin E", "label": "CDK3/cyclin E", "symbol": "CDK3/cyclin E"},
    {"field": "CDK4/cyclin D1", "label": "CDK4/cyclin D1", "symbol": "CDK4/cyclin D1"},
    {"field": "CDK4/cyclin D3", "label": "CDK4/cyclin D3", "symbol": "CDK4/cyclin D3"},
    {"field": "CDK5/p25", "label": "CDK5/p25", "symbol": "CDK5/p25"},
    {"field": "CDK5/p35", "label": "CDK5/p35", "symbol": "CDK5/p35"},
    {"field": "CDK6/cyclin D1", "label": "CDK6/cyclin D1", "symbol": "CDK6/cyclin D1"},
    {"field": "CDK6/cyclin D3", "label": "CDK6/cyclin D3", "symbol": "CDK6/cyclin D3"},
    {"field": "CDK7/cyclin H", "label": "CDK7/cyclin H", "symbol": "CDK7/cyclin H"},
    {"field": "CDK9 cyclin K", "label": "CDK9 cyclin K", "symbol": "CDK9 cyclin K"},
    {"field": "CDK9/cyclin T1", "label": "CDK9/cyclin T1", "symbol": "CDK9/cyclin T1"},
    {"field": "CHK1", "label": "CHK1", "symbol": "CHK1"},
    {"field": "CHK2", "label": "CHK2", "symbol": "CHK2"},
    {"field": "CK1a1", "label": "CK1a1", "symbol": "CK1a1"},
    {"field": "CK1d", "label": "CK1d", "symbol": "CK1d"},
    {"field": "CK1epsilon", "label": "CK1epsilon", "symbol": "CK1epsilon"},
    {"field": "CK1g1", "label": "CK1g1", "symbol": "CK1g1"},
    {"field": "CK1g2", "label": "CK1g2", "symbol": "CK1g2"},
    {"field": "CK1g3", "label": "CK1g3", "symbol": "CK1g3"},
    {"field": "CK2a", "label": "CK2a", "symbol": "CK2a"},
    {"field": "CK2a2", "label": "CK2a2", "symbol": "CK2a2"},
    {"field": "CLK1", "label": "CLK1", "symbol": "CLK1"},
    {"field": "CLK2", "label": "CLK2", "symbol": "CLK2"},
    {"field": "CLK3", "label": "CLK3", "symbol": "CLK3"},
    {"field": "CLK4", "label": "CLK4", "symbol": "CLK4"},
    {"field": "COT1/MAP3K8", "label": "COT1/MAP3K8", "symbol": "COT1/MAP3K8"},
    {"field": "CSK", "label": "CSK", "symbol": "CSK"},
    {"field": "CTK MATK", "label": "CTK MATK", "symbol": "CTK MATK"},
    {"field": "DAPK1", "label": "DAPK1", "symbol": "DAPK1"},
    {"field": "DAPK2", "label": "DAPK2", "symbol": "DAPK2"},
    {"field": "DCAMKL2", "label": "DCAMKL2", "symbol": "DCAMKL2"},
    {"field": "DDR2", "label": "DDR2", "symbol": "DDR2"},
    {"field": "DMPK", "label": "DMPK", "symbol": "DMPK"},
    {"field": "DRAK1/STK17A", "label": "DRAK1/STK17A", "symbol": "DRAK1/STK17A"},
    {"field": "DYRK1/DYRK1A", "label": "DYRK1/DYRK1A", "symbol": "DYRK1/DYRK1A"},
    {"field": "DYRK1B", "label": "DYRK1B", "symbol": "DYRK1B"},
    {"field": "DYRK2", "label": "DYRK2", "symbol": "DYRK2"},
    {"field": "DYRK3", "label": "DYRK3", "symbol": "DYRK3"},
    {"field": "DYRK4", "label": "DYRK4", "symbol": "DYRK4"},
    {"field": "EGFR", "label": "EGFR", "symbol": "EGFR"},
    {"field": "EPHA1", "label": "EPHA1", "symbol": "EPHA1"},
    {"field": "EPHA2", "label": "EPHA2", "symbol": "EPHA2"},
    {"field": "EPHA3", "label": "EPHA3", "symbol": "EPHA3"},
    {"field": "EPHA4", "label": "EPHA4", "symbol": "EPHA4"},
    {"field": "EPHA5", "label": "EPHA5", "symbol": "EPHA5"},
    {"field": "EPHA6", "label": "EPHA6", "symbol": "EPHA6"},
    {"field": "EPHA7", "label": "EPHA7", "symbol": "EPHA7"},
    {"field": "EPHA8", "label": "EPHA8", "symbol": "EPHA8"},
    {"field": "EPHB1", "label": "EPHB1", "symbol": "EPHB1"},
    {"field": "EPHB2", "label": "EPHB2", "symbol": "EPHB2"},
    {"field": "EPHB3", "label": "EPHB3", "symbol": "EPHB3"},
    {"field": "EPHB4", "label": "EPHB4", "symbol": "EPHB4"},
    {"field": "ERBB2/HER2", "label": "ERBB2/HER2", "symbol": "ERBB2/HER2"},
    {"field": "ERBB4/HER4", "label": "ERBB4/HER4", "symbol": "ERBB4/HER4"},
    {"field": "ERK1", "label": "ERK1", "symbol": "ERK1"},
    {"field": "ERK2 MAPK1", "label": "ERK2 MAPK1", "symbol": "ERK2 MAPK1"},
    {"field": "FAK/PTK2", "label": "FAK/PTK2", "symbol": "FAK/PTK2"},
    {"field": "FER", "label": "FER", "symbol": "FER"},
    {"field": "FES/FPS", "label": "FES/FPS", "symbol": "FES/FPS"},
    {"field": "FGFR1", "label": "FGFR1", "symbol": "FGFR1"},
    {"field": "FGFR2", "label": "FGFR2", "symbol": "FGFR2"},
    {"field": "FGFR3", "label": "FGFR3", "symbol": "FGFR3"},
    {"field": "FGFR4", "label": "FGFR4", "symbol": "FGFR4"},
    {"field": "FGR", "label": "FGR", "symbol": "FGR"},
    {"field": "FLT1/VEGFR1", "label": "FLT1/VEGFR1", "symbol": "FLT1/VEGFR1"},
    {"field": "FLT3", "label": "FLT3", "symbol": "FLT3"},
    {"field": "FLT4/VEGFR3", "label": "FLT4/VEGFR3", "symbol": "FLT4/VEGFR3"},
    {"field": "FMS", "label": "FMS", "symbol": "FMS"},
    {"field": "FRK/PTK5", "label": "FRK/PTK5", "symbol": "FRK/PTK5"},
    {"field": "FYN", "label": "FYN", "symbol": "FYN"},
    {"field": "GCK MAP4K2", "label": "GCK MAP4K2", "symbol": "GCK MAP4K2"},
    {"field": "GRK2", "label": "GRK2", "symbol": "GRK2"},
    {"field": "GRK3", "label": "GRK3", "symbol": "GRK3"},
    {"field": "GRK4", "label": "GRK4", "symbol": "GRK4"},
    {"field": "GRK5", "label": "GRK5", "symbol": "GRK5"},
    {"field": "GRK6", "label": "GRK6", "symbol": "GRK6"},
    {"field": "GRK7", "label": "GRK7", "symbol": "GRK7"},
    {"field": "GSK3a", "label": "GSK3a", "symbol": "GSK3a"},
    {"field": "GSK3b", "label": "GSK3b", "symbol": "GSK3b"},
    {"field": "Haspin", "label": "Haspin", "symbol": "Haspin"},
    {"field": "HCK", "label": "HCK", "symbol": "HCK"},
    {"field": "HGK MAP4K4", "label": "HGK MAP4K4", "symbol": "HGK MAP4K4"},
    {"field": "HIPK1", "label": "HIPK1", "symbol": "HIPK1"},
    {"field": "HIPK2", "label": "HIPK2", "symbol": "HIPK2"},
    {"field": "HIPK3", "label": "HIPK3", "symbol": "HIPK3"},
    {"field": "HIPK4", "label": "HIPK4", "symbol": "HIPK4"},
    {"field": "IGF1R", "label": "IGF1R", "symbol": "IGF1R"},
    {"field": "IKKa/CHUK", "label": "IKKa/CHUK", "symbol": "IKKa/CHUK"},
    {"field": "IKKb/IKBKB", "label": "IKKb/IKBKB", "symbol": "IKKb/IKBKB"},
    {"field": "IKKe/IKBKE", "label": "IKKe/IKBKE", "symbol": "IKKe/IKBKE"},
    {"field": "IR", "label": "IR", "symbol": "IR"},
    {"field": "IRAK1", "label": "IRAK1", "symbol": "IRAK1"},
    {"field": "IRAK4", "label": "IRAK4", "symbol": "IRAK4"},
    {"field": "IRR/INSRR", "label": "IRR/INSRR", "symbol": "IRR/INSRR"},
    {"field": "ITK", "label": "ITK", "symbol": "ITK"},
    {"field": "JAK1", "label": "JAK1", "symbol": "JAK1"},
    {"field": "JAK2", "label": "JAK2", "symbol": "JAK2"},
    {"field": "JAK3", "label": "JAK3", "symbol": "JAK3"},
    {"field": "JNK1", "label": "JNK1", "symbol": "JNK1"},
    {"field": "JNK2", "label": "JNK2", "symbol": "JNK2"},
    {"field": "JNK3", "label": "JNK3", "symbol": "JNK3"},
    {"field": "KDR/VEGFR2", "label": "KDR/VEGFR2", "symbol": "KDR/VEGFR2"},
    {"field": "KHS MAP4K5", "label": "KHS MAP4K5", "symbol": "KHS MAP4K5"},
    {"field": "LCK", "label": "LCK", "symbol": "LCK"},
    {"field": "LIMK1", "label": "LIMK1", "symbol": "LIMK1"},
    {"field": "LKB1", "label": "LKB1", "symbol": "LKB1"},
    {"field": "LOK/STK10", "label": "LOK/STK10", "symbol": "LOK/STK10"},
    {"field": "LRRK2", "label": "LRRK2", "symbol": "LRRK2"},
    {"field": "LYN", "label": "LYN", "symbol": "LYN"},
    {"field": "LYN B", "label": "LYN B", "symbol": "LYN B"},
    {"field": "MAPKAPK2", "label": "MAPKAPK2", "symbol": "MAPKAPK2"},
    {"field": "MAPKAPK3", "label": "MAPKAPK3", "symbol": "MAPKAPK3"},
    {"field": "MAPKAPK5/PRAK", "label": "MAPKAPK5/PRAK", "symbol": "MAPKAPK5/PRAK"},
    {"field": "MARK1", "label": "MARK1", "symbol": "MARK1"},
    {"field": "MARK2/PAR-1Ba", "label": "MARK2/PAR-1Ba", "symbol": "MARK2/PAR-1Ba"},
    {"field": "MARK3", "label": "MARK3", "symbol": "MARK3"},
    {"field": "MARK4", "label": "MARK4", "symbol": "MARK4"},
    {"field": "MEK1", "label": "MEK1", "symbol": "MEK1"},
    {"field": "MEK2", "label": "MEK2", "symbol": "MEK2"},
    {"field": "MEKK2", "label": "MEKK2", "symbol": "MEKK2"},
    {"field": "MEKK3", "label": "MEKK3", "symbol": "MEKK3"},
    {"field": "MELK", "label": "MELK", "symbol": "MELK"},
    {"field": "MINK/MINK1", "label": "MINK/MINK1", "symbol": "MINK/MINK1"},
    {"field": "MKK6", "label": "MKK6", "symbol": "MKK6"},
    {"field": "MLCK/MYLK", "label": "MLCK/MYLK", "symbol": "MLCK/MYLK"},
    {"field": "MLCK2/MYLK2", "label": "MLCK2/MYLK2", "symbol": "MLCK2/MYLK2"},
    {"field": "MLK1/MAP3K9", "label": "MLK1/MAP3K9", "symbol": "MLK1/MAP3K9"},
    {"field": "MLK2/MAP3K10", "label": "MLK2/MAP3K10", "symbol": "MLK2/MAP3K10"},
    {"field": "MLK3/MAP3K11", "label": "MLK3/MAP3K11", "symbol": "MLK3/MAP3K11"},
    {"field": "MNK1", "label": "MNK1", "symbol": "MNK1"},
    {"field": "MNK2", "label": "MNK2", "symbol": "MNK2"},
    {"field": "MRCKa/CDC42BPA", "label": "MRCKa/CDC42BPA", "symbol": "MRCKa/CDC42BPA"},
    {"field": "MRCKb/CDC42BPB", "label": "MRCKb/CDC42BPB", "symbol": "MRCKb/CDC42BPB"},
    {"field": "MSK1/RPS6KA5", "label": "MSK1/RPS6KA5", "symbol": "MSK1/RPS6KA5"},
    {"field": "MSK2/RPS6KA4", "label": "MSK2/RPS6KA4", "symbol": "MSK2/RPS6KA4"},
    {"field": "MSSK1/STK23", "label": "MSSK1/STK23", "symbol": "MSSK1/STK23"},
    {"field": "MST1/STK4", "label": "MST1/STK4", "symbol": "MST1/STK4"},
    {"field": "MST2/STK3", "label": "MST2/STK3", "symbol": "MST2/STK3"},
    {"field": "MST3/STK24", "label": "MST3/STK24", "symbol": "MST3/STK24"},
    {"field": "MST4", "label": "MST4", "symbol": "MST4"},
    {"field": "MUSK", "label": "MUSK", "symbol": "MUSK"},
    {"field": "MYO3b", "label": "MYO3b", "symbol": "MYO3b"},
    {"field": "NEK1", "label": "NEK1", "symbol": "NEK1"},
    {"field": "NEK11", "label": "NEK11", "symbol": "NEK11"},
    {"field": "NEK2", "label": "NEK2", "symbol": "NEK2"},
    {"field": "NEK3", "label": "NEK3", "symbol": "NEK3"},
    {"field": "NEK4", "label": "NEK4", "symbol": "NEK4"},
    {"field": "NEK6", "label": "NEK6", "symbol": "NEK6"},
    {"field": "NEK7", "label": "NEK7", "symbol": "NEK7"},
    {"field": "NEK9", "label": "NEK9", "symbol": "NEK9"},
    {"field": "NIK/MAP3K14", "label": "NIK/MAP3K14", "symbol": "NIK/MAP3K14"},
    {"field": "NLK", "label": "NLK", "symbol": "NLK"},
    {"field": "OSR1/OXSR1", "label": "OSR1/OXSR1", "symbol": "OSR1/OXSR1"},
    {"field": "P38a/MAPK14", "label": "P38a/MAPK14", "symbol": "P38a/MAPK14"},
    {"field": "P38b/MAPK11", "label": "P38b/MAPK11", "symbol": "P38b/MAPK11"},
    {"field": "P38d/MAPK13", "label": "P38d/MAPK13", "symbol": "P38d/MAPK13"},
    {"field": "P38g", "label": "P38g", "symbol": "P38g"},
    {"field": "p70S6K/RPS6KB1", "label": "p70S6K/RPS6KB1", "symbol": "p70S6K/RPS6KB1"},
    {"field": "p70S6Kb/RPS6KB2", "label": "p70S6Kb/RPS6KB2", "symbol": "p70S6Kb/RPS6KB2"},
    {"field": "PAK1", "label": "PAK1", "symbol": "PAK1"},
    {"field": "PAK2", "label": "PAK2", "symbol": "PAK2"},
    {"field": "PAK3", "label": "PAK3", "symbol": "PAK3"},
    {"field": "PAK4", "label": "PAK4", "symbol": "PAK4"},
    {"field": "PAK5", "label": "PAK5", "symbol": "PAK5"},
    {"field": "PAK6", "label": "PAK6", "symbol": "PAK6"},
    {"field": "PASK", "label": "PASK", "symbol": "PASK"},
    {"field": "PBK/TOPK", "label": "PBK/TOPK", "symbol": "PBK/TOPK"},
    {"field": "PDGFRa", "label": "PDGFRa", "symbol": "PDGFRa"},
    {"field": "PDGFRb", "label": "PDGFRb", "symbol": "PDGFRb"},
    {"field": "PDK1/PDPK1", "label": "PDK1/PDPK1", "symbol": "PDK1/PDPK1"},
    {"field": "PHKg1", "label": "PHKg1", "symbol": "PHKg1"},
    {"field": "PHKg2", "label": "PHKg2", "symbol": "PHKg2"},
    {"field": "PIM1", "label": "PIM1", "symbol": "PIM1"},
    {"field": "PIM2", "label": "PIM2", "symbol": "PIM2"},
    {"field": "PIM3", "label": "PIM3", "symbol": "PIM3"},
    {"field": "PKA", "label": "PKA", "symbol": "PKA"},
    {"field": "PKAcg", "label": "PKAcg", "symbol": "PKAcg"},
    {"field": "PKCa", "label": "PKCa", "symbol": "PKCa"},
    {"field": "PKCb1", "label": "PKCb1", "symbol": "PKCb1"},
    {"field": "PKCb2", "label": "PKCb2", "symbol": "PKCb2"},
    {"field": "PKCd", "label": "PKCd", "symbol": "PKCd"},
    {"field": "PKCepsilon", "label": "PKCepsilon", "symbol": "PKCepsilon"},
    {"field": "PKCeta", "label": "PKCeta", "symbol": "PKCeta"},
    {"field": "PKCg", "label": "PKCg", "symbol": "PKCg"},
    {"field": "PKCiota", "label": "PKCiota", "symbol": "PKCiota"},
    {"field": "PKCmu/PRKD1", "label": "PKCmu/PRKD1", "symbol": "PKCmu/PRKD1"},
    {"field": "PKCnu/PRKD3", "label": "PKCnu/PRKD3", "symbol": "PKCnu/PRKD3"},
    {"field": "PKCtheta", "label": "PKCtheta", "symbol": "PKCtheta"},
    {"field": "PKCzeta", "label": "PKCzeta", "symbol": "PKCzeta"},
    {"field": "PKD2/PRKD2", "label": "PKD2/PRKD2", "symbol": "PKD2/PRKD2"},
    {"field": "PKG1a", "label": "PKG1a", "symbol": "PKG1a"},
    {"field": "PKG1b", "label": "PKG1b", "symbol": "PKG1b"},
    {"field": "PKG2/PRKG2", "label": "PKG2/PRKG2", "symbol": "PKG2/PRKG2"},
    {"field": "PKN1/PRK1", "label": "PKN1/PRK1", "symbol": "PKN1/PRK1"},
    {"field": "PKN2/PRK2", "label": "PKN2/PRK2", "symbol": "PKN2/PRK2"},
    {"field": "PLK1", "label": "PLK1", "symbol": "PLK1"},
    {"field": "PLK2", "label": "PLK2", "symbol": "PLK2"},
    {"field": "PLK3", "label": "PLK3", "symbol": "PLK3"},
    {"field": "PRKX", "label": "PRKX", "symbol": "PRKX"},
    {"field": "PYK2", "label": "PYK2", "symbol": "PYK2"},
    {"field": "RAF1", "label": "RAF1", "symbol": "RAF1"},
    {"field": "RET", "label": "RET", "symbol": "RET"},
    {"field": "RIPK2", "label": "RIPK2", "symbol": "RIPK2"},
    {"field": "RIPK5", "label": "RIPK5", "symbol": "RIPK5"},
    {"field": "ROCK1", "label": "ROCK1", "symbol": "ROCK1"},
    {"field": "ROCK2", "label": "ROCK2", "symbol": "ROCK2"},
    {"field": "RON/MST1R", "label": "RON/MST1R", "symbol": "RON/MST1R"},
    {"field": "ROS/ROS1", "label": "ROS/ROS1", "symbol": "ROS/ROS1"},
    {"field": "RSK1", "label": "RSK1", "symbol": "RSK1"},
    {"field": "RSK2", "label": "RSK2", "symbol": "RSK2"},
    {"field": "RSK3", "label": "RSK3", "symbol": "RSK3"},
    {"field": "RSK4", "label": "RSK4", "symbol": "RSK4"},
    {"field": "SGK1", "label": "SGK1", "symbol": "SGK1"},
    {"field": "SGK2", "label": "SGK2", "symbol": "SGK2"},
    {"field": "SGK3/SGKL", "label": "SGK3/SGKL", "symbol": "SGK3/SGKL"},
    {"field": "SIK2", "label": "SIK2", "symbol": "SIK2"},
    {"field": "SLK/STK2", "label": "SLK/STK2", "symbol": "SLK/STK2"},
    {"field": "SNARK/NUAK2", "label": "SNARK/NUAK2", "symbol": "SNARK/NUAK2"},
    {"field": "SRMS", "label": "SRMS", "symbol": "SRMS"},
    {"field": "SRPK1", "label": "SRPK1", "symbol": "SRPK1"},
    {"field": "SRPK2", "label": "SRPK2", "symbol": "SRPK2"},
    {"field": "STK16", "label": "STK16", "symbol": "STK16"},
    {"field": "STK22D/TSSK1", "label": "STK22D/TSSK1", "symbol": "STK22D/TSSK1"},
    {"field": "STK25/YSK1", "label": "STK25/YSK1", "symbol": "STK25/YSK1"},
    {"field": "STK33", "label": "STK33", "symbol": "STK33"},
    {"field": "STK38/NDR1", "label": "STK38/NDR1", "symbol": "STK38/NDR1"},
    {"field": "STK39/STLK3", "label": "STK39/STLK3", "symbol": "STK39/STLK3"},
    {"field": "SYK", "label": "SYK", "symbol": "SYK"},
    {"field": "TAK1", "label": "TAK1", "symbol": "TAK1"},
    {"field": "TAOK1", "label": "TAOK1", "symbol": "TAOK1"},
    {"field": "TAOK2/TAO1", "label": "TAOK2/TAO1", "symbol": "TAOK2/TAO1"},
    {"field": "TAOK3/JIK", "label": "TAOK3/JIK", "symbol": "TAOK3/JIK"},
    {"field": "TBK1", "label": "TBK1", "symbol": "TBK1"},
    {"field": "TEC", "label": "TEC", "symbol": "TEC"},
    {"field": "TGFBR2", "label": "TGFBR2", "symbol": "TGFBR2"},
    {"field": "TIE2/TEK", "label": "TIE2/TEK", "symbol": "TIE2/TEK"},
    {"field": "TLK2", "label": "TLK2", "symbol": "TLK2"},
    {"field": "TRKA", "label": "TRKA", "symbol": "TRKA"},
    {"field": "TRKB", "label": "TRKB", "symbol": "TRKB"},
    {"field": "TRKC", "label": "TRKC", "symbol": "TRKC"},
    {"field": "TSSK2", "label": "TSSK2", "symbol": "TSSK2"},
    {"field": "TTK", "label": "TTK", "symbol": "TTK"},
    {"field": "TXK", "label": "TXK", "symbol": "TXK"},
    {"field": "TYK1/LTK", "label": "TYK1/LTK", "symbol": "TYK1/LTK"},
    {"field": "TYK2", "label": "TYK2", "symbol": "TYK2"},
    {"field": "TYRO3 SKY", "label": "TYRO3 SKY", "symbol": "TYRO3 SKY"},
    {"field": "ULK1", "label": "ULK1", "symbol": "ULK1"},
    {"field": "ULK2", "label": "ULK2", "symbol": "ULK2"},
    {"field": "ULK3", "label": "ULK3", "symbol": "ULK3"},
    {"field": "VRK1", "label": "VRK1", "symbol": "VRK1"},
    {"field": "WEE1", "label": "WEE1", "symbol": "WEE1"},
    {"field": "WNK2", "label": "WNK2", "symbol": "WNK2"},
    {"field": "WNK3", "label": "WNK3", "symbol": "WNK3"},
    {"field": "YES/YES1", "label": "YES/YES1", "symbol": "YES/YES1"},
    {"field": "ZAK/MLTK", "label": "ZAK/MLTK", "symbol": "ZAK/MLTK"},
    {"field": "ZAP70", "label": "ZAP70", "symbol": "ZAP70"},
    {"field": "ZIPK/DAPK3", "label": "ZIPK/DAPK3", "symbol": "ZIPK/DAPK3"}
  ]
}

export const abbvieTestSettings:SettingsT = {
  "mode": "abbvie",
  "response_type": "abbvie",
  "limits": [0.0, 100.0],
  "primary": "Sirolimus-0.1_Cond_5_A549",
  "secondary": [
    "Etoposide-0.5_Cond_5_A549",
    "Fenbendazole-0.62_Cond_5_A549",
    "Sirolimus-0.1_Cond_5_A549",
    "Mitomycin C-0.5_Cond_5_A549",
    "Mitomycin C-5_Cond_5_A549",
    "Vincristine-0.5_Cond_5_A549",
    "Vorinostat-10_Cond_5_A549"
  ],
  edge_weight_factor : {
    "chem": 2.0,
    "biol": 5.0
  },
  data_fields: [
    { field: "DMSO-0_Cond_0.5_A549", label: "DMSO-0_Cond_0.5_A549", symbol: "D_00_05_A"},
    { field: "DMSO-0_Cond_5_A549", label: "DMSO-0_Cond_5_A549", symbol: "D_00_50_A"},
    { field: "Etoposide-0.5_Cond_0.5_A549", label: "Etoposide-0.5_Cond_0.5_A549", symbol: "E_05_05_A"},
    { field: "Etoposide-0.5_Cond_5_A549", label: "Etoposide-0.5_Cond_5_A549", symbol: "E_05_50_A" },
    { field: "Etoposide-5_Cond_0.5_A549", label: "Etoposide-5_Cond_0.5_A549", symbol: "E_50_05_A"},
    { field: "Etoposide-5_Cond_5_A549", label: "Etoposide-5_Cond_5_A549", symbol: "E_50_50_A"},
    { field: "Fenbendazole-0.62_Cond_0.5_A549", label: "Fenbendazole-0.62_Cond_0.5_A549", symbol: "F_062_05_A"},
    { field: "Fenbendazole-0.62_Cond_5_A549", label: "Fenbendazole-0.62_Cond_5_A549", symbol: "F_062_05_A"},
    { field: "Mitomycin C-0.5_Cond_0.5_A549", label: "Mitomycin C-0.5_Cond_0.5_A549", symbol: "M_05_05_A"},
    { field: "Mitomycin C-0.5_Cond_5_A549", label: "Mitomycin C-0.5_Cond_5_A549", symbol: "M_05_50_A"},
    { field: "Mitomycin C-5_Cond_0.5_A549", label: "Mitomycin C-5_Cond_0.5_A549", symbol: "M_50_05_A"},
    { field: "Mitomycin C-5_Cond_5_A549", label: "Mitomycin C-5_Cond_5_A549", symbol: "M_50_50_A"},
    { field: "Sirolimus-0.1_Cond_0.5_A549", label: "Sirolimus-0.1_Cond_0.5_A549", symbol: "S_01_05_A"},
    { field: "Sirolimus-0.1_Cond_5_A549", label: "Sirolimus-0.1_Cond_5_A549", symbol: "S_01_50_A"},
    { field: "Vincristine-0.5_Cond_0.5_A549", label: "Vincristine-0.5_Cond_0.5_A549", symbol: "Vi_05_05_A"},
    { field: "Vincristine-0.5_Cond_5_A549", label: "Vincristine-0.5_Cond_5_A549", symbol: "Vi_05_50_A"},
    { field: "Vincristine-5_Cond_0.5_A549", label: "Vincristine-5_Cond_0.5_A549", symbol: "Vi_50_05_A"},
    { field: "Vincristine-5_Cond_5_A549", label: "Vincristine-5_Cond_5_A549", symbol: "Vi_50_50_A"},
    { field: "Vorinostat-10_Cond_0.5_A549", label: "Vorinostat-10_Cond_0.5_A549", symbol: "Vo_10_05_A"},
    { field: "Vorinostat-10_Cond_5_A549", label: "Vorinostat-10_Cond_5_A549", symbol: "Vo_10_50_A"},
    { field: "DMSO-0_Cond_0.5_HepG2", label: "DMSO-0_Cond_0.5_HepG2", symbol: "D_00_05_H"},
    { field: "DMSO-0_Cond_5_HepG2", label: "DMSO-0_Cond_5_HepG2", symbol: "D_00_50_H"},
    { field: "Etoposide-0.5_Cond_0.5_HepG2", label: "Etoposide-0.5_Cond_0.5_HepG2", symbol: "E_05_05_H"},
    { field: "Etoposide-0.5_Cond_5_HepG2", label: "Etoposide-0.5_Cond_5_HepG2", symbol: "E_05_50_H"},
    { field: "Etoposide-5_Cond_0.5_HepG2", label: "Etoposide-5_Cond_0.5_HepG2", symbol: "E_50_05_H"},
    { field: "Etoposide-5_Cond_5_HepG2", label: "Etoposide-5_Cond_5_HepG2", symbol: "E_50_50_H"},
    { field: "Fenbendazole-0.62_Cond_0.5_HepG2", label: "Fenbendazole-0.62_Cond_0.5_HepG2", symbol: "F_062_05_H"},
    { field: "Fenbendazole-0.62_Cond_5_HepG2", label: "Fenbendazole-0.62_Cond_5_HepG2", symbol: "F_062_50_H"},
    { field: "Mitomycin C-0.5_Cond_0.5_HepG2", label: "Mitomycin C-0.5_Cond_0.5_HepG2", symbol: "M_05_05_H"},
    { field: "Mitomycin C-0.5_Cond_5_HepG2", label: "Mitomycin C-0.5_Cond_5_HepG2", symbol: "M_05_50_H"},
    { field: "Mitomycin C-5_Cond_0.5_HepG2", label: "Mitomycin C-5_Cond_0.5_HepG2", symbol: "M_50_05_H"},
    { field: "Mitomycin C-5_Cond_5_HepG2", label: "Mitomycin C-5_Cond_5_HepG2", symbol: "M_50_50_H"},
    { field: "Sirolimus-0.1_Cond_0.5_HepG2", label: "Sirolimus-0.1_Cond_0.5_HepG2", symbol: "S_01_05_H"},
    { field: "Sirolimus-0.1_Cond_5_HepG2", label: "Sirolimus-0.1_Cond_5_HepG2", symbol: "S_01_50_H"},
    { field: "Vincristine-0.5_Cond_0.5_HepG2", label: "Vincristine-0.5_Cond_0.5_HepG2", symbol: "Vi_05_05_H"},
    { field: "Vincristine-0.5_Cond_5_HepG2", label: "Vincristine-0.5_Cond_5_HepG2", symbol: "Vi_05_50_H"},
    { field: "Vincristine-5_Cond_0.5_HepG2", label: "Vincristine-5_Cond_0.5_HepG2", symbol: "Vi_50_05_H"},
    { field: "Vincristine-5_Cond_5_HepG2", label: "Vincristine-5_Cond_5_HepG2", symbol: "Vi_50_50_H"},
    { field: "Vorinostat-10_Cond_0.5_HepG2", label: "Vorinostat-10_Cond_0.5_HepG2", symbol: "Vo_10_05_H"},
    { field: "Vorinostat-10_Cond_5_HepG2", label: "Vorinostat-10_Cond_5_HepG2", symbol: "Vo_10_50_H"},
  ]
}