import React from 'react';
import styled from 'styled-components/macro';
import {FlexItem} from "@components/layout/FlexStyles";
import {ScaleLinear} from "d3-scale";

interface PropsI {
  scale: ScaleLinear<string, string>;
  title: string;
  width?: number;
  lowText?: string;
  highText?: string;
}

const ColorLegend = (props:PropsI) => {
  const {
    scale,
    title,
    width=275,
    lowText="0.0",
    highText="1.0"
  } = props;
  return (
    <LegendContainer width={width}>
      <LegendTitle>{title}:</LegendTitle>
      <LegendLimits>{lowText}</LegendLimits>
      <FlexItem width={'75px'}>
        {scale && (
            <ColorScale startColor={scale.range()[0]} endColor={scale.range()[1]} />
        )}
      </FlexItem>
      <LegendLimits>{highText}</LegendLimits>
    </LegendContainer>
  );
};

export default ColorLegend;

const LegendContainer = styled.div<{width: number}>`
  display: flex;
  flex-direction: row;
  width: ${(p) => p.width }px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5px;
  padding: 0;
  height: 12px;
`;

const LegendTitle = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const LegendLimits = styled(LegendTitle)`
  color: ${(p) => p.theme.palette.textPrimary};
`;

const ColorScale = styled.div<{startColor: string, endColor: string}>`
  width: 100%;
  background: ${(p) =>
    'linear-gradient(to right, ' + p.startColor + ', ' + p.endColor + ')'};
  height: 12px;
  border: none;
  border-radius: 2px;
`;
