import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const SelectionIcon = ({size = 24}:{size?: number}) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M20.5,22.8h-2.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8h2.5c.4,0,.8.3.8.8s-.3.8-.7.8ZM15,22.8h-2.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8h2.5c.4,0,.8.3.8.8s-.3.8-.8.8ZM9.4,22.8h-2.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8h2.5c.4,0,.8.3.8.8s-.3.8-.8.8ZM3.9,22.8h-.4c-1.1,0-2-.8-2.2-1.9,0-.4.2-.8.6-.9.4,0,.8.2.9.6,0,.4.4.6.7.6h.4c.4,0,.8.3.8.8s-.3.8-.8.8ZM22,20.6c-.4,0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0,.4-.3.8-.8.8ZM2,18.5c-.4,0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0,.4-.3.8-.8.8ZM22,15c-.4,0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0,.4-.3.8-.8.8ZM2,13c-.4,0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0,.4-.3.8-.8.8ZM22,9.5c-.4,0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0,.4-.3.8-.8.8ZM2,7.4c-.4,0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8s.8.3.8.8v2.5c0,.4-.3.8-.8.8ZM22,4c-.4,0-.7-.3-.7-.6,0-.4-.4-.6-.7-.6h-.4c-.4,0-.8-.3-.8-.8s.3-.8.8-.8h.4c1.1,0,2,.8,2.2,1.9,0,.4-.2.8-.6.9,0,0,0,0-.1,0ZM17.1,2.8h-2.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8h2.5c.4,0,.8.3.8.8s-.3.8-.8.8ZM11.5,2.8h-2.5c-.4,0-.8-.3-.8-.8s.3-.8.8-.8h2.5c.4,0,.8.3.8.8s-.3.8-.8.8ZM6,2.8h-2.5c-.4,0-.8-.3-.8-.8s.3-.8.7-.8h2.5c.4,0,.8.3.8.8s-.3.8-.8.8Z"/>
      <path fill={colorHighlight} d="M12,18.8c-3.7,0-6.8-3-6.8-6.8s3-6.8,6.8-6.8,6.8,3,6.8,6.8-3,6.8-6.8,6.8ZM12,6.8c-2.9,0-5.2,2.4-5.2,5.2s2.4,5.2,5.2,5.2,5.2-2.4,5.2-5.2-2.4-5.2-5.2-5.2Z"/>
    </svg>
  )
}

export default SelectionIcon;