import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { APP_CONFIG } from "@app_config/app";
import styled from 'styled-components/macro';
import { useNavigate } from "react-router-dom";
import { UserContext } from '@stores/UserContext';
import OrdersCart from './OrdersCart';
import TextButton from '@components/controls/TextButton/TextButton';
import { FlexColumn } from '@as_core/elements/flexStyles';
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";
import useOrders from "@business/orders/useOrders";
import { UserT } from "@stores/UserContext";
import useShoppingCart, {CartItemT, CartObjectType, UseCartObjectReturnType} from "@as_core/hooks/useShoppingCart";
import { BasicTextInput } from "@as_core/account/forms/FormStyles";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import { TextSmall } from "@as_core/elements/textStyles";
import Alert from "@components/elements/Alert";
import {getCartPrice} from "@business/orders/components/utils";

type reductionFieldT = {
  value: string;
  new_value: string;
}

const productReductionFields: reductionFieldT[] = [
  { value: 'id', new_value: 'stripe_product_id' },
  { value: 'price_id', new_value: 'stripe_price_id' },
  { value: 'metadata.catalogNumber', new_value: 'catalogNumber' },
  { value: 'metadata.categoryCode', new_value: 'categoryCode' },
  { value: 'name', new_value: 'productName' },
  { value: 'price', new_value: 'productPrice' },
  { value: 'metadata.unitSize', new_value: 'unitSize' },
]

function reduceProductFields(item: CartItemT) {
  const newProduct = {}
  productReductionFields.forEach((field) => {
    if (field.value === 'price_id') {
      const price = getCartPrice(item);
      newProduct[field.new_value] = price['id'];
    } else if (field.value === 'price') {
      const price = getCartPrice(item);
      newProduct[field.new_value] = price.unit_amount;
    } else {
      newProduct[field.new_value] = _.get(item.product, field.value, '');
    }
    // console.log(field.value, field.new_value, newProduct[field.new_value]);
  })
  return newProduct;
}

function createInitialOrderName(user: UserT): string {
  const date = new Date();
  return date.toLocaleDateString().concat(' ', user.regInfo.organization.name);
}

function streamlineOrder(order: CartObjectType) {
  const newOrder = {}
  Object.keys(order).forEach((key) => {
    newOrder[key] = {
      product: reduceProductFields(order[key]),
      priceType: order[key].priceType,
      quantity: order[key].quantity
    }
  })
  // console.log('streamLinedOrder', newOrder);
  return newOrder
}

interface PropsI {
  ordersCart: UseCartObjectReturnType;
}

const debug = false;
const OrderConfirm = (props:PropsI) => {
  const {ordersCart} = props;
  const [inProcess, setInProcess] = useState<boolean>(false);
  const ordersCartCellHealth = useShoppingCart({});
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { createOrderSession } = useOrders();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [orderName, setOrderName] = useState<string>(createInitialOrderName(user));
  const orgType = user?.regInfo?.organization?.type ? user.regInfo.organization.type : 'commercial' ;

  // handle limited availability of Cell Health Data.
  useEffect(() => {
    if (Object.hasOwn(ordersCart.data, 'AS-SYS-ACS12-ACD')) {
      if (ordersCart.length() === 2 && Object.hasOwn(ordersCart.data, 'AS-SCREEN-3RND')) {
        ordersCartCellHealth.add(ordersCart.data['AS-SYS-ACS12-ACD'].product, ordersCart.data['AS-SYS-ACS12-ACD'].quantity,
          ordersCart.data['AS-SYS-ACS12-ACD'].priceType);
        ordersCart.delete('AS-SYS-ACS12-ACD');
        ordersCart.delete('AS-SCREEN-3RND');
      } else {
        ordersCartCellHealth.add(ordersCart.data['AS-SYS-ACS12-ACD'].product, ordersCart.data['AS-SYS-ACS12-ACD'].quantity,
          ordersCart.data['AS-SYS-ACS12-ACD'].priceType);
        ordersCart.delete('AS-SYS-ACS12-ACD');
      }
    }
  }, []);
  if (debug) console.log('OrderConfirm | user:', user, 'ordersCart:', ordersCart);

  const handleSubmit = async (e: { preventDefault: () => void; }, orderType: string) => {
    setInProcess(true);
    e.preventDefault();
    if (orderType === 'PO') {
      setAlertOpen(true);


      // console.log('handleSubmit | PO');
    } else {
      console.log('handleSubmit | Stripe Checkout', ordersCart.data);
      const params = {
        system: APP_CONFIG.system,
        name: orderName,
        region: user.userRegion,
        type: 'screen',
        payment_type: orderType,
        order: streamlineOrder(ordersCart.data)
      }
      try {
        const resp = await createOrderSession(params);
        console.log('createOrderSession | response:', resp);
        if (resp.status === 'OK') {
          window.open(resp.url, '_self');
        } else if (resp.status === 'Error') {
          console.error('Error Create Order Session')
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  // console.log('ordersCart', ordersCart);
  // console.log('orderName: ', orderName);
  // console.log('ordersCartCellHealth', ordersCartCellHealth);
  return (
    <OrdersConfirmContainer>
      <FlexColumn>
        <MenuButton
          text={'Go Back to Refine Order'}
          onClick={() => navigate('/orders/create')}
          icon={<BackArrowIcon size={32} />}
          width={220}
          height={40}
        />
        <FlexColumn h_centered>
          {ordersCart.length() ?
            <>
              <OrdersName>
                <TextSmall>Order Name:</TextSmall>
                <HoverInfoText text={'Order name that will be shown in your orders list and used in fulfillment requests'} />
                <StyledTextInput
                  id='order_name'
                  type='text'
                  name='Order Name'
                  value={orderName}
                  onChange={(e) => setOrderName(e.target.value)}
                />
              </OrdersName>
              <OrdersCart
                cart={ordersCart}
                noQuantityChanges={true}
                debug={debug}
              />
              <ProductSettings>
                <Label>Customer Type:</Label><Value>{orgType}</Value>
                <Label>Customer Region:</Label><Value>{user.userRegion}</Value>
              </ProductSettings>
              <OrdersButton>
                <TextButton
                  width={200}
                  label={'Purchase via PO'}
                  onClick={(e) => handleSubmit(e, 'PO')}
                />
                <TextButton
                  width={200}
                  label={'Purchase via Credit Card'}
                  inProcess={inProcess}
                  onClick={(e) => handleSubmit(e, 'Stripe')}
                />
              </OrdersButton>
            </>
            :
            null
          }
          {ordersCartCellHealth.length() ?
            <>
              <Header>Limited Availability Assays  (Click on button below to email us)</Header>
              <OrdersCart
                cart={ordersCartCellHealth}
                noQuantityChanges={true}
                debug={debug}
              />
              <ProductSettings>
                <Label>Customer Type:</Label><Value>{orgType}</Value>
                <Label>Customer Region:</Label><Value>{user.userRegion}</Value>
              </ProductSettings>
              <OrdersButton>
                <TextButton
                  width={200}
                  label={'Contact Us For Availability'}
                  onClick={() => window.location.href = 'mailto:info@asedasciences.com?subject=Interested in screening in SYSTEMETRIC Cell Health Screen'}
                />
              </OrdersButton>
            </> : null}
        </FlexColumn>
      </FlexColumn>
      <Alert
        type={'general'}
        title={'Purchase Order'}
        message={'Check-out via PO is currently in development.'}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </OrdersConfirmContainer>
  )
}



export default OrderConfirm;

const OrdersConfirmContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const OrdersName = styled.div`
  display: flex;
  width: max-content;
  flex-direction: row;
  align-items: center;
`;

const OrdersButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
`;

const StyledTextInput = styled(BasicTextInput)`
  display: flex;
  width: 200px;
`

const Header = styled.div`
  display: flex;
  font-size: 16px;
  font-style: italic;
  margin-top: 30px;
  width: 100%;
`;

const ProductSettings = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Label = styled.div`
  font-size: 10px;
  font-style: italic;
  padding-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled(Label)`
`;
