import { IBugReport, BugReportsParamsT, ResponseT } from './bugs.types';
import axios, { AxiosRequestConfig } from 'axios';
import { BugReports } from '@utils/api';
import { handleError } from '@utils/misc';
import useCognito from "@as_core/account/useCognito";

axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', JSON.stringify(response, null, 2))
  return response
})

const useBugReports = () => {
  const { getToken } = useCognito();

  const getBugReports = async (params: BugReportsParamsT): Promise<ResponseT<IBugReport>> => {
    const token = getToken();
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await BugReports.get(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBugReports ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  return {
    getBugReports
  };
}

export default useBugReports;