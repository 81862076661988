import styled from 'styled-components/macro';
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import React, {useContext} from "react";
import {UserContext} from "@stores/UserContext";
import useCognito from "@as_core/account/useCognito";
import LogoutIcon from "@mui/icons-material/Login";
import MenuButton from "@components/controls/MenuButton";

const PgNotDomainAllowed = () => {
  const { user} = useContext(UserContext);
  const { logout } = useCognito();

  const handleLogout = () => {
    logout(user?.authEmail);
  };

  return (
    <Center>
      <ErrorMessages messages={[
        <MessageItem key={"access message"}>This is a restricted application. You are not part of the allowed users for this application</MessageItem>,
        <MessageItem key={"access message pub"}>You can access the publicly-available version at </MessageItem>,
        <MessageItem key={"access href"}><a href="https://3rnd-app.asedasciences.com">3rnd-app.asedasciences.com</a></MessageItem>,
        <MessageItem key={"log out"}> Sign Out of Application:
          <StyledMenuButton
            key={'register_logout'}
            text={'Sign Out of Account'}
            icon={<LogoutIcon />}
            onClick={handleLogout}
          />
        </MessageItem>
      ]}/>
    </Center>
  );
};

export default PgNotDomainAllowed;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const StyledMenuButton = styled(MenuButton)`
  display: flex;
`;
