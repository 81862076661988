import React, {useEffect, useState} from 'react';
import styled from "styled-components/macro";
import useRequestStatus, {RequestStatusT} from "../hooks/useRequestStatus";
import {Item} from "../common/flexStyles";
import ShowRequestStatus from "../common/ShowRequestStatus";
import ConsultingProcessMap from "./ConsultingProcessMap";

type PropsT = {
  view: string;
  requestStatus: RequestStatusT;
}

const debug = false;
const ConsultingProcessDetails = (props:PropsT) => {
  const {view, requestStatus} = props;
  const { getCountsFromStatus } = useRequestStatus();
  const [statusCounts, setStatusCounts] = useState<{ [key: string]: number }>(getCountsFromStatus(requestStatus));

  if (debug) console.log()

  useEffect(() => {
    if (requestStatus !== undefined) setStatusCounts(getCountsFromStatus(requestStatus));
  }, [requestStatus]);

  return (
    <ConsultingProcessDetailsContainer>
      <Item width={'380px'} h_align={'center'}>
        <ConsultingProcessMap
          counts={statusCounts}
          showCounts={false}
        />
      </Item>
      { view === "partner" ?
        <Item width={'380px'} h_align={'center'}>
          <ShowRequestStatus
            status={requestStatus}
          />
        </Item>
        : null
      }
    </ConsultingProcessDetailsContainer>
  )
}

export default ConsultingProcessDetails;

const ConsultingProcessDetailsContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.width};
`;
ConsultingProcessDetailsContainer.defaultProps = {
  width: 'max-content'
}
