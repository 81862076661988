import { Box, BoxProps } from '@mui/material';
import React from 'react';
import styled from "styled-components/macro";

export interface Props extends BoxProps {
  scrollX?: boolean,
  scrollY?: boolean
}

// @ts-expect-error fix eslint highlight
export const ScrollBox = (props: Props) => <Panel {...props} />;

const overflow = (enabled) => {
  if (enabled === true) {
    return 'scroll'
  } else if (enabled === false) {
    return 'hidden'
  } else {
    return 'auto';
  }
}

export const Panel = styled(Box)<{ scrollX: boolean, scrollY: boolean }>`
  overflow-x: ${(p) => overflow(p.scrollX)};
  overflow-y: ${(p) => overflow(p.scrollY)};
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track { 
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;
