import React, { useContext, useState } from 'react';
import useInvitations from "@utils/api/useInvitations";
import { UserContext } from '@stores/UserContext';
import InvitationCard from "@subApps/account/userLoginAlerts/components/InvitationCard";
import DataLoading from "@as_core/elements/DataLoading";
import useUserAppInfo from "@utils/useUserAppInfo";
import useCognito from "@as_core/account/useCognito";
import { InvitationT } from '@src/type';

type PropsT = {
  invitations: InvitationT[];
  setInvitationsUpdated?: (b:boolean)=>void;
}

const debug = false;
const InvitationCards = (props:PropsT) => {
  const {invitations} = props;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { respondToInvite } = useInvitations();
  const { getToken } = useCognito();
  const { getUserRepositories, getUserInfo } = useUserAppInfo();
  const { user , setUser} = useContext(UserContext);
  if (debug) console.log('user:', user, 'invitations:', invitations);

  const handleInviteResponse = (response: string, inviteType: string, inviteId: string) => {
    // TODO - Need to update user when repositories['current'] = '' -- no other repositories.
    setIsProcessing(true);

    if (debug) console.log('handleInviteResponse:', response, inviteId);
    respondToInvite(response, user, inviteId).then((response) => {
      if (debug) console.log(response);
      if (inviteType === 'Share Repository') {
        getUserRepositories().then((repos) => {
          setUser({...user, appInfo: {...user.appInfo, repositories: repos}})
          setIsProcessing(false);
          if (props?.setInvitationsUpdated) props.setInvitationsUpdated(true);
        })
      } else {
        getUserInfo(getToken(), {}).then((appInfo) => {
          if (debug) console.log('appInfo', appInfo);
          setUser({...user, appInfo: appInfo});
          setIsProcessing(false);
          if (props?.setInvitationsUpdated) props.setInvitationsUpdated(true);
        })
      }
    });
  };

  return (
    <>
      { isProcessing ?
        <DataLoading />
        :
        <>
          {invitations.map((invite, index) =>
            <InvitationCard
              key={`org_invite_${index}`}
              type={'org'}
              invite={invite}
              subscription={user.appInfo.subscription.type}
              handleInviteResponse={handleInviteResponse}
            />
          )}
        </>
      }
    </>
  );
};

export default InvitationCards;
