import React from 'react';
import styled from 'styled-components/macro';
import SimpleTable from '../../shared/SimpleTable';
import ColumnFieldPickerTable from './ColumnFieldPickerTable';
import useFileImport from '../../useFileImport';
import {FlexItem, FlexRow, FlexSpacer} from "@as_core/elements/flexStyles";
import {TextSmallBold, TextSmall, TextBold, TextSpace } from "@as_core/elements/textStyles";

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
interface ColumnFieldPickerProps {
  height: number;
  width: number;
  selectedColumns?: (string | undefined)[];
  onColumnPicked: (colName: string) => void;

}

const ColumnFieldPicker = (props: ColumnFieldPickerProps) => {
  if (debug) console.log('ColumnFieldPicker | props:', props);
  const { onColumnPicked, height, width, selectedColumns=[] } = props;
  const { context, getActiveColumn } = useFileImport();
  const activeColumn = getActiveColumn();
  const { getPreview } = useFileImport();
  if (debug) console.log('ColumnFieldPicker | height:', height, ' width:', width);
  return (
    <Container height={height}>
      <FileSpecification>
        <FlexItem>
          <TextSmallBold color={'accent'}>File:</TextSmallBold>
          <TextSpace />
          <TextSmall>{context.workbook.filename}</TextSmall>
        </FlexItem>
        <FlexItem>
          <TextSmallBold color={'accent'}>Sheet:</TextSmallBold>
          <TextSpace />
          <TextSmall>{activeColumn.sheetName}</TextSmall>
        </FlexItem>
      </FileSpecification>
      <FlexRow h_centered>
        <DataSampleContainer>
          <Title>Data Preview</Title>
          <MySimpleTable
            title={activeColumn.colName}
            rows={getPreview(
              context.workbook.workbook,
              activeColumn.sheetName,
              activeColumn.colIdx
            )} />
        </DataSampleContainer>
        <PickerContainer>
          <ColumnHeader>
            <TextSmallBold color={'accentSecondary'}>Select/Create field for column:</TextSmallBold>
            <FlexSpacer width={5}/>
            <TextBold color={'primary'}>{activeColumn.colName}</TextBold>
          </ColumnHeader>
          <MyColumnFieldPickerTable
            columnName={activeColumn.colName}
            height={height}
            width={width}
            onColumnPicked={onColumnPicked}
            selectedColumns={selectedColumns}
          />
        </PickerContainer>
      </FlexRow>
    </Container>
  );
};

export default ColumnFieldPicker;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  height: calc(${(p)=>p.height} - 220px);
  width: 900px;
  flex-direction: column;
`;

const FileSpecification = styled.div`
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const Item = styled.div`
  display: flex;
  font-size: 14px;
  padding-left: 10px;
`;

const Title = styled(Item)`
  font-weight: bold;
`;

const ColumnHeader = styled.div`
  display: flex;
  padding: 3px;
`;

const DataSampleContainer = styled.div`
  display: flex;
  margin-top: 3px;
  flex-direction: column;
  padding: 5px;
  width: 180px;
  max-height: 300px;
`;

const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MyColumnFieldPickerTable = styled(ColumnFieldPickerTable)<{height: number}>`
  display: flex;
  width: 100%;
  max-height: calc(${(p)=>p.height} - 300px);
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const MySimpleTable = styled(SimpleTable)`
  display: flex;
  width: calc(100% - 10px);
`;
