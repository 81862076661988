import React from 'react';
import { Stack } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { LineBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface LineModalEditorProps {
  plot?: PlotConfig<LineBase>;
  onSave?: (config: PlotConfig<LineBase>) => void;
}

export const LineModalEditor = ({ plot, onSave }: LineModalEditorProps) => {
  const updateTimeColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, timeColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateValueColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateGroupColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onSave(newPlotConfig);
  };


  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-key'
        label={'Time Variable'}
        value={plot.config.timeColumn}
        options={plot.columns}
        onChange={updateTimeColumn}
      />
      <VariableSelect
        id='variable-value'
        label={'Value Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValueColumn}
      />
      <VariableSelect
        id='variable-group'
        label={'Group By'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateGroupColumn}
      />
    </Stack>
  );
};
