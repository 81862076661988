import React from 'react';
import { DrcBase, PlotConfig } from '../../../plot.types';
import { Stack } from '@mui/system';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { LayerCard } from '../LayerCard';

type DrcCardProps = {
  plot: PlotConfig<DrcBase>;
  onChange: (newPlotConfig: PlotConfig<DrcBase>) => void;
};

export const DrcCard = ({ plot, onChange }: DrcCardProps) => {
  const updateDoseColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<DrcBase> = {
      ...plot,
      config: { ...plot.config, doseColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateResponseColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<DrcBase> = {
      ...plot,
      config: { ...plot.config, responseColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateGroupByColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<DrcBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onChange(newPlotConfig);
  };


  return (
    <LayerCard title={'Dose Response Curve'} disableExpand>
      <Stack spacing={2} width={'100%'}>
        <VariableSelect
          id='variable-dose'
          label={'Dose Variable'}
          value={plot.config.doseColumn}
          options={plot.columns}
          onChange={updateDoseColumn}
        />
        <VariableSelect
          id='variable-response'
          label={'Response Variable'}
          value={plot.config.responseColumn}
          options={plot.columns}
          onChange={updateResponseColumn}
        />
        <VariableSelect
          id='variable-groupBy'
          label={'Group by'}
          value={plot.config.groupByColumn}
          options={plot.columns}
          onChange={updateGroupByColumn}
        />
      </Stack>
    </LayerCard>
  );
};
