import React, { ChangeEvent, useState } from 'react';
import styled from "styled-components/macro";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {RequestT, RequestUpdateT} from "../hooks/useRequests";
import useRequestForms from "../hooks/useRequestForms";
import { BasicTextInput } from '@as_core/account/forms/FormStyles';
import TextButton from '@as_core/controls/buttons/TextButton';
import DataLoading from '@as_core/elements/DataLoading';

import useRequestStatus from '../hooks/useRequestStatus';

type PropsT = {
  request: RequestT;
  isProcessing: boolean;
  setIsProcessing: (value: boolean) => void;
  updateSelectedRequest: (id: string, updates: RequestUpdateT)=>Promise<void>;
  handleClose: ()=>void;
}

const debug = false;
const ShippingFormUpdates = (props:PropsT) => {
  const { request, isProcessing, setIsProcessing, updateSelectedRequest, handleClose} = props;
  const { updateRequestStatus } = useRequestStatus();
  const queryClient = useQueryClient();
  const [shippingProvider, setShippingProvider] = useState<string>('');
  const [shippingTrackingNumber, setShippingTrackingNumber] = useState<string>('');
  const { createRequestForm } = useRequestForms();
  if (debug) console.log('ShippingFormUpdates | request.id:', request.id);

  const mutation = useMutation({
    mutationFn: () => updateRequestStatus(request, 'compoundsShipped'),
    onSuccess: (data) => {
      if (debug) console.log('ShippingFormUpdates | StatusUpdate | mutation | OnSuccess | data:', request.id, data);
      queryClient.invalidateQueries({ queryKey: ['requestStatus', request.id] }).then();
      queryClient.invalidateQueries({ queryKey: ['requestForms', request.id, 'shipping_information'] }).then();
    }
  });

  const handleUpdate = () => {
    const payload = {shippingProvider: shippingProvider, shippingTrackingNumber: shippingTrackingNumber};
    setIsProcessing(true);
    createRequestForm({
      "request_id": request.id,
      "name": "request_" + request.id.toString() + "_shipping_information",
      "form_type": "shipping_information",
      "value": JSON.stringify(payload),
    }).then((response) => {
      if (debug) console.log('ShippingFormUpdates | createRequestForm | response:', response);
      updateSelectedRequest(request.id, {status: 'Shipped'})
      .then((request)=>{
        if (debug) console.log('ShippingFormUpdates | createRequestForm | response:', request);
        mutation.mutate();
        handleClose();
        setIsProcessing(false);
      })
    })
  }

  return(
    <Container>
      { isProcessing ?
      <DataLoading />
      :
      <>
        <Row>
          <Label>Shipping Provider:</Label>
          <Value>
            <BasicTextInput
            value = {shippingProvider}
            onChange={(e:ChangeEvent<HTMLInputElement>) => {setShippingProvider(e.target.value)}}
            />
          </Value>
        </Row>
        <Row>
          <Label>Shipping Tracking Number:</Label>
          <Value>
            <BasicTextInput
            value = {shippingTrackingNumber}
            onChange={(e:ChangeEvent<HTMLInputElement>) => {setShippingTrackingNumber(e.target.value)}}
            />
          </Value>
        </Row>
        <Row>
          <TextButton
          key={'request_form_upload_confirm'}
          label={'Confirm Shipping'}
          width={200}
          onClick={handleUpdate}
          isDisabled={shippingProvider.length === 0 || shippingTrackingNumber.length === 0}
          />
        </Row>
      </>
      }
    </Container>
  )
}
export default ShippingFormUpdates;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`

const Label = styled.label`
    display: flex;
    font-size: 15px;
    width: 200px;
    margin-right: 5px;
    justify-content: flex-end;
    color: ${(p) => p.theme.palette.accentSecondary};
`

const Value = styled.div`
    display: flex;
    width: 250px;
    color: ${(p) => p.theme.palette.textSecondary};
`;
