import React, { useState } from 'react';
import styled from 'styled-components/macro';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import DataLoading from '@as_core/elements/DataLoading';
import {Email, CalendarToday} from '@mui/icons-material';
import TextButton from '@as_core/controls/buttons/TextButton';
import { RequestFormsContainer, RequestFormsHeader, Row} from '../common/flexStyles';
import {RequestT, RequestUpdateT} from '../hooks/useRequests';
import RequestToDo, {StatusMapT} from '../common/RequestToDo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useRequestStatus, { RequestStatusT } from '../hooks/useRequestStatus';
// ToDo -- clean up the styled components and update

const ScheduleInfo = 'Please schedule the meeting using the email button. '
  + 'Once you have confirmed a date/time, please confirm successful scheduling via the Confirm Scheduling button.';
const ConfirmMeetingInfo = 'Once you held the consulting meeting, please confirm '
  + ' meeting occurred via the Confirm Successful Meeting button.';

const statusToStatusUpdate: { [key: string]: string } = {
  'Scheduled' : 'meetingScheduled',
  'Completed' : 'meetingCompleted'
}

interface PropsI {
  viewMode: string;
  request: RequestT;
  status: RequestStatusT;
  updateSelectedRequest: (id: string, updates: RequestUpdateT) => Promise<void>;
}

const debug = false;
const ConsultingRequestForms = (props: PropsI) => {
  const {viewMode, request, status, updateSelectedRequest} = props;
  const queryClient = useQueryClient();
  const { updateRequestStatus } = useRequestStatus();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: (
      params: {request: RequestT, statusUpdate:string}
    ) => updateRequestStatus(params.request, params.statusUpdate),
    onSuccess: (data) => {
      if (debug) console.log('ShippingFormUpdates | StatusUpdate | mutation | OnSuccess | data:', request.id, data);
      queryClient.invalidateQueries({ queryKey: ['requestStatus', request.id] }).then();
    }
  });

  const handleClick = (updatedStatus: string) => {
    if (debug) console.log('ConsultingRequestForms.handleClick | updatedStatus:', updatedStatus);
    mutation.mutate({request: request, statusUpdate: statusToStatusUpdate[updatedStatus]});
    updateSelectedRequest(request.id, {status: updatedStatus})
      .then((request)=>{
        if (debug) console.log('ConsultingRequestForms | updateRequest | request:', request);
        setIsProcessing(false);
      }
    )
  }

  if (debug) console.log('ConsultingRequestForms | request, status:', request, status);

  const statusMap: StatusMapT[] = [
    {label: 'Schedule Meeting(s)', checked: ['Scheduled', 'Completed'].includes(request.status)},
    {label: 'Attend Meeting(s)', checked: request.status === 'Completed'},
  ]
  return (
    <RequestFormsContainer>
      <RequestFormsHeader>User Consulting Request Actions</RequestFormsHeader>
      <Row padding={'0'}>
        <RequestToDo statusMap={statusMap} viewMode={viewMode}/>
      </Row>
      { isProcessing ? <DataLoading />
        : request.status === 'Open' && viewMode === 'user'?
        <>
          <Row v_align={'center'} h_align={'space-around'} width={'max-content'}>
            <Label>Schedule Meeting: <HoverInfoText text={ScheduleInfo}/></Label>
            <Value>
              <TextButton
                width={210}
                height={30}
                fontSize={12}
                margin={'2px'}
                icon={<EmailIcon />}
                label={'Click to Schedule via Email'}
                onClick={() => window.location.href = `mailto:info@asedasciences.com?subject=Need to Schedule ${request?.prod_name}`}
              />
            </Value>
          </Row>
          <Row>
            <TextButton
              label = 'Confirm Scheduling'
              icon={<CalendarIcon />}
              height={28}
              width={180}
              onClick={()=> handleClick('Scheduled')}
            />
          </Row>
        </>
      : request.status !== 'Completed' && viewMode === 'user'?
        <Row>
          <HoverInfoText text={ConfirmMeetingInfo}/>
          <TextButton
            label = 'Confirm Successful Meeting(s)'
            width={225}
            height={28}
            isDisabled={request.status!=='Scheduled'}
            onClick={()=> handleClick('Completed') }
          />
        </Row>
        :
        <Row />
      }
    </RequestFormsContainer>
  );
};

export default ConsultingRequestForms;

const Label = styled.div`
  display: flex;
  font-size: 14px;
  width: max-content;
  text-align: end;
`;

const Value = styled(Label)`
  flex-direction: row;
  justify-content: center;
  min-width: max-content;
`;

const EmailIcon = styled(Email)`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const CalendarIcon = styled(CalendarToday)`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
`;
