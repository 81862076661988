import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const OrdersActiveIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = style.name === "dark" ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M12,22.8C6.1,22.8,1.2,17.9,1.2,12S6.1,1.3,12,1.3S22.8,6.1,22.8,12S17.9,22.8,12,22.8z M12,2.8c-5.1,0-9.2,4.1-9.2,9.2
	s4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2S17.1,2.8,12,2.8z"/>
      <path fill={colorHighlight} d="M12,12l7.2,3.5C17.9,18.2,15.1,20,12,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8L12,12z"/>
    </svg>
  )
}

export default OrdersActiveIcon;