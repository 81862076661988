import React, {useContext} from 'react';
import styled from "styled-components/macro";
import {UserContext} from "@stores/UserContext";
import TextButton from "@components/controls/TextButton/TextButton";
import {useNavigate} from "react-router-dom";

const debug = false;

const SubscriptionOrderButton = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  if (debug) console.log('SubscriptionOrderButton | user:', user);

  return(
    <SubscriptionPriceContainer>
      <TextButton
        label='Subscribe Now'
        onClick={() => navigate('/subscriptions/order')}
        width={120}
        height={25}
      />
    </SubscriptionPriceContainer>
  )
}

export default SubscriptionOrderButton;

const SubscriptionPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
`;
