import React, { useContext } from 'react';
import styled from "styled-components/macro";
import { StyleContext } from '@theme/AppStyles';
import {mapCountsToPositions, positionT} from "../utils/processMaps";

const checkMark = '\u2713'
const notificationPositions: {[key: string]: positionT} = {
  'open' : { x: 11, y: 8},
  'meetingScheduled': { x: 180, y: 8},
  'meetingOccurred': { x: 347, y: 8},
  'meetingComplete': {x: 516, y:8},
  'Open' : { x: 11, y: 8},
  'Scheduled': { x: 180, y: 8},
  'Meeting': { x: 347, y: 8},
  'Completed': {x: 516, y:8}
}

type PropsT = {
  showCounts?: boolean;
  counts: {[key:string]: number}
}

const debug = false;
const ConsultingProcessMap = (props:PropsT) => {
  const {showCounts = true, counts} = props;
  const [style] = useContext(StyleContext);
  const image = (style.name === 'dark') ? '/img/Request_Process_Images/Consulting_Process_dark.svg' : '/img/Consulting_Process_Images/Assay_Process_light.svg';
  // image details
  const width= 655;
  const height = 162.65;
  const scale = 1.0;

  const positions = mapCountsToPositions(counts, notificationPositions);
  if (debug) console.log('counts', counts, 'positions', positions);

  return (
    <MapContainer width={scale*width} height={scale*height}>
      { positions.map((p, index) => (
        <NotificationCountBubble key={`count_bubble_${index}`} x={p.x} y={p.y}>{ showCounts ? p.count : checkMark } </NotificationCountBubble>
      ))}
      <img src={image} alt={'process image map'}/>
    </MapContainer>
  )
}

export default ConsultingProcessMap;

const MapContainer = styled.div<{width: number, height: number}>`
  display: flex;
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

const NotificationCountBubble = styled.div<{x:number, y:number}>`
  position: absolute;
  font-size: 16px;
  z-index: 1;
  top: ${(p)=>p.y}px;
  left: ${(p)=>p.x}px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.textPrimary };
  background-color:  ${(props) => props.theme.palette.accentPrimary };
  border: 2px solid ${(p)=>p.theme.palette.backgroundSecondary};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;