import React from 'react';
import styled from 'styled-components/macro';
import { ButtonBase } from '@mui/material';

interface IButtonText {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element | null;
  width?: number;
  outlined?: boolean;
}

const ButtonText = (props: IButtonText) => {
  const { label, onClick, icon = null, width = 200, outlined } = props;
  return (
    <TextButton outlined={outlined} width={width} onClick={onClick}>
      <ButtonIcon>{icon}</ButtonIcon>
      <ButtonLabel>{label}</ButtonLabel>
    </TextButton>
  );
};
export default React.memo(ButtonText);

const TextButton = styled(ButtonBase)<{ width: number; outlined: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: ${(p) => p.width}px;
  height: 32px;

  &.MuiButtonBase-root {
    color: ${(p) => p.theme.palette.textSecondary};
    background-color: ${(p) =>
      p.outlined
        ? p.theme.palette.accentPrimary
        : p.theme.palette.backgroundPrimary};
    border-radius: 10px;
    border: 1px solid ${(p) => p.theme.palette.textSecondary};

    :hover {
      color: ${(p) => p.theme.palette.textPrimary};
      background-color: ${(p) => p.theme.palette.backgroundQuaternary};
    }
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ButtonLabel = styled.div`
  display: flex;
`;
