import React, {useEffect, useState} from 'react';
import styled from "styled-components/macro";
import {Item} from "../common/flexStyles";
import useRequestStatus, {RequestStatusT} from "../hooks/useRequestStatus";
import ShowRequestStatus from "../common/ShowRequestStatus";
import AssayProcessMap from "../assay/AssayProcessMap";

type PropsT = {
  view: string;
  requestStatus: RequestStatusT;
  width?: string
}

const debug = false;
const AssayProcessDetails = (props:PropsT) => {
  const { view, requestStatus, width='720px' } = props;
  const { getCountsFromStatus } = useRequestStatus();
  const [statusCounts, setStatusCounts] = useState<{ [key: string]: number }>(getCountsFromStatus(requestStatus));

  useEffect(() => {
    setStatusCounts(getCountsFromStatus(requestStatus));
  }, [requestStatus]);

  if (debug) console.log('AssayProcessDetails | view:', view, 'requestStatus', requestStatus, 'statusCounts', statusCounts);

  return (
    <RequestProcessDetailsContainer>
      <Item width={width} h_align={'center'}>
        <AssayProcessMap
          counts={statusCounts}
          showCounts={false}
        />
      </Item>
      { view === 'partners' ?
        <Item width={width} h_align={'center'}>
          <ShowRequestStatus status={requestStatus} />
        </Item>
        : null
      }
    </RequestProcessDetailsContainer>
  )
}

export default AssayProcessDetails;

const RequestProcessDetailsContainer = styled.div<{width?: string}>`
  display: flex;
  flex-direction: column;
  min-width: ${(p) => p.width};
`;
RequestProcessDetailsContainer.defaultProps = {
  width: 'max-content'
}
