import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const BiomimeticsIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={colorHighlight} strokeWidth={15} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M50.3,438H106c0,0,7-64,23-62s30,62,30,62h29c0,0,4-233,19-232s19,232,19,232h26c0,0-7-392,8-392
  s29,392,29,392h23c0,0-7-311,15-307s22,307,22,307h38c0,0-2-270,19-270s20,270,20,270h31"/>
        <path stroke={color} strokeWidth={15} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M50.3,46v427.9h427.9l0,0"/>
    </svg>
  )
}

export default BiomimeticsIcon;