import React from 'react';

export const FileImportContext = React.createContext(null);


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isGlobalChecked: false,
    childChecked: [] // Initialize as an empty array; right now we do initialisation in the necessary components
};

const checkboxGlobalSlice = createSlice({
    name: 'globalCheckbox',
    initialState,
    reducers: {
        setIsGlobalChecked(state, action) {
            state.isGlobalChecked = action.payload;
            state.childChecked = state.childChecked.map(() => action.payload);
        },
        setChildChecked(state, action) {
            const { index, checked } = action.payload;
            state.childChecked[index] = checked;
            state.isGlobalChecked = state.childChecked.every((c) => c);
        },
        initializeChildChecked(state, action) {
            state.childChecked = Array(action.payload).fill(false);
        }
    }
});

export const { setIsGlobalChecked, setChildChecked, initializeChildChecked } = checkboxGlobalSlice.actions;
export default checkboxGlobalSlice.reducer;
