import {PlotAction} from "./plot.types";
import {Box, SpeedDial, SpeedDialAction} from "@mui/material";
import React from "react";

interface Props {
  icon: React.ReactElement;
  actions: Array<PlotAction>;
  onClick?: VoidFunction;
}

export const ActionButtons = ({icon,actions,onClick}: Props) => {

  const handleClick = (action, event) => {
    event.preventDefault();
    event.stopPropagation();
    action.onClick(event);
  }

  return (
    <Box
      sx={{height: 0, flexGrow: 1}}
      style={{transform: 'translate(-6px,-64px) translateZ(0px)'}}
      data-html2canvas-ignore
    >
      <SpeedDial
        ariaLabel='Layers'
        hidden={false}
        icon={icon}
        direction={'left'}
        onClick={onClick}
        open={true}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            arrow
            onClick={e => handleClick(action,e)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};