import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';

interface ColorBarT {
  value: number;
  min?: number;
  max?: number;
  width?: number;
  height?: number;
}

const colorScale = scaleLinear<string>()
    .domain([0, 0.5, 1])
    .range(['#FFFFFF', '#E778AB', '#ff005d']);

const ColorBar = (props:ColorBarT) => {
  const { value, width=150, height=12, min = 0.0, max = 1.0 } = props;
  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value) * 100;

  return (
      <Container width={width} height={height}>
        <Bar color={colorScale(value).toString()} width={percent + '%'} height={height} />
      </Container>
  );

};

ColorBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ColorBar;

const Container = styled.div<{height: number; width: number}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  padding: 0;
  border: ${(p) => p.theme.borders.thinAccentSecondary};
  border-radius: 5px;
`;

const Bar = styled.div<{color: string, width: string, height: number}>`
  background-color: ${(p) => p.color};
  height: ${(p) => p.height }px;
  width: ${(p) => p.width };
`;
