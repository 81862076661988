import {
  BarBase,
  BoxplotBase,
  DrcBase,
  HistogramBase,
  LineBase,
  PieBase,
  PlotBase,
  PlotConfig,
  PlotData,
  ScatterBase,
  VolcanoBase,
} from '@plotting/single-plot-view/plot.types';
import { BaseLayerType } from '@plotting/single-plot-view/plot.types';
import { v4 as uuidv4 } from 'uuid';

export const buildPlotConfig = (
  plot: PlotConfig<PlotBase>,
  type: BaseLayerType
): PlotConfig<PlotBase> => {
  if (type === 'scatter') {
    return buildScatterPlot(plot);
  } else if (type === 'drc') {
    return buildDrcPlot(plot);
  } else if (type === 'bar') {
    return buildBarPlot(plot);
  } else if (type === 'line') {
    return buildLineChart(plot);
  } else if (type === 'boxplot') {
    return buildBoxPlot(plot);
  } else if (type === 'volcano') {
    return buildVolcanoPlot(plot);
  } else if (type === 'pie') {
    return buildPieChart(plot);
  } else if (type === 'histogram') {
    return buildHistogram(plot);
  }
};

const buildScatterPlot = (
  plot: PlotConfig<PlotBase>
): PlotConfig<ScatterBase> => {
  const variableList = Object.keys(plot.data[0]);
  const scatter: PlotConfig<ScatterBase> = {
    ...plot,
    config: {
      base: 'scatter',
      authors: plot.config.authors,
      xColumn: variableList.includes('x') && 'x',
      yColumn: variableList.includes('y') && 'y',
    },
  };
  return scatter;
};

const buildDrcPlot = (plot: PlotConfig<PlotBase>): PlotConfig<DrcBase> => {
  const drcPlot: PlotConfig<DrcBase> = {
    ...plot,
    config: {
      base: 'drc',
      authors: plot.config.authors,
      groupByColumn: 'todo',
      doseColumn: 'todo',
      responseColumn: 'todo',
    },
  };
  return drcPlot;
};

const buildBarPlot = (plot: PlotConfig<PlotBase>): PlotConfig<BarBase> => {
  const variableList = Object.keys(plot.data[0]);
  const barplot: PlotConfig<BarBase> = {
    ...plot,
    config: {
      base: 'bar',
      authors: plot.config.authors,
      groupByColumns: variableList.includes('group')
        ? ['group']
        : variableList.includes('name')
          ? ['name']
          : [],
      valueColumn: variableList.includes('value') && 'value',
      isSorted: true,
      orientation: 'horizontal',
    },
  };
  return barplot;
};

const buildBoxPlot = (plot: PlotConfig<PlotBase>): PlotConfig<BoxplotBase> => {
  const variableList = Object.keys(plot.data[0]);
  const boxPlot: PlotConfig<BoxplotBase> = {
    ...plot,
    config: {
      base: 'boxplot',
      authors: plot.config.authors,
      groupByColumn: variableList.includes('group')
        ? 'group'
        : variableList.includes('name')
          ? 'name'
          : undefined,
      valueColumn: variableList.includes('value') && 'value',
    },
  };
  return boxPlot;
};

const buildLineChart = (plot: PlotConfig<PlotBase>): PlotConfig<LineBase> => {
  const variableList = Object.keys(plot.data[0]);
  const line: PlotConfig<LineBase> = {
    ...plot,
    config: {
      base: 'line',
      authors: plot.config.authors,
      timeColumn: variableList.includes('time') && 'time',
      valueColumn: variableList.includes('value') && 'value',
    },
  };
  return line;
};

const buildVolcanoPlot = (
  plot: PlotConfig<PlotBase>
): PlotConfig<VolcanoBase> => {
  const variableList = Object.keys(plot.data[0]);
  const volcanoPlot: PlotConfig<VolcanoBase> = {
    ...plot,
    config: {
      base: 'volcano',
      authors: plot.config.authors,
      foldChangeColumn:
        variableList.includes('log2FoldChange') && 'log2FoldChange',
      pValueColumn: variableList.includes('pvalue') && 'pvalue',
      pValueThreshold: 0.05,
      foldChangeThreshold: 2,
    },
  };
  return volcanoPlot;
};

const buildPieChart = (plot: PlotConfig<PlotBase>): PlotConfig<PieBase> => {
  const variableList = Object.keys(plot.data[0]);

  const pieChart: PlotConfig<PieBase> = {
    ...plot,
    config: {
      base: 'pie',
      authors: plot.config.authors,
      groupByColumn: variableList.includes('group')
        ? 'group'
        : variableList.includes('name')
          ? 'name'
          : undefined,
      valueColumn: variableList.includes('value') && 'value',
    },
  };
  return pieChart;
};

const buildHistogram = (
  plot: PlotConfig<PlotBase>
): PlotConfig<HistogramBase> => {
  const variableList = Object.keys(plot.data[0]);

  const histogram: PlotConfig<HistogramBase> = {
    ...plot,
    config: {
      base: 'histogram',
      authors: plot.config.authors,
      valueColumn: variableList.includes('value') && 'value',
    },
  };
  return histogram;
};

export const getBasicPlotConfig = (
  compounds: Array<string>,
  sources: Array<string>,
  dataset: PlotData,
  columns: Array<{ label: string; value: string; type: string }> = [],
  dataFormatted?: Array<any>
): PlotConfig<PlotBase> => {
  return {
    uuid: uuidv4(),
    createdOn: Date.now(),
    modifiedOn: Date.now(),
    userId: 'testuser',
    compoundIds: compounds,
    sourceIds: sources,
    name: `New Plot`,
    type: 'user_plot',
    config: {
      base: 'scatter',
      authors: ['Test User'],
    },
    data: dataset,
    dataFormatted: dataFormatted,
    columns: columns,
  };
};
