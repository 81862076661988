import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery } from '@tanstack/react-query';
import ErrorMessages, {MessageItem} from '@as_core/elements/UserMessages';
import {BasicTableFieldsT} from '@as_core/tables/BasicTable/BasicTable';
import {getValueByPath} from '@utils/misc';
import { ListLoader } from '@as_core/elements/listStyles';
import useOrders from '@business/orders/useOrders';
import {renderOrderCost} from '@business/orders/components/utils';
import {OrderT} from '@business/types';
import { FlexRow } from '@as_core/elements/flexStyles';
import MenuButton from '@components/elements/MenuButton';
import BasicTable from '@as_core/tables/BasicTable';
import ViewIcon from '@as_core/icons/view.icon';
import ViewOrderDetails from './ViewOrderDetails';

const ItemsInfo:string = 'Total number of items that were ordered.';
const RequestsInfo:string = 'Each order may result in multiple requests that need to be filled. '
   + 'Types of screens, consulting, or 3Rnd subscriptions all act as separate requests that have to '
   + 'be completed for the total order to be considered fulfilled and complete. '
   + 'If 0 -- please submit a bug report and provide date/time of order. '
const StatusInfo: string = 'The available statuses are: success (payment complete and requests initiated) and '
   + 'complete (all requests contained in the order are completed).';

const tableFields: BasicTableFieldsT[] = [
  { value: 'view', label: '', type: 'computed', width: 40 },
  { value: 'name', label: 'Order Name', align: 'left', width: 200},
  { value: 'created', label: 'Order Date', type: 'date', width: 150 },
  { value: 'type', label: 'Order Type', width: 100 },
  { value: 'line_items', label: '# Items', info: ItemsInfo, width: 80},
  { value: 'requests', label: '# Requests', info: RequestsInfo, width: 100},
  { value: 'status', label: 'Status', info: StatusInfo, width: 100},
  { value: 'cost', label: 'Total Cost', align: 'right', width: 80}
];

const getTableRows = (orders: OrderT[], handleViewClick: (id:string)=>void) => {
  const rows = [];
  orders.forEach((order) => {
    const row = {};
    for (const field of tableFields) {
      if (field?.type && field.type === 'date') {
        const value = getValueByPath(order, field.value, '');
        if (value !== '') {
          const dateObj = new Date(value)
          row[field.value] = dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString();
        } else {
          row[field.value] = '';
        }
      } else if (field.value === 'view') {
        row[field.value] =
          <MenuButton
            onClick={() => handleViewClick(order?.orderId)}
            icon={<ViewIcon />}
            text={'View Order'}
            tooltipPlacement={'bottom'}
          />;
      } else if (field.value === 'line_items') {
        row[field.value] = Object.values(order.order).length;
      } else if (field.value === 'requests') {
        row[field.value] = order.requests.length;
      } else if (field.value === 'cost') {
        row[field.value] = renderOrderCost(order);
      } else if (field.value === 'payment_date') {
        row[field.value] = Number(getValueByPath(order, 'payment.amount', 0)/100.0).toLocaleString();
      } else {
        row[field.value] = order[field.value];
      }
    }
    rows.push(row);
  });
  return rows;
};

interface PropsI {
  orderType: string;
}

const debug = false;
const OrdersList = (props:PropsI) => {
  const {orderType} = props;
  if (debug) console.log('ordersList {orderType}', orderType);
  const { getOrders } = useOrders();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderView, setSelectedOrderView] = useState('success');

  // load the current status
  let { isPending, isError, error, data: orders } = useQuery({
    queryKey: ['orders', 'open'],
    queryFn: () => getOrders({in_process: orderType !== 'complete'})
  })
  if (isError) console.error(error.message);

  const filterOrders = (orderType: string) => {
    if (isPending) return [];
    return orderType === 'complete' ? orders.filter((o) => o.open === false) : orders.filter((o) => o.open);
  }

  const handleViewClick = (orderId: string) => {
    if (debug) console.log('handleViewClick ', orderId);
    const matched:OrderT = orders.find((o) => o.orderId === orderId);
    if (matched) {
      setSelectedOrder(matched);
      if (matched.status === 'canceled') {
        setSelectedOrderView('canceled');
      } else {
        setSelectedOrderView('success');
      }
    }
  };

  if (debug) console.log('ordersList | isPending: ', isPending, 'orders.length', orders.length);
  const filteredOrders = filterOrders(orderType);

  return (
    <>
      { !isPending && filteredOrders.length ?
        <ListContainer>
          { selectedOrder !== null ?
            <ViewOrderDetails
              order={selectedOrder}
              orderMode={selectedOrderView}
              handleBack={()=>setSelectedOrder(null)}
              topBuffer={60}
            />
            :
            <BasicTable
              fields={tableFields}
              rows={getTableRows(filteredOrders, handleViewClick)}
              border={false}
            />
          }
        </ListContainer>
      : isPending ?
          <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
            <ListLoader />
          </FlexRow>
      :
          <ErrorMessages messages={[
            <MessageItem key='emi_no_orders'>You do not have any {orderType === 'complete' ? ' complete ' : ' open '} orders</MessageItem>
          ]}/>
      }
    </>
  );
};

export default OrdersList;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
