import React from 'react';
import styled from 'styled-components/macro';
import { FlexRow } from "@components/layout/FlexStyles";
import {TextRegular} from '@components/elements/TextStyles';

interface PropsI {
  message?: string;
  centered?: boolean;
  width?: number;
}

const DataLoadingMessage = (props:PropsI) => {
  const { message = '' , centered = true, width = 50} = props;
  return (
    <>
      { centered ?
        <FlexRow width={"100%"} height={"100%"} v_centered h_centered>
          { message ?
            <TextMessage color={'primary'}>{ message }</TextMessage>
            :
            <img width={`${width}px`} src={'/img/data-loading.gif'} alt={'loading'} />
          }
        </FlexRow>
        :
        <img width={`${width}px`} src={'/img/data-loading.gif'} alt={'loading'} />
      }
    </>
  );
};

export default DataLoadingMessage;

const TextMessage = styled(TextRegular)`
  width: 400px;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  background: ${(p) => p.theme.palette.backgroundSecondary};
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
`;
