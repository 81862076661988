import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip, ButtonBase } from '@mui/material';
import NotificationCount from '@components/elements/NotificationCount';

interface MenuButtonPropsT {
  text: string;
  icon: React.ReactNode;
  notificationCount?: number;
  onClick?: (val: unknown) => void;
  isActive?: boolean;
  width?: number | string;
  height?: number;
  margin?: string;
  tooltipPlacement?: 'right' | 'bottom' | 'left' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start';
  innerText?: string;
}

const MenuButton = (props: MenuButtonPropsT) => {
  return (
    <MenuButtonContainer margin={props.margin} width={props.width}>
      {props?.notificationCount ? (
        <NotificationCount count={props.notificationCount} />
      ) : null}
      <Tooltip title={props.text} arrow placement={props.tooltipPlacement}>
        <TextContainer onClick={props.onClick}>
          <IconButton className={props.isActive ? 'active' : ''}>
            {props.icon}
          </IconButton>
          {props.innerText && <span>{props.innerText}</span>}
        </TextContainer>
      </Tooltip>
    </MenuButtonContainer>
  );
};
export default React.memo(MenuButton);

MenuButton.defaultProps = {
  isActive: false,
  margin: '4px 1px',
  tooltipPlacement: 'right',
};

const TextContainer = styled.div`
  cursor: pointer;

  & span {
    font-size: 16px;
    margin-left: 8px;
  }
`;

const MenuButtonContainer = styled.div<{
  margin: string;
  width: number | string;
}>`
  position: relative;
  margin: ${(p) => p.margin};
  width: ${(p) => p.width};

  border-radius: 4px;
  padding: 2px;
  :hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  }
`;

const IconButton = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 10px;
  filter: grayscale(1) brightness(${(p) => p.theme.menu.inActive.brightness})
    contrast(${(p) => p.theme.menu.inActive.contrast});
  :hover {
    filter: grayscale(0) brightness(${(p) => p.theme.menu.active.brightness})
      contrast(${(p) => p.theme.menu.active.contrast});
  }
  &.active {
    filter: grayscale(0) brightness(${(p) => p.theme.menu.active.brightness})
      contrast(${(p) => p.theme.menu.active.contrast});
  }
`;
