import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { ValueRendererT } from '@stores/fields';
import SingleSelectRenderer from '@components/valueRenderers/SingleSelectRendererV2';
import Editable from '../Editable';
import { SelectChangeEvent } from '@mui/material/Select';

interface DisplayDataFieldProps {
  fieldId: string;
  fieldInfo: object;
  propId: string;
  displayType: string;
  handleChange: (payload: {
    fieldId: string;
    propId: string;
    newValue: string;
  }) => void;
  className?: string;
}

const DisplayDataField = (props: DisplayDataFieldProps) => {
  const { fieldId, fieldInfo, propId, displayType, handleChange } = props;

  const getValue = useCallback(() => {
    const value = _.get(fieldInfo, propId);
    if (_.isUndefined(value) === true) {
      return '';
    }
    if (_.isBoolean(value) === true) {
      return value.toString();
    } else {
      return value;
    }
  }, [fieldId, fieldInfo, propId]);

  const [newValue, setNewValue] = React.useState(getValue());
  const inputRef = React.useRef();

  const handleSubmit = () => {
    handleChange({ fieldId, propId, newValue });
  };

  const handleReset = () => {
    console.log('RESET');
    setNewValue(getValue());
  };

  const _handleChange = (event: SelectChangeEvent<unknown>) => {
    //console.log('DisplayField _handleChange', event.target.value as string);
    handleChange({
      fieldId,
      propId,
      newValue: event.target.value as string,
    });
  };

  const getOptions = React.useCallback((): string[] => {
    if (displayType === 'boolean') {
      return ['true', 'false'];
    }
    switch (propId) {
      case 'value_renderer':
        return Object.values(ValueRendererT);
      case 'table.align':
        return ['left', 'right', 'center'];
      default:
        return [];
    }
  }, [propId, displayType]);

  return (
    <>
      {displayType === 'singleSelect' ? (
        <SingleSelectRenderer
          getValue={getValue}
          getOptions={getOptions}
          onChange={_handleChange}
        />
      ) : displayType === 'boolean' ? (
        <SingleSelectRenderer
          getValue={getValue}
          getOptions={getOptions}
          onChange={_handleChange}
        />
      ) : (
        <Editable
          className={props?.className}
          text={newValue}
          placeholder=''
          childRef={inputRef}
          handleSubmit={() => handleSubmit()}
          handleReset={() => handleReset()}
          type='textarea'
        >
          {propId === 'long_description' ? (
            <StyledTextArea
              ref={inputRef}
              rows={5}
              cols={70}
              name={fieldId}
              value={newValue}
              placeholder={newValue}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
          ) : (
            <StyledInput
              ref={inputRef}
              type='text'
              name={fieldId}
              value={newValue}
              placeholder={newValue}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
          )}
        </Editable>
      )}
    </>
  );
};

export default DisplayDataField;

const StyledInput = styled.input`
  font-size: 12px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.default};
  color: ${(p) => p.theme.palette.textPrimary};
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;

const StyledTextArea = styled.textarea`
  font-size: 12px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.default};
  color: ${(p) => p.theme.palette.textPrimary};
  margin: 0;
  padding: 0;
  width: 100%;
  resize: none;
  white-space: pre-line;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;
