import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';
import NameMolSvgTile from '@components/compounds/images/NameMolSvgTile';
import MagnifiedSvgImage from '@components/compounds/images/MagnifiedSvgImage';
import { FlexRow, FlexItem, FlexSpacer } from '@components/layout/FlexStyles';
import { TextSmallBold } from '@components/elements/TextStyles';
import { colorSchemes } from '@utils/scales/color/ColorSchemes';
import ColorLegend, { ColorMapT } from './ColorLegend';
import { truncateString } from '@utils/misc';
import PinIcon from '@components/icons/pin.icon';

import { hsl } from 'd3-color';
import { scaleLinear, scaleQuantile } from 'd3-scale';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import { checkDuplicatedRows } from '@components/compounds/compounds.utils';
import { StyleContext } from '@theme/AppStyles';
import { CompoundT } from '@src/type';
import { CompoundFieldsT } from '@stores/fields';

const colorScheme = colorSchemes['aseda'].scheme;
const colorMaps: Array<ColorMapT> = [
  {
    highColor: hsl(colorScheme[0]).brighter(0).formatHex(),
    highValue: 10,
    lowColor: hsl(colorScheme[0]).darker(1.5).formatHex(),
    lowValue: 1,
    gradient: 'linear',
  },
  {
    highColor: hsl(colorScheme[2]).brighter(0).formatHex(),
    highValue: 10,
    lowColor: hsl(colorScheme[2]).darker(1.5).formatHex(),
    lowValue: 1,
    gradient: 'linear',
  },
];

const hm_columns: Array<HeatMapColumnsT> = [
  { fieldId: 'zf_mo24', renderer: 'color_map', colorMapIndex: 0, name: 'MO24' },
  { fieldId: 'zf_dp24', renderer: 'color_map', colorMapIndex: 0, name: 'DP24' },
  { fieldId: 'zf_sm24', renderer: 'color_map', colorMapIndex: 0, name: 'SM24' },
  { fieldId: 'zf_mort', renderer: 'color_map', colorMapIndex: 0, name: 'MORT' },
  { fieldId: 'zf_cran', renderer: 'color_map', colorMapIndex: 0, name: 'CRAN' },
  { fieldId: 'zf_axis', renderer: 'color_map', colorMapIndex: 0, name: 'AXIS' },
  { fieldId: 'zf_edem', renderer: 'color_map', colorMapIndex: 0, name: 'EDEM' },
  { fieldId: 'zf_musc', renderer: 'color_map', colorMapIndex: 0, name: 'MUSC' },
  { fieldId: 'zf_ltrk', renderer: 'color_map', colorMapIndex: 0, name: 'LTRK' },
  { fieldId: 'zf_brn', renderer: 'color_map', colorMapIndex: 0, name: 'BRN' },
  { fieldId: 'zf_skin', renderer: 'color_map', colorMapIndex: 0, name: 'SKIN' },
  { fieldId: 'zf_nc', renderer: 'color_map', colorMapIndex: 0, name: 'NC' },
  { fieldId: 'zf_tchr', renderer: 'color_map', colorMapIndex: 0, name: 'TCHR' },
  {
    fieldId: 'zf_any_effect',
    renderer: 'color_map',
    colorMapIndex: 0,
    name: 'ANY',
  },
  { fieldId: 'zf_epr', renderer: 'color_map', colorMapIndex: 1, name: 'EPR' },
  {
    fieldId: 'zf_lpr_l',
    renderer: 'color_map',
    colorMapIndex: 1,
    name: 'LPR_L',
  },
  {
    fieldId: 'zf_lpr_d',
    renderer: 'color_map',
    colorMapIndex: 1,
    name: 'LPR_D',
  },
];

interface ColumnsT {
  fieldId: string;
  renderer: string;
  name: string;
}

interface HeatMapColumnsT extends ColumnsT {
  colorMapIndex: number;
}

interface CompoundHeatMapProps {
  data: CompoundT[];
  allFields: CompoundFieldsT;
  scroll?: boolean;
  xScroll?: boolean;
  handleEndReached?: () => void;
  pinnedData?: CompoundT[];
  nameWidth?: number;
  cellSize?: number;
  loading?: boolean;
  loadingMore?: boolean;
  height?: string;
  width?: string;
}

const domain = [0.1, 10]; // -log(uM) units [ 0.5 (0.3) 100.0 (2) ]

const linear = [
  scaleLinear<string>()
    .domain([0, 1])
    .range([colorMaps[0].highColor, colorMaps[0].lowColor]),
  scaleLinear<string>()
    .domain([0, 1])
    .range([colorMaps[1].highColor, colorMaps[1].lowColor]),
];

const scale = [
  scaleQuantile<string>()
    .domain(domain)
    .range([
      linear[0](0),
      linear[0](0.25),
      linear[0](0.5),
      linear[0](0.75),
      linear[0](1.0),
    ]),
  scaleQuantile<string>()
    .domain(domain)
    .range([
      linear[1](0),
      linear[1](0.25),
      linear[1](0.5),
      linear[1](0.75),
      linear[1](1.0),
    ]),
];

const debug = false;

const CompoundHeatMap = ({
  data,
  pinnedData,
  allFields,
  nameWidth = 250,
  cellSize = 40,
  handleEndReached,
  scroll = true,
  xScroll = true,
  loading,
  loadingMore,
  height = '100%',
  width = '100%',
}: CompoundHeatMapProps) => {
  const { height: windowHeight } = useWindowDimensions();
  const [hoveredRow, setHoveredRow] = useState<string>(null);
  const [requestedMore, setRequestedMore] = useState<boolean>(false);
  const [style] = useContext(StyleContext);
  if (debug) console.log('CompoundHeatMap | data', data);

  const notPinnedRows = useMemo(() => {
    return data.filter(
      (obj) =>
        !pinnedData.some((pinned) => pinned?.searchKey === obj?.searchKey)
    );
  }, [pinnedData, data]);

  const pinnedRows = useMemo(() => {
    return data.filter((obj) =>
      pinnedData.some((pinned) => pinned?.searchKey === obj?.searchKey)
    );
  }, [pinnedData, data]);

  const scrollHeight = windowHeight - 199 - pinnedRows.length * (cellSize + 1);

  const scrollBoxRef = useRef(null);

  const handleScroll = () => {
    const scrollBox = scrollBoxRef.current;
    if (
      scrollBox &&
      scrollBox.scrollHeight - scrollBox.scrollTop === scrollBox.clientHeight
    ) {
      // Load more data here
      console.log('Reached the bottom! Load more data...');
      if (!loading && !loadingMore && !requestedMore) {
        handleEndReached();
        setRequestedMore(true);
        setTimeout(() => {
          setRequestedMore(false);
        }, 2000); // Adjust the delay time (in milliseconds) as needed
      }
    }
  };

  useEffect(() => {
    if (scroll) {
      const scrollBox = scrollBoxRef.current;
      scrollBox.addEventListener('scroll', handleScroll);

      return () => {
        if (scrollBox) {
          scrollBox.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    setRequestedMore(false);
  }, [data, loading, loadingMore]);

  const defaultColors = {
    NA: style.name === 'dark' ? '#666666' : '#BBBBBB',
    NT: style.name === 'dark' ? '' : '',
  };
  // values to assist with layout
  const titleFontSize = cellSize > 30 ? 14 : 12;
  const cellFontSize = cellSize > 30 ? 14 : 10;

  const renderHeader = () => {
    return (
      <HeaderRow size={cellSize}>
        {<HeaderNameCell size={200} width={nameWidth}></HeaderNameCell>}
        {hm_columns.map((c, index) => {
          const xShift = cellSize <= 25 ? 6 : 1.7 * titleFontSize;
          const yShift =
            (0.2 * index * cellSize) / 150 + 4 * (4 - c?.name.length);
          return (
            <HeaderHeatMapCell
              key={c.name}
              size={cellSize}
              color={colorMaps[c.colorMapIndex].highColor}
            >
              <RotatedText
                numChar={c?.name.length}
                fontSize={titleFontSize}
                xShift={-xShift}
                yShift={yShift}
              >
                {c?.name}
              </RotatedText>
            </HeaderHeatMapCell>
          );
        })}
      </HeaderRow>
    );
  };

  const renderNameCell = (row) => {
    const truncateStringLength = Math.floor(nameWidth / 10);
    const name = truncateString(
      _.get(row, allFields['compound_name']?.data_key, 'unknown'),
      truncateStringLength
    );
    const mol_svg = _.get(row, 'mol_svg', '');
    return (
      <NameMolSvgTile name={name} mol_svg={mol_svg} width={200} hover={true} />
    );
  };

  const renderHeatMapCell = (row, col, highlight, index) => {
    let value = _.get(row, allFields[col?.fieldId]?.data_key, '');
    let color = '';
    let cellClassName = '';
    if (typeof value === 'number') {
      color = scale[col.colorMapIndex](value);
      value = value.toFixed(1);
    } else {
      if (Object.hasOwn(defaultColors, value)) {
        color = defaultColors[value];
      }
    }
    // handle the row highlighted/downlighted
    if (highlight) {
      cellClassName = 'highlighted';
      color = hsl(color).brighter(1.2).toString();
    } else {
      if (hoveredRow) {
        color = hsl(color).darker(1.1).toString();
        cellClassName = 'downlighted';
      }
    }
    return (
      <HeatMapCell
        key={value + index + row}
        fontSize={cellFontSize}
        size={cellSize}
        color={color}
        className={cellClassName}
      >
        {value}
      </HeatMapCell>
    );
  };

  const renderRow = (row, isPinned) => {
    const highlight = hoveredRow === row.searchKey ? 'highlighted' : '';
    //console.log(row.searchKey);
    return (
      <HeatMapRow
        key={row.searchKey}
        size={cellSize}
        className={highlight}
        onMouseEnter={() => setHoveredRow(row.searchKey)}
        onMouseLeave={() => setHoveredRow(null)}
      >
        {
          <NameCell
            fontSize={titleFontSize}
            className={highlight}
            width={nameWidth}
          >
            {renderNameCell(row)}-{isPinned && <PinIcon />}
          </NameCell>
        }
        {hm_columns.map((c, index) => {
          return renderHeatMapCell(row, c, highlight, index);
        })}
      </HeatMapRow>
    );
  };

  useEffect(() => {
    checkDuplicatedRows(data);
  }, [data]);

  return (
    <HeatmapContainer height={height} width={width}>
      <FlexRow h_centered v_centered>
        <FlexSpacer width={150} />
        <FlexItem>
          <TextSmallBold color={colorMaps[0].highColor}>
            BMD10 (uM)
          </TextSmallBold>
        </FlexItem>
        <FlexItem>
          <ColorLegend
            title={''}
            width={100}
            showLabels={false}
            colorMap={colorMaps[0]}
          />
        </FlexItem>
        <FlexItem>
          <TextSmallBold color={colorMaps[1].highColor}>LEL (uM)</TextSmallBold>
        </FlexItem>
        <FlexItem>
          <ColorLegend
            title={''}
            width={100}
            showLabels={false}
            colorMap={colorMaps[1]}
          />
        </FlexItem>
      </FlexRow>
      <FlexItem>
        <Table>
          {renderHeader()}
          {pinnedRows.map((r) => renderRow(r, true))}
          {scroll ? (
            <ScrollBox
              ref={scrollBoxRef}
              height={scrollHeight}
              yScroll={scroll}
              xScroll={xScroll}
            >
              {notPinnedRows.map((r) => renderRow(r, false))}
            </ScrollBox>
          ) : (
            <>{notPinnedRows.map((r) => renderRow(r, false))}</>
          )}
        </Table>
      </FlexItem>
      <MagnifiedSvgImage imageOffset={10} size={200} />
    </HeatmapContainer>
  );
};

export default CompoundHeatMap;

const HeatmapContainer = styled.div<{ height?: string; width?: string }>`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  overflow-y: hidden;
  overflow-x: hidden;
`;

const Table = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
`;

const ScrollBox = styled.div<{
  height: number;
  xScroll: boolean;
  yScroll: boolean;
}>`
  height: ${(p) => p.height}px;
  overflow-y: ${(p) => (p.xScroll ? 'auto' : 'hidden')};
  overflow-x: ${(p) => (p.xScroll ? 'auto' : 'hidden')};
`;

const HeaderRow = styled.div<{ size?: number }>`
  display: flex;
  height: ${(p) => 1.5 * p.size}px;
  align-items: flex-end;
  flex-direction: row;
  align-content: baseline;
  border-bottom: 2px solid ${(p) => p.theme.palette.backgroundPrimary};
`;

const HeatMapRow = styled.div<{ size?: number }>`
  display: flex;
  flex-direction: row;
  max-height: ${(p) => p.size}px;
  margin-top: 1px;
  &.highlighted {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
  }
`;

const NameCell = styled.div<{
  fontSize?: number;
  size?: number;
  width: number;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: ${(p) => p.size}px;
  width: ${(p) => p.width}px;
  font-size: ${(p) => p.fontSize}px;
`;

const HeaderNameCell = styled(NameCell)`
  text-align: right;
  justify-content: right;
  align-content: center;
`;

const HeaderHeatMapCell = styled.div<{ size?: number; color?: string }>`
  display: flex;
  height: ${(p) => 1.5 * p.size}px;
  width: ${(p) => p.size}px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 1px;
  background-color: ${(p) => p.color};
`;

const RotatedText = styled.div<{
  fontSize: number;
  numChar?: number;
  xShift?: number;
  yShift?: number;
}>`
  height: max-content;
  width: max-content;
  transform-origin: center;
  transform: rotate(-90deg) translateX(${(p) => p.xShift}px)
    translateY(${(p) => p.yShift}px);
  font-size: ${(p) => p.fontSize}px;
  color: ${(p) => p.theme.palette.textPrimary};
`;
RotatedText.defaultProps = {
  xShift: 0,
  yShift: 0,
  numChar: 2,
  fontSize: 12,
};

const HeatMapCell = styled.div<{
  fontSize?: number;
  size?: number;
  color?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.fontSize}px;
  background-color: ${(p) => p.color};
  margin-left: 1px;
  color: ${(p) => p.theme.palette.textSecondary};
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  max-height: ${(p) => p.size}px;
  max-width: ${(p) => p.size}px;
  &.highlighted {
    color: ${(p) => p.theme.palette.textPrimary};
  }
  &.downlighted {
    color: ${(p) => p.theme.palette.textSecondary};
  }
`;
HeatMapCell.defaultProps = {
  size: 50,
  color: '#666666',
  fontSize: 10,
};
