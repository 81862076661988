import React, {ReactNode, useEffect, useState} from "react";
import styled from 'styled-components/macro';
import BasicTable from "@as_core/tables/BasicTable";
import useRequestStatus, {RequestStatusT, StatusTableFields} from "../hooks/useRequestStatus";
import {RequestT} from "../hooks/useRequests";

interface PropsI {
  view?: string;
  request?: RequestT;
  status: RequestStatusT;
  showBorder?: boolean;
}

const debug = false;
const ShowRequestStatus = (props: PropsI) => {
  const {view='user', request, status, showBorder = false} = props;
  const {getStatusToTableRows} = useRequestStatus();
  const [statusRows, setStatusRows] = useState<{[key:string]: string | number | ReactNode }[]>([]);
  if (debug) console.log('ShowRequestStatus | view: ', view, ' status:', status, 'request', request);

  const updateStates = () => {
    if (status === null) {
      setStatusRows([]);
    } else {
      const rows = getStatusToTableRows(status);
      if (debug) console.log('ShowRequestStatus | useEffect | rows:', rows);
      setStatusRows(rows);
    }
  }

  useEffect(()=>{
    updateStates();
  },[]);

  useEffect(()=>{
    updateStates();
  },[status]);

  return (
    <ShowRequestStatusContainer border={showBorder}>
      { status === null ? null :
        <BasicTable fields={StatusTableFields} rows={statusRows} />
      }
    </ShowRequestStatusContainer>
  );
};

export default ShowRequestStatus;

const ShowRequestStatusContainer = styled.div<{border: boolean}>`
  display: flex;
  height: max-content;
  width: max-content;
  padding: ${(p) => p.border ? '10px' : 0 }
  border-radius: 15px;
  border: ${(p) => p.border ? '1px' : 0 } solid ${(p) => p.theme.palette.accentSecondary};
`;
