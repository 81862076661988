import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '@mui/material';
import useFileImport from '../../useFileImport';
import { Cell, Row } from '../RowElements';
import CheckBox from '@components/controls/CheckBox';
import { useDispatch } from 'react-redux';
import { setChildChecked } from '@stores/FileImportContext';


const UnMatchedColumnRow = ({ columnIdx, onSubmit, automaticMatch = "", onMatchClick, isChecked = false }: {
  columnIdx: number;
  onSubmit: (idx: number) => void;
  automaticMatch?: string;
  onMatchClick?: (idx: number, match: string) => void;
  isChecked?: boolean;
}) => {
  const { context: { importer, columns, activeStep } } = useFileImport();
  const dispatch = useDispatch();

  let label = "Import Manually";

  if (importer === 'compound_set' && activeStep === 1) {
    label = 'Use as Identity';
  }
  if (importer === 'target_panel') {
    label = 'Import as (Unselected => Target Assay)';
  }

  const matchLabel = automaticMatch === "no automatic match found" ? label : automaticMatch;

  const handleCheckBoxChange = (value: boolean) => {
    dispatch(setChildChecked({ index: columnIdx, checked: value }));
  };

  return (
    <Row key={columnIdx}>
      {/* <StyledCell parent={"compoundSet"}>
        <CheckBox
          selected={isChecked}
          onChange={handleCheckBoxChange}
          disabled={false}
        />
      </StyledCell> */}
      <Cell parent={"compoundSet"}>{columns[columnIdx].colName}</Cell>
      <Cell parent={"compoundSet"}>
        <StyledButtonMatch onClick={() => onMatchClick(columnIdx, automaticMatch)}>
          {automaticMatch}
        </StyledButtonMatch>
      </Cell>
      <Cell parent={"compoundSet"}>
        <StyledButton
          onClick={() => {
            onSubmit(columnIdx);
          }}
        >
          {columns[columnIdx].field_id ? matchLabel : label}
        </StyledButton>
      </Cell>
    </Row>
  );
};

export default UnMatchedColumnRow;

const StyledButton = styled(Button)`
  height: 40px;
  color: white;
  background: ${(p) => p.theme.palette.backgroundTertiary};
`;

const StyledButtonMatch = styled.button`
  background: transparent;
  width: 150px !important;
  height: 40px;
  color: ${(p) => p.theme.palette.accentPrimary};
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};;
  border-radius: 5px;
  padding: 10px 15px;

  text-align: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${(p) => p.theme.palette.accentPrimary};;
    color: #fff;
  }
`;

const StyledCell = styled(Cell)`
  cursor: pointer;
`;
