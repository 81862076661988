import { canvasScaleFactor } from "../config/colors";

export const initCanvas = (canvas, width, height) => {
  canvas.width = canvasScaleFactor * width;
  canvas.height = canvasScaleFactor * height;

  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
};



