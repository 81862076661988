import React, { useContext } from 'react';
import { PlotContext } from '../plot.context';
import { PlotConfig, VolcanoBase } from '@plotting/single-plot-view/plot.types';
import { VolcanoPlot } from '@dataviz/volcanoplot/VolcanoPlot';
import { Theme } from '@nivo/core';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';

type VolcanoplotWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<VolcanoBase>;
  isSmallChart?: boolean;
};

export const VolcanoplotWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: VolcanoplotWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<VolcanoBase> }).plot;

  const data = [
    {
      id: 'first-series',
      data: plot.data.map((d) => {
        return {
          x: d[plot.config.foldChangeColumn],
          y: -Math.log10(d[plot.config.pValueColumn]),
          geneName: d['Gene name'],
        };
      }),
      // .slice(1, 1000),
    },
  ];

  return (
    <VolcanoPlot
      data={data}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      datavizTheme={datavizTheme}
      pValueThreshold={plot.config.pValueThreshold}
      foldChangeThreshold={plot.config.foldChangeThreshold}
      margin={isSmallChart ? DEFAULT_SMALL_GRAPH_MARGIN : undefined}
    />
  );
};
