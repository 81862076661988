import React, { createContext, useEffect, useState } from 'react';
import {
  createGlobalStyle,
  ThemeProvider,
  DefaultTheme,
} from 'styled-components/macro';
import reset from 'styled-reset';

import darkTheme from './dark/Theme';
import lightTheme from './light/Theme';
import { MuiStyles } from '@theme/MuiStyles';
import {PaletteT} from "@theme/theme.type";

export type PlotsColorT = {
  [key: string]: {
    name: string;
    scheme: string[];
    type: string;
    unique: number;
  }
}

export type StyleT = {
  name: string;
  palette: PaletteT;
  fonts: { [key: string] : string};
  sizes: { [key: string] : string};
  weights: { [key: string] : number};
  borders: { [key: string] : string};
  zIndex: { [key: string] : number};
  dimensions: { [key: string] : number | string};
  borderRadius: number;
  menuRadius: number;
  plots: PlotsColorT;
  menu: { [key: string]: {brightness: number, contrast: number}};
  renderers: { [key:string]: string};
}

interface IThemeStyles {
  theme?: DefaultTheme;
}

export const THEME = {
  LIGHT: lightTheme,
  DARK: darkTheme,
  DEFAULT: darkTheme,
};
export const StyleContext = createContext(null);

export const StyleProvider = ({
  theme,
  children,
}: React.PropsWithChildren<IThemeStyles>) => {
  const [activeTheme, setActiveTheme] = useState(theme || THEME.DEFAULT);

  useEffect(() => {
    setActiveTheme(theme || THEME.DEFAULT);
  }, [theme]);

  return (
    <StyleContext.Provider value={[activeTheme, setActiveTheme]}>
      <MuiStyles theme={activeTheme}>
        <ThemeProvider theme={activeTheme}>
          <>
            <GlobalStyle />
            {children}
          </>
        </ThemeProvider>
      </MuiStyles>
    </StyleContext.Provider>
  );
};

const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
  background: ${(p) => p.theme.palette.backgroundPrimary};
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.weights.default};
  font-size: ${(props) => props.theme.sizes.default};
  line-height: ${(props) => props.theme.sizes.large};
  color: ${(props) => props.theme.palette.textPrimary};
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.textPrimary};
  }

  ul {
    list-style: none;
  }

  & .MuiPaper-root {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
    color: ${(props) => props.theme.palette.textPrimary};
  }

  & .MuiInputBase-input {
  font-family: ${(props) => props.theme.fonts.main};
  font-weight: ${(props) => props.theme.weights.light};
  font-size: ${(props) => props.theme.sizes.small};
  line-height: ${(props) => props.theme.sizes.large};
  color: ${(props) => props.theme.palette.textPrimary};
  }
  & .MuiListItem-root.Mui-selected,
  & .MuiListItem-root.Mui-selected:hover {
    background-color: ${(props) => props.theme.palette.backgroundQuaternary};
  }
  & .MuiButtonBase-root.MuiCheckbox-root {
    color: ${(props) => props.theme.palette.textPrimary};
    opacity: 0.25;
  }
  & .MuiButtonBase-root.MuiCheckbox-root:hover {
    color: ${(props) => props.theme.palette.accentPrimary};
    opacity: 1.0;
  }
  & .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    opacity: 1.0;
    color: ${(props) => props.theme.palette.accentPrimary};
  }
  & .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate {
    opacity: 1.0;
    color: ${(props) => props.theme.palette.accentPrimary};
  }
`;
