import React from 'react';
import CellHealthIcon from "@components/icons/cellhealth.icon";
import ZebrafishIcon from "@components/icons/zebrafish.icon";
import BiomimeticsIcon from "@components/icons/biomimetics.icon";

export const showCodes = ['AS-SYS', 'AS-ZBE', 'AS-BMVK', 'AS-CQC-SST'];

const categoryIcons = {
  'AS-SYS': <CellHealthIcon size={48}/>,
  'AS-ZBE': <ZebrafishIcon size={48}/>,
  'AS-BMVK': <BiomimeticsIcon size={48}/>
}

export function getIcon(code: string) {
  return Object.hasOwn(categoryIcons, code) ? categoryIcons[code] : null;
}
