import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { APP_CONFIG } from '@app_config/app';
import { UserContext } from '@stores/UserContext';
import { Row, StyledA, StyledForm } from './FormStyles';
import UserAccountPolicies from '../fields/UserAccountPolicies';
import { isValidEmail, getPasswordStrength } from '../utils';
import useCognito from '@as_core/account/useCognito';
import { useNavigate } from 'react-router-dom';
import Button from '@as_core/account/fields/Button';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import AppDialogHeader from '@as_core/account/components/AppDialogHeader';

import InputNewPassword from '@as_core/account/fields/InputNewPassword';
import InputField from '@as_core/components/inputs/InputField';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

// note -- handle all messages internally until everything is done so that a new component does not get
// created -- and maintain user information

type ValidFormPartsT = {
  isEmailValid: boolean;
  isPasswordValid: boolean;
};

interface SignUpI {
  setMessage?: (m: string) => void;
}

const debug = false;
const SignUp = (props: SignUpI) => {
  const [validFormParts, setValidFormParts] = useState<ValidFormPartsT>({
    isEmailValid: false,
    isPasswordValid: false,
  });
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { createAccount, login, getAuthRoles } = useCognito();

  // capture the user input to update validation state as needed
  const handleBlur = () => {
    if (debug) console.log('handleBlur');
  };

  // set appropriate fields in the userData object
  const handleChange = (key: string, value: string) => {
    if (debug) console.log('handleChange | key', key, 'value', value);
    switch (key) {
      case 'email':
        setUserEmail(value);
        setValidFormParts((prev) => ({
          ...prev,
          isEmailValid: isValidEmail(value),
        }));
        if (isValidEmail(value)) {
          setErrorMessage('');
        } else {
          setErrorMessage('Not valid email format');
        }
        break;
      case 'password': {
        setUserPassword(value);
        const passwordStrength = getPasswordStrength(value);
        setValidFormParts((prev) => ({
          ...prev,
          isPasswordValid: passwordStrength.strength === 5,
        }));
        if (passwordStrength.strength < 5) {
          setErrorMessage(passwordStrength.reason);
        } else {
          setErrorMessage('');
        }
      }
    }
  };

  const handleSubmit = () => {
    if (!validFormParts.isEmailValid) {
      setErrorMessage('Not valid email format');
    } else if (!validFormParts.isPasswordValid) {
      setErrorMessage('Password fails requirements');
    } else {
      setCreatingAccount(true);
      if (props?.setMessage) props.setMessage('Creating User Account');
      createAccount(userEmail, userPassword)
        .then(
          // successfully created the user cognito account -- now they need to confirm email
          (result) => {
            if (debug) console.log('createAccount: result', result);
            setCreatingAccount(false);
            setUser((prev) => ({
              ...prev,
              isAuthenticated: true,
              isEmailVerified: result.userConfirmed,
              authId: result.userSub,
              authEmail: userEmail,
            }));
            // Handle both workflows -- either email auto-verified or not
            if (result.userConfirmed) {
              if (props?.setMessage) props.setMessage('Authenticating User');
              login(userEmail, userPassword)
                .then((result: CognitoUserSession) => {
                  const userId = result.getIdToken().payload;
                  setUser((prev) => ({
                    ...prev,
                    isAuthenticated: true,
                    isEmailVerified: true,
                    authId: userId['cognito:username'],
                    authEmail: userId.email,
                    authRoles: getAuthRoles(result),
                    authSession: result,
                  }));
                  if (props?.setMessage) props.setMessage('');
                  navigate('/user/register');
                })
                .catch((error) => {
                  if (props?.setMessage) props.setMessage('');
                  console.error('Error authenticating: ', error);
                  if (error === 'Incorrect username or password.') {
                    setErrorMessage(
                      'Incorrect email/password or user needs to create an account'
                    );
                  }
                });
            } else {
              if (props?.setMessage) props.setMessage('');
              navigate(`/user/verify-email?email=${userEmail}`);
            }
          }
        )
        .catch((error) => {
          setCreatingAccount(false);
          console.error('CognitoSignupException: ' + error);
          const cleanError = error.replace(
            'UsernameExistsException: ',
            'Error: '
          );
          setErrorMessage(cleanError);
        });
    }
  };
  if (debug) console.log('validFormParts', validFormParts);

  return (
    <Container key={'user-sign-up'}>
      {creatingAccount ? (
        <StatusMessage>Creating User Account</StatusMessage>
      ) : (
        <>
          <AppDialogHeader
            message={'Create a User Account'}
            tooltip={APP_CONFIG.createAccountInfo}
          />
          <Row height={'24px'} align={'center'}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
          </Row>
          <StyledForm key={'user-create-account-form'}>
            <InputField
              key={'new-user-email'}
              label={'User Account Email'}
              value={userEmail}
              autoComplete={'email'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange('email', e.target.value)
              }
              onBlur={handleBlur}
              error={false}
              type={'string'}
              size={'small'}
            />
            <InputNewPassword
              id={'new-user-password'}
              label={'User Account Password'}
              value={userPassword}
              error={false}
              onChange={(v: string) => handleChange('password', v)}
              handleBlur={handleBlur}
            />
            <UserAccountPolicies />
            <Button
              type={'submit'}
              label={'Create Account'}
              onClick={handleSubmit}
              disabled={
                !(validFormParts.isEmailValid && validFormParts.isPasswordValid)
              }
            />
            <StyledA
              key={'return'}
              href={'/user/login'}
              size={'12px'}
              topMargin={'10px'}
            >
              return to sign-in
            </StyledA>
          </StyledForm>
        </>
      )}
    </Container>
  );
};

export default SignUp;

export const Container = styled.div`
  width: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
  position: relative;
`;

export const StatusMessage = styled.div`
  display: flex;
  padding: 50px;
  font-size: 24px;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  border-radius: 30px;
`;
