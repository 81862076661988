import React from 'react';
import { Modal, Paper, Typography, Button, Box } from '@mui/material';


interface ErrorModalProps {
    message: string;
    onClose: () => void;
    open: boolean; // Add the "open" prop
  }
  
const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px',
  width: '300px',
  display: 'flex',
  flexDirection: 'column', // Stack children vertically
  alignItems: 'center', // Center horizontally
};

const titleStyles = {
  fontSize: '24px',
  marginBottom: '16px',
};

const bodyStyles = {
  fontSize: '16px',
  marginBottom: '16px',
};

const buttonStyles = {
  marginTop: 'auto', // Push the button to the bottom of the modal
};

const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose }) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Paper sx={modalStyles}>
        <Typography variant="h5" style={titleStyles}>Error</Typography>
        <Typography variant="body1" style={bodyStyles}>{message}</Typography>
        <Box sx={buttonStyles}>
          <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ErrorModal;
