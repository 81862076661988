import { useState } from 'react';

// Define the type of the object for clarity. Adjust it based on your needs.
type ObjectType = string[];

// The hook's return type
export type UseObjectArrayReturnType = {
  data: ObjectType;
  add: (id: string) => void;
  addFront: (id: string) => void;
  addList: (ids: string[]) => void;
  delete: (id: string) => void;
  deleteList: (ids: string[]) => void;
  reset: () => void;
};

const useObjectArray = (initialData: ObjectType = []): UseObjectArrayReturnType => {
  const [data, setData] = useState<ObjectType>(initialData);

  const add = (id: string) => {
    setData(prevData => [...prevData, id]);
  };

  const addFront = (id: string) => {
    setData(prevData => [id, ...prevData]);
  };

  const addList = (ids: string[]) => {
    const uniqSet = new Set([...data, ...ids]);
    setData([...uniqSet]);
  };

  const deleteItem = (id: string) => {
    setData(prevData =>
      prevData.filter((v) => v !== id));
  };

  const deleteList = (ids: string[]) => {
    setData(prevData =>
      prevData.filter((v) => !ids.includes(v)));
  };

  const reset = () => {
    setData([]);
  };

  return { data, add, addFront, addList, delete: deleteItem, deleteList, reset };
};

export default useObjectArray;
