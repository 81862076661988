import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import {UseObjectArrayReturnType} from "@as_core/hooks/useObjectArray";
import { FlexRow, } from '@components/layout/FlexStyles';
import {labelColors} from "../config/universe";
import {AesSettingsT, GraphSettingsT, VertexT} from "../types";
import {getVerticesByIds} from "../utils/vertex";
import {convertArrayToObject} from "../utils/vector";
import PropertiesPanel from './PropertiesPanel';
import LabeledVertex from "@components/universe/elements/LabeledVertex";

const BANNER_HEIGHT = 120;

interface PropsI {
  vertices: VertexT[],
  settings: GraphSettingsT,
  resetSelected: () => void,
  pinnedCompounds: UseObjectArrayReturnType,
  aesSettings: {[key: string]: AesSettingsT}
}


const DetailSelectedPinned = (props:PropsI) => {
  const { vertices, settings, resetSelected, pinnedCompounds, aesSettings } = props;
  const { height: windowHeight } = useWindowDimensions();
  const [propertiesPanelHeight, setPropertiesPanelHeight] = useState(
    windowHeight - BANNER_HEIGHT - 260
  );
  const pinnedCompoundsPanelHeight = Math.min(pinnedCompounds.data.length * 280, windowHeight - 110);

  function getHoveredVertexData() {
    if (settings?.hoveredVertex) {
      return settings.hoveredVertex.data;
    }
    if (pinnedCompounds?.data.length) {
      const vertex = getVertexFromId(pinnedCompounds?.data[0]);
      return vertex?.data;
    }
    return null;
  }

  function getVertexFromId(id: string) {
    return vertices.find((v) => id === v.id);
  }

  function getPinnedVertices() {
    const verticesById = convertArrayToObject(getVerticesByIds(pinnedCompounds.data, vertices), 'id');
    return pinnedCompounds.data.map((id, i)=>(
      {v: verticesById[id], color: labelColors['pinned'][i]}
    ));
  }

  const handlePinSelect = () => {
    resetSelected();
  }

  useEffect(() => {
    setPropertiesPanelHeight(windowHeight - BANNER_HEIGHT - 260);
  }, [windowHeight]);

  return (
    <FlexRow>
      { settings?.selectedVertex ?
      <SelectedDetailsContainer>
        <LabeledVertex
          onPinSelect={handlePinSelect}
          labelColor={labelColors['selected']}
          magnify={6}
          vertex={settings.selectedVertex}
          radius={3}
          vertexAesSettings={aesSettings[settings.selectedVertex.id]}
          secondaryColor={settings?.hoveredVertex ? labelColors['hovered'] : labelColors['pinned'][0]}
          secondaryFields={getHoveredVertexData()}
          settings={settings}
          selected={true}
          inGraph={false}
          isNeighbor={true}
        />
        <PropertiesContainer>
          <PropertiesPanel
            vertex={settings?.selectedVertex}
            hoveredVertex={settings?.hoveredVertex}
            pinnedVertices={getPinnedVertices()}
            distributions={settings?.densities}
            height={propertiesPanelHeight}
          />
        </PropertiesContainer>
      </SelectedDetailsContainer>
        : null }
      { pinnedCompounds.data.length ?
        <PinnedVerticesContainer height={pinnedCompoundsPanelHeight}>
          <PinnedVerticesScroll>
            { pinnedCompounds.data.map((id: string, i: number) => {
              const pinnedVertex = getVertexFromId(id);
              return(
                <LabeledVertex
                  key={`labeled_vertex_${i}`}
                  magnify={3}
                  highlight={false}
                  labelColor={labelColors['pinned'][i]}
                  onPinSelect={()=>pinnedCompounds.delete(id)}
                  vertex={pinnedVertex}
                  radius={6}
                  vertexAesSettings={aesSettings[id]}
                  settings={settings}
                  selected={true}
                  inGraph={false}
                />
              );
            })}
          </PinnedVerticesScroll>
        </PinnedVerticesContainer>
        : null }
    </FlexRow>
  )
}

export default DetailSelectedPinned;

const SelectedDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 2px;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0;
  color: ${(p) => p.theme.palette.titlePrimary};
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const PinnedVerticesContainer = styled(SelectedDetailsContainer)<{height: number}>`
  height: ${(p) => p.height}px;
  overflow-y: auto;
`;

const PinnedVerticesScroll = styled.div`
  height: max-content;
`;
