import React from 'react';
import { Cell, Row } from '../shared/RowElements';

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';

interface DoseResponsePair {
  dose: number;
  response: number;
}

// Define the specific structure for the expected data
interface DoseResponseData {
  compound_id: string;
  sample_id: string;
  data: DoseResponsePair[];
}

// Define the structure for more generic data
interface GenericRecord {
  [key: string]: string | number | boolean | object | undefined;
}

// Main interface that combines both types
interface ImportDoseResponseReviewRowProps {
  data: DoseResponseData | GenericRecord;
  dataIdx: number;
}

const ImportDoseResponseReviewRow = (props: ImportDoseResponseReviewRowProps) => {
  const { data, dataIdx } = props;

  // Type guard to check if `data` is DoseResponseData
  function isDoseResponseData(data: DoseResponseData | GenericRecord): data is DoseResponseData {
    return (
      typeof data === 'object' &&
      'compound_id' in data &&
      'sample_id' in data &&
      Array.isArray(data.data)
    );
  }

  // If the data matches the DoseResponseData structure
  if (isDoseResponseData(data)) {
    const preview = data.data
      .slice(0, 4) // Take up to 4 pairs
      .map((pair) => `(${pair.dose.toFixed(2)}, ${pair.response})`)
      .join(', ');

    if (debug) console.log('ImportDoseResponseReviewRow | preview:', preview);

    return (
      <Row key={dataIdx}>
        <Cell>{data.compound_id}</Cell>
        <Cell>{data.sample_id}</Cell>
        <Cell>{data.data.length}</Cell>
        <Cell>{preview}</Cell>
      </Row>
    );
  } else {
    // Handle case where `data` is a generic record
    return (
      <Row key={dataIdx}>
        <Cell>Unknown Compound</Cell>
        <Cell>Unknown Sample</Cell>
        <Cell>N/A</Cell>
        <Cell>No preview available</Cell>
      </Row>
    );
  }
};

export default ImportDoseResponseReviewRow;

