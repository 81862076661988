import React, {useEffect, useState, useContext} from 'react';
import styled from 'styled-components/macro';
import BasicTable from "@as_core/tables/BasicTable";
import IconButton from "@ui-components/controls/IconButton";
import {Cancel, Check} from "@mui/icons-material";
import {AccountDetailContainer, HorizontalLine, Title, Center} from "../../shared/flexStyles";
import {UserContext} from "@stores/UserContext";
import {AccessLabels, AccessRepositoryT, InvitedUserT} from "../useRepos";
import useRepos from "@subApps/account/repositoryManagement/useRepos";
import {getObjectValueByKey} from "@utils/misc";
import {RepositoryDetailsT} from "../useRepos";

const columns = [
  {label:'Email', value: 'authEmail', type: 'string', width: 300},
  {label:'Joined', value: 'userAdded', type: 'date',  width: 120},
  {label:'Access Type', value: 'accessType', type: 'access',  width: 120},
  {label:'', value: 'remove', type: 'action', width: 40}
];

function getTableRows(invitedUsers: InvitedUserT[], handleRemoveUser: (id: string) => void) {
  const rows = [];
  invitedUsers.forEach((user)=>{
    const row = {};
    columns.forEach((c) => {
      if (c.type === 'date') {
        const value = getObjectValueByKey(user, c.value, '');
        if (value === '') return value;
        row[c.value] = new Date(value).toLocaleDateString();
      } else if (c.type === 'sharing') {
          row[c.value] = <Check />;
      } else if (c.type === 'access') {
        const value: AccessRepositoryT = getObjectValueByKey(user, c.value, 'readOnly');
        row[c.value] = AccessLabels[value];
      } else if (c.type === 'action') {
        if (c.value === 'remove') {
          row[c.value] =
            <IconButton
              tooltip={'Remove from Repository'}
              icon={<Cancel />}
              onClick={()=>handleRemoveUser(user.authId)}
            />
        }
      } else {
        row[c.value] = getObjectValueByKey(user, c.value, '');
      }
    })
    rows.push(row);
  })
  return rows;
}

type PropsT = {
  repo: RepositoryDetailsT;
  usersUpdated: (repoId:string, changeType: string)=>void;
}

const debug = false;
const RepoUsers = (props: PropsT) => {
  const { repo } = props;
  const { user } = useContext(UserContext);
  const { deleteRepositoryUser } = useRepos();
  const [rows, setRows] = useState([]);
  const invitedUsers = repo?.invitedUsers ? repo.invitedUsers : [];
  if (debug) console.log('RepositoryUsers | repo', repo, invitedUsers, invitedUsers);
  const isOwner = user.authId === repo.ownerAuthId;
  if (debug) console.log('RepositoryUsers | isOwner', isOwner, 'user', user);

  useEffect(() => {
    setRows(getTableRows(invitedUsers, handleRemoveUser));
  }, [repo, invitedUsers]);

  const handleRemoveUser = (user_id: string) => {
    if (debug) console.log('handleDeleteUser', user_id, 'from', repo.uuid);
    deleteRepositoryUser(repo.uuid, user_id).then(() => {
      props.usersUpdated(repo.uuid, 'inviteeDeleted');
    });
  };

  return (
    <AccountDetailContainer key={'repository-users'}>
      <HorizontalLine />
      { invitedUsers.length ?
        <>
          <Title>Users with Repository Access</Title>
          <BasicTable fields={columns} rows={rows}/>
        </>

        :
        <Center>
          <Message>No shared users attached to this repository</Message>
        </Center>
      }
    </AccountDetailContainer>
  );
};

export default RepoUsers;

const Message = styled.div`
  margin-top: 10px; 
  font-style: italic;
  font-size: 16px;
  color: ${(p) => p.theme.palette.accentSecondary};
  line-height: ${(p) => p.theme.sizes.xlarge};
`;
