import React from 'react';
import Input from '@mui/material/Input';

interface InputProps {
  value?: string;  // Add a value prop to pass the current value
  placeholder: string;
  onInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: number;
}

const CustomInput: React.FC<InputProps> = ({ value, placeholder, onInput, width }) => {
  return (
    <Input
      placeholder={placeholder}
      value={value} // Set the value prop to control the input's value
      onChange={onInput} // Use onChange instead of onInput to capture changes
      sx={{
        backgroundColor: 'white',
        width: width ? `${width}px` : '300px',
        height: 38,
        borderRadius: '7px',
        marginTop: '-13px',
        paddingLeft: '10px',
        '& input::placeholder': {
          color: 'black',
          opacity: 1,
          fontWeight: 400,
          fontSize: '13px',
          paddingLeft: '10px',
        },
      }}
    />
  );
}

export default CustomInput;
