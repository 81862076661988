import React, { useState } from 'react';
import { LayerCard } from '../LayerCard';
import { BarBase, PlotConfig } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Box } from '@mui/system';
import {
  Checkbox,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

type BarCardProps = {
  plot: PlotConfig<BarBase>;
  onChange: (newPlotConfig: PlotConfig<BarBase>) => void;
};

export const BarCard: React.FC<BarCardProps> = ({ plot, onChange }) => {
  const [isStackedOptionShowed, setIsStackedOptionShowed] = useState(
    plot.config.groupByColumns.length === 2
  );

  const updateGroupColumn = (columnName: string) => {
    const newGroupColumns = [
      columnName,
      ...(plot.config.groupByColumns[1] ? [plot.config.groupByColumns[1]] : []),
    ];
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, groupByColumns: newGroupColumns },
    };
    onChange(newPlotConfig);
  };

  const updateSubgroupColumn = (columnName: string) => {
    const newGroupColumns = [
      plot.config.groupByColumns[0],
      columnName,
    ];
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, groupByColumns: newGroupColumns },
    };
    onChange(newPlotConfig);
  };

  const updateValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateIsSorted = (event: React.ChangeEvent<HTMLInputElement>, isSorted: boolean) => {
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, isSorted },
    };
    onChange(newPlotConfig);
  };

  const updateOrientation = (
    event: React.MouseEvent<HTMLElement>,
    orientation: 'horizontal' | 'vertical' | null
  ) => {
    if (orientation !== null) {
      const newPlotConfig: PlotConfig<BarBase> = {
        ...plot,
        config: { ...plot.config, orientation },
      };
      onChange(newPlotConfig);
    }
  };

  const updateGroupingMode = (
    event: React.MouseEvent<HTMLElement>,
    groupingMode: 'stacked' | 'grouped' | null
  ) => {
    if (groupingMode !== null) {
      const newPlotConfig: PlotConfig<BarBase> = {
        ...plot,
        config: { ...plot.config, groupingMode },
      };
      onChange(newPlotConfig);
    }
  };

  const updateIsLogScale = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newPlotConfig: PlotConfig<BarBase> = {
      ...plot,
      config: { ...plot.config, isLogScale: checked },
    };
    onChange(newPlotConfig);
  };

  const onUseGroupBarplotCheckboxClick = () => {
    if (isStackedOptionShowed) {
      const newPlotConfig: PlotConfig<BarBase> = {
        ...plot,
        config: {
          ...plot.config,
          groupByColumns: [plot.config.groupByColumns[0]],
        },
      };
      onChange(newPlotConfig);
      setIsStackedOptionShowed(false);
    } else {
      setIsStackedOptionShowed(true);
    }
  };

  return (
    <>
      <LayerCard title={'Barplot'} disableExpand>
        <VariableSelect
          id='variable-value'
          label={'Numeric Variable'}
          value={plot.config.valueColumn}
          options={plot.columns}
          onChange={updateValue}
        />

        <VariableSelect
          id='group-value'
          label={'Grouping Variable'}
          value={plot.config.groupByColumns[0]}
          options={plot.columns}
          onChange={updateGroupColumn}
        />

        <ToggleButtonGroup
          size='small'
          color='standard'
          value={plot.config.orientation || 'vertical'}
          exclusive
          onChange={updateOrientation}
          aria-label='orientation'
        >
          <ToggleButton value='horizontal'>Horizontal</ToggleButton>
          <ToggleButton value='vertical'>Vertical</ToggleButton>
        </ToggleButtonGroup>

        <Box display='flex' alignItems='center' gap={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={plot.config.isLogScale}
                onChange={(e) => updateIsLogScale(e, e.target.checked)}
              />
            }
            label={<Typography fontSize={12}>Use Log Scale</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={plot.config.isSorted}
                onChange={(e) => updateIsSorted(e, e.target.checked)}
              />
            }
            label={<Typography fontSize={12}>Sort groups</Typography>}
          />
        </Box>
      </LayerCard>
      <LayerCard title={'Grouped or Stacked Barplot'} disableExpand>
        <FormControlLabel
          control={
            <Checkbox
              checked={isStackedOptionShowed}
              onClick={onUseGroupBarplotCheckboxClick}
            />
          }
          label={
            <Typography fontSize={12}>Use group or stacked barplot</Typography>
          }
        />
        {isStackedOptionShowed && (
          <>
            <VariableSelect
              id='subgroup-value'
              label='Additional grouping variable'
              value={plot.config.groupByColumns[1]}
              options={plot.columns}
              onChange={updateSubgroupColumn}
            />

            <ToggleButtonGroup
              size='small'
              color='standard'
              value={plot.config.groupingMode || 'stacked'}
              exclusive
              onChange={updateGroupingMode}
              aria-label='grouping-mode'
            >
              <ToggleButton value='stacked'>Stacked</ToggleButton>
              <ToggleButton value='grouped'>Grouped</ToggleButton>
            </ToggleButtonGroup>
          </>
        )}
      </LayerCard>
    </>
  );
};
