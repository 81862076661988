import React from 'react';
import RequestDetailsTile from '../common/RequestDetailsTile';
import AssayRequestForms from '../assay/AssayRequestForms';
import ConsultingRequestForms from '../consulting/ConsultingRequestForms';
import useVendors from '../hooks/useVendors';
import { RequestT, RequestUpdateT } from '../hooks/useRequests';
import { RequestStatusT } from '../hooks/useRequestStatus';

interface PropsI {
  request: RequestT;
  status: RequestStatusT;
  handleRequestUpdate: (id: string, updates: RequestUpdateT) => Promise<void>;
}

function isComplete(status: RequestStatusT, stage: string):boolean {
  if (Object.hasOwn(status, stage)) return status[stage].complete;
  return false;
}

const debug = false;
const RequestDetails = (props: PropsI) => {
  const {request, status, handleRequestUpdate} = props;
  const { getRequestType } = useVendors();

  if (debug) console.log('user/RequestDetails | {request.id, status, requestType}:',
      request.id, status, getRequestType(request));

  return (
    <>
      <RequestDetailsTile
        viewMode='user'
        request={request}
        userEmails={{}}
      />
      { getRequestType(request) === 'consulting' ?
        <ConsultingRequestForms
          viewMode='user'
          request={request}
          status={status}
          updateSelectedRequest={handleRequestUpdate}
        />
        :
        <AssayRequestForms
          viewMode='user'
          request={request}
          status={status}
          allowEdit={(!isComplete(status, 'compoundsShipped'))}
          updateSelectedRequest={handleRequestUpdate}
        />
      }
    </>
  );
};

export default RequestDetails;
