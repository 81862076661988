import React from "react";
import styled from "styled-components/macro";
import {Tooltip} from "@mui/material";

type PropsT = {
  text: string;
  info: string;
}
const HoverText = (props:PropsT) => {
  return(
    <Tooltip title={props.info} arrow={true}>
      <String>{props.text}</String>
    </Tooltip>
  )
}

export default HoverText;

const String = styled.div`
`;
