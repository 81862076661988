import React, { useState } from 'react';
import _ from "lodash";
import useCognito from "@as_core/account/useCognito";
import { DoseResponse } from '@utils/api';

import { UserDataContainer } from "@pages/pages_styles/userData_style";
import { FlexColumn, FlexRow, FlexItem, FlexSpacer } from "@components/layout/FlexStyles";
import { TextSmall, TextSmallBold } from "@components/elements/TextStyles";
import TextButton from '@components/controls/TextButton';

import { getDoseResponseResultsAsTableData, getCsvDataFromTableData, downloadCSV }
  from "@components/dataSets/dataSet.service";
import CurveFitResults from "./curveFit/CurveFitResults";
import RadioButtonSelector from "@pages/shared/RadioButtonSelector";
import Alert from '@components/elements/Alert';
import DataLoading from "@as_core/elements/DataLoading";
import DataSaveIcon from "@components/icons/dataSave.icon";
import useWindowDimensions from "@as_core/hooks/useWindowDimensions";
import { DataSetT } from '@components/dataSets/dataSet.types';


const options = [{ value: 'LL.4', label: 'LL.4 (4-param log-logistic)' }];

interface ComputeCurveFitProps {
  dataSet: DataSetT;
  setDataSet: (dataSet: DataSetT) => void;
}

const debug = false;
const ComputeCurveFit = (props: ComputeCurveFitProps) => {
  const { dataSet, setDataSet } = props;
  const [isComputing, setIsComputing] = useState<boolean>(false);
  const [fitComputed, setFitComputed] = useState<boolean>(false);
  const [fitType, setFitType] = useState<string>(options[0].value);
  const { height: windowHeight } = useWindowDimensions();
  if (debug) console.log('ComputeCurveFit | dataSet', dataSet);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const { getToken } = useCognito();

  const handleChange = (event) => {
    if (debug) console.log('ComputeCurveFit | handleChange', event.target.value);
    setFitType(event.target.value);
  };

  const handleSaveResults = () => {
    const data = getDoseResponseResultsAsTableData(dataSet);
    if (debug) console.log("ComputeCurveFit | handleSaveResults {data}:", data);
    const dataCsv = getCsvDataFromTableData(data);
    if (debug) console.log("ComputeCurveFit | handleSaveResults {data}:", data);
    downloadCSV(dataCsv, 'curveFit.csv');
  }

  const handleSubmit = () => {
    if (debug) console.log('ComputeCurveFit | handleSubmit');
    computeCurveFit(fitType).then().catch();
  };

  const computeCurveFit = async (fitType) => {
    setIsComputing(true);
    const payload = {
      model_fit: fitType,
      experiments: dataSet.dataFormatted,
    };
    const token = getToken();
    await DoseResponse.compute(token, payload).then((res) => {
      if (debug) console.log('ComputeCurveFit | res:', res);
      if (res.status === 200) {
        let data = _.get(res, 'data');
        if (debug) console.log("ComputeCurveFit | data:", data);
        if (data?.errors.length) {
          console.log(
            'DoseResponse API ERROR',
            data?.errors.length,
            ' dose_response API errors'
          );
          console.log('DoseResponse API ERROR:', data?.errors);
          setAlertMessage('Errors: ' + data?.errors.join(', '));
          setAlertOpen(true);
        }
        if (data?.data.length) {
          setDataSet({ ...dataSet, dataFormatted: data.data });
          setFitComputed(true);
        } else {
          console.log(
            'DoseResponse API ERROR: returned empty data array ... skipping'
          );
        }
      }
    });
    setIsComputing(false);
  };

  return (
    <UserDataContainer key={'ComputeCurveFit'} vertical>
      <FlexRow width={'100%'} h_centered>
        <FlexItem>
          <TextSmallBold>Select Curve Fit Type:</TextSmallBold>
        </FlexItem>
        <FlexSpacer />
        <FlexItem>
          <RadioButtonSelector
            selected={fitType}
            options={options}
            onChange={handleChange}
          />
        </FlexItem>
      </FlexRow>
      <FlexRow width={'100%'} h_centered>
        <TextButton
          text='Submit Curve Fit Calculation'
          height={30}
          width={200}
          onClick={handleSubmit}
        />
        {fitComputed ?
          <TextButton
            text={'Save Curve Fit Parameters'}
            onClick={handleSaveResults}
            icon={<DataSaveIcon size={32} />}
            width={220}
            height={30}
          />
          : <></>
        }
      </FlexRow>
      <FlexRow height={'15px'}>
        <FlexSpacer />
      </FlexRow>
      <UserDataContainer key={'CurveFitResults'}>
        <FlexColumn width={'100%'} h_centered v_centered>
          <FlexItem>Curve Fit Results ({fitType}):</FlexItem>
          {isComputing ?
            <FlexItem width={'100%'}>
              <DataLoading />
            </FlexItem>
            : fitComputed ?
              <FlexItem width={'100%'}>
                <CurveFitResults
                  dataSet={dataSet.dataFormatted}
                  fitType={fitType}
                  height={windowHeight - 500}
                />
              </FlexItem>
              :
              <FlexItem>
                <TextSmall color={'accentSecondary'}>
                  Fit Not Yet Computed (Select Type and Click Submit)
                </TextSmall>
              </FlexItem>
          }
        </FlexColumn>
      </UserDataContainer>
      <Alert
        type={'general'}
        title={'Curve Fit Issue'}
        message={alertMessage}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </UserDataContainer>
  );
};

export default ComputeCurveFit;
