import React from "react";
import styled from "styled-components/macro";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

export type StatusMapT = {
  label: string,
  checked: boolean
}

interface PropsI {
  viewMode: string;
  statusMap: StatusMapT[];
}

const debug = false;
const RequestToDo = (props:PropsI) => {
  const {viewMode, statusMap} = props;
  if (debug) console.log('props', props);

  const getHeader = () => {
    const total = statusMap.length;
    const completed = statusMap.filter(m => m.checked === true).length;
    const notComplete = statusMap.filter(m => m.checked === false).length;
    const allComplete = !statusMap.some(m => m.checked === false);
    if (debug) console.log('allComplete', allComplete, 'notComplete', notComplete);
    return(
      <Highlight>
        {
          allComplete ? 'All Steps Completed:': viewMode === 'user' ?
          `Steps to Complete (${notComplete} of ${total})` :
          `Steps Completed (${completed} of ${total})`
        }
      </Highlight>
    );
  }

  return(
    <ToDoContainer>
      <ToDoList>
        <Item>{getHeader()}</Item>
        {statusMap.map((item, index) => (
          <Item key={`todo_item_${index}`}>
            { item.checked ?
              <CheckBox />
              :
              <CheckBoxOutlineBlank />
            }
            <Label>{item.label}</Label>
          </Item>
        ))}
      </ToDoList>
    </ToDoContainer>
  )
}
export default RequestToDo;

const ToDoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  padding: 5px;
`;

const ToDoList = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.accentSecondary};
  margin-right: 5px;
`;

const Label = styled(Item)`
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Highlight = styled(Label)`
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 14px;
  font-weight: bold;
`;