import { RequestT } from '../hooks/useRequests';

export type positionT = {
  x: number;
  y: number;
}

export type positionCountT = {
  x: number;
  y: number;
  count: number;
}

export function getStatusCounts(requests:RequestT[]): {[key:string]: number} {
  const counts = {};
  requests.forEach((r) => {
    if (Object.hasOwn(counts, r.status)) {
      counts[r.status] += 1;
    } else {
      counts[r.status] = 1;
    }
  })
  return counts;
}

export function mapCountsToPositions(
  counts: {[key:string]: number},
  mapPositions: {[key:string]: positionT}
) : positionCountT[] {
  const positions = [];
  Object.keys(counts).forEach((status) => {
    // console.log('status', status, counts[status]);
    if (Object.hasOwn(mapPositions, status)) {
      positions.push({
        x: mapPositions[status].x,
        y: mapPositions[status].y,
        count: counts[status]
      })
    }
  });
  return positions;
}