// Bar Chart Component
// A Wrapper around the NIVO's ResponsiveBar component
// It adds a bit of styling to fit our application

import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Margin, Theme } from '@nivo/core';
import { ScaleSpec } from '@nivo/scales';
import { getBarchartTooltip } from './getBarchartTooltip';
import { getDatavizTheme } from '@plotting/single-plot-view/plot-panel/plot.themes';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { colorSchemes } from '@utils/scales/color/ColorSchemes';
import {
  DEFAULT_TITLE_SIZE,
  DEFAULT_X_AXIS_STYLE,
  DEFAULT_Y_AXIS_STYLE,
  DEFAULT_LEGEND_CONFIG,
} from '@dataviz/constants';

const DEFAULT_MARGIN = {
  top: 60,
  right: 140,
  bottom: 90,
  left: 116,
};

interface BarChartProps {
  data: BarDatum[];
  keys: string[];
  indexBy: string;
  margin?: Margin;
  orientation?: 'horizontal' | 'vertical';
  groupingMode?: 'grouped' | 'stacked';
  valueAxis?: {
    name?: string;
    isLogScale?: boolean;
  };
  title?: string;
  titleSize?: number;
  datavizTheme?: Theme;
  isLegendEnabled?: boolean;
}

interface PlotTitleProps {
  innerWidth: number;
}

const BarChart = ({
  data,
  keys,
  indexBy,
  margin,
  orientation,
  groupingMode,
  valueAxis,
  title,
  titleSize,
  datavizTheme,
  isLegendEnabled,
}: BarChartProps) => {
  const { palette } = useContext(ThemeContext);
  const finalDatavizTheme = datavizTheme ?? getDatavizTheme({}, palette);

  const plotMargin = margin || DEFAULT_MARGIN;

  const plotTitleStyle = {
    fontFamily: finalDatavizTheme.fontFamily,
    fontSize: titleSize || DEFAULT_TITLE_SIZE,
    fill: finalDatavizTheme.textColor,
    textAnchor: 'middle',
  } as const;

  const PlotTitle = ({ innerWidth }: PlotTitleProps) => {
    return (
      <text x={innerWidth / 2} y={-plotMargin.top / 2} style={plotTitleStyle}>
        {title}
      </text>
    );
  };

  const tooltip = getBarchartTooltip();

  const valueScale: ScaleSpec = valueAxis.isLogScale
    ? { type: 'symlog' }
    : { type: 'linear' };

  const asedaColors = colorSchemes['aseda'].scheme;

  const axisBottomConfig = {
    ...DEFAULT_X_AXIS_STYLE,
    legend: orientation === 'horizontal' ? valueAxis?.name : indexBy,
    format: orientation === 'horizontal' ? '~g' : undefined,
  };

  const axisLeftConfig = {
    ...DEFAULT_Y_AXIS_STYLE,
    legend: orientation === 'horizontal' ? undefined : valueAxis?.name, // no axis name for the Y axis in horizontal mode to avoid overlap with tick labels
    format: orientation === 'horizontal' ? undefined : '~g',
  };

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={plotMargin}
      layout={orientation}
      groupMode={groupingMode}
      tooltip={tooltip}
      axisBottom={axisBottomConfig}
      axisLeft={axisLeftConfig}
      theme={finalDatavizTheme}
      layers={[PlotTitle, 'grid', 'axes', 'bars', 'markers', 'legends']}
      valueScale={valueScale}
      valueFormat={'>-.3~d'}
      colors={asedaColors}
      indexScale={{ type: 'band' }}
      enableGridX={true}
      enableGridY={true}
      animate={true}
      legends={
        keys.length > 1 && isLegendEnabled
          ? [{ ...DEFAULT_LEGEND_CONFIG, dataFrom: 'keys' }]
          : undefined
      }
      labelSkipHeight={20}
    />
  );
};

export default BarChart;
