import {
  schemeCategory10,
  schemeAccent,
  schemeDark2,
  schemePaired,
  schemePastel1,
  schemePastel2,
  schemeSet1,
  schemeSet2,
  schemeSet3,
  interpolateRdBu,
  interpolatePuOr,
  interpolatePiYG,
  interpolateBlues,
  interpolateGreens,
  interpolateGreys,
  interpolateOranges,
  interpolatePurples,
  interpolateReds,
  interpolateRdPu,
  interpolateOrRd,
  interpolateYlGn,
} from 'd3-scale-chromatic';
import { ColorScheme } from '@utils/scales/color/ColorScale.types';

const endcap = (interpolate) => {
  return [interpolate(0), interpolate(1.0)];
};

const midcap = (interpolate) => {
  return [interpolate(0), interpolate(0.5), interpolate(1.0)];
};

export const COLORS = [
  {
    id: 'aseda',
    name: 'Aseda',
    scheme: [
      '#DB0B5B',
      '#008819',
      '#006CFD',
      '#31BEAF',
      '#BE8331',
      '#8931BE',
      '#BE5831',
      '#E1B92A',
      '#808095',
    ],
    type: 'discrete',
    unique: 9,
  },
  {
    id: 'category10',
    name: 'Category10',
    scheme: schemeCategory10,
    type: 'discrete',
    unique: 10,
  },
  {
    id: 'accent',
    name: 'Accent',
    scheme: schemeAccent,
    type: 'discrete',
    unique: 8,
  },
  {
    id: 'dark2',
    name: 'Dark2',
    scheme: schemeDark2,
    type: 'discrete',
    unique: 8,
  },
  {
    id: 'paired',
    name: 'Paired',
    scheme: schemePaired,
    type: 'discrete',
    unique: 12,
  },
  {
    id: 'pastel1',
    name: 'Pastel1',
    scheme: schemePastel1,
    type: 'discrete',
    unique: 9,
  },
  {
    id: 'pastel2',
    name: 'Pastel2',
    scheme: schemePastel2,
    type: 'discrete',
    unique: 12,
  },
  {
    id: 'set1',
    name: 'Set1',
    scheme: schemeSet1,
    type: 'discrete',
    unique: 9,
  },
  {
    id: 'set2',
    name: 'Set2',
    scheme: schemeSet2,
    type: 'discrete',
    unique: 8,
  },
  {
    id: 'set3',
    name: 'Set3',
    scheme: schemeSet3,
    type: 'discrete',
    unique: 12,
  },
  {
    id: 'blues',
    name: 'Blues',
    scheme: endcap(interpolateBlues),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'greens',
    name: 'Greens',
    scheme: endcap(interpolateGreens),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'greys',
    name: 'Greys',
    scheme: endcap(interpolateGreys),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'oranges',
    name: 'Oranges',
    scheme: endcap(interpolateOranges),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'purples',
    name: 'Purples',
    scheme: endcap(interpolatePurples),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'reds',
    name: 'Reds',
    scheme: endcap(interpolateReds),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'red_purple',
    name: 'Red - Purple',
    scheme: endcap(interpolateRdPu),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'orange_red',
    name: 'Orange - Red',
    scheme: endcap(interpolateOrRd),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'yellow_green',
    name: 'Yellow - Green',
    scheme: endcap(interpolateYlGn),
    type: 'sequential',
    unique: 2,
  },
  {
    id: 'pnk_green',
    name: 'Pink - Green',
    scheme: midcap(interpolatePiYG),
    type: 'diverging',
    unique: 3,
  },
  {
    id: 'red_blue',
    name: 'Red - Blue',
    scheme: midcap(interpolateRdBu),
    type: 'diverging',
    unique: 3,
  },
  {
    id: 'purple_orange',
    name: 'Purple - Orange',
    scheme: midcap(interpolatePuOr),
    type: 'diverging',
    unique: 3,
  },
];

const TYPES = [
  { id: 'discrete', label: 'solid' },
  { id: 'sequential', label: 'sequential' },
  { id: 'diverging', label: 'diverging' },
];

const SCHEMES = {
  discrete: COLORS.filter((scale) => scale.type === 'discrete'),
  sequential: COLORS.filter((scale) => scale.type === 'sequential'),
  diverging: COLORS.filter((scale) => scale.type === 'diverging'),
};

const colorSchemes = COLORS.reduce((acc, color) => {
  acc[color.id] = color as ColorScheme;
  return acc;
}, {});

const getCustomScheme = (colors: Array<string>, type: string) =>
  ({
    id: 'custom',
    name: 'Custom',
    type: type,
    scheme: colors,
    unique: colors.length,
  } as ColorScheme);

const arrayEquals = (a, b) =>
  a.length === b.length && a.every((val, idx) => val === b[idx]);

const getScheme = (colors: Array<string>, type?: string) => {
  const match = type
    ? SCHEMES[type].find((entry) => arrayEquals(colors, entry.scheme))
    : COLORS.find((entry) => arrayEquals(colors, entry.scheme));
  if (match) {
    return { ...match } as ColorScheme;
  } else {
    return getCustomScheme(colors, type);
  }
};

export { TYPES, SCHEMES, colorSchemes, getScheme, getCustomScheme };
