import React from 'react';
import styled from 'styled-components/macro';
import zebraRenderer from '@components/valueRenderers/zebraRenderer';

const LegendZebrafish = () => {
  const values = ['NE', 100.0, 1.0, 0.10, 0.005];
  return (
    <LegendZebrafishContainer>
      <LegendText><Title>BMD10 (uM)</Title><Def>NE - No Effect</Def></LegendText>
      <LegendBars>
        { values.map((v, i) => {
          return(
            <Cell key={i}>
              <CellNumber>{v}</CellNumber>
              <BarChart>
                { zebraRenderer(
                  {
                    'className': 'zf_legend'+i,
                    'cellData': v,
                    'column': { barsHorizontal: false}
                  }) }
              </BarChart>
            </Cell>
          )
        })}
      </LegendBars>
    </LegendZebrafishContainer>
  );
};

export default LegendZebrafish;

const LegendZebrafishContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 4px;
  height: 40px;
  width: max-content;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary };
`;

const LegendText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px;
  flex-wrap: nowrap;
  color: ${(p) => p.theme.palette.textSecondary};
  font-size: ${(p) => p.theme.sizes.xsmall};
`;

const Title = styled.div`
  display: flex;
  height: 20px;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.theme.sizes.xsmall};
`;

const Def = styled.div`
  display: flex;
  height: 20px;
  font-size: 10px;
`;

const LegendBars = styled.div`
  margin: 0 2px;
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 1px;
  width: 60px;
  height: 30px;
`;

const CellNumber = styled.div`
  display: flex;
  width: max-content;
  align-items: flex-end;
  font-size: 12px;
  margin-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const BarChart = styled.div`
  display: flex;
  align-items: flex-start;
  width: max-content;
  margin-left: 5px;
`;