import { combineReducers } from '@reduxjs/toolkit';

import compounds from './stores/compounds';
import activeCompound from './stores/activeCompound';
import filters from '@stores/filters';
import filterExtents from '@stores/filters';
import magnifiedUrlImage from './stores/magnifiedUrlImage';
import magnifiedSvgImage from './stores/magnifiedSvgImage';
import fields from '@stores/fields';
import compoundViews from './stores/compoundViews';
import globalCheckbox from './stores/FileImportContext';

const rootReducer = combineReducers({
  compounds,
  activeCompound,
  magnifiedUrlImage,
  magnifiedSvgImage,
  fields,
  filters,
  filterExtents,
  compoundViews,
  globalCheckbox,

});

export default rootReducer;
