import React, { useState, useRef } from 'react';
import { Menu, MenuItem } from '@mui/material';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@src/store';
import { CompoundT } from '@src/type';
import DataSetIcon from '@components/icons/dataSet.icon';
import useCompounds from '@src/components/compounds/useCompounds';
import useCognito from '@as_core/account/useCognito';
import Alert from '@components/elements/Alert';
import MenuButton from '@components/elements/MenuButton';
import { getListCompounds } from '@stores/compoundViews';

type FilterOption = {
  title: string;
  uuid: string;
};

interface FilterDropdownProps {
  fieldOptions: FilterOption[]; // Array of fieldOptions or labels
  setData: (data: Array<CompoundT>) => void; // Function to update data in the parent
  setTotalCompounds: (val: number) => void;
  setLoadedAll: (val: boolean) => void;
  setLoading: (val: boolean) => void;
  setNoLoadMore: (val: boolean) => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  fieldOptions,
  setData,
  setTotalCompounds,
  setLoadedAll,
  setLoading,
  setNoLoadMore
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // Initialize with Redux filters
  const compoundViews = useSelector((state: RootState) => state.compoundViews);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { getToken } = useCognito();
  const dispatch = useDispatch();
  const abortControllerRef = useRef(new AbortController());

  const newController = new AbortController();
  abortControllerRef.current = newController;
  const controller = abortControllerRef.current;

  const { getCompoundsFinite } = useCompounds();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlert = () => {
    setAlertOpen(false);
  };

  const handleOptionClick = async (value: string) => {
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];

    setSelectedOptions(newSelectedOptions);
    setNoLoadMore(newSelectedOptions.length > 0)
    const listSelecteduuid =
      newSelectedOptions.length > 0
        ? newSelectedOptions
        : compoundViews.viewIds;

    setAnchorEl(null);
    await dispatch(getListCompounds(getToken(), listSelecteduuid, controller.signal ));
    let listCompoundsIds = Object.values(compoundViews.views)
      .filter((view) => listSelecteduuid.includes(view.uuid)) // Filter views by predefined UUIDs
      .flatMap((view) => view.compoundIds?.[0]?.compoundIds || []);

    const tot = listCompoundsIds.length
    if (newSelectedOptions.length === 0) {
      listCompoundsIds = listCompoundsIds.length > 0 ? listCompoundsIds.slice(0, 75) : []
    }

    if (listCompoundsIds.length > 0) {
      setLoading(true);
      await getCompoundsFinite({}, [
        { libraryId: 'user', compoundIds: listCompoundsIds },
      ], { signal: controller.signal }).then((res) => {
        if (res.data.length > 0) {
          setLoadedAll(false);
          setData(res.data);
          setTotalCompounds(tot);
        }
        setLoading(false);
      });
    } else {
      setAlertOpen(true);
      setTotalCompounds(0);
      setData([]);
      setNoLoadMore(false)
      
    }
  };

  return (
    <Container>
      <MenuButton
        onClick={handleClick}
        icon={<DataSetIcon />}
        text={'Select dataset'}
        tooltipPlacement={'bottom'}
      />
      <StyledMenu
        id='filter-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fieldOptions.map((option, index) => (
          <StyledMenuItem
            key={`${option.title}_${index}`}
            selected={selectedOptions.includes(option.uuid)}
            onClick={() => handleOptionClick(option.uuid)}
          >
            {selectedOptions.includes(option.uuid)}
            {option.title}
          </StyledMenuItem>
        ))}
      </StyledMenu>

      <Alert
        type='general'
        title='No Data'
        message='The selected dataset does not have associated compounds'
        alertOpen={alertOpen}
        closeAlert={handleAlert}
      />
    </Container>
  );
};

export default FilterDropdown;

const Container = styled.div`
  display: inline-block;
  padding-top: 4px;
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 160px;
    background-color: ${(p) => p.theme.palette.backgroundPrimary};
  }
`;

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.selected
      ? props.theme.palette.textPrimary
      : props.theme.palette.textSecondary};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  &:hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  }
  &::before {
    content: ${(props) => (props.selected ? '"✔️ "' : '""')};
    margin-right: 8px;
  }
`;
