import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useData from '@components/dataSets/useData';
import useCognito from '@as_core/account/useCognito';
import useCompounds from '@components/compounds/useCompounds';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import DataLoading from '@as_core/elements/DataLoading';
import BasicTable from '@as_core/tables/BasicTable';

import { RootState } from "@src/store";  // Updated to include AppDispatch type
import { getCompoundViews } from '@stores/compoundViews';

// Define the shape of the dataset or favorite item
interface DataSetItem {
  uuid?: string;
  name?: string;
  title?: string;
  type?: string;
  nColumns?: number;
  nRows?: number;
  createdOn?: string;
  compoundCount?: number;
  fieldIds?: string[];
  columns_names?: string[];
}

interface DataSetsState {
  data: DataSetItem[];
  favorites: DataSetItem[];
}

interface LoadedState {
  data: boolean;
  favorites: boolean;
}

type PropsT = {
  type: string;
  width: number;
  height: number;
};

const FIELDS = {
  data: [
    { value: 'name', label: 'File', type: 'string', width: 270 },
    { value: 'createdOn', label: 'Created', type: 'date', width: 100 },
    { value: 'type', label: 'Type', type: 'string', width: 100 },
    { value: 'nColumns', label: '# Cols', type: 'number', width: 100 },
    { value: 'nRows', label: '# Rows', type: 'number', width: 100 },
  ],
  favorites: [
    { value: 'title', label: 'Favorite', type: 'string', width: 250 },
    { value: 'created', label: 'Created', type: 'date', width: 200 },
    { value: 'count', label: '# Compounds', type: 'number', width: 100 },
    { value: 'columns', label: '# Cols', type: 'number', width: 100 },
  ],
};

// Avoid using 'any' by specifying expected value types
const transformData = (value: string | null | undefined, type: string) => {
  switch (type) {
    case 'date':
      return new Date(value).toLocaleDateString();
    case 'count':
      return value?.length ?? 0;  // Add safeguard for null/undefined
    default:
      return value;
  }
};

// Improve typing of dataSet with DataSetItem[]
const getRows = (type: string, dataSet: DataSetItem[]) => {
  const fields = FIELDS[type];
  return dataSet.map((d) =>
    fields.reduce((acc, f) => ({
      ...acc,
      [f.value]: transformData(d[f.value], f.type),
    }), {})
  );
};

const DatasetList: React.FC<PropsT> = ({ type, width, height }) => {
  const compoundViews = useSelector((state: RootState) => state.compoundViews);
  const dispatch = useDispatch();  // Explicitly type useDispatch with AppDispatch
  const navigate = useNavigate();
  const { getAllData } = useData();
  const { getToken } = useCognito();
  const { getCompounds } = useCompounds();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [dataSets, setDataSets] = useState<DataSetsState>({ data: [], favorites: [] });
  const [loaded, setLoaded] = useState<LoadedState>({ data: false, favorites: false });

  const datasetType = type === 'favorites' ? 'favorites' : 'data';

  const fetchData = useCallback(async () => {
    setIsDataLoading(true);
    const token = getToken();
    const { data } = await getAllData(token, {});
    const myData = data.filter((d: DataSetItem) => ['Generic', 'Dose Response'].includes(d.type ?? ''));
    setDataSets((prev) => ({ ...prev, data: myData }));
    setLoaded((prev) => ({ ...prev, data: true }));
    setIsDataLoading(false);
  }, [getAllData, getToken]);

  const fetchFavorites = useCallback(async () => {
    setIsDataLoading(true);
    const token = getToken();
    dispatch(getCompoundViews(token));
    let favorites = Object.values(compoundViews.views).map((v) => ({
      uuid: v.uuid,
      title: v.title,
      created: v.createdOn,
      count: v.compoundCount,
      columns: v.fieldIds.length,
      columns_names: v.fieldIds,
    }));

    const res = await getCompounds({ library: 'user', limit: 10 });
    if (res.total) {
      const baseIndex = favorites.findIndex((v) => v.title === 'My Compounds');
      if (baseIndex !== -1) {
        favorites[baseIndex].count = res.total;
      }
    } else {
      favorites = favorites.filter((v) => v.title !== 'My Compounds');
    }
    setDataSets((prev) => ({ ...prev, favorites }));
    setLoaded((prev) => ({ ...prev, favorites: true }));
    setIsDataLoading(false);
  }, [dispatch, getCompounds, getToken, compoundViews.views]);

  useEffect(() => {
    if (!loaded[type]) {
      if (datasetType === 'data') {
        fetchData();
      } else {
        fetchFavorites();
      }
    }
  }, [datasetType, fetchData, fetchFavorites, loaded, type]);

  useEffect(() => {
    if (!loaded.favorites && datasetType === 'favorites') {
      fetchFavorites();
    }
  }, [compoundViews, datasetType, fetchFavorites, loaded.favorites]);

  const handleSelect = useCallback((index: number) => {
    const selected = dataSets[datasetType][index];
    navigate('/plots/create', {
      state: { dataId: selected?.uuid, dataType: datasetType, columns: selected?.columns_names },
    });
  }, [dataSets, datasetType, navigate]);

  const rows = useMemo(() => getRows(datasetType, dataSets[datasetType]), [datasetType, dataSets]);

  if (isDataLoading) {
    return (
      <PlotCreateListContainer width={width} height={height}>
        <Center>
          <DataLoading />
        </Center>
      </PlotCreateListContainer>
    );
  }

  if (!dataSets[datasetType].length) {
    return (
      <PlotCreateListContainer width={width} height={height}>
        <ErrorMessages
          messages={[
            <MessageItem key="1" color="accentSecondary">
              {datasetType === 'data' ? 'You have not uploaded any datasets.' : 'You have not saved favorites.'}
            </MessageItem>,
            <MessageItem key="2">
              Click on &quot;Upload New Data Set&quot; on the upper-right to start loading data.
            </MessageItem>,
          ]}
        />
      </PlotCreateListContainer>
    );
  }

  return (
    <PlotCreateListContainer width={width} height={height}>
      <ScrollBox>
        <BasicTable
          fields={FIELDS[datasetType]}
          rows={rows}
          onRowClick={handleSelect}
        />
      </ScrollBox>
    </PlotCreateListContainer>
  );
};

export default DatasetList;

const PlotCreateListContainer = styled.div<{ height: number; width: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ScrollBox = styled.div`
  display: flex;
  height: 93%;
  overflow-y: auto;
  overflow-x: hidden;
`;
