import React, {useState, useEffect} from "react";
import styled from "styled-components/macro";
import {StripeProductT} from "@business/products/useStripeProducts";
import BasicTable from "@as_core/tables/BasicTable";
import {getShowPrice, getAddToCart, getMinOrderPrice, getShowUnit} from "@business/products/components/utils";

type FieldsT = {value: string, label: string, width: number, align?: string};
const fields: FieldsT[] = [
  {value: 'addToCart', label: '', width: 50},
  {value: 'name', label: 'Product Name', width: 200, align: 'left'},
  {value: 'metadata.catalogNumber', label: 'Catalog Number', width: 150, align: 'left'},
  {value: 'description', label: 'Description', width: 400, align: 'left'},
  {value: 'showUnit', label: 'Unit', width: 125},
  {value: 'showPrice', label: 'Unit Price', width: 100, align: 'right'},
  {value: 'minOrderPrice', label: 'Minimum Order Price', width: 100, align: 'right'}
]

type PropsT = {
  orgType: string,
  products: StripeProductT[],
  onSelect: (product: StripeProductT, priceType: string) => void;
}
const ProductsTable = (props:PropsT) => {
  const [rows, setRows] = useState<StripeProductT[]>([]);
  const {orgType, products} = props;

  useEffect(()=>{
    const updatedRows = products.map((r) => (
      { ...r,
        showPrice: getShowPrice(orgType, r),
        addToCart: getAddToCart(orgType, r, props.onSelect),
        minOrderPrice: getMinOrderPrice(orgType, r),
        showUnit: getShowUnit(r)
      }
    ))
    setRows(updatedRows)
  },[products]);

  return(
    <ProductsTableContainer>
      <BasicTable
        fields={fields}
        rows={rows}
        pillRows={false}
      />
    </ProductsTableContainer>
  )
}

export default ProductsTable;

const ProductsTableContainer = styled.div`
  display: flex;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
