import React from 'react';
import { ScatterPlotDatum, ScatterPlotNodeProps } from '@nivo/scatterplot';

export const getScatterplotNode = ({
  node,
  blendMode,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}: ScatterPlotNodeProps<ScatterPlotDatum>) => {
  if (node.y === null || node.y === undefined) {
    return null;
  }
  return (
    <g transform={`translate(${node.x},${node.y})`}>
      <circle
        r={node.size / 2}
        fill={node.color}
        style={{ mixBlendMode: blendMode }}
        onMouseEnter={(event) => onMouseEnter?.(node, event)}
        onMouseMove={(event) => onMouseMove?.(node, event)}
        onMouseLeave={(event) => onMouseLeave?.(node, event)}
        onClick={(event) => onClick?.(node, event)}
      />
    </g>
  );
};
