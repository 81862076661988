import React, {useState} from 'react';
import styled from 'styled-components/macro';
import { getValueByPath } from "@utils/misc";
import BasicTable from '@as_core/tables/BasicTable';
import RequestFormFileDetails from "./RequestFormFileDetails";
import useRequestForms, {RequestFormT} from "../hooks/useRequestForms";
import MenuButton from "@components/elements/MenuButton";
import ViewIcon from "@as_core/icons/view.icon";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";
import StationaryModal from "@as_core/bugsReporting/components/StationaryModal";
import TrashIcon from "@components/icons/trash.icon";
import Alert from "@components/elements/Alert";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

const NoDeleteInfo:string = 'Once you have confirmed shipping, plate map files cannot be deleted. '
 + 'Please reach out directly to the laboratory to which you shipped your compounds to provide any '
 + 'updated files.';

const tableFields: {[key: string]: BasicTableFieldsT[]} = {
  'short': [
    {value: 'view', label: '', type: 'action', width: 40},
    {value: 'file_name', label: 'File', type: 'file', width: 150},
    {value: 'delete', label: '', type: 'action', width: 60},
  ],
  'long': [
    {value: 'view', label: '', type: 'action', width: 40},
    {value: 'file_name', label: 'File Name', type: 'string', width: 150},
  ]
};

const getTableRows = (
  viewMode: string,
  formType: string,
  formName: string,
  requestForms: RequestFormT[],
  showDelete: boolean,
  fields: BasicTableFieldsT[],
  handleClick: (id:string, type:string)=>void
) => {
  const rows = [];
  requestForms.forEach((form) => {
    if (form.form_type === formType) {
      const row = [];
      fields.forEach((f) => {
        if (f.type === 'action') {
          if (f.value === 'view') {
            row[f.value] = <MenuButton
            onClick={() => handleClick(form?.id, 'view')}
            icon={<ViewIcon/>}
            text={'View ' + formName + ' Map Details'}
            tooltipPlacement={'bottom'}
            />;
          } else if (f.value === 'delete') {
            if (showDelete) {
              row[f.value] = <MenuButton
              onClick={() => handleClick(form?.id, 'delete')}
              icon={<TrashIcon />}
              text={'Delete Uploaded ' + formName + ' File'}
              tooltipPlacement={'bottom'}
              />;
            } else if (viewMode === 'user' && formType === 'plate_map') {
              row[f.value] = <HoverInfoText text={NoDeleteInfo}/>
            }
          }
        } else if (f.type === 'string_truncate') {
          row[f.value] = <HoverTextTruncate text={form.original_file_name} />
        } else {
          row[f.value] = getValueByPath(form, f.value, '');
        }
      });
      rows.push(row);
    }
  })
  return rows;
}

type PropsT = {
  viewMode: string;
  allowEdit: boolean,
  formType: string,
  formName: string,
  requestForms: RequestFormT[];
  setUpdated: () => void;
  short?: boolean;
  header?: boolean;
}


const debug = false;
const RequestFormFilesTable = (props:PropsT) => {
  const { viewMode, allowEdit, formType, formName,  requestForms, setUpdated, short=true, header= false } = props;
  const { deleteRequestForm } = useRequestForms();
  if (debug) console.log('requestFormsTable | requestForms: ', requestForms);
  const [selectedRequestForm, setSelectedRequestForm] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);

  const handleClick = (requestFormId: string, clickType: string) => {
    if (debug) console.log('handleClick: ', requestFormId, clickType);
    const matched:RequestFormT = requestForms.find((o) => o.id === requestFormId);
    if (matched) {
      setSelectedRequestForm(matched);
      if (clickType === 'view') {
        setIsDetailsOpen(true);
      } else {
        setIsDeleteAlertOpen(true);
      }
    } else {
      console.error(`RequestsTable | unable to locate request by id ${requestFormId})`);
    }
  };

  const handleDeleteRequestForm = () => {
    deleteRequestForm(selectedRequestForm)
      .then(()=>{
          setUpdated();  // force refresh
        }
      )
  }

  return (
    <RequestTableContainer>
      { requestForms !== undefined && requestForms.length !== 0 ?
        <BasicTable
          header={header}
          fields={short ? tableFields['short']: tableFields['long']}
          rows={getTableRows(viewMode, formType, formName,
            requestForms, allowEdit, short ? tableFields['short']: tableFields['long'], handleClick)}
          border={false}
        />
        :
        <Message>No {formType === 'plate_map' ? 'plate map' : 'assay results'} files added to this request.</Message>
      }
      <StationaryModal
        title={formName + ' Upload Details'}
        opacity={0.95}
        height={240}
        width={420}
        modalOpen={isDetailsOpen}
        onCloseClick={() => setIsDetailsOpen(false)}
      >
        <RequestFormFileDetails
          requestForm={selectedRequestForm}
        />
      </StationaryModal>
      <Alert
        type={'deleteConfirm'}
        title={'Delete Uploaded ' + formName}
        message={'Confirm delete by typing DELETE below to Enable Delete'}
        alertOpen={isDeleteAlertOpen}
        onConfirm={()=>handleDeleteRequestForm()}
        closeAlert={() => setIsDeleteAlertOpen(false)}
      />
    </RequestTableContainer>
  );
};

export default RequestFormFilesTable;

const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  font-size: 12px;
  padding: 5px 15px;
  margin: 5px;
  color: ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`
