import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import ColorBar from '@components/elements/ColorBar';
import { useSelector } from 'react-redux';
import { RootState } from "@src/store";
import { FieldSliceT } from '@stores/fields';
import { Tooltip } from '@mui/material';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';

import { assayFields } from '@components/compounds/view/detail/assays.config';
import { assayT } from '@components/compounds/view/detail/assays.service';
import { CompoundT } from '@src/type';

interface ColorTableT {
  compound: CompoundT;
  assay: assayT;
}

const ColorBarTable = (props: ColorTableT) => {
  const { compound, assay } = props;
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  const fields = assayFields[assay];
  return (
    <Container>
      {fields.map((f) => (
        <FlexRow key={'coltab_row_' + f} height={'20px'} v_centered>
          <FlexItem>
            <Tooltip
              title={allFields[f].short_description}
              placement={'left-end'}
              arrow
            >
              <LabelCell key={'lab_cell_' + f}>{allFields[f].symbol}</LabelCell>
            </Tooltip>
          </FlexItem>
          <FlexItem>
            <ColorBar
              key={f}
              value={_.get(compound, allFields[f].data_key, 0)}
              width={210}
            />
          </FlexItem>
        </FlexRow>
      ))}
    </Container>
  );
};

export default ColorBarTable;

const Container = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`;

const LabelCell = styled.div`
  width: 35px;
  text-align: right;
  font-size: ${(p) => p.theme.sizes.xsmall};
  font-weight: ${(p) => p.theme.weights.light};
  padding-right: 0;
  :hover {
    cursor: pointer;
  }
`;
