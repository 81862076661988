import React, { memo } from 'react';
import { Tooltip } from '@mui/material';

interface TextButtonPropsT {
  tooltip?: string,
  tooltipPlacement?: 'right' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | 'top',
  text: string,
  icon?: React.ReactNode, 
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  isActive?: boolean,
  isDisabled?: boolean,
  iconRight?: boolean,
  margin?: string,
  fontSize?: number,
  width?: number,
  height?: number,
}

const TextButton = (props: TextButtonPropsT) => {

  return (
      <Tooltip
        title={props.text}
        placement={props?.tooltipPlacement ? props.tooltipPlacement : 'right'}
        arrow
      >
        <TextButton {...props} />
      </Tooltip>
  );
}
export default memo(TextButton);