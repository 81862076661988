import React from 'react';
import { Modal, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  open: boolean;
  onClose: (redirect: boolean) => void;
}

const PopUp: React.FC<Props> = ({ open, onClose }: Props) => {
  const handleClose = (shouldRedirect: boolean) => {
    onClose(shouldRedirect);
  };

  const handleYesClick = () => {
    handleClose(true);
  };

  const handleNoClick = () => {
    handleClose(false);
  };

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <div
        style={{
          position: 'absolute',
          top: '25%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
          maxWidth: '400px',
          color: "black",
          padding: '50px',
          textAlign: 'center',
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          Are you sure you want to return back?
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Button size="small" onClick={handleYesClick}>
            Yes
          </Button>
          <Button size="small" onClick={handleNoClick}>
            No
          </Button>
        </div>
        <IconButton
          style={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={handleNoClick}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Modal>
  );
};

export default PopUp;
