import {useState} from "react";
import { AxiosRequestConfig } from 'axios';
import { handleError } from '@utils/misc';
import { ProductsCategoriesFull } from "./products.local";
import {ProductCategoryT} from "./products.types";
import useCognito from "@as_core/account/useCognito";
import {authCoreClient} from "@utils/api/base";

export type StripePriceT = {
  id: string,
  object: string,
  active: boolean,
  billing_scheme: string,
  created: Date,
  currency: string,
  custom_unit_amount: string | number,
  livemode: boolean,
  lookup_key: string,
  metadata: {[key: string]: string},
  nickname: string,
  product: string,
  recurring: boolean,
  tax_behavior: string,
  tiers_mode: string | number,
  transform_quantity: number,
  type: string,
  unit_amount: number,
  unit_amount_decimal: string
}

export type StripeProductT = {
  id: string,
  object: "product",
  active: boolean,
  attributes: string[],
  created: number,
  default_price: string,
  description: string,
  features: string,
  images: string[],
  livemode: boolean,
  metadata: {
    catalogNumber?: string,
    catalogType?: string,
    categoryCode?: string,
    pricingQuantity?: string,
    pricingType?: string,
    system?: string,
    unitOrderMinSize?: string,
    unitSize?: string
  },
  prices?: {[key: string]: StripePriceT},
  price?: StripePriceT,
  name: string,
  package_dimensions: string,
  shippable: boolean,
  statement_descriptor: string,
  tax_code: string,
  type: string,
  unit_label: string,
  "updated": Date,
  "url": string
}

export const Products = {
  all: (token:string, config: AxiosRequestConfig) => authCoreClient(token).get('/products/with_prices', config),
};

const debug: boolean = false;
const useStripeProducts = () => {
  // const system = APP_CONFIG.system;
  const { getToken } = useCognito();
  const [stripeProducts, setStripeProducts] = useState<StripeProductT[]>([]);
  const [stripeProductsLoaded, setStripeProductsLoaded] = useState<boolean>(false);

  const getAllProducts = async (region: string): Promise<Array<StripeProductT>> => {
    if (stripeProductsLoaded) {
      return stripeProducts;
    } else {
      let resp;
      let products = null;
      try {
        // const params = {query: 'meta[\"system\"]:' + system}
        const params = {limit: 100, region: region}
        const config: AxiosRequestConfig = {params};
        resp = await Products.all(getToken(), config);
        if (debug) console.log('Stripe API Response', resp.data);
        products = resp.data
        products.sort((a:StripeProductT, b:StripeProductT) =>
          a.metadata?.catalogNumber && a.metadata.catalogNumber.localeCompare(b.metadata?.catalogNumber));
        if (debug) console.log('sorted products', products);
        setStripeProducts(products);
        setStripeProductsLoaded(true);
        return products;
      } catch (err) {
        handleError(err);
        return null;
      }
    }
  };

  const getProductCategories = async (): Promise<Array<ProductCategoryT>> => {
    return ProductsCategoriesFull;
  };

  return {
    getProductCategories,
    getAllProducts,
  };
};

export default useStripeProducts;
