import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const DataUploadIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M17.2,21.5H7c-2.6,0-4.6-2.1-4.6-4.6v-2h1.5v2C3.9,18.6,5.3,20,7,20h10.2c1.7,0,3.1-1.4,3.1-3.1v-2h1.5v2
	C21.9,19.4,19.8,21.5,17.2,21.5z"/>
        <path fill={colorHighlight} d="M10.6,18.6c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.4-0.7-0.9-0.7-1.5v-3.7H6.8c-0.7,0-1.4-0.4-1.7-1c-0.3-0.7-0.3-1.5,0.2-2.1
	l5.2-6.9c0.8-1,2.3-0.9,3.1,0l5.2,6.9c0.4,0.6,0.5,1.4,0.2,2c-0.3,0.7-1,1.1-1.7,1.1H16v3.6c0,1.2-0.9,2.2-2.2,2.2h-3.1
	C10.7,18.6,10.6,18.6,10.6,18.6z M12,3.5c-0.1,0-0.2,0-0.4,0.2l-5.2,6.9c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.1,0.1,0.2,0.4,0.2h3.1v5.2
	c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.4,0.2,0.6,0.2l0.1,0h3.1c0.4,0,0.7-0.3,0.7-0.7v-5.1h2.8c0.2,0,0.3-0.1,0.3-0.2
	c0-0.1,0.1-0.3,0-0.5l-5.2-6.9C12.3,3.6,12.1,3.5,12,3.5z"/>
      </svg>
    </>
  )
}

export default DataUploadIcon;