import { ScatterPlotDatum } from "@nivo/scatterplot";

export type LinearRegressionResult = {
    slope: number,
    intercept: number,
    r2: number
}

export const runLinearRegression = (inputData: ScatterPlotDatum[]): LinearRegressionResult => {

    let n = inputData.length;
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let sum_yy = 0;

    inputData.forEach(dataPoint => {
        const { x, y } = dataPoint

        if (typeof x !== "number" || typeof y !== "number") {
            return;
        }

        sum_x += x;
        sum_y += y;
        sum_xy += (x * y);
        sum_xx += (x * x);
        sum_yy += (y * y);
    })

    const slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
    const intercept = (sum_y - slope * sum_x) / n;
    const r2 = Math.pow((n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)), 2);

    return ({ slope, intercept, r2 })
};
