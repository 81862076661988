import React from 'react';
import styled from 'styled-components/macro';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


interface SingleSelectRendererProps {
  getValue: () => string;
  getOptions: () => string[];
  onChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
}

const SingleSelectRenderer = (props: SingleSelectRendererProps) => {
  const { getValue, getOptions, onChange } = props;

  return (
      <StyledFormControl>
        <StyledSelect
          variant={'filled'}
          value={getValue()}
          onChange={onChange}
          displayEmpty
          disableUnderline
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value=''>-</MenuItem>
          {getOptions().map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
  );
};

export default SingleSelectRenderer;

const StyledFormControl = styled(FormControl)`
  color: ${(props) => props.theme.palette.textPrimary};
  margin: ${(props) => props.theme.sizes.xsmall};
  min-width: ${(props) => props.theme.sizes.xsmall};

  & .MuiSelect-icon {
    right: -${(p) => p.theme.sizes.xsmall};
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
    border-radius: ${(p) => p.theme.borderRadius};
    color: ${(props) => props.theme.palette.accentPrimary};
  }
`;

const StyledSelect = styled(Select)`
  width: 90%;
  & .MuiSelect-select {
    padding: 3px;
  }
  & .hover {
    border: 0;
  }
  & .MuiSelect-select:before {
    border: 0;
  }
  & .MuiFilledInput-input.MuiSelect-select {
    color: ${(p) => p.theme.palette.textPrimary };
  }
`;
