import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const NewCompoundIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M2.5 17.5L12 23L21.5 17.5V6.5L12 1L2.5 6.5V17.5Z" stroke={color} strokeWidth="2"
                strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M15.8 10.8H13.2V8.2C13.2 7.6 12.7 7 12 7C11.4 7 10.8 7.5 10.8 8.2V10.8H8.2C7.6 10.8
            7 11.3 7 12C7 12.6 7.5 13.2 8.2 13.2H10.8V15.8C10.8 16.4 11.3 17 12 17C12.6 17 13.2 16.5
            13.2 15.8V13.2H15.8C16.4 13.2 17 12.7 17 12C17 11.3 16.4 10.8 15.8 10.8Z" fill={colorHighlight}/>
        </g>
      </svg>
    </>

  )
}

export default NewCompoundIcon;