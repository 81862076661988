import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';

export interface TableDataT {
  columns: Array<string>;
  rows: Array<object>;
}

interface propsT {
  tableData: TableDataT;
}

const debug = false;
const DataTable = (props: propsT) => {
  if (debug) console.log('DataTable | props', props);
  const { tableData } = props;
  const columns: GridColDef[] = [];
  tableData.columns.forEach((column) => {
    columns.push({
      field: column,
      headerName: column,
      description: column,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Tooltip title={column}>
          <span className='table-cell-trucate'>{params.formattedValue}</span>
        </Tooltip>
      ),
    });
  });
  const rows = [];
  let i = 0;
  tableData.rows.forEach((row) => {
    row['id'] = i;
    rows.push(row);
    i += 1;
  });
  return <DataGrid columns={columns} rows={rows} />;
};

export default DataTable;
