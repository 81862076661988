import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
	size?: number;
  }

const OasisLogo = ({
    size = 64,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = style.name === "dark" ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill={colorHighlight} d="M310.6,381.9c0-3.1-2.5-5.6-5.6-5.6c-1.7,0-2.3-0.4-3.8-1.5c-2-1.5-5.1-3.7-10.5-3.7
	s-8.5,2.2-10.5,3.7c-1.5,1.1-2.1,1.5-3.8,1.5s-2.3-0.4-3.8-1.5c-2-1.5-5.1-3.7-10.5-3.7s-8.5,2.2-10.5,3.7c-1.5,1.1-2.1,1.5-3.8,1.5
	c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6c5.4,0,8.5-2.2,10.5-3.7c1.5-1.1,2.1-1.5,3.8-1.5c1.7,0,2.3,0.4,3.8,1.5
	c2,1.5,5.1,3.7,10.5,3.7s8.5-2.2,10.5-3.7c1.5-1.1,2.1-1.5,3.8-1.5s2.3,0.4,3.8,1.5c2,1.5,5.1,3.7,10.5,3.7
	C308.1,387.5,310.6,385,310.6,381.9z M242.2,359.4c0-3.1-2.5-5.6-5.6-5.6c-1.7,0-2.3-0.4-3.8-1.5c-2-1.5-5.1-3.7-10.5-3.7
	s-8.5,2.2-10.5,3.7c-1.5,1.1-2.1,1.5-3.9,1.5c-1.7,0-2.3-0.4-3.8-1.5c-2-1.5-5.1-3.7-10.5-3.7s-8.5,2.2-10.5,3.7
	c-1.5,1.1-2.1,1.5-3.8,1.5s-2.3-0.4-3.8-1.5c-2-1.5-5.1-3.7-10.5-3.7s-8.5,2.2-10.5,3.7c-1.5,1.1-2.1,1.5-3.8,1.5
	c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6c5.4,0,8.5-2.2,10.5-3.7c1.5-1.1,2.1-1.5,3.8-1.5s2.3,0.4,3.8,1.5c2,1.5,5.1,3.7,10.5,3.7
	s8.5-2.2,10.5-3.7c1.5-1.1,2.1-1.5,3.8-1.5s2.3,0.4,3.8,1.5c2,1.5,5.1,3.7,10.5,3.7s8.5-2.2,10.5-3.7c1.5-1.1,2.1-1.5,3.8-1.5
	c1.8,0,2.3,0.4,3.9,1.5c2,1.5,5.1,3.7,10.5,3.7C239.6,365,242.2,362.5,242.2,359.4z M273.8,318.8c0-3.1-2.5-5.6-5.6-5.6
	c-1.7,0-2.3-0.4-3.8-1.5c-2-1.5-5.1-3.7-10.5-3.7s-8.5,2.2-10.5,3.7c-1.5,1.1-2.1,1.5-3.8,1.5c-3.1,0-5.6,2.5-5.6,5.6
	s2.5,5.6,5.6,5.6c5.4,0,8.5-2.2,10.5-3.7c1.5-1.1,2.1-1.5,3.8-1.5s2.3,0.4,3.8,1.5c2,1.5,5.1,3.7,10.5,3.7
	C271.3,324.4,273.8,321.9,273.8,318.8z"/>
          <path fill={color} d="M495.8,181.9c-22.1,12.1-50.1,20.3-80.5,24.1c1.1,10.5,2.5,21.1,4.2,31.6c1,6.3-0.8,12.7-4.9,17.6
	c-4.1,4.7-10,7.4-16.2,7.4h-29.1c-6.3,0-12.2-2.7-16.2-7.5c-4.1-4.8-5.9-11.2-4.9-17.6c1.6-10,2.9-20.1,4-30.1
	c-1.3-0.1-2.6-0.2-3.9-0.3c-32.4-2.9-57.4-8.4-81.6-13.7c-32-7-62.3-13.6-109-14.7c-54.4-1.4-92.8,6.8-129.2,27.1
	c-3.2,1.8-7.3,0.7-9.1-2.6c-1.8-3.2-0.6-7.3,2.6-9.1c48.8-27.3,98.3-29.8,136-28.8c48,1.2,80.3,8.3,111.5,15.1
	c23.7,5.2,48.3,10.5,79.9,13.4c1.3,0.1,2.6,0.2,4,0.3c2.1-24.6,3-48.8,3.2-71.8c-17.6,12.9-32.7,28.9-42.2,41.7
	c-2.9,4-7.5,6.2-12.3,6.2c-0.8,0-1.6-0.1-2.3-0.2c-5.6-0.9-10.2-4.7-12-10c-8.4-24.5-4.6-59,23.6-76.7c0.5-0.3,1-0.6,1.6-0.9
	c-8.1-1.1-15.7-1.7-22.2-1.8c-5.7-0.1-10.9-3.3-13.5-8.4c-2.6-5-2.1-11,1.1-15.7c15-21.1,45.2-38.1,76-25.5
	c10.8,4.4,22.2,11.8,29.6,23.9c7.4-12.1,18.8-19.5,29.6-23.9c30.8-12.5,61,4.4,76,25.6c3.2,4.6,3.7,10.6,1.1,15.6
	c-2.6,5.1-7.8,8.3-13.5,8.4c-6.6,0.1-14.3,0.7-22.4,1.8c0.6,0.3,1.1,0.6,1.7,1c28.1,17.6,32,52,23.7,76.4
	c-1.8,5.4-6.4,9.2-12.1,10.1c-0.8,0.1-1.6,0.2-2.4,0.2c-4.9,0-9.5-2.3-12.5-6.3c-9.2-12.6-24.2-28.4-42-41.3
	c0.2,22.5,1,46.2,3.1,70.2c28.5-3.6,54.7-11.2,75.3-22.5c3.2-1.7,7.3-0.6,9.1,2.6C500.2,176.1,499,180.1,495.8,181.9z M367.8,180.8
	c-0.7,10.1-1.6,20.3-2.7,30.6h37.4c-1.1-10.2-2-20.5-2.7-30.6H367.8z M398.9,167.5c-0.5-10.4-0.9-20.7-1.1-30.7h-28.1
	c-0.2,10.1-0.6,20.3-1.1,30.7H398.9z M449.2,94.7c-18.8-11.8-36.3-10.9-53.4,2.6c29.1,14.8,54.4,40,68,58.6c0.7,1,1.7,0.9,2.1,0.9
	c0.7-0.1,1.2-0.5,1.4-1.2C474,135.9,471.1,108.5,449.2,94.7z M390.5,73.5c0.3,1.9,0.8,3.7,0.9,5.8c0.1,1.5,0.1,3,0.2,4.5
	c13.5-9.3,26.4-11.7,37.9-10.3c17.2-4.2,34.1-6.1,47.2-6.2c1.1,0,1.6-0.7,1.8-1.1c0.3-0.6,0.2-1.3-0.2-1.9
	c-9.2-13-25.5-24.2-43.6-24.2c-5.4,0-11,1.1-16.5,3.3C402.9,49.7,393.6,59.7,390.5,73.5z M383.8,106.5c-4.7,2-9.3,4.4-13.8,7
	c0,3.3,0,6.5,0,9.9h27.7c0-3.3,0-6.5,0-9.7C393.1,111,388.5,108.6,383.8,106.5z M337.8,73.5c12.3-1.5,26,1.2,40.4,11.9
	c0-2.1,0.1-4.2-0.2-6.1c-1.7-17-11.3-28.8-28.8-35.9c-5.6-2.3-11.1-3.3-16.5-3.3c-18,0-34.3,11.2-43.6,24.2
	c-0.4,0.6-0.5,1.3-0.1,1.9c0.2,0.5,0.7,1.1,1.7,1.1C303.8,67.5,320.6,69.3,337.8,73.5z M371.7,97.2c-9.1-7.2-18.3-10.8-27.8-10.8
	c-8.3,0-16.8,2.8-25.5,8.2c-22.1,13.8-24.8,41.3-18.1,61c0.2,0.7,0.7,1.1,1.4,1.2c0.4,0.1,1.3,0.1,1.9-0.8
	C317.6,137.1,343.1,111.5,371.7,97.2z M406.3,239.8c-0.8-5-1.3-10-2-15h-41c-0.7,5-1.2,10-2,15c-0.4,2.5,0.3,4.9,1.9,6.8
	c1.5,1.8,3.7,2.8,6.1,2.8h29.1c2.3,0,4.5-1,6-2.8C406,244.6,406.7,242.2,406.3,239.8z"/>
          <path fill={color} stroke={color} strokeWidth={10} strokeMiterlimit={10} d="M336.3,289.7l-100.1-62v-26.2c0-11.9-17.9-11.9-17.9,0v26.2l-95.3,56c-2.4,1.2-4.8,4.8-4.8,8.3v113.2L92,422
	c-9.5,7.1,1.2,21.4,10.7,14.3l25-15.5l95.3,56c2.4,1.2,6,1.2,9.5,0l104.9-63.2V289.7H336.3z M318.4,404.1l-90.6,53.6l-90.6-53.6
	V296.8l90.6-53.6l90.6,53.6V404.1z"/>
          <ellipse stroke={colorHighlight} strokeWidth={20} strokeMiterlimit={10} transform="matrix(8.248055e-02 -0.9966 0.9966 8.248055e-02 41.379 221.3451)"
                   cx="140.9" cy="88.2" rx="41.9" ry="41.9"/>
      </svg>
    </>
  )
}

export default OasisLogo;