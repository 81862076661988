import React from 'react';
import styled from 'styled-components/macro';
import RequestInfoHeader from '../common/RequestInfoHeader';
import Assign from "./partner/Assign";
import StatusUpdate from "./partner/StatusUpdate";
import { RequestT, RequestUpdateT } from '../hooks/useRequests';
import UploadData from "./partner/UploadData";

export const ActionToModalTitle: {[key:string]: string} = {
  'open' : 'Assign Request',
  'shipped'  : 'Update Request Status',
  'received': 'Update Request Status',
  'running': 'Upload Assay Data',
}

export function getRequestUpdateModalTitle(action: string): string {
  if (Object.hasOwn(ActionToModalTitle, action)) return ActionToModalTitle[action];
  return action;
}

export const UpdateModalDimensions = {
  open: { height: 200, width: 500 },
  shipped: { height: 400, width: 500 },
  received: { height: 400, width: 500 },
  running: { height: 400, width: 450 },
};

function getForm(
  action:string,
  view: string,
  currentRequest:RequestT,
  handleRequestUpdate: (id:string, updates: RequestUpdateT) => void,
  handleCancel: () => void,
) {
  switch(action) {
    case 'open':
      return (
        <Assign
          request={currentRequest}
          handleUpdate={handleRequestUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'shipped':
    case 'received':
      return (
        <StatusUpdate
          request={currentRequest}
          handleRequestUpdate={handleRequestUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'running':
      return (
        <UploadData
          request={currentRequest}
          handleUpdate={handleRequestUpdate}
          handleCancel={handleCancel}
        />
      )
    default:
      console.error('UpdateRequest | unknown form for action', action);
  }
}

type PropsT = {
  action: string;
  viewMode: string;
  request: RequestT;
  handleRequestUpdate: (id: string, updates: RequestUpdateT) => Promise<void>;
  closeDialog?: () => void;
}

const debug = false;
const UpdateRequest = (props:PropsT) => {
  let { action, viewMode, request, handleRequestUpdate} = props;
  debug && console.log('UpdateRequest | {action, viewMode, request}:', action, viewMode, request.id);

  const _handleUpdate = async (id:string, updates: RequestUpdateT) => {
    await handleRequestUpdate(id, updates);
    props?.closeDialog && props.closeDialog();
  }

  return (
    <UserEditContainer>
      <RequestInfoHeader action={action} request={request} />
      { getForm(action, viewMode, request, _handleUpdate, props?.closeDialog) }
    </UserEditContainer>
  );
};

export default UpdateRequest;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
