import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import Checkbox from '@components/controls/CheckBox';

import { UserContext } from '@stores/UserContext';

const emailPreferences = {
  emailsFromAsedaSciences: false,
  emailsFromAsedaSciencesPartners: false,
  emailsFromAsedaSciencesMarketing: false,
};

interface UserEmailPreferencesProps {
  isEditMode: boolean;
}

const UserEmailPreferences = ({ isEditMode }: UserEmailPreferencesProps) => {
  const debug = false;
  const { user } = useContext(UserContext);
  const [localEmailPreferences, setLocalEmailPreferences] = useState({
    ...emailPreferences,
  });
  if (debug) console.log('UserSubscriptions | user:', user);

  const togglePreferences = (value: boolean, preference: string) => {
    if (isEditMode) {
      setLocalEmailPreferences((previous) => ({
        ...previous,
        [preference]: value,
      }));
    }
  };

  return (
    <Container>
      <Row>
        <Item>
          <Checkbox
            selected={localEmailPreferences.emailsFromAsedaSciences}
            onChange={(value) =>
              togglePreferences(value, 'emailsFromAsedaSciences')
            }
            text='Promotional emails from AsedaSciences'
            disabled={!isEditMode}
          />
          <Checkbox
            selected={localEmailPreferences.emailsFromAsedaSciencesPartners}
            onChange={(value) =>
              togglePreferences(value, 'emailsFromAsedaSciencesPartners')
            }
            text='Promotional emails from AsedaSciences Partners/Services'
            disabled={!isEditMode}
          />
          <Checkbox
            selected={localEmailPreferences.emailsFromAsedaSciencesMarketing}
            onChange={(value) =>
              togglePreferences(value, 'emailsFromAsedaSciencesMarketing')
            }
            text='Promotional emails from AsedaSciences Marketing Partners'
            disabled={!isEditMode}
          />
        </Item>
      </Row>
    </Container>
  );
};

export default UserEmailPreferences;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;

  & div {
    color: white !important;
  }

  & span {
    padding: 0 16px 0 0 !important;
  }
`;
