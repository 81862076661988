import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const StarIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "#F2F2F2" : "#8F8F8F";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M7.58114 20C7.40584 20 7.23176 19.9429 7.0832 19.8308C6.80646 19.6215 6.67718 19.2618 6.7525
          18.9147L7.78182 14.1833L4.29102 10.9888C4.03442 10.7551 3.93639 10.3849 4.04174 10.0462C4.14709 9.70813
          4.43372 9.46878 4.77308 9.43605L9.39185 8.99849L11.2179 4.53921C11.3526 4.21158 11.6592 4 11.9999
          4C12.3407 4 12.6473 4.21158 12.7819 4.53845L14.608 8.99849L19.2261 9.43605C19.5662 9.46802 19.8528
          9.70813 19.9582 10.0462C20.0635 10.3843 19.9661 10.7551 19.7095 10.9888L16.2187 14.1827L17.248
          18.9139C17.3234 19.2618 17.194 19.6215 16.9174 19.8302C16.6414 20.0389 16.2734 20.0549 15.9827
          19.8726L11.9999 17.389L8.01718 19.874C7.88254 19.9575 7.73251 20 7.58114 20ZM11.9999 16.3192C12.1513
          16.3192 12.3012 16.3616 12.436 16.445L16.1948 18.7909L15.2233 14.3252C15.154 14.0074 15.2574 13.6756
          15.4927 13.4606L18.7888 10.4442L14.428 10.0309C14.114 10.001 13.844 9.79501 13.7213 9.49311L11.9999
          5.28491L10.2765 9.49375C10.1552 9.79361 9.88515 9.99959 9.57191 10.0295L5.21047 10.4427L8.50645
          13.4592C8.74254 13.6748 8.84582 14.006 8.77587 14.3246L7.80514 18.7901L11.5639 16.445C11.6985 16.3616
          11.8486 16.3192 11.9999 16.3192ZM9.35657 9.08472C9.35657 9.08472 9.35657 9.08549 9.35584 9.08613L9.35657
          9.08472ZM14.642 9.08269L14.6427 9.08409C14.6427 9.08332 14.6427 9.08332 14.642 9.08269Z"
                fill={color}/>
        </g>
      </svg>
    </>
  )
}

export default StarIcon;