import React from "react";
import styled from 'styled-components/macro';
import PageContainer from "@as_core/pages/PageContainer";
import { FlexItem } from "@components/layout/FlexStyles";
import TextButton from "@components/controls/TextButton/TextButton";
import { TextRegular } from '@components/elements/TextStyles';
import DetailsIcon from '@components/icons/details.icon';
import BasicTable from "@as_core/tables/BasicTable";
import BasicColumnTable from "@as_core/tables/BasicColumnTable";

const TableData = {
  "columns": [
    {label:"A", value: "A", width: 100},
    {label:"B", value: "B", width: 150},
    {label:"C", value: "C", width: 200},
  ],
  "rows": [
    { "A": 1, "B": 1, "C": 1},
    { "A": 2, "B": 2, "C": 3},
    { "A": 3, "B": 2, "C": 3}
  ]
}

const TableColumnData = {
  "rows": [
    {label:"", value: "A", height: 100},
    {label:"A", value: "A", height: 100},
    {label:"B", value: "B", height: 150},
    {label:"C", value: "C", height: 200},
  ],
  "columns": [
    { "A": 1, "B": 1, "C": 1},
    { "A": 2, "B": 2, "C": 3},
    { "A": 3, "B": 2, "C": 3}
  ]
}



const myArray = [];

for (let i = -1; i <= 11; i++) {
  myArray.push(i);
}


const PgDevTest = () => {
  return (
    <PageContainer title={"Developer Component Test Page"} >
      <HeaderRow><TextRegular color={'primary'}>Button Styles</TextRegular></HeaderRow>
      <Row>
        <FlexItem><TextButton label='Normal' /></FlexItem>
        <FlexItem><TextButton label='isDisabled' isDisabled={true}/></FlexItem>
        <FlexItem><TextButton label='isWarning' isWarning={true}/></FlexItem>
        <FlexItem><TextButton label='inProcess' inProcess={true}/></FlexItem>
      </Row>
      <HeaderRow><TextRegular color={'primary'}>Key Icons</TextRegular></HeaderRow>
      <Row>
        <FlexItem><DetailsIcon /></FlexItem>
      </Row>
      <HeaderRow>BasicTable</HeaderRow>
      <Row>
        <BasicTable fields={TableData["columns"]} rows={TableData["rows"]} debug={false}/>
      </Row>
      <HeaderRow>BasicColumnTable</HeaderRow>
      <Row>
        <BasicColumnTable fields={TableColumnData["rows"]} columns={TableColumnData["columns"]} debug={true}/>
      </Row>
    </PageContainer>
  );
};

export default PgDevTest;

const Row = styled.div`
  display: flex;
  padding: 5px;
  width: calc(100% - 50px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const HeaderRow = styled(Row)`
  margin-top: 25px;
`;