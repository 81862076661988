import React from 'react';
import styled from 'styled-components/macro';
import LabNotes from '../components/labNotes/LabNotes';

const PgLabNotes = (props: { className?: string }) => {
  return (
    <Container className={props?.className}>
      <LabNotes></LabNotes>
    </Container>
  );
};

export default PgLabNotes;

const Container = styled.div``;
