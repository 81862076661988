import styled from 'styled-components/macro';

export const FlexRowSpaced = styled.div<{ height?: string; width?: string }>`
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
FlexRowSpaced.defaultProps = {
  height: 'max-content',
  width: '100%',
};

export const FlexRow = styled.div<{
  height?: string;
  width?: string;
  h_centered?: boolean;
  v_centered?: boolean;
  panel?: boolean;
}>`
  ${(props) =>
    props.width &&
    `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : '100%'};
  `}
  height: ${(p) => p.height};
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.v_centered ? 'center' : 'flex-start')};
  justify-content: ${(props) => (props.h_centered ? 'center' : 'flex-start')};
  ${(p) =>
    p.panel &&
    `
    background: ${p.theme.palette.backgroundSecondary};
    border-radius: 15px;
    border: 1px solid ${p.theme.palette.backgroundQuaternary};
  `}
`;
FlexRow.defaultProps = {
  h_centered: false,
  v_centered: false,
  panel: false,
  height: 'max-content',
  width: 'max-content',
};

export const FlexColumn = styled.div<{
  width?: string;
  height?: string;
  h_centered?: boolean;
  v_centered?: boolean;
}>`
  ${(props) =>
    props.width &&
    `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : '100%'};
  `}
  ${(props) =>
    props.height &&
    `
    height: ${/^\d+(?:px|%)/.test(props.width) ? props.width : '100%'};
  `}
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.h_centered ? 'center' : 'flex-start')};
  justify-content: ${(props) => (props.v_centered ? 'center' : 'flex-start')};
`;
FlexRow.defaultProps = {
  h_centered: false,
  v_centered: false,
};

export const FlexItem = styled.div<{
  height?: string;
  width?: string;
  h_centered?: boolean;
  alignRight?: boolean;
}>`
  display: flex;
  padding: 3px;
  color: ${(p) => p.theme.palette.textPrimary};
  ${(props) =>
    props.width &&
    `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : 'auto'};
  `}
  ${(props) =>
    props.height &&
    `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : 'max-content'};
  `}
  align-content: ${(props) =>
    props.h_centered ? 'center' : props.alignRight ? 'flex-end' : 'flex-start'};
`;
FlexItem.defaultProps = {
  h_centered: false,
  alignRight: false,
};

export const FlexSpacer = styled.div<{ width?: number }>`
  width: ${(p) => p.width}px;
`;
FlexSpacer.defaultProps = {
  width: 50,
};

export const FlexBanner = styled.div<{ border?: boolean; spacing?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
  height: 50px;
  justify-content: ${(p) => (p.spacing ? p.spacing : 'space-between')};
  border-bottom-color: ${(p) => p.theme.palette.backgroundQuaternary};
  border-bottom-style: solid;
  border-bottom-width: ${(p) => (p.border ? '1px' : '0px')};
  width: 100%;
`;
FlexBanner.defaultProps = {
  border: false,
  spacing: 'space-between',
};

export const FlexBannerGroup = styled.div<{ align?: string; width?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(p) => (p.align ? p.align : 'center')};
  width: ${(p) => (p.width ? p.width : 'max-content')};
`;
FlexBannerGroup.defaultProps = {
  align: 'center',
  width: 'max-content',
};
