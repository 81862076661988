import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const SettingsIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M11.9984 22C10.7053 22 9.65327 20.948 9.65327 19.6549V19.6353C9.65327 19.3569 9.48446
        19.1108 9.22317 19.0082C8.50758 18.7272 7.84054 18.3388 7.24041 17.8537C7.01934 17.675 6.72005 17.6482
        6.47799 17.7856C6.47721 17.7861 6.4742 17.7878 6.47342 17.7882C5.34723 18.4229 3.91226 18.0258 3.2748
        16.9031C2.96571 16.3588 2.88758 15.7269 3.05483 15.124C3.22243 14.5198 3.61603 14.0174 4.16315
        13.7091L4.17289 13.7036C4.41045 13.5686 4.54522 13.3007 4.50832 13.0369C4.40885 12.3272 4.411 11.5951
        4.51493 10.8861C4.55417 10.6179 4.41694 10.3448 4.17343 10.2065L4.16065 10.1992C3.61603 9.89235 3.22243
        9.3899 3.05483 8.78578C2.88758 8.18284 2.96571 7.55101 3.2748 7.00666C3.9123 5.88395 5.34727 5.48699
        6.47342 6.12151L6.52044 6.14821C6.76074 6.2847 7.05967 6.25835 7.28211 6.08114C7.87067 5.61226 8.52275
        5.23551 9.22028 4.96132C9.48329 4.8579 9.65324 4.6113 9.65324 4.33305C9.78214 1.22159 14.2154 1.22304 14.3436
        4.33305V4.34876C14.3436 4.62549 14.5122 4.87158 14.7732 4.97574C15.4631 5.25103 16.108 5.62684 16.6899
        6.09271C16.9128 6.27117 17.2122 6.29826 17.4526 6.16177L17.5211 6.12284C18.6497 5.48699 20.0846 5.88403
        20.722 7.0067C21.0311 7.55101 21.1093 8.18284 20.942 8.78578C20.7744 9.3899 20.3808 9.89235 19.8337
        10.2006L19.7801 10.2311C19.5413 10.3667 19.4064 10.6345 19.4446 10.8976C19.5458 11.5961 19.5483 12.317
        19.4524 13.0162C19.4162 13.2806 19.5509 13.5485 19.7877 13.6829L19.8349 13.7097C20.3814 14.0181 20.7745
        14.5203 20.942 15.1239C21.5036 17.1196 19.3245 18.809 17.5234 17.7882L17.4962 17.7728C17.2534 17.6349
        16.9525 17.6629 16.7293 17.844C16.1363 18.3253 15.4773 18.7121 14.7705 18.9938C14.5112 19.0971 14.3436
        19.3428 14.3436 19.6196V19.6549C14.3436 20.948 13.2916 22 11.9984 22ZM6.80894 16.1332C7.31088 16.1332
        7.80993 16.3037 8.22327 16.6378C8.69914 17.0224 9.22778 17.3303 9.79461 17.5529C10.6585 17.8922 11.2167
        18.7095 11.2167 19.6353V19.6549C11.2598 20.6922 12.7375 20.6914 12.7802 19.6549V19.6196C12.7802 18.6989
        13.3342 17.8831 14.1916 17.5414C14.7516 17.3183 15.2739 17.0116 15.7441 16.6301C16.4708 16.0403 17.4614
        15.9551 18.268 16.4131L18.293 16.4273C18.6687 16.6389 19.1493 16.5066 19.3625 16.1311C19.4649 15.9507
        19.4908 15.7415 19.4354 15.5419C19.3798 15.3412 19.2487 15.174 19.0662 15.0712C19.0654 15.0708 19.0646
        15.0704 19.0638 15.0699L19.0155 15.0425C18.2159 14.5883 17.7793 13.7097 17.9034 12.8041C17.9795 12.2494
        17.9776 11.6757 17.8972 11.1216C17.7677 10.2267 18.214 9.32255 19.008 8.87166L19.0639 8.83992C19.2487
        8.73579 19.3798 8.56866 19.4355 8.36791C19.4909 8.16834 19.465 7.95911 19.3626 7.77877C19.1493 7.40323
        18.6686 7.271 18.2909 7.48371L18.2247 7.52135C17.4243 7.97584 16.4385 7.89415 15.7129 7.31329C15.2515
        6.94389 14.7404 6.64601 14.1939 6.42791C13.3351 6.08524 12.7802 5.26916 12.7802 4.34884V4.33309C12.7373
        3.29613 11.2596 3.29617 11.2168 4.33309C11.2168 5.25849 10.6576 6.07625 9.79238 6.41638C9.23982 6.63358
        8.7231 6.93224 8.25641 7.30399C7.53168 7.88141 6.54729 7.96142 5.74857 7.50782L5.70385 7.48246C5.32831
        7.27088 4.84767 7.40319 4.63442 7.77869C4.53201 7.95907 4.5061 8.1683 4.56144 8.36787C4.61714 8.56858
        4.74827 8.73575 4.93073 8.83855L4.94585 8.84711C5.74521 9.3011 6.19388 10.2115 6.062 11.1125C5.97957
        11.6749 5.97785 12.2573 6.0568 12.8202C6.18367 13.7271 5.74735 14.6074 4.94515 15.063L4.93311
        15.0698C4.74831 15.174 4.61718 15.3411 4.56148 15.5419C4.5061 15.7414 4.53201 15.9507 4.63442
        16.131C4.84759 16.5065 5.32827 16.6388 5.70604 16.4261C6.05164 16.2298 6.43109 16.1332 6.80894
        16.1332ZM11.9984 16.098C9.73547 16.098 7.8944 14.2569 7.8944 11.994C8.11985 6.54939 15.8779 6.55099
        16.1025 11.994C16.1025 14.2569 14.2614 16.098 11.9984 16.098ZM11.9984 9.45338C10.5976 9.45338 9.45784
        10.5931 9.45784 11.994C9.59742 15.3644 14.4 15.3634 14.539 11.994C14.539 10.593 13.3993 9.45338 11.9984
        9.45338Z"/>
      </svg>
    </>

  )
}

export default SettingsIcon;