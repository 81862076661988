import styled from "styled-components/macro";

export const UserDataContainer = styled.div<{ vertical?: boolean; border?: boolean;}>`
  display: flex;
  width: 100%;
  flex-direction: ${(p) => p.vertical ? 'column' : 'row'};
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  border: ${(p) => p.border ? p.theme.borders.thinAccentSecondary : '0'};
`;

UserDataContainer.defaultProps = {
  vertical: false,
  border: false
}
