import React from 'react';
import styled from 'styled-components/macro';

const LegendDefault = () => {
  return (
    <LegendDefaultContainer>
    </LegendDefaultContainer>
  );
};

export default LegendDefault;

const LegendDefaultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  margin-right: 4px;
  height: 40px;
  width: max-content;
  border-radius: 8px;
  border: 0 solid ${(p) => p.theme.palette.backgroundQuaternary };
`;

