import React from 'react';
import { Cell, Row } from '../shared/RowElements';

export interface ColumnT {
  sheetName: string;
  sheetIdx: number;
  colName: string;
  colIdx: number;
  colType: string;
  selected: boolean;
  fieldFilter: string;
  field_id: string;
  data: Record<string, string | number | boolean | object | undefined>[];
  // rows: ColumnRowsT[];
}



interface GenericColumnRowProps {
  column: ColumnT;
  colIdx: number;
}

const GenericColumnRow = (props: GenericColumnRowProps) => {
  const { column, colIdx } = props;
  let data = column?.data;
  let previewLen = Math.min(4, data.length);
  let dataPreview = data.slice(0,previewLen).join(', ');

  return (
    <Row key={colIdx}>
      <Cell>{column?.colName}</Cell>
      <Cell>{column?.colType}</Cell>
      <Cell>{dataPreview}</Cell>
    </Row>
  );
};

export default GenericColumnRow;
