import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import BasicTable from '@as_core/tables/BasicTable';
import { getObjectValueByKey } from '@utils/misc';
import { FieldsTypeT } from '@subApps/admin/components/ObjectDetails';
import { getTableRows } from '@subApps/admin/components/shared';
import SearchField from '@as_core/components/inputs/SearchField';
import {UserEventsItemT} from "@utils/useUserAppInfo";

const columns: FieldsTypeT[] = [
  {
    value: 'view',
    label: '',
    type: 'action',
    id: { key: 'authId', label: 'User' },
    width: 40,
  },
  { value: 'authId', label: 'Id', width: 260 },
  { value: 'authEmail', label: 'Email', width: 320 },
  { value: 'event', label: 'Event', width: 90 },
  { value: 'eventType', label: 'Event Type', width: 100 },
  { value: 'eventCount', label: 'Event Count', width: 180 },
];

export type EventSummaryT = {
  authId: string,
  authEmail: string,
  event: string,
  eventType: string,
  eventCount: number
}

interface UserEventsListI {
  allUserEvents: UserEventsItemT[];
  handleSelect: (v: string) => void;
}

const reduceUserEventsList = (events: UserEventsItemT[]): EventSummaryT[] => {
  const summary = {};
  events.forEach((e) => {
    const event_key = e.authId + '_' + 'e.event_type'
    if (!Object.hasOwn(summary, event_key)) {
      summary[event_key] = {
        authId: e.authId,
        authEmail: e?.authEmail ? e.authEmail : '',
        event: e.event,
        eventType: e.event_type,
        eventCount: 1
      }
    } else {
      summary[event_key].eventCount += 1;
    }
  })
  return Object.values(summary);
}

const debug = false;
const UserEventsList = (props: UserEventsListI) => {
  const {allUserEvents} = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [summary, setSummary] = useState<EventSummaryT[]>(reduceUserEventsList(allUserEvents));
  if (debug) console.log('UserList | allUserEvents: ', allUserEvents);

  // HANDLERS
  const handleClick = (action: string, userId: string) => {
    if (debug) console.log('UserList | handleClick (action):', action, ' (userId):', userId);
    props.handleSelect(userId);
  };

  useEffect(() => {
    setSummary(reduceUserEventsList(allUserEvents));
  }, [allUserEvents]);

  // search function
  function search(event) {
    const term = event.target.value;
    if (debug) console.log('UserList | search term:', term);
    if (props.allUserEvents.length && term.length > 0) {
      return props.allUserEvents.filter(
        (user) =>
          getObjectValueByKey(user, 'authId')
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          getObjectValueByKey(user, 'authEmail')
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          getObjectValueByKey(user, 'event')
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          getObjectValueByKey(user, 'eventType')
            .toLowerCase()
            .includes(term.toLowerCase())
      );
    } else {
      return props.allUserEvents;
    }
  }

  const filteredUserEvents = searchTerm !== '' ? search(searchTerm) : summary;
  if (debug) console.log('searchTerm', searchTerm, 'filteredUserEvents', filteredUserEvents);

  return (
    <UserEventsListContainer>
      {props.allUserEvents.length ? (
        <>
          <SearchField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={search}
          />
          <ScrollContainer>
            <BasicTable
              key={'user-table'}
              fields={columns}
              rows={getTableRows(filteredUserEvents, columns, handleClick)}
            />
          </ScrollContainer>
        </>
      ) : null}
    </UserEventsListContainer>
  );
};
export default UserEventsList;

const UserEventsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 1030px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
`;
