import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export const useResize = (ref) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  function setDimensions(ref) {
    setWidth(ref.current.clientWidth);
    setHeight(ref.current.clientHeight);
  }

  useEffect(() => {
    if (ref.current) setDimensions(ref);

    const handleResize = debounce(() => setDimensions(ref), 500);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return { width, height };
};
