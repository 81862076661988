import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Droppable } from 'react-beautiful-dnd';
import ItemDND from './ItemDND';
import _ from 'lodash';
import SearchField from '@as_core/components/inputs/SearchField';

interface IColumnDND {
  title?: string;
  columnId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any;
  height: number;
  hasSearch?: boolean;
}

const ColumnDND = ({
  title,
  columnId,
  state,
  height,
  hasSearch,
}: IColumnDND) => {
  const column = state.columns[columnId];

  const [searchValue, setSearchValue] = useState('');
  const [localColumn, setLocalColumn] = useState(state.columns[columnId]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    setLocalColumn(state.columns[columnId]);
  }, [columnId, state]);

  useEffect(() => {
    if (hasSearch) {
      const matches = Object.keys(state?.fields).filter(
        (fieldKey) =>
          _.get(state.fields[fieldKey], 'long_name', ' ')
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          _.get(state.fields[fieldKey], 'group_name', ' ')
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      );

      setLocalColumn({
        ...column,
        fieldIds: column.fieldIds.filter((field) => matches.includes(field)),
      });
    }
  }, [searchValue, state]);

  return (
    <Container>
      <Title>{title}</Title>
      <SearchContainer>
        {hasSearch ? (
          <SearchField
            placeholder='Search'
            value={searchValue}
            onChange={handleSearch}
          />
        ) : (
          <></>
        )}
      </SearchContainer>
      <Droppable droppableId={columnId}>
        {(provided, snapshot) => (
          <FieldList
            {...provided.droppableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            height={height}
          >
            {localColumn?.fieldIds?.map((fieldId, index) => {
              return (
                <ItemDND
                  key={`${fieldId}-${index}`}
                  index={index}
                  state={state}
                  columnId={columnId}
                  fieldId={fieldId}
                />
              );
            })}
            {provided.placeholder}
          </FieldList>
        )}
      </Droppable>
    </Container>
  );
};

export default ColumnDND;

const SearchContainer = styled.div`
  width: 200px;
  height: 30px;
`;

const Container = styled.div`
  width: 50%;
  height: 560px;
  padding: 5px;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const Title = styled.h3`
  padding: 2px;
  font-size: 16px;
  color: ${(p) => p.theme.palette.titlePrimary};
`;

const FieldList = styled.div<{ height: number }>`
  padding: 2px;
  height: calc(${(p) => p.height}px - 130px);
  border: ${(p) => p.theme.borders.thin};
  border-radius: ${(p) => p.theme.borderRadius};
  transition: background-color 0.2s ease;
  overflow-y: scroll;
`;
