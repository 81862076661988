export const properties = {
  exact_mol_wt: {
    name: 'Mol. Weight',
    format: '.2f',
  },
  lipinski_hbd: {
    name: 'H-bond donors',
    format: '.0f',
  },
  lipinski_hba: {
    name: 'H-bond acceptors',
    format: '.0f',
  },
  polar_surface_area: {
    name: 'Polar Surface Area',
    format: '.2f',
  },
  crippen_log_p: {
    name: 'log P',
    format: '.2f',
  },
  aromatic_rings: {
    name: 'Aromatic rings',
    format: '.0f',
  },
  heavy_atom_count: {
    name: 'Heavy atom count',
    format: '.0f',
  },
  heavy_atom_mol_wt: {
    name: 'Heavy atom mol. weight',
    format: '.2f',
  },
  rotatable_bonds: {
    name: 'Rotatable bonds',
    format: '.0f',
  },
};
