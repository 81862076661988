import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';
interface PropsT {
  size?: number;
}

const HeatMapIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2.5" y="2.5" width="4" height="4" fill={color}/>
          <rect x="7.5" y="2.5" width="4" height="4" fill={colorHighlight}/>
          <rect x="12.5" y="2.5" width="4" height="4" fill={color}/>
          <rect x="17.5" y="2.5" width="4" height="4" fill={colorHighlight}/>
          <rect x="2.5" y="7.5" width="4" height="4" fill={color}/>
          <rect x="7.5" y="7.5" width="4" height="4" fill={color}/>
          <rect x="12.5" y="7.5" width="4" height="4" fill={colorHighlight}/>
          <rect x="17.5" y="7.5" width="4" height="4" fill={color}/>
          <rect x="2.5" y="12.5" width="4" height="4" fill={colorHighlight}/>
          <rect x="7.5" y="12.5" width="4" height="4" fill={color}/>
          <rect x="12.5" y="12.5" width="4" height="4" fill={color}/>
          <rect x="17.5" y="12.5" width="4" height="4" fill={color}/>
          <rect x="2.5" y="17.5" width="4" height="4" fill={color}/>
          <rect x="7.5" y="17.5" width="4" height="4" fill={color}/>
          <rect x="12.5" y="17.5" width="4" height="4" fill={colorHighlight}/>
          <rect x="17.5" y="17.5" width="4" height="4" fill={color}/>
      </svg>
    </>
  )
}

export default HeatMapIcon;