import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import useStripeProducts, {StripeProductT} from '../products/useStripeProducts';
import {ProductCategoryT} from '../products/products.types';
import { UserContext } from '@stores/UserContext';
import ProductsTable from '../products/components/ProductsTable';
import OrdersCart from './components/OrdersCart';
import TextButton from '@components/controls/TextButton/TextButton';
import { FlexRow, FlexColumn } from '@components/layout/FlexStyles';
import { ListLoader } from '@as_core/elements/listStyles';
import ProductCategorySelector from "@business/products/components/ProductCategorySelector";
import {useNavigate} from "react-router-dom";
import {getIcon, showCodes} from "@subApps/orders/utils/orderIcons";
import {UseCartObjectReturnType} from "@as_core/hooks/useShoppingCart";

interface PropsI {
  ordersCart: UseCartObjectReturnType;
}

const debug: boolean = false;
const OrderCreate = (props: PropsI) => {
  const {ordersCart} = props;
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [activeCategory, setActiveCategory] = useState<string>('');
  const [products, setProducts] = useState<Array<StripeProductT>>([]);
  const [productCategories, setProductCategories] = useState<Array<ProductCategoryT>>([]);
  const { getAllProducts, getProductCategories } = useStripeProducts();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const orgType = user?.regInfo?.organization?.type ? user.regInfo.organization.type : 'commercial' ;
  if (debug) console.log('OrderCreate | user:', user, 'orgType:', orgType);
  if (debug) console.log('OrderCreate {products}', products);

  const handleSelectCategory = (code: string) => {
    if (debug) console.log('OrderCreate | handleSelectCategory', code);
    setActiveCategory(code);
  }

  // load the data
  const loadData = React.useCallback(async () => {
    if (!isDataLoading) {
      setIsDataLoading(true);
      const respProducts: Array<StripeProductT> = await getAllProducts(user.userRegion);
      if (debug) console.log('OrderCreate | loadData | getAllProducts | resp', respProducts);
      setProducts(respProducts);
      const respCategories: ProductCategoryT[] = await getProductCategories();
      if (debug) console.log('OrderCreate | loadData | getProductsCategories | resp', respCategories);
      const showCategories = respCategories
        .filter((c) => showCodes.includes(c.categoryCode))
        .map((c) => ({ ...c, icon: getIcon(c.categoryCode)}));
      if (debug) console.log('OrderCreate | loadData | getProductsCategories | showCategories', showCategories);
      setProductCategories(showCategories);
      if (showCategories.length) setActiveCategory(showCategories[0]?.categoryCode);
      setIsDataLoading(false);
      setDataLoaded(true);
    }
  }, []);

  // load data when component mounts first time
  useEffect(() => {
    if (!dataLoaded && !isDataLoading) {
      loadData().then();
    }
  }, []);

  // add product to cart at minimum order size;
  const handleAddToCart = (product: StripeProductT, priceType: string) => {
    const quantity = Number(product.metadata.unitOrderMinSize);
    if (debug) console.log('OrderCreate | handleAddToCart', ordersCart.data, quantity, product, priceType);
    ordersCart.add(product, quantity, priceType);
    if (!Object.hasOwn(ordersCart.data, 'AS-SCREEN-3RND') && !product.name.includes('Consulting')) {
      const product = products.find((p) => p.metadata.catalogNumber === 'AS-SCREEN-3RND');
      if (debug) console.log(product);
      if (product) ordersCart.add(product, 1, 'complementary');
    }
  };

  if (ordersCart.length() === 1 && Object.hasOwn(ordersCart.data, 'AS-SCREEN-3RND')) {
    ordersCart.delete('AS-SCREEN-3RND');
  }
  if (debug) console.log('OrderCreate | products', products);

  const showProducts = products.filter((p) =>
    p.metadata.catalogNumber !== 'AS-SCREEN-3RND'
    && p.metadata.categoryCode === activeCategory);
  return (
    <OrdersCreateContainer>
      {isDataLoading ? (
        <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
          <ListLoader />
        </FlexRow>
      ) : (
        <FlexColumn width={'max-content'} h_centered>
          <ProductsContainer>
            <ProductCategorySelector
              productCategories={productCategories}
              active={activeCategory}
              onSelect={handleSelectCategory}
            />
            <ProductsTable
              orgType={orgType}
              products={showProducts}
              onSelect={handleAddToCart}
            />
          </ProductsContainer>
          <ProductSettings>
            <Label>Customer Type:</Label><Value>{orgType}</Value>
            <Label>Customer Region:</Label><Value>{user.userRegion}</Value>
          </ProductSettings>
          <HorizontalLine />
          <OrdersCart
            cart={ordersCart}
            debug={false}
          />
          {ordersCart.length() ? (
            <OrdersButton>
              <TextButton
                width={300}
                label={'Proceed to Order Confirm/Purchase'}
                onClick={()=>navigate('/orders/confirm')}
              />
            </OrdersButton>
          ) : null}
        </FlexColumn>
      )}
    </OrdersCreateContainer>
  );
};

export default OrderCreate;

const OrdersCreateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const ProductsContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
`;

const ProductSettings = styled.div`
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Label = styled.div`
  font-size: 10px;
  font-style: italic;
  padding-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled(Label)`
`;

const OrdersButton = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const HorizontalLine = styled.div`
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid ${(p) => p.theme.palette.accentSecondary};
  height: 15px;
`;
