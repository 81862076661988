import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useLocation } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import CompoundView from '../components/compounds/CompoundView';
import { addFilter, clearAllFilters } from '@stores/filters';
import { useDispatch } from 'react-redux';

const debug = false;
const PgCompounds = () => {
  let { library, view } = useParams(); // this is the view parameter
  if (view === undefined) view = 'table'
  if (debug) console.log('PgCompounds | library: ', library, ' view:', view);
  const query = useQuery();
  const dispatch = useDispatch();
  const from = query.get('from');
  const filter = query.get('filter');
  const value = query.get('value');

  if (filter && value) {
    // handle link-outs from dashboard
    const newFilter = {
      type: 'select',
      uuid: uuidv4(),
      field: filter,
      comparator: 'includes',
      value: [value],
    };
    dispatch(clearAllFilters());
    dispatch(addFilter(newFilter));
  } else {
    if (from === 'menu') {
      // handle entering from side menu when potentially came in from side menu
      dispatch(clearAllFilters());
    }
  }
  const title = library === 'user' ? 'My Compounds' : 'AsedaSciences Compounds';

  return (
    <PageContainer title={title}>
      <CompoundView matchId={'myCompounds'} libraryId={library} view={view} />
    </PageContainer>
  );
};

export default PgCompounds;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
