import React from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';
import DisplayValue from './DisplayValue';
import { CompoundFieldsT } from '@stores/fields';
import { CompoundT } from '@src/type';


interface HandleChangeProps {
  action: 'UPDATECOMPOUND' | 'SETVIEW' | 'ADDOBJECT' | 'REMOVEOBJECT';
  payload: 
  | { field_id: string; newValue: string | number | boolean }
  | { view: string }
  | { fieldId: string; propId: string };

}

interface SectionRowProps {
  field_id: string;
  data_key: string;
  fields: CompoundFieldsT;
  compound: CompoundT;
  userId: string;
  handleChange: (props: HandleChangeProps) => void;
  className?: string;
}

const SectionRow = (props: SectionRowProps) => {
  const { field_id, fields, data_key, compound, handleChange } = props;

  //console.log("SectionRow {field_id, fieldSrc}",field_id, fieldSrc);

  return (
    <Row className={props?.className} {...props}>
      <Prop>
        {/* {_.get(compound, `${fieldSrc}.${field_id}.source_id`) === userId ? (
          <User>u</User>
        ) : (
          <React.Fragment />
        )} */}
        {fields[field_id].long_name}
      </Prop>
      <Value>
        <DisplayValue
          compound={compound}
          fields={fields}
          fieldId={field_id}
          propId={data_key}
          handleChange={handleChange}
        />
      </Value>
    </Row>
  );
};

export default SectionRow;

interface ExtraProps {
  compound?: CompoundT;
  field_id?: string;
  fieldSrc?: string;
}

const Row = styled.tr<ExtraProps>`
  margin-bottom: 3px;
  border-bottom: ${(p) => p.theme.borders.thin};
  
  background-color: ${({ compound, field_id, theme, fieldSrc }) => {
    const sourceId = _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') as string;
    return sourceId === 'new'
      ? theme.palette.backgroundQuaternary
      : 'inherit';
  }};
  
  color: ${({ compound, field_id, theme, fieldSrc }) => {
    const sourceId = _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') as string;
    return sourceId === 'new'
      ? theme.palette.textQuaternary
      : 'inherit';
  }};
  
  &:nth-child(even) {
    background-color: ${({ compound, field_id, theme, fieldSrc }) => {
      const sourceId = _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') as string;
      return sourceId === 'new'
        ? theme.palette.accentSecondary
        : 'inherit';
    }};
  }
`;


const Cell = styled.td<ExtraProps>`
  padding-left: ${(p) => p.theme.sizes.xxsmall};
  vertical-align: text-top;
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: ${(p) => p.theme.weights.light};
`;

const Prop = styled(Cell)`
  padding-left: ${(p) => p.theme.sizes.xxsmall};
`;

const Value = styled(Cell)`
  padding-left: ${(p) => p.theme.sizes.large};
`;
