import React from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import RadioButton from '@pages/shared/RadioButton';
import HoverInfoText from "@as_core/account/fields/HoverInfoText";

interface RadioSelectorOptionsT {
  options: Array<{ value: string; label: string; info?: string }>;
  selected: string;
  radioKey?: string;
  vertical?: boolean;
  fontSize?: number;
  onChange: (e) => void;
}

const RadioButtonSelector = (props: RadioSelectorOptionsT) => {
  const { options, selected, radioKey, vertical = false, fontSize=14, onChange } = props;
  return (
    <RadioSelectorContainer
      key={radioKey || uuidv4()}
      onChange={onChange}
      vertical={vertical}
    >
      {options.map((v, i) => {
        const isChecked = selected === v.value;
        return (
          <Item key={i}>
            <StyledRadioButton
              key={v.value + '_' + i}
              id={v.label}
              fontSize={fontSize}
              checked={selected === v.value}
              value={v.value}
            >
              <StyledLabel isChecked={isChecked}>{v.label}</StyledLabel>
              
            </StyledRadioButton>
            { v?.info ? <HoverInfoText text={v.info}/> : null}
          </Item>
        );
      })}
    </RadioSelectorContainer>
  );
};

export default RadioButtonSelector;

const RadioSelectorContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.vertical ? 'column' : 'row')};
  
`;

const StyledRadioButton = styled(RadioButton)`
  display: flex;
  padding-left: 10px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const Item = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  margin-right: 10px;
`;

const StyledLabel = styled.span<{isChecked: boolean}>`
  font-weight: bold;
  color: ${(p) => p.isChecked ? p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  font-weight: ${(p) => (p.isChecked ? 'bold' : 'normal')};
  text-decoration: ${(p) => (p.isChecked ? 'underline' : 'none')};
`;
