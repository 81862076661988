import React from 'react';
import { LayerCard } from '../LayerCard';
import { VolcanoBase, PlotConfig } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Stack } from '@mui/system';
import { Slider, Typography } from '@mui/material';

type VolcanoCardProps = {
  plot: PlotConfig<VolcanoBase>;
  onChange: (newPlotConfig: PlotConfig<VolcanoBase>) => void;
};

export const VolcanoCard = ({ plot, onChange }: VolcanoCardProps) => {

  const updateFoldChangeColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<VolcanoBase> = {
      ...plot,
      config: { ...plot.config, foldChangeColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updatePValueColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<VolcanoBase> = {
      ...plot,
      config: { ...plot.config, pValueColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updatePValueThreshold = (e: Event, newThreshold: number) => {
    const newPlotConfig: PlotConfig<VolcanoBase> = {
      ...plot,
      config: { ...plot.config, pValueThreshold: newThreshold },
    };
    onChange(newPlotConfig);
  };

  const updateFoldChangeThreshold = (e: Event, newThreshold: number) => {
    const newPlotConfig: PlotConfig<VolcanoBase> = {
      ...plot,
      config: { ...plot.config, foldChangeThreshold: newThreshold },
    };
    onChange(newPlotConfig);
  };

  return (
    <LayerCard title={'Volcano Plot'} disableExpand>
      <Stack spacing={2} width={'100%'}>
        <VariableSelect
          id='variable-key'
          label={'Fold Change Variable'}
          value={plot.config.foldChangeColumn}
          options={plot.columns}
          onChange={updateFoldChangeColumn}
        />
        <VariableSelect
          id='variable-value'
          label={'P-Value Variable'}
          value={plot.config.pValueColumn}
          options={plot.columns}
          onChange={updatePValueColumn}
        />
        <br />
        <div>
          <Typography
            id='input-slider'
            color={'textSecondary'}
            sx={{ marginBottom: '0px', fontSize: 14 }}
          >
            p-value Threshold
          </Typography>
          <Slider
            defaultValue={0.05}
            step={0.01}
            marks
            size='small'
            min={0.01}
            max={0.2}
            valueLabelDisplay='auto'
            onChange={updatePValueThreshold}
          />
        </div>
        <div>
          <Typography
            id='input-slider-fold-change'
            color={'textSecondary'}
            sx={{ marginBottom: '0px', fontSize: 14 }}
          >
            Fold Change Threshold
          </Typography>
          <Slider
            defaultValue={2}
            step={0.1}
            marks
            size='small'
            min={1}
            max={3}
            valueLabelDisplay='auto'
            onChange={updateFoldChangeThreshold}
          />
        </div>
      </Stack>
    </LayerCard>
  );
};
