import React from 'react';
import { arc as d3arc } from 'd3';
import { nanColor } from '../config/colors';

const arc = d3arc().cornerRadius(0.2);

export type CoronaSegmentT = {
  fieldType: string;
  fieldName: string;
  fieldSymbol: string;
  startAngle: number;
  endAngle: number;
  innerRadius: number;
  outerRadius: number;
}

interface PropsI {
  color?: string,
  segment: CoronaSegmentT
}

const CoronaSegment = (props:PropsI) => {
  return (
    <path
      className={`vertex-corona-ring-${props.segment.fieldType}`}
      d={arc(props.segment)}
      fill={props?.color ? props.color : nanColor}
    />
  );
};

export default CoronaSegment;
