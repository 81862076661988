import React, { useState } from 'react';
import styled from 'styled-components';
import useData from '@components/dataSets/useData';

import UploadDocumentListItem from './UploadDocumentListItem';
import Alert from '@components/elements/Alert';
import StationaryModal from '@components/elements/StationaryModal';
import PreviewData from './PreviewData';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import { DataSetT } from '@components/dataSets/dataSet.types';
import { FlexItem } from '@components/layout/FlexStyles';
import DataLoading from '@as_core/elements/DataLoading';
import useCognito from '@as_core/account/useCognito';
import ErrorMessages, { MessageItem } from '@as_core/elements/UserMessages';
import SearchField from '@as_core/components/inputs/SearchField';
import MenuButton from "@components/controls/MenuButton";
import {Replay} from "@mui/icons-material";


const debug: boolean = process.env.REACT_APP_HOST_TYPE === 'dev';
export const UploadDocumentList = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [previewUuid, setPreviewUuid] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const [dataSets, setDataSets] = useState<DataSetT[]>([]);
  const [dataSetToDelete, setDataSetToDelete] = useState<string>('');
  const { getAllData, deleteData } = useData();
  const { getToken } = useCognito();
  const { height, width } = useWindowDimensions();

  const modalHeight = height - 150;
  const modalWidth = width - 200;

  // HANDLERS

  const updateSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setSearchText('');
    }
  };

  const handlePreviewClick = (uuid) => {
    if (debug) console.log('handlePreviewClick ', uuid);
    setPreviewUuid(uuid);
    setModalOpen(true);
  };

  const handleDeleteClick = (uuid) => {
    if (debug) console.log('handleDeleteClick ', uuid);
    setDataSetToDelete(uuid);
    setIsDeleteAlertOpen(true);
  };

  const _handleCancel = () => {
    setDataSetToDelete('');
    setIsDeleteAlertOpen(false);
  };

  const _handleRefresh = () => {
    setLoaded(false);
  }

  const _handleDeleteConfirm = () => {
    if (debug) console.log('deleteConfirmed for ');
    deleteData(getToken(), dataSetToDelete).then(() => {
      setDataSets((previous) =>
        previous.filter((data) => data.uuid !== dataSetToDelete)
      );
      setDataSetToDelete('');
    });
  };

  if (!loaded) {
    getAllData(getToken(), {}).then(({ data }) => {
      setLoaded(true);
      setDataSets(data);
    });
  }

  if (!loaded) {
    return (
      <DocumentListContainer>
        <DataLoading />
      </DocumentListContainer>
    );
  }
  let filteredList = dataSets;
  if (searchText.length > 2) {
    filteredList = dataSets.filter(
      (d) =>
        d.name.toLowerCase().includes(searchText.toLowerCase()) ||
        d.type.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  if (debug) console.log('UploadDocumentList | dataSets:', dataSets);
  return (
    <DocumentListContainer>
      {dataSets && dataSets.length ? (
        <>
          <TopBar>
            <MenuButton
              text={'Refresh List'}
              icon={<StyledReplay />}
              onClick={_handleRefresh}
              tooltipPlacement={'bottom-start'}
            />
            <FlexItem width={'100%'}>
              <SearchField
                value={searchText}
                onChange={updateSearch}
                onKeyDown={handleKeyDown}
              />
            </FlexItem>
          </TopBar>
          <ScrollContainer>
            <DocumentList>
              {filteredList.map((data, index) => (
                <UploadDocumentListItem
                  dataSet={data}
                  handleDeleteClick={handleDeleteClick}
                  handlePreviewClick={handlePreviewClick}
                  key={`document-list-item-${index}`}
                />
              ))}
            </DocumentList>
          </ScrollContainer>
          <Alert
            type={'deleteConfirm'}
            title={'Delete Plot'}
            message={'Confirm delete by typing DELETE below to Enable Delete'}
            alertOpen={isDeleteAlertOpen}
            onConfirm={_handleDeleteConfirm}
            closeAlert={_handleCancel}
          />
          <StationaryModal
            title={'Data Preview'}
            modalOpen={modalOpen}
            onCloseClick={() => setModalOpen(false)}
            height={modalHeight}
            width={modalWidth}
          >
            <PreviewData
              uuid={previewUuid}
              height={modalHeight - 40}
              width={modalWidth}
            />
          </StationaryModal>
        </>
      ) : (
        <ErrorMessages
          messages={[
            <MessageItem key="msg1" color="accentSecondary">
              You have not uploaded any datasets.
            </MessageItem>,
            <MessageItem key="msg2">
              Click on &quot;Upload New Data Set&quot; on the upper-right to start loading data.
            </MessageItem>,
          ]}
        />

      )}
    </DocumentListContainer>
  );
};

const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: calc(100vh - 120px);
  width: calc(100% - 20px);
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledReplay = styled(Replay)`
  :hover {
    color: ${(p) => p.theme.palette.accentSecondary}
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  width: calc(100% - 10px);
  height: 100%;
  padding: 5px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const DocumentList = styled.div`
  display: flex;
  flex-grow: initial;
  flex-direction: column;
  width: 100%;
`;
