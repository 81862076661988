import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
	size?: number;
  }

const CustomCurationIcon = ({
	size = 24,
  }: PropsT) => {

  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <polygon fill={color} opacity={0.5} points="145.9,256 255.6,309.6 363.5,247.3 361.2,281.4 394.7,272.7 397.4,469.2 254.1,507.1 113.9,470.2 114.3,273.5
				144.8,276.9"/>
        <path fill={colorHighlight} d="M258,133.2h6c2.4,9.2,10.7,16,20.7,16c11.7,0,21.3-9.6,21.3-21.3s-9.6-21.3-21.3-21.3
			c-9.9,0-18.3,6.8-20.7,16h-6c-2.4-9.2-10.7-16-20.7-16c-11.7,0-21.3,9.6-21.3,21.3s9.6,21.3,21.3,21.3
			C247.4,149.2,255.7,142.4,258,133.2z M284.7,117.3c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7
			C274.1,122.1,278.8,117.3,284.7,117.3z M226.8,127.9c0-5.9,4.8-10.7,10.7-10.7s10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7
			S226.8,133.8,226.8,127.9z"/>
        <path fill={color} d="M362.7,255.1c-5-7.7-12.5-13.6-21.2-16.6l-28.7-9.9c-5.6-1.9-10.4-5.4-14.1-10c-1.8-2.3-5.2-2.7-7.5-0.9
			c-2.3,1.8-2.7,5.2-0.9,7.5c0.9,1.1,1.9,2.2,2.9,3.2l-6.6,18.5c-8.7,6.2-19.4,9.1-30,7.8c-2.9-0.3-5.6,1.7-5.9,4.7
			c-0.3,2.9,1.7,5.6,4.7,5.9c8.9,1,17.7-0.2,25.9-3.4l-17.5,49.3c-1,2.7,0.4,5.8,3.2,6.8c2.7,1,5.8-0.4,6.8-3.2l28.3-79.4
			c6.2,3.6,9.6,3.8,35.9,13.2c6.5,2.2,12,6.6,15.8,12.4c1.6,2.4,4.9,3.2,7.4,1.6C363.5,260.9,364.2,257.6,362.7,255.1L362.7,255.1z"
        />
        <path fill={color} d="M229.6,228.4c5.7-5.9,9.6-13.4,11.1-21.6c44.9,13.6,91.5-19.7,91.9-67.7c0-0.1,0-43.8,0-43.8
			c0-20.5-16.7-37.3-37.3-37.3h-67.9c-20.5,0-37.3,16.7-37.3,37.3v42.4c-7.5-1.9-13.5-7.6-15.5-15.2c-2.1-7.9-3.2-16.2-3.1-24.5
			c1.3-118.7,178.5-118.2,179.8,0c0.1,8.3-0.9,16.5-3.1,24.5c-0.7,2.9,0.9,5.7,3.7,6.6c2.9,0.7,5.7-0.9,6.6-3.7
			c2.4-8.9,3.6-18.1,3.4-27.3c-1.4-132.1-199.6-132.8-201.1,0c-0.1,9.3,1.1,18.5,3.4,27.3c3.4,12.5,13.9,21.6,26.6,23.4
			c1.9,13.4,7.6,26,16.5,36.3c1.9,2.2,5.3,2.5,7.5,0.6c2.2-1.9,2.5-5.3,0.6-7.5c-9.5-11-14.7-25-14.7-39.6V95.5
			c0-14.7,11.9-26.7,26.7-26.7h67.9c14.7,0,26.7,11.9,26.7,26.7v43.2c0,44.3-45.3,72.3-84.1,55.8c-4.3-1.8-8.6,2.4-7.1,6.7
			c-0.6,12.4-8.8,23.3-20.7,27.4l-28.7,9.9c-16.8,5.8-28,21.5-28,39.1v35.5c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3v-35.5
			c0-13.1,8.3-24.8,20.7-29l0,0c0,0.1-0.6,0.2,13.5-4.6l-1.6,49c-0.1,1.4,0.5,2.7,1.4,3.8l18.7,20c2,2.1,5.4,2.2,7.6,0.2
			c2.1-2,2.2-5.4,0.2-7.6l-17.2-18.4l1.6-50.9c4.7-1.6,7.6-2.4,11.6-4.8l28.3,79.4c1,2.7,4.1,4.2,6.8,3.2s4.2-4.1,3.2-6.8
			L229.6,228.4z"/>
        <path fill={color} d="M319.5,258c-2.9,0.1-5.2,2.6-5.2,5.5l0.9,27.6l-17.2,18.4c-2,2.1-1.9,5.5,0.2,7.6c2.2,2,5.6,1.9,7.6-0.2
			l18.7-20c0.9-1,1.5-2.4,1.4-3.8l-0.9-29.8C325,260.1,322.5,257.8,319.5,258L319.5,258z"/>
        <path fill={color} d="M207.1,318.9c0.6-2.3-0.7-4.6-3-5.2L165,303.2c-2.3-0.6-4.6,0.7-5.2,3c-0.6,2.3,0.7,4.6,3,5.2l39.1,10.5
			C204.2,322.5,206.5,321.2,207.1,318.9z"/>
        <path fill={colorHighlight} d="M128.7,371.1l46.9,12.6c3.5,0.9,6.9-1.7,6.9-5.3v-42c0-2.5-1.7-4.6-4-5.3l-46.9-12.6
			c-3.5-0.9-6.9,1.7-6.9,5.3v42C124.7,368.3,126.3,370.5,128.7,371.1z M133.2,327.9l40.8,10.9v35.6l-40.8-10.9V327.9z"/>
        <path fill={color} d="M232.9,343.5l-34.8-9.3c-2.3-0.6-4.6,0.7-5.2,3c-0.6,2.3,0.7,4.6,3,5.2l34.8,9.3c2.3,0.6,4.6-0.7,5.2-3
			C236.5,346.4,235.2,344.1,232.9,343.5L232.9,343.5z"/>
        <path fill={color} d="M195.9,390l34.8,9.3c2.3,0.6,4.6-0.7,5.2-3c0.6-2.3-0.7-4.6-3-5.2l-34.8-9.3c-2.3-0.6-4.6,0.7-5.2,3
			C192.2,387,193.6,389.4,195.9,390z"/>
        <path fill={color} d="M193.6,358.3l49.3,13.2c2.3,0.6,4.6-0.7,5.2-3c0.6-2.3-0.7-4.6-3-5.2l-49.3-13.2c-2.3-0.6-4.6,0.7-5.2,3
			C190,355.4,191.3,357.7,193.6,358.3z"/>
        <path fill={color} d="M191.9,373.9l49.3,13.2c2.3,0.6,4.6-0.7,5.2-3c0.6-2.3-0.7-4.6-3-5.2l-49.3-13.2c-2.3-0.6-4.6,0.7-5.2,3
			C188.3,371,189.7,373.3,191.9,373.9z"/>
        <path fill={colorHighlight} d="M242.6,427.8l-46.9-12.6c-3.5-0.9-6.9,1.7-6.9,5.3v42c0,2.5,1.7,4.6,4,5.3l46.9,12.6c3.5,0.9,6.9-1.7,6.9-5.3
			v-42C246.6,430.6,245,428.5,242.6,427.8z M238.1,471l-40.8-10.9v-35.6l40.8,10.9V471z"/>
      <path fill={color} d="M179.4,415L130,401.8c-2.3-0.6-4.6,0.7-5.2,3c-0.6,2.3,0.7,4.6,3,5.2l49.3,13.2c2.3,0.6,4.6-0.7,5.2-3
			C183,417.9,181.6,415.6,179.4,415z"/>
      <path fill={color} d="M162,453.1l-31.9-8.6c-2.3-0.6-4.6,0.7-5.2,3c-0.6,2.3,0.7,4.6,3,5.2l31.9,8.6c2.3,0.6,4.6-0.7,5.2-3
			C165.6,456.1,164.2,453.8,162,453.1z"/>
      <path fill={color} d="M179.4,429.8L130,416.6c-2.3-0.6-4.6,0.7-5.2,3c-0.6,2.3,0.7,4.6,3,5.2l49.3,13.2c2.3,0.6,4.6-0.7,5.2-3
			C183,432.7,181.6,430.4,179.4,429.8z"/>
        <path fill={color} d="M179.4,444.6L130,431.4c-2.3-0.6-4.6,0.7-5.2,3c-0.6,2.3,0.7,4.6,3,5.2l49.3,13.2c2.3,0.6,4.6-0.7,5.2-3
			C183,447.6,181.6,445.2,179.4,444.6z"/>
        <path fill={colorHighlight} d="M336.3,352.2l46.9-12.6c2.4-0.6,4-2.8,4-5.3v-42c0-3.6-3.4-6.2-6.9-5.3l-46.9,12.6c-2.4,0.6-4,2.8-4,5.3v42
			C329.5,350.5,332.9,353.1,336.3,352.2L336.3,352.2z M338,307.3l40.8-10.9V332L338,342.9V307.3z"/>
        <path fill={color} d="M285.5,321.4l35.1-9.4c2.3-0.6,3.6-2.9,3-5.2c-0.6-2.3-2.9-3.6-5.2-3l-35.1,9.4c-2.3,0.6-3.6,2.9-3,5.2
			C280.9,320.7,283.3,322,285.5,321.4L285.5,321.4z"/>
        <path fill={color} d="M271.4,369.6l34.8-9.3c2.3-0.6,3.6-2.9,3-5.2c-0.6-2.3-2.9-3.6-5.2-3l-34.8,9.3c-2.3,0.6-3.6,2.9-3,5.2
			C266.8,368.9,269.2,370.2,271.4,369.6L271.4,369.6z"/>
        <path fill={color} d="M271.3,340l49.3-13.2c2.3-0.6,3.6-2.9,3-5.2c-0.6-2.3-2.9-3.6-5.2-3l-49.3,13.2c-2.3,0.6-3.6,2.9-3,5.2
			C266.7,339.3,269,340.6,271.3,340z"/>
        <path fill={color} d="M271.3,354.8l49.3-13.2c2.3-0.6,3.6-2.9,3-5.2s-2.9-3.6-5.2-3l-49.3,13.2c-2.3,0.6-3.6,2.9-3,5.2
			C266.7,354.1,269.1,355.4,271.3,354.8z"/>
        <path fill={colorHighlight} d="M380.5,409.5L270.1,439c-2.4,0.6-4,2.8-4,5.3v44.2c0,3.6,3.4,6.2,6.9,5.3l110.3-29.6c2.4-0.6,4-2.8,4-5.3
			v-44.2C387.3,411.2,383.9,408.5,380.5,409.5z M378.8,456.6l-104.2,27.9v-37.9l104.2-27.9V456.6z"/>
        <path fill={color} d="M285.5,382.9l98.6-26.4c2.3-0.6,3.6-2.9,3-5.2c-0.6-2.3-2.9-3.6-5.2-3l-98.6,26.4c-2.3,0.6-3.6,2.9-3,5.2
			C280.9,382.1,283.3,383.5,285.5,382.9L285.5,382.9z"/>
        <path fill={color} d="M271.3,431.1l63.5-17c2.3-0.6,3.6-2.9,3-5.2c-0.6-2.3-2.9-3.6-5.2-3l-63.5,17c-2.3,0.6-3.6,2.9-3,5.2
			C266.7,430.4,269.1,431.7,271.3,431.1z"/>
        <path fill={color} d="M271.3,401.5l112.9-30.2c2.3-0.6,3.6-2.9,3-5.2s-2.9-3.6-5.2-3l-112.9,30.2c-2.3,0.6-3.6,2.9-3,5.2
			C266.7,400.8,269,402.1,271.3,401.5z"/>
        <path fill={color} d="M271.3,416.3l112.9-30.2c2.3-0.6,3.6-2.9,3-5.2c-0.6-2.3-2.9-3.6-5.2-3l-112.9,30.2c-2.3,0.6-3.6,2.9-3,5.2
			C266.7,415.6,269,416.9,271.3,416.3z"/>
        <path fill={color} d="M394.7,269.1l-25,6.7v-26.6c0-2.2-1.2-4.2-3-5.2c-1.9-1.1-4.2-1.1-6.1,0l-54.2,32c-2,1.2-2.7,3.8-1.5,5.8
			c1.2,2,3.8,2.7,5.8,1.5l50.5-29.8v24.5l-76,20.4l7.8-4.6c2-1.2,2.7-3.8,1.5-5.8c-1.2-2-3.8-2.7-5.8-1.5l-32.8,19.3l-106.5-54.1
			c-4-2-8.8,0.9-8.8,5.4v18.3l-23.3-6.2c-3.5-0.9-6.9,1.7-6.9,5.3v195c0,2.5,1.7,4.6,4,5.3l137.3,36.8c2.8,0.7,5.7,0.7,8.5,0
			l137.3-36.8c2.4-0.6,4-2.8,4-5.3v-195C401.5,270.8,398.1,268.2,394.7,269.1z M149.2,261.2l68.6,34.8l-68.6-18.4V261.2z M119,278.4
			c15.3,4.1,110.7,29.7,132.8,35.6v106.5c0,2.4,1.9,4.3,4.3,4.3s4.3-1.9,4.3-4.3V314c23.3-6.2,106.3-28.5,106.3-28.5l26.4-7.1V467
			l-132.8,35.6v-62.2c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3v62.2L119,467L119,278.4z"/>
      </svg>
    </>
  )
}

export default CustomCurationIcon;