import _ from 'lodash';

export type assayT = 'chi' | 'zf';
import { assayFields } from '@components/compounds/view/detail/assays.config';

export function assayAvailable(compound, allFields, assay: assayT) {
  for (const f of assayFields[assay]) {
    if (_.get(compound, allFields[f]?.data_key, '') !== '') return true;
  }
}
