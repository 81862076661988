import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { IBugReport } from './bugs.types';
import BugComments from '@as_core/bugsReporting/bugComments';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

interface IUserField {
  label?: string;
  key: string;
  type: string;
  editable?: boolean;
}

const userFields: Array<IUserField> = [
  { label: 'Title', key: 'title', type: 'string' },
  { label: 'Category', key: 'category', type: 'string' },
  { label: 'Submitter Email', key: 'submit.submitter.email', type: 'string' },
  { label: 'Created On', key: 'submit.time_submitted', type: 'date' },
  { label: 'Description', key: 'description', type: 'string' },
  { type: 'line', key: 'line1' },
  {
    label: 'Acknowledged',
    key: 'submit.acknowledged',
    type: 'boolean',
    editable: true,
  },
  { label: 'Date Acknowledged', key: 'submit.time_acknowledged', type: 'date' },
  { label: 'Bug Reference', key: 'bug_reference', type: 'string' },
  { label: 'Assigned To', key: 'resolution.assigned', type: 'string' },
  { label: 'Date Assigned', key: 'resolution.time_assigned', type: 'date' },
  { type: 'line', key: 'line2' },
  { label: 'Resolved', key: 'resolved', type: 'boolean' },
  { label: 'Resolved Date', key: 'resolution.time_resolved', type: 'date' },
  { label: 'Resolved Comments', key: 'resolution.comments', type: 'comments' },
];

const BugPreview = ({ bug }: { bug: IBugReport }) => {
  const getFormattedValue = (bug: IBugReport, item: IUserField) => {
    const value = _.get(bug, item.key, '');

    if (item.type === 'boolean')
      return value ? <CheckBox /> : <CheckBoxOutlineBlank />;
    if (item.type === 'date')
      return value ? new Date(value).toLocaleString() : '';

    return value;
  };

  return (
    <ViewContainer>
      <ReportContainer>
        {userFields.map((item) =>
          item.type === 'line' ? (
            <HorizontalLine key={item.key} />
          ) : (
            <>
              {_.get(bug, item.key, '') !== '' ? (
                <InfoRow key={item.key}>
                  <Label>{item.label}:</Label>
                  <Value>{getFormattedValue(bug, item)}</Value>
                </InfoRow>
              ) : null}
            </>
          )
        )}
        {bug?.comments && bug.comments.length ? (
          <BugComments key='bug-comments-preview' comments={bug?.comments} />
        ) : null}
      </ReportContainer>
    </ViewContainer>
  );
};

export default BugPreview;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 368px;
  border-radius: 15px;
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.textSecondary};
`;

const InfoRow = styled.div`
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  margin-right: 5px;
  text-align: right;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled.div`
  font-size: 14px;
  width: calc(100% - 160px);
  color: ${(p) => p.theme.palette.textPrimary};
`;
