import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TableRowsIcon from "@mui/icons-material/TableRows";
import "./TableFilter.css";
import CustomSnackbar from "./CustomSnackbar";
import CloseIcon from "@mui/icons-material/Close";
import useCompounds from "@components/compounds/useCompounds";


interface Filter {
  id: string; // Assuming the id is a string
  name: string;
  created_at: string;
  filter_count: number;
  aggregate_count: number;
  table_count: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface TableFilterProps {
  handleClose: (id: number) => void;
  id: number;
  onApply: (data) => void;
}

const headCells: HeadCell[] = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name of Filter",
  },
  {
    id: "filter_count",
    numeric: true,
    disablePadding: false,
    label: "Filters",
  },
  {
    id: "aggregate_count",
    numeric: true,
    disablePadding: false,
    label: "Aggregates",
  },
  {
    id: "table_count",
    numeric: true,
    disablePadding: false,
    label: "Tables",
  },
  {
    id: "dateOfCreation",
    numeric: false,
    disablePadding: false,
    label: "Date of Creation",
  },

  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

const TableFilter: React.FC<TableFilterProps> = ({
  handleClose,
  id,
  onApply,
}) => {
  const { deleteSaveWork, fetchSavedWork, applySavedWork } = useCompounds();

  const [filters, setFilters] = useState<Filter[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchSavedWork()
      .then((filtersData) => {
        // Assuming filtersData is already in the desired format
        setFilters(filtersData.data);
      })
      .catch((error) => {
        console.error("Error fetching saved work:", error);
        // Optionally, display an error message to the user
      });
  }, []);
  // Add fetchSavedWork as a dependency
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString(undefined);
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState<"success" | "error">(
    "success"
  );

  const handleDeleteFilter = async (filterId: string) => {
    try {
      await deleteSaveWork(filterId);
      showSnackbar("success", "Filter deleted successfully");

      // Update local state to reflect the change
      setFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.id !== filterId)
      );
    } catch (error) {
      console.error("Error deleting filter:", error);
      showSnackbar("error", "Error deleting filter");
      // Set an error state if you have one, or handle the error as needed
    }
  };

  // Function to handle snackbar display
  const showSnackbar = (type: "success" | "error", message: string) => {
    setSnackbarType(type);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleApply = async (savedWorkId: string) => {
    try {
      const data = await applySavedWork(savedWorkId);
      onApply(data);
    } catch (error) {
      console.error("Error applying saved work:", error);
      // Handle the error appropriately...
    }
  };

  return (
    <div className="Tablecontainer">
      <div className="flex-space-between">
        <Typography variant="h6" component="h6" className="titletext">
          <TableRowsIcon className="icon" fontSize="small" />
          SAVED WORK{" "}
        </Typography>
        <CloseIcon
          className="closeIcon"
          onClick={() => handleClose(id)}
          style={{ color: "#F2F3F4" }}
        />
      </div>
      <br />

      <Box>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        padding:
                          headCell.id === "filterName" ? "10px" : undefined,
                      }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filters.map((filter) => (
                  <TableRow key={filter.id}>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {filter.name}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {filter.filter_count}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {filter.aggregate_count}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {filter.table_count}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {formatDate(filter.created_at)}
                    </TableCell>

                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: "0 5px" }}
                        onClick={() => handleApply(filter.id)}
                      >
                        {" "}
                        Apply
                      </Button>

                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteFilter(filter.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filters.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
          />
        </Paper>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        type={snackbarType}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
};

export default TableFilter;
