import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import UserEventsList from "@subApps/admin/components/UserEventsList";
import UserEventsDetail from "@subApps/admin/components/UserEventsDetail";

import useUserAppInfo, {UserEventsItemT} from '@utils/useUserAppInfo';
import {BasicTextInput} from "@as_core/account/forms/FormStyles";

const debug = false;
const UserInsights = () => {
  // API and CONTEXT
  const { user } = useContext(UserContext);
  const [daysSince, setDaysSince] = useState<string>('30');
  const { getAllUserEvents } = useUserAppInfo();

  // STATE VARIABLES
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [userEvents, setUserEvents] = useState<UserEventsItemT[]>([]);
  const [allUserEvents, setAllUserEvents] = useState<UserEventsItemT[]>([]);
  if (debug) console.log('UserInsights | user:', user);

  // HANDLERS
  const onSelect = (userId:string) => {
    if (debug) console.log('UserInsights | onSelect userId', userId);
    setSelectedUserId(userId);
  };

  const handleChange = (value: string) => {
    if (value !== daysSince) {
      setDaysSince(value);
    }
    if (Number(value) > 0)  {
      setLoaded(false);
    }
  }

  // DATA LOADING
  useEffect(() => {
    if (!loaded) {
      getAllUserEvents(daysSince).then((response) => {
        if (debug) console.log('getAllUserEvents | response', response);
        setLoaded(true);
        setAllUserEvents(response);
      });
    }
  }, [loaded, daysSince]);

  if (loaded) {
    if (debug) console.log('UserInsights | userEvents:', userEvents);
  }

  if (debug) console.log('selectedUserId', selectedUserId);

  useEffect(() => {
    if (selectedUserId) {
      if (debug) console.log(selectedUserId);
      const userEvents = allUserEvents.filter((e) => e.authId === selectedUserId);
      setUserEvents(userEvents);
    }
  }, [selectedUserId]);

  return (
    <Container>
      <ParameterBox>
        <Item>Show Data for the Past Number of Days:</Item>
        <Item>
          <BasicTextInput
            value={daysSince}
            onChange={(v) => handleChange(v.target.value)}
          />
        </Item>
      </ParameterBox>
      { selectedUserId ?
        <UserEventsDetail
          userEvents={userEvents}
          backToList={() => setSelectedUserId(null)}
        />
      :
        <UserEventsList
          allUserEvents={allUserEvents}
          handleSelect={onSelect}
        />
      }
    </Container>
  );
};

export default UserInsights;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ParameterBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const Item = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: 5px;
`;

