import React, { memo } from 'react';

import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import { Editor } from 'ketcher-react';
import { ButtonsConfig } from 'ketcher-react';
import Miew from 'miew';
import { Ketcher } from 'ketcher-core';
import 'ketcher-react/dist/index.css'
(window as typeof globalThis).Miew = Miew;

interface CustomButtonsConfig extends ButtonsConfig {
  explicit?: { hidden: boolean };
  reaction?: { hidden: boolean };
  image?: { hidden: boolean };
}

interface IEditorProps {
  mol?: string;
  onChange?: (mol: string) => void;
}
const structServiceProvider = new StandaloneStructServiceProvider();

const debug = false;
export const CompoundEditor = memo(function CompoundEditor({
  mol,
  onChange,
}: IEditorProps) {

  const handleOnInit = async (ketcher: Ketcher) => {
    (window as typeof globalThis).ketcher = ketcher;

    ketcher.setMolecule(mol).then();

    ketcher.editor.subscribe('change', () => {
      ketcher.getMolfile('v3000').then(
        (molfile) => {
          if (debug)
            console.log('CompoundEditor | handleChange {molfile}', molfile);
          onChange?.(molfile);
        }
      );
    });
  };

  return (
      <Editor
        staticResourcesUrl={''}
        structServiceProvider={structServiceProvider}
        onInit={handleOnInit}
        errorHandler={console.error}
        buttons={{
          arom: { hidden: true },
          dearom: { hidden: true },
          cip: { hidden: true },
          check: { hidden: true },
          analyse: { hidden: true },
          recognize: { hidden: true },
          settings: { hidden: true },
          explicit: { hidden: true },
          miew: { hidden: true },
          fullscreen: { hidden: true},
          sgroup: { hidden: true },
          reaction: { hidden: true },
          'reaction-plus': { hidden: true },
          'reaction-mapping-tools': { hidden: true },
          shape: { hidden: true },
          'shape-ellipse': { hidden: true },
          'shape-rectangle': { hidden: true },
          'shape-line': { hidden: true },
          text: { hidden: true },
          image: { hidden: true },
          arrows: { hidden: true },
          rgroup: { hidden: true },
        } as CustomButtonsConfig }
      />
  );
});
