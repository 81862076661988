import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import ImportGenericTable from './ImportGenericTable';
import UploadDataStepper from '../shared/UploadDataStepper';
import UploadGeneric from './UploadGeneric';
import useFileImport from '../useFileImport';
import GetStepDetails from '../shared/GetToastMessage';
import Toast from '../shared/Toast';

function getSteps() {
  return ['Upload Spreadsheet', 'Review Data', 'Save'];
}

interface IImportGeneric {
  height?: number;
  width?: number;
  handleReset?: () => void;
}

const debug = false;
const ImportGeneric = (props: IImportGeneric) => {
  const { height, width, handleReset } = props;
  const [toast, setToast] = useState<{ message: string; duration: number } | null>(null);
  const { context } = useFileImport();
  if (debug) console.log('ImportGeneric: {step}', context.activeStep);

  // ###########################################################################
  // STEPPER
  // ###########################################################################
  const steps = getSteps();

  if (context.activeStep < 1) {
    // handling reset issue
    context.setActiveStep(1);
  }

  const handleNext = () => {
    if (debug) console.log('UploadData | handleNext');
    context.setActiveStep((currStep) => currStep + 1);
  };

  const handleBack = () => {
    if (debug) console.log('UploadData | handleBack');
    context.setActiveStep((currStep) => {
      const nextStep = currStep - 1;
      if (nextStep === 1) {
        // placeholder
      }
      if (nextStep < 1) {
        handleReset();
      }
      return nextStep;
    });
  };


  useEffect(() => {
    const { message, duration } = GetStepDetails(context.activeStep, 'ImportGeneric');
    if (message) {
      setToast({ message, duration });
    } else {
      setToast(null);
    }
  }, [context.activeStep]);

  if (debug) console.log('ImportGeneric {activeStep}', context.activeStep);
  return (
    <Container height={height} width={width}>
      <UploadDataStepper
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        uploadStep={1}
        steps={steps}
      />
      <ToastContainer>
        {toast && <Toast message={toast?.message} duration={toast?.duration} onClose={() => setToast(null)}/>}
      </ToastContainer>
      <StepContainer>
        {(() => {
          switch (context.activeStep) {
            case 1:
              return <ImportGenericTable width={width} />;
            case 2:
              return (
                <UploadGeneric
                  height={height}
                  width={width}
                  handleNext={handleNext}
                />
              );
            case 3:
              return (
                <UploadGeneric
                  height={height}
                  width={width}
                  handleNext={handleNext}
                />
              );
          }
        })()}
      </StepContainer>
    </Container>
  );
};

export default ImportGeneric;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  padding: 0.5rem;
  align-items: center;
`;

const StepContainer = styled.div`
  display: flex;
  border: ${(p) => p.theme.borders.thin};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: ${(p) => p.theme.sizes.small};
`;

const ToastContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;