import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import SimpleDropdown from './simpleDropdown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import './Selectdatashown.css'; // Import the CSS file
import Button from '@mui/material/Button';
import SaveFilter, { Condition } from './SaveFilter'; // Import the SaveFilter component
import { SelectedDataProps } from './ColumnFilter';
import { IFilter } from '@pages/PgDataAnalytics';

interface SelectedDataShownProps {
  selectedData: SelectedDataProps[];
  SelectedValue: string | number;
  onEditFilter: (EditingFilterId: string | number) => void;
  listData: { conditions: Condition[] };
  AndOR: 'AND' | 'OR';
  datalist: IFilter[];
  filterid: string;
}

const SelectedDataShown: React.FC<SelectedDataShownProps> = ({
  selectedData,
  SelectedValue,
  onEditFilter,
  listData,
  AndOR,
  datalist,
  filterid,
}) => {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>(
    String(SelectedValue)
  );
  const [openSaveFilter, setOpenSaveFilter] = useState(false); // State to control SaveFilter visibility

  const handleCloseSaveFilter = () => {
    setOpenSaveFilter(false);
    // Add additional logic if needed based on shouldRedirect
  };

  // Assuming that datalist is an array and you want to get the id from the first element
  const id = datalist.length > 0 ? datalist[0].id : null;

  return (
    <div>
      <div className='selected-data-shown-container'>
        <SimpleDropdown
          value={selectedDropdownValue}
          handleChangedrop={(event) => {
            setSelectedDropdownValue(event.target.value);
          }}
          menuItems={[
            {
              value: 'keep',
              label: 'Keep Rows',
              icon: <FilterAltIcon fontSize='small' />,
            },
            {
              value: 'remove',
              label: 'Remove Rows',
              icon: <FilterAltOffIcon fontSize='small' />,
            },
          ]}
        />
        <span className='filter-item'>where</span>

        {selectedData.map((data, index) => (
          <div key={index} className='containerselectedData'>
            {index > 0 && <span className='filter-item'>{AndOR}</span>}
            <Chip
              label={data.selectedColumn.label}
              color='primary'
              className='filter-icon custom-chip'
            />
            <span className='filter-item'>{data.selectedLabel}</span>

            {data.selectedDate === 'todayAtNoon' &&
              data.inputValue !== '' &&
              data.minValue === '' && (
                <Chip
                  label={data.inputValue}
                  color='success'
                  className='date-chip custom-chip'
                />
              )}

            {data.selectedDate === 'todayAtNoon' &&
              data.inputValue !== '' &&
              data.minValue !== '' && (
                <>
                  <Chip
                    label={data.minValue}
                    color='success'
                    className='date-chip custom-chip'
                  />
                  <span className='filter-item'>and</span>
                  <Chip
                    label={data.inputValue}
                    color='success'
                    className='date-chip custom-chip'
                  />
                </>
              )}

            {data.inputValue === '' &&
              data.selectedDate !== 'todayAtNoon' &&
              data.selectedDatend === 'todayAtNoon' && (
                <Chip
                  label={data.selectedDate.toLocaleString()}
                  color='success'
                  className='date-chip custom-chip'
                />
              )}

            {data.inputValue === '' &&
              data.selectedDate === 'todayAtNoon' &&
              data.selectedDatend !== 'todayAtNoon' &&
              data.selectedDatestart !== 'todayAtNoon' && (
                <>
                  <Chip
                    label={data.selectedDatestart.toLocaleString()}
                    color='success'
                    className='date-chip custom-chip'
                  />
                  <span className='filter-item'>and</span>
                  <Chip
                    label={data.selectedDatend.toLocaleString()}
                    color='success'
                    className='date-chip custom-chip'
                  />
                </>
              )}
          </div>
        ))}
      </div>
      <div className='save-cancel-buttons'>
        <Button
          variant='contained'
          className='button-save'
          onClick={() => onEditFilter(id)}
        >
          Edit Filter
        </Button>
      </div>
      {openSaveFilter && (
        <SaveFilter
          open={openSaveFilter}
          onClose={handleCloseSaveFilter}
          listData={listData}
          AndOR={AndOR}
          id={filterid}
        />
      )}
    </div>
  );
};

export default SelectedDataShown;
