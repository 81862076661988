import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { arc } from 'd3-shape';
import { scaleLinear } from 'd3-scale';

const Gauge = ({ value = 0.2, min = 0, max = 1 }) => {
  const backgroundArc = arc()
    .innerRadius(0.75)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)();
  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);
  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);
  const angle = angleScale(percent);
  const colorScale = scaleLinear()
    .domain([0, 0.5, 1.0])
    .range(['#FFFFFF', '#E778AB', '#ff005d']);
  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));
  const smileScale = scaleLinear().domain([min, max]).range([0.15, -0.05]);
  const smileValue = smileScale(value);

  return (
    <Container>
      <svg
        style={{ overflow: 'visible' }}
        width='250'
        height='150'
        viewBox={[-1, -1, 2, 1.2].join(' ')}
      >
        <defs>
          <linearGradient
            id='Gauge__gradient'
            gradientUnits='userSpaceOnUse'
            x1='-1'
            x2='1'
            y2='0'
          >
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${index / (gradientSteps.length - 1)}`}
              />
            ))}
          </linearGradient>
          <filter id='f1' x='-.1' y='-.1' width='200%' height='200%'>
            <feOffset result='offOut' in='SourceAlpha' dx='0.01' dy='0.01' />
            <feGaussianBlur result='offOut2' in='offOut' stdDeviation='.01' />
            <feBlend in='SourceGraphic' in2='offOut2' mode='normal' />
          </filter>
          <filter id='f2' x='-.1' y='-.1' width='200%' height='200%'>
            <feOffset result='offOut' in='SourceAlpha' dx='0.01' dy='0.02' />
            <feGaussianBlur result='offOut2' in='offOut' stdDeviation='.02' />
            <feBlend in='SourceGraphic' in2='offOut2' mode='normal' />
          </filter>
        </defs>
        <path
          d={backgroundArc}
          fill='url(#Gauge__gradient)'
          stroke='#000000'
          strokeWidth='0.005'
          // filter='url(#f2)'
        />
        <line
          // Gauge Needle
          y1='0.22'
          y2='-1'
          stroke='#CD1157'
          strokeWidth='0.027'
          strokeLinecap='round'
          transform={`rotate(${angle * (180 / Math.PI)})`}
        />
        <circle
          // Face Outline
          cx='0'
          cy='0'
          r='0.15'
          fill={colorScale(percent)}
          stroke='#000000'
          strokeWidth='0.005'
          // filter='url(#f1)'
        />
        <circle
          // Left Eye
          cx='0.06'
          cy='-.04'
          r='0.015'
          stroke='#060F3F'
          strokeWidth='0.01'
          fill='#060F3F'
        />
        <circle
          // Right Eye
          cx='-0.06'
          cy='-.04'
          r='0.015'
          stroke='#060F3F'
          strokeWidth='0.01'
          fill='#060F3F'
        />
        <path
          // Smile Mouth
          d={'M -0.06, 0.05 S 0,' + smileValue + ', 0.06, 0.05'}
          fill='none'
          stroke='#060F3F'
          strokeWidth='0.012'
        ></path>
      </svg>
    </Container>
  );
};

Gauge.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default Gauge;

const Container = styled.div`
  /* background-color: ${(p) => p.theme.palette.backgroundSecondary}; */
  padding-top: 0;
`;
