import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from '@mui/material';

interface MenuTooltipProps {
  text: string;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
  children: React.ReactElement;
}

const MenuTooltip = (props: MenuTooltipProps) => {
  const { text, tooltipPlacement = 'right'} = props;
  return (
    <StyledTooltip
      title={text}
      placement={tooltipPlacement}
      arrow
    >
      { props.children }
    </StyledTooltip>
  );
}
export default MenuTooltip;

const StyledTooltip = styled(Tooltip)`
`;

