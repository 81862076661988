import React from 'react';
import styled from 'styled-components/macro';
import {FlexItem} from "@components/layout/FlexStyles";
import {labelColors} from "../config/universe";
import { properties } from '../config/properties';
import PropertyDensity from '@components/universe/elements/PropertyDensity';

import {DistributionsT, PinnedVertexT, VertexT} from "@components/universe/types";
import {getValueByPath} from "@utils/misc";

interface PropsI {
  vertex: VertexT,
  hoveredVertex: VertexT,
  pinnedVertices: PinnedVertexT[],
  distributions: DistributionsT,
  height?: number
}

const PropertiesPanel = (props:PropsI) => {
  const { vertex, hoveredVertex, pinnedVertices, distributions, height=300 } = props;
  const propertiesHeight = Object.keys(distributions).length * 85;

  const getSecondaryValues = (property: string) => {
    const secondaryValues = [];
    if (hoveredVertex
      && hoveredVertex.id !== vertex.id
      && !pinnedVertices.find((p) => p.v.id === hoveredVertex.id)) {
      const value = getValueByPath(hoveredVertex?.chem_data, property, null)
      if (value !== null) {
        secondaryValues.push({value: value, color: labelColors['hovered']})
      }
    }
    if (pinnedVertices.length) {
      pinnedVertices.forEach((p) => {
        const value = getValueByPath(p.v?.chem_data, property, null)
        if (value !== null) {
          secondaryValues.push({value: value, color: p.color})
        }
      })
    }
    return secondaryValues;
  }

  return (
    <Container height={height}>
      <PropertiesContainer height={propertiesHeight}>
      {Object.keys(distributions).map((property, index) => {
        if (getValueByPath(vertex?.chem_data, property, null) === null) {
          return null;
        }
        return (
          <FlexItem key={`flex_item_${index}`}>
            <PropertyDensity
              key={property}
              value={getValueByPath(vertex?.chem_data, property, 0)}
              secondaryValues={getSecondaryValues(property)}
              valueFormat={properties[property].format}
              density={distributions[property]}
              label={properties[property].name}
              width={230}
            />
          </FlexItem>
        );
      })}
      </PropertiesContainer>
    </Container>
  );
};

export default PropertiesPanel;

const Container = styled.div<{height: number}>`
  display: flex;
  width: 242px;
  height: ${(p) => p.height}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PropertiesContainer = styled.div<{height: number}>`
  display: flex;
  flex-direction: column;
  height: ${(p) => p.height}px;
`;