import React from 'react';
import styled from 'styled-components/macro';

const Info = () => {
  return(
    <InfoContainer>
      <InfoTitle>Helping scientists design safer compounds</InfoTitle>
      <InfoMessage>AsedaSciences cloud-based, AI driven platform enables scientists to
        rapidly visualize and explore the relationship between chemical structure and its biological
        effect to design safer compounds with higher fidelity.</InfoMessage>
      <InfoTitle>A new approach to safer design</InfoTitle>
      <InfoMessage>We combine machine learning (ML), high content cellular screens and cloud computing
        to predict human and environmental safety risk, without the use of animals.</InfoMessage>
      <InfoMessage>1. Screen - Proprietary high-content cellular screens and algorithms
        create a biological fingerprint of a compounds toxic effects</InfoMessage>
      <InfoMessage>2. Analyze - Our proprietary ML algorithms then compare fingerprints
        between new and historical compounds to predict safety risk</InfoMessage>
      <InfoMessage>3. Visualize - Elegant network visualization allows closest neighbor comparisons to
        thousands of on-market and withdrawn compounds</InfoMessage>
      <InfoMessage>4. Prioritize - Fingerprint similarity to known human toxins
        allows the selection, prioritization and progression of safer compounds</InfoMessage>
      <InfoMessage>5. Organize - Upload, store and organize all of your compound structures and
        associated biological data in a single, secure location</InfoMessage>
      <InfoMessage>6. Democratize - Democratize your data - every data point, for every compound, readily
        available to everyone across an organization</InfoMessage>
      <InfoMessage>Visit <Anchor href={"https://asedasciences.com"} target='_blank' rel='noopener noreferrer'>AsedaSciences.com</Anchor>
        for full details</InfoMessage>
    </InfoContainer>);
}

export default Info;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const InfoTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  margin-bottom: 10px;
`;

const InfoMessage = styled.div`
  display: flex;
  font-size: 16px;
  padding-left: 20px;
  padding-bottom: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
  width: 100%;
`;

const Anchor = styled.a`
  padding: 0 5px;
`;