import styled from 'styled-components/macro';
import { Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import React, { useMemo } from 'react';

interface IHoverInfoText {
  text: string | Array<string>;
  variant?: 'info' | 'cart';
  showAsBullets?: boolean;
  placement?:
    | 'right-end'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

/**
 *
 * @param props
 * @constructor
 */
const HoverInfoText = (props: IHoverInfoText) => {
  const { text, variant='info', showAsBullets=true, placement } = props;

  const usedText = useMemo(() => {
    if (typeof text === 'string') return text;
    else {
      if (showAsBullets) {
        return (
          <List>
            {text?.map((info, index) => (
              <ListItem key={index}>{info}</ListItem>
            ))}
          </List>
        );
      }
      return (
        <Segments>
          {text?.map((info, index) => (
            <SegmentItem key={index}>{info}</SegmentItem>
          ))}
        </Segments>
      );
    }
  }, [text]);

  return (
    <Tooltip
      title={usedText}
      placement={placement || 'right'}
      slots={{
        tooltip: StyledTooltip,
      }}
    >
      { variant === 'info' ?
        <IconInfo />
        :
        <IconCart />
      }
    </Tooltip>
  );
};
export default HoverInfoText;

const List = styled.ul`
  margin-block: 0;
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  padding: 0 0 5px 15px;
`;

const ListItem = styled.li`
  align-items: flex-start;
  text-align: left;
  padding: 1px;
  line-height: 120%;
`;

const Segments = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const SegmentItem = styled.div`
  align-items: flex-start;
  text-align: left;
  padding: 5px 1px;
  line-height: 120%;
`;


const StyledTooltip = styled.div`
  font-size: 12px;
  background: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid ${(p) => p.theme.palette.accentPrimary};
  color: ${(p) => p.theme.palette.textSecondary};
  max-width: 300px;
  margin: 2px 2px 2px 8px;
  word-wrap: break-word;
  padding: 4px 8px;
  font-weight: 300;
  border-radius: 8px;
`;

const IconInfo = styled(Info)`
  color: ${(p) => p.theme.palette.accentPrimary};
  cursor: help;
`;

const IconCart = styled(Info)`
  color: ${(p) => p.theme.palette.accentSecondary};
  cursor: help;
`;
