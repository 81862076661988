import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CompoundFieldT } from '@stores/fields';
import _ from 'lodash';
import MenuButton from '@components/elements/MenuButton';
import NewFieldIcon from '@components/icons/newField.icon';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import PersonIcon from '@mui/icons-material/Person';
import BackArrowIcon from '@components/icons/backArrow.icon';
import SearchField from '@as_core/components/inputs/SearchField';

type FieldSelectorPropT = {
  showBack?: boolean;
  selectedField: string;
  fields: Array<CompoundFieldT>;
  handleFieldClick: (field: string) => void; // from calling component to do action on the selected field
  newField?: boolean; // enable new Field function
  newFieldActive?: boolean; // if icon should be active
  newFieldClick?: (field: string) => void;
};

const debug = false;
const DataFieldSelector = (props: FieldSelectorPropT) => {
  const {
    selectedField,
    fields,
    handleFieldClick,
    newField = false,
    newFieldActive = false,
    newFieldClick,
  } = props;
  const navigate = useNavigate();

  if (debug) {
    console.log(
      'DataFieldSelector | selectedField:',
      selectedField,
      ' fields:',
      fields
    );
  }

  const [filter, setFilter] = useState('');

  // HANDLERS
  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setFilter('');
    }
  };

  // search for dataFields
  const getFilteredFieldIds = useCallback(
    () =>
      _.intersection(
        ...filter
          .split(' ')
          .map((word) =>
            fields.filter((f) =>
              (
                _.get(f, 'category', '') +
                _.get(f, 'long_name', '') +
                _.get(f, 'short_name', '') +
                _.get(f, 'symbol', '') +
                _.get(f, '_source', '')
              )
                .toLowerCase()
                .includes(word.toLowerCase())
            )
          )
      ),
    [filter, fields]
  );

  const searchResultIds = getFilteredFieldIds();
  // RENDER
  return (
    <FieldSelectorContainer>
      <FlexRow h_centered v_centered>
        {props?.showBack ? (
          <MenuButton
            text={'Return to prior view'}
            onClick={() => navigate(-1)}
            icon={<BackArrowIcon size={32} />}
            width={220}
            height={40}
          />
        ) : null}
        <FlexItem width={'10%'}>
          <FieldIconContainer>
            {newField ? (
              <MenuButton
                onClick={newFieldClick}
                isActive={newFieldActive}
                icon={<NewFieldIcon />}
                text={'Add New Field'}
                tooltipPlacement={'bottom'}
              />
            ) : (
              <></>
            )}
          </FieldIconContainer>
        </FlexItem>
        <FlexItem width={'90%'}>
          <SearchField
            value={filter}
            onChange={handleChangeFilter}
            onKeyDown={handleKeyDown}
          />
        </FlexItem>
      </FlexRow>
      <SearchResults>
        {searchResultIds.map((f, i) => {
          return (
            <SelectableField
              className={f.field_id === selectedField ? 'active' : ''}
              key={i}
              onClick={() => handleFieldClick(f.field_id)}
            >
              <FieldIcon>
                {f?._source.includes('user') ? (
                  <PersonIcon style={{ width: '24px', padding: 0 }} />
                ) : null}
              </FieldIcon>
              <FieldName>
                {f?.short_name ? f?.short_name : f?.field_id}
              </FieldName>
              <FieldShortDesc>{f?.short_description}</FieldShortDesc>
            </SelectableField>
          );
        })}
      </SearchResults>
    </FieldSelectorContainer>
  );
};

export default DataFieldSelector;

const FieldSelectorContainer = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 5px;
`;

const FieldIconContainer = styled.div`
  display: flex;
  width: 60px;
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
`;

const SelectableField = styled(ButtonBase)`
  display: flex;
  font-size: 12px;
  width: 100%;
  text-align: left;
  align-items: flex-start;
  &.MuiButtonBase-root {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
    border-radius: 10px;
    margin: 3px;
    padding: 2px;
  }
  &.active {
    background-color: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.textPrimary};
  }
  :hover {
    background-color: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const FieldIcon = styled.div`
  width: 8%;
  justify-items: center;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const FieldName = styled.div`
  width: 32%;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const FieldShortDesc = styled.div`
  width: 65%;
  color: ${(p) => p.theme.palette.textSecondary};
`;
