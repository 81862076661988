import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';

type Props = {
  children: React.ReactNode;
  handleClickAway: () => void;
  className?: string;
};

export type Ref = {
  openModal: () => void;
  closeModal: () => void;
};

const Modal = forwardRef<Ref, Props>((props, ref) => {
  const { handleClickAway } = props;
  const [display, setDisplay] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => open(),
      closeModal: () => close(),
    };
  });

  const open = () => {
    setDisplay(true);
  };
  const close = () => {
    setDisplay(false);
  };

  if (display) {
    return createPortal(
      <ModalContainer className={props?.className}>
        <ModalBackground onClick={handleClickAway} />
        <ModalContent>{props.children}</ModalContent>
      </ModalContainer>,
      document.getElementById('modal-root')
    );
  } else {
    return null;
  }
});

Modal.displayName = 'Modal';

export default Modal;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color 1s;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: ${(p) => p.theme.borderRadius};
`;

const ModalContent = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  /* height: fit-content; */
  width: fit-content;
  /* height: calc(100vh - 75px); */
  /* width: calc(100vw - 75px); */
  padding: ${(p) => p.theme.sizes.small};
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  /* overflow-y: scroll; */
  border-radius: ${(p) => p.theme.borderRadius};
`;
