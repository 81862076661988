import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';

type Props = {
  children: React.ReactNode;
  title: string;
  modalOpen: boolean;
  onCloseClick: () => void;
  width?: number;
  height?: number;
  opacity?: number;
  className?: string;
};

const StationaryModal = (props: Props) => {
  const {
    title,
    modalOpen,
    onCloseClick,
    width = 600,
    height = 400,
    opacity = 0.9,
  } = props;

  return (
    <StyledModal
      open={modalOpen}
      hideBackdrop={true}
      width={width}
      height={height}
      opacity={opacity}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalContent>
        <ModalHeader className={'handle'}>
          <FlexItem>{title}</FlexItem>
          <FlexItem>
            <StyledClose onClick={onCloseClick} />
          </FlexItem>
        </ModalHeader>
        <ModalContent>{props.children}</ModalContent>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </StyledModal>
  );
};

export default StationaryModal;

const StyledClose = styled(Close)`
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.palette.accentPrimary};
    filter: contrast(1) brightness(1);
  }
`;

const StyledModal = styled(Modal)<{
  width: number;
  height: number;
  opacity: number;
}>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  opacity: ${(p) => p.opacity};
  border-radius: 15px;
  border: 1px solid #636363;
  box-shadow: 20px 20px 10px ${(p) => p.theme.palette.backgroundSecondary};
  padding: 0;
  &.MuiModal-root {
    position: absolute;
    left: calc(50vw - 0.5 * ${(p) => p.width}px);
    top: calc(50vh - 0.5 * ${(p) => p.height}px);
    border-radius: 15px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  font-size: 18px;
  height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: 15px 15px 0 0;
  width: 100%;
`;

const FlexItem = styled.div`
  display: flex;
  padding: 0 10px 0 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
