import React from 'react';
import styled from 'styled-components/macro';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import {UseObjectArrayReturnType} from "@as_core/hooks/useObjectArray";
import {ZoomTransform} from "d3";
import { getScreenCoords } from '../utils/vector';
import {AesSettingsT, GraphSettingsT, XYCoordinate} from '../types';
import {labelColors} from "../config/universe";
import LabeledVertex from "@components/universe/elements/LabeledVertex";

interface PropsI {
  transform: ZoomTransform,
  settings: GraphSettingsT,
  pinnedCompounds: UseObjectArrayReturnType,
  aesSettings: {[key: string]: AesSettingsT},
  contextMenuOpen: boolean,
  hoveredVertexLabel : string
}


const DetailHovered = (props: PropsI) => {
  const {
    transform,
    settings,
    pinnedCompounds,
    aesSettings,
    contextMenuOpen,
    hoveredVertexLabel,
  } = props;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const hoveredVertex = settings.hoveredVertex;

  function getLabelPosition(vertex: { x: number, y: number; }, label:string) {
    const pos = getScreenCoords([vertex.x, vertex.y], transform);
    const radius = 8 * transform.k;
    pos[0] = Math.min(pos[0] + radius, windowWidth - 15 * label.length);
    pos[1] = Math.max(pos[1], 40);
    return { x: pos[0], y: pos[1] };
  }

  let labelPosition: XYCoordinate = { x: 0, y: -100 };
  if (hoveredVertex) {
    labelPosition = getLabelPosition(hoveredVertex, hoveredVertexLabel);
  }

  return (
    <>
      {hoveredVertex && !pinnedCompounds.data.includes(hoveredVertex.id) ? (
        <HoveredVertexContainer right={0}>
          <LabeledVertex
            labelColor={labelColors['hovered']}
            magnify={3}
            vertex={hoveredVertex}
            radius={6}
            vertexAesSettings={aesSettings[hoveredVertex.id]}
            settings={settings}
            selected={true}
            inGraph={false}
          />
        </HoveredVertexContainer>
      ) : (
        <></>
      )}
      {hoveredVertex && !contextMenuOpen ? (
        <HoveredVertexFloatingLabel
          x={labelPosition.x + 50}
          y={windowHeight - labelPosition.y - 100}
        >
          {hoveredVertexLabel}
        </HoveredVertexFloatingLabel>
      ) : (
        <></>
      )}
    </>
  );
};

export default DetailHovered;

const HoveredVertexFloatingLabel = styled.div<{ x: number; y: number }>`
  font-size: 16px;
  color: #39a9cb;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border-radius: 10px;
  border: 2px solid ${(p) => p.theme.palette.accentSecondary};
  position: fixed;
  left: ${(p) => p.x}px;
  bottom: ${(p) => p.y}px;
  z-index: 10;
  padding: 2px 8px;
`;

const HoveredVertexContainer = styled.div<{ right?: number }>`
  position: fixed;
  flex-direction: column;
  justify-items: center;
  padding: 0;
  opacity: 1.0;
  height: 275px;
  width: 250px;
  top: 100px;
  right: ${(p) => p.right}px;
  z-index: 8;
`;
HoveredVertexContainer.defaultProps = {
  right: 50,
};
