const timers = {};

export const debounce = (name, callback, timeout) => {
  if (timers[name]) {
    clearTimeout(timers[name]);
  }
  timers[name] = setTimeout(() => {
    callback();
  }, timeout);
  return { timer: timers[name], clear, remove };
};

export const clear = (name) => {
  if (timers[name]) {
    clearTimeout(timers[name]);
  }
};

export const remove = (name) => {
  if (timers[name]) {
    clearTimeout(timers[name]);
    delete timers[name];
  }
};
