import { Aggregation } from '@utils/api';
import { handleError } from '@utils/misc';
import axios, { AxiosRequestConfig } from 'axios';

export interface BucketCfieldsNumericT {
  values: Array<{
    _id: number;
    count: number;
  }>;
  boundaries: number[];
  min: number;
  max: number;
}

axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', JSON.stringify(response, null, 2))
  return response
})

const useAggregation = () => {
  const getCancelSource = () => axios.CancelToken.source();

  const getBucketAutoCfieldsNumeric = async (
    token: string,
    cfieldId,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAutoCfieldsNumeric(
        token,
        cfieldId,
        config
      );
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAutoCfieldsNumeric ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getBucketCfieldsNumeric = async (
    token: string,
    field_id,
    config: AxiosRequestConfig
  ): Promise<BucketCfieldsNumericT> => {
    let resp;

//DHR    try {
      //console.log("bucketCfieldsNumeric {field_id, config}:", field_id, config); //DHR
      resp = await Aggregation.bucketCfieldsNumeric(token, field_id, config);
      //console.log("bucketCfieldsNumeric {resp}:", resp); //DHR
//DHR   } catch (err) {
//DHR      console.log("bucketCfieldsNumeric ERROR2:", err)
//DHR      handleError(err);
//      return null;
//DHR    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsNumeric ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketCfieldsCategory = async (
    token: string,
    field_id,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketCfieldsCategory(token, field_id, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketCfieldsFilterCategory = async (
    token: string,
    field_id,
    filterId,
    filterValue,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketCfieldsFilterCategory(
        token,
        field_id,
        filterId,
        filterValue,
        config
      );
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsFilterCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketCfieldsExistsCategory = async (
    token: string,
    field_id,
    existsId,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketCfieldsExistsCategory(
        token,
        field_id,
        existsId,
        config
      );
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsExistsCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketCfieldsCategoryDistributionExists = async (
    token: string,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketCfieldsCategoryDistributionExists(
        token,
        config
      );
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsCategoryExists ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketCfieldsCategoryDistributionFiltered = async (
    token: string,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketCfieldsCategoryDistributionFiltered(
        token,
        config
      );
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsCategoryFiltered ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketCfieldsCategoryDistribution = async (
    token: string,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketCfieldsCategoryDistribution(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketCfieldsCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketAnnotationsCategory = async (
    token: string,
    field_id,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAnnotationsCategory(token, field_id, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAnnotationsCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketAnnotationsDataCategory = async (
    token: string,
    ann_field_id,
    data_exists_field_id,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAnnotationsDataCategory(token, ann_field_id, data_exists_field_id, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAnnotationsDataCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  }

  const getBucketAnnotationsClassCategory = async (
    token: string,
    ann_field_id,
    ann_name,
    class_field_id,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAnnotationsClassCategory(token, ann_field_id, ann_name, class_field_id, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAnnotationsClassCategory ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketAnnotationsCategoryDistribution = async (
    token: string,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAnnotationsCategoryDistribution(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAnnotationsCategoryDistribution ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketAnnotationsDataCategoryDistribution = async (
    token: string,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAnnotationsDataCategoryDistribution(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAnnotationsDataCategoryDistribution ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const getBucketAnnotationsClassCategoryDistribution = async (
    token: string,
    config: AxiosRequestConfig
  ) => {
    let resp;

    try {
      resp = await Aggregation.bucketAnnotationsClassCategoryDistribution(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getBucketAnnotationsClassCategoryDistribution ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  return {
    getCancelSource,
    getBucketAutoCfieldsNumeric,
    getBucketCfieldsNumeric,
    getBucketCfieldsCategory,
    getBucketCfieldsFilterCategory,
    getBucketCfieldsExistsCategory,
    getBucketCfieldsCategoryDistribution,
    getBucketCfieldsCategoryDistributionExists,
    getBucketCfieldsCategoryDistributionFiltered,
    getBucketAnnotationsCategory,
    getBucketAnnotationsClassCategory,
    getBucketAnnotationsDataCategory,
    getBucketAnnotationsCategoryDistribution,
    getBucketAnnotationsClassCategoryDistribution,
    getBucketAnnotationsDataCategoryDistribution,
  };
};

export default useAggregation;
