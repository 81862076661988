import React from "react";
import styled from "styled-components/macro";
import {OrderT} from "@business/types";
import _ from "lodash";
import MenuButton from '@components/controls/MenuButton/MenuButton';
import TrashIcon from '@components/icons/trash.icon';
import BasicTable from '@as_core/tables/BasicTable';
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {ordersCartTableFields, OrdersCartPropsI} from "@business/orders/components/OrdersCart";
import {CartItemT, CartObjectType, UseCartObjectReturnType} from "@as_core/hooks/useShoppingCart";
import {getPrice, priceToShow} from "@business/products/components/utils";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import { RequestT } from '@subApps/orders/hooks/useRequests';
import { getValueByPath } from '@utils/misc';

export type ShowFieldsT = {
  label: string;
  value: string;
  render?: string;
  width?: number;
}

export function renderOrderCost(order: OrderT | RequestT) {
  let total_cost = Number(getValueByPath(order, 'payment.amount', 0)/100.0).toLocaleString();
  const currency = getValueByPath(order, 'payment.currency', '');
  if (currency === 'usd') {
    total_cost = '$ ' + total_cost;
  }
  return total_cost;
}

export function renderOrderDetails(order: OrderT, viewMode:string='cart') {
  const orderFields: ShowFieldsT[] = [
    { value: 'product.catalogNumber', label: 'Catalog #', width: 150 },
    { value: 'product.productName', label: 'Product', width: 220 },
    { value: 'quantity', label: 'Quantity', width: 100 },
    { value: 'product.unitSize', label: 'Unit Size', width: 100 },
  ];
  const rows = [];
  Object.values(order.order).forEach((order) => {
    const row = {};
    orderFields.forEach((f) => {
      row[f.value] = getValueByPath(order, f.value, '');
    })
    rows.push(row);
  })
  return(
    <BasicTable
      fields={orderFields}
      border={viewMode!=='cart'}
      rows={rows}/>
  );
}

export function getCartShowPrice(item: CartItemT) {
  const price = getCartPrice(item);
  return priceToShow(price.unit_amount);
}

export function getCartPriceType(item: CartItemT) {
  const availableDiscountPrices = Object.keys(item.product.prices)
    .filter((v) => v.includes('multi') && v.includes(item.priceType));
  if (availableDiscountPrices.length===0) return item.priceType;
  if (availableDiscountPrices.length > 1) {
    console.error('Multiple price discounts -- not implemented -- using first one')
  }
  const parts = availableDiscountPrices[0].split('_');
  // const quantifier = parts[2];
  const threshold = Number(parts[3]);
  // console.log('getQuantityPrice', availableDiscountPrices, parts[0], parts[1], quantifier, threshold);
  if (item.quantity > threshold) return availableDiscountPrices[0];
  return item.priceType;
}

export function getCartPrice(item: CartItemT) {
  const priceType = getCartPriceType(item);
  return getPrice(item.product, priceType);
}

// compute and format total cost from the shoppingCart
export function getCartTotalCost(cart: CartObjectType) {
  let totalCost = 0;
  for (const cartItem of Object.keys(cart)) {
    const item = cart[cartItem];
    if (cartItem !== 'AS-SYS-ACS12-ACD') {
      const price = getCartPrice(item);
      totalCost = totalCost + price.unit_amount / 100.0 * item.quantity;
    }
  }
  return '$ ' + totalCost.toLocaleString(undefined, {minimumFractionDigits: 2});
}

// format the specific columns from the shoppingCart
export const getCartTableRows = (cart:UseCartObjectReturnType, props: OrdersCartPropsI) => {
  const rows = [];
  for (const cartItem of Object.keys(cart.data)) {
    if (props.debug) console.log('getCartTableRows', cartItem, cart.data[cartItem]);
    const item = cart.data[cartItem];
    const metadata = item.product.metadata;
    if (props.debug) console.log('cartItem', cartItem, item)
    const quantity = item.quantity
    if (props.debug) console.log('OrdersCart | getTableRows', cartItem, item.product);
    const row = {};
    row['delete'] = item.product.metadata.catalogNumber === 'AS-SCREEN-3RND' ? null :
      <MenuButton
        icon={<TrashIcon />}
        onClick={() => cart.delete(item.product.metadata.catalogNumber)}
        text={'Delete Item'}
        tooltipPlacement={'bottom-end'}
      />;
    row['info'] = <HoverInfoText text={item.product.description} variant={'cart'} />
    for (const field of ordersCartTableFields) {
      if (!['delete', 'info', 'price', 'quantity', 'totalCost'].includes(field.value)) {
        row[field.value] = _.get(item.product, field.value, '');
      } else if (field.value === 'price') {
        row[field.value] = getCartShowPrice(item);
      } else if (field.value === 'quantity') {
        if (props.noQuantityChanges || metadata.catalogNumber === 'AS-SCREEN-3RND') {
          row[field.value] = <PrimaryText>{ quantity } </PrimaryText>;
        } else {
          const subtract = quantity > Number(item.product.metadata.unitOrderMinSize) ?
            <StyledRemoveCircle sx={{fontSize: 18}} onClick={()=>cart.add(item.product, -1, item.priceType)}/> : <EmptyIcon />;
          const add = <StyledAddCircle sx={{fontSize: 18}} onClick={()=>cart.add(item.product, 1, item.priceType)}/>
          row[field.value] = <QuantityContainer>{subtract}<QuantityText>{quantity}</QuantityText>{add}</QuantityContainer>;
        }
      } else if (field.value === 'totalCost') {
        if (cartItem === 'AS-SYS-ACS12-ACD') {
          row[field.value] = <PrimaryText>Contact Us</PrimaryText>
        } else {
          const price = getCartPrice(item);
          const showValue = priceToShow(quantity * price.unit_amount);
          row[field.value] = <PrimaryText>{showValue}</PrimaryText>;
        }
      }
    }
    rows.push(row);
  }
  return rows;
}


const PrimaryText = styled.div`
  display: flex;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const QuantityContainer = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  color: ${(p) => p.theme.palette.textPrimary};
`;


const StyledRemoveCircle = styled(RemoveCircle)`
  display: flex;
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentPrimary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const StyledAddCircle = styled(AddCircle)`
  display: flex;
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentPrimary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const EmptyIcon = styled.div`
  width: 18px;
`;

const QuantityText = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;
`;
