import React, { useContext } from "react";
import { useParams } from 'react-router-dom';
import {UserContext} from "@stores/UserContext";
import PageContainer from "@as_core/pages/PageContainer";
import { getTitle, getComponent } from './user/utils';
import useShoppingCart from "@as_core/hooks/useShoppingCart";

const debug = false;
const PgOrders = () => {
  const { action } = useParams(); // this is the action that sets what to do
  const { user, setUser} = useContext(UserContext);
  const ordersCart = useShoppingCart({});

  if (user.subApp !== 'Orders') {
    setUser((prev) => ({
      ...prev, subApp: 'Orders'
    }));
  }

  if (debug) console.log("PgOrders | action:", action);

  return (
    <PageContainer title={getTitle(action)} padding={5}>
      { getComponent(action, ordersCart) }
    </PageContainer>
  );
};

export default PgOrders;
