import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { AccountTree, GroupAdd, Person } from '@mui/icons-material';

export const accountMenuItems: MenuItemsT[] = [
  {
    id: 'u.repositories',
    name: 'Manage My Repositories',
    pathname: '/account/repositories/owned',
    icon: <AccountTree />
  },
  {
    id: 'u.subscriptions',
    name: 'Manage My Team',
    pathname: '/account/subscriptions',
    icon: <GroupAdd />,
    subscriptions: ['pilot', 'academic+']
  },
  {
    id: 'u.user',
    name: 'Manage My Profile',
    pathname: '/account/user',
    icon: <Person />
  },
  {
    id: 'u.preferences',
    name: 'Manage My Preferences',
    pathname: '/account/preferences',
    icon: <SettingsIcon />
  },
];

const debug = false;
export const getAccountMenus = (subscription: string) => {
  if (debug) console.log('getMenuAccount | subscription', subscription);
  return accountMenuItems;
}


