import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const DetailsIcon = ({size = 24}: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M17.6,0.4H4.2c-2,0-3.7,1.7-3.7,3.7v15.8c0,2,1.7,3.7,3.7,3.7H20c2,0,3.7-1.7,3.7-3.7V6.7L17.6,0.4z
        M18.1,3l3.1,3.3h-0.9 c-1.2,0-2.2-1-2.2-2.2V3z M20,22.1H4.2c-1.2,0-2.2-1-2.2-2.2V4.1c0-1.2,1-2.2,
        2.2-2.2h12.3v2.2c0,2,1.7,3.7,3.7,3.7h1.9v12.1 C22.2,21.1,21.2,22.1,20,22.1z"/>
        <path fill={colorHighlight} d="M12.1,19c-2.5,0-4.6-2.2-4.6-5s2.1-5,4.6-5c2.5,0,4.6,2.2,4.6,5S14.7,19,12.1,19z M12.1,10c-2,0-3.6,
        1.8-3.6,4 c0,2.2,1.6,4,3.6,4s3.6-1.8,3.6-4C15.7,11.8,14.1,10,12.1,10z"/>
        <path fill={colorHighlight} d="M12.1,11.1c-0.3,0-0.5,0-0.5,0.2c0.3,0.3,0.4,0.6,0.4,1.1c0,0.9-0.5,1.5-1.4,1.5c-0.4,0-0.8-0.2-0.9-0.5
	      c-0.3,0.1-0.3,0.4-0.3,0.7c0,1.7,1.2,3,2.7,3s2.7-1.3,2.7-3S13.7,11.1,12.1,11.1z"/>
        <path fill={colorHighlight} d="M12.1,19.8c-5.3,0-9.4-5.1-9.6-5.3L2.2,14l0.4-0.5c0.2-0.2,4.3-5.3,9.6-5.3s9.4,5.1,9.6,5.3l0.4,0.5l-0.4,0.5
	      C21.5,14.7,17.4,19.8,12.1,19.8z M4.1,14c1.1,1.2,4.3,4.3,8,4.3s6.9-3.1,8-4.2c-1.1-1.2-4.3-4.2-8-4.2C8.4,9.8,5.2,12.9,4.1,14z"/>
      </svg>
    </>
  )
}

export default DetailsIcon;