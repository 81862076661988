import React, { useEffect, useState, useRef, useMemo } from 'react';
import BaseTable, { Column } from 'react-base-table';
import { Checkbox } from '@mui/material';
import 'react-base-table/styles.css';
import styled, { css } from 'styled-components/macro';
import { callOrReturn, normalizeColumns, noop } from '../../utils';
import { Tooltip } from '@mui/material';
import styles from '../../../components-style/SelectableTable.module.css';
import '../../../components-style/SelectableTable.scss';
import PushPinIcon from '@components/icons/pushPin.icon';
import { CompoundT } from '@src/type';

const debug = false;

interface HeaderRendererProps {
  cells: React.ReactNode[];
  columns: CustomColumn[];
  selectedRowKeys: string[];
  onSelectedRowsChange: (keys: string[]) => void;
  searchKeys: string[];
  hasSelectAll: boolean;
}

interface RowData {
  _id: string;
  [key: string]:
    | boolean
    | number
    | object
    | string
    | Array<string | number | boolean | object>;
}

// Extending Column to include the optional `frozen` property.
export interface CustomColumn extends Column {
  frozen?: 'left' | 'right';
}

const headerRenderer = ({
  cells,
  columns,
  selectedRowKeys,
  onSelectedRowsChange,
  searchKeys,
  hasSelectAll,
}: HeaderRendererProps) => {
  if (columns.every((x) => x?.frozen === 'left')) return cells;

  return columns.map((_column, index) => {
    if (index === 0 && hasSelectAll) {
      const [isChecked, setIsChecked] = useState<boolean>(false);

      const handleOnClick = () => {
        if (selectedRowKeys?.length < searchKeys?.length) {
          onSelectedRowsChange(searchKeys);
          setIsChecked(true);
        } else {
          onSelectedRowsChange([]);
          setIsChecked(false);
        }
      };

      return (
        <CheckboxContainer key={index}>
          <Checkbox
            indeterminate={
              selectedRowKeys?.length > 0 &&
              selectedRowKeys?.length < searchKeys?.length
            }
            checked={isChecked}
            onClick={handleOnClick}
          />
        </CheckboxContainer>
      );
    }

    const cell = cells[index];

    // Check if cell is a valid React element
    if (React.isValidElement(cell)) {
      const description =
        cell.props?.children?.[1]?.props?.column?.description || '';

      return (
        <Tooltip key={index} title={description} arrow placement='bottom'>
          {cell}
        </Tooltip>
      );
    }

    return null;
  });
};

interface SelectionCellProps {
  rowData: RowData;
  column: {
    selectedRowKeys: string[];
    rowKey: string;
    handleUnpin: (rowData: RowData) => void;
    onChange: (params: {
      selected: boolean;
      rowData: RowData;
      rowIndex: number;
    }) => void;
  };
  rowIndex: number;
}

const SelectionCell = (props: SelectionCellProps) => {
  const { rowData, column } = props;
  const { selectedRowKeys, rowKey, handleUnpin } = column;

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { rowData, column, rowIndex } = props;
    const { onChange } = column;

    onChange({ selected: e.target.checked, rowData, rowIndex });
  };

  const checked = selectedRowKeys?.includes(rowData[rowKey] as string);

  const onUnpin = () => {
    handleUnpin(rowData);
  };

  return (
    <div className={styles.select}>
      <Checkbox checked={checked} onChange={_handleChange} />
      <PushPinIcon id='pin-icon' className={styles.pin} onClick={onUnpin} />
    </div>
  );
};

interface SelectableTableProps {
  columns: CustomColumn[];
  children: React.ReactNode;
  selectable: boolean;
  selectedRowKeys: string[];
  setPinnedData: React.Dispatch<React.SetStateAction<CompoundT[]>>;
  onSelectedRowsChange: (keys: string[]) => void;
  hasSelectAll: boolean;
  data: CompoundT[];
  rowKey: string;
  frozenData: CompoundT[];
  onRowSelect: (params: {
    selected: boolean;
    rowData: RowData;
    rowIndex: number;
  }) => void;
  rowClassName?: (params: { rowData: RowData; rowIndex: number }) => string;
}

const SelectableTable = (props: SelectableTableProps) => {
  const {
    columns,
    children,
    selectable,
    selectedRowKeys,
    setPinnedData,
    onSelectedRowsChange,
    hasSelectAll,
    ...rest
  } = props;

  const tableRef = useRef(null);

  const [localData, setLocalData] = useState(props.data);
  const [_columns, set_Columns] = useState(
    columns || normalizeColumns(children)
  );

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  const _handleSelectChange = ({
    selected,
    rowData,
    rowIndex,
  }: {
    selected: boolean;
    rowData: RowData;
    rowIndex: number;
  }) => {
    const selectedRowKeys = [...props.selectedRowKeys];
    const key = rowData[props.rowKey] as string;

    if (debug) {
      console.log(
        '_handleSelectChange | rowData:',
        rowData,
        'rowIndex:',
        rowIndex,
        'selectedRowKeys',
        selected
      );
    }

    if (selected) {
      if (!selectedRowKeys.includes(key)) selectedRowKeys.push(key);
    } else {
      const index = selectedRowKeys.indexOf(key);
      if (index > -1) {
        selectedRowKeys.splice(index, 1);
      }
    }
    props.onRowSelect({ selected, rowData, rowIndex });
    props.onSelectedRowsChange(selectedRowKeys);
  };

  const handleUnpin = (rowData: CompoundT): void => {
    setPinnedData((previous: CompoundT[]) =>
      previous.filter((pinned) => pinned._id !== rowData._id)
    );
  };

  const _rowClassName = ({
    rowData,
    rowIndex,
  }: {
    rowData: RowData;
    rowIndex: number;
  }) => {
    const { rowKey, rowClassName, selectedRowKeys } = props;

    if (debug) {
      console.log(
        '_rowClassName | rowData:',
        rowData,
        'rowIndex:',
        rowIndex,
        'selectedRowKeys',
        selectedRowKeys
      );
    }

    const rowClass = rowClassName
      ? callOrReturn(rowClassName, { rowData, rowIndex })
      : '';
    const key = rowData[rowKey] as string;

    return [rowClass, selectedRowKeys.includes(key) && 'row-selected']
      .filter(Boolean)
      .concat(' ');
  };

  useEffect(() => {
    if (selectable) {
      const selectionColumn = {
        width: 80,
        flexShrink: 0,
        resizable: false,
        frozen: 'left',
        cellRenderer: SelectionCell,
        key: '__selection__',
        rowKey: props.rowKey,
        selectedRowKeys: selectedRowKeys,
        frozenData: props.frozenData,
        onChange: _handleSelectChange,
        handleUnpin: handleUnpin,
      };
      set_Columns([
        selectionColumn,
        ...(columns || normalizeColumns(children)),
      ]);
    }
  }, [selectable, props.rowKey, selectedRowKeys, columns, props.frozenData]);

  const headerFrozenHeight = useMemo(() => {
    return tableRef?.current?.table?.headerRef.props.height;
  }, [tableRef?.current?.table?.headerRef.props.height]);

  const searchKeys = localData.map(
    (compound: CompoundT) => compound?.searchKey
  );

  return (
    <StyledTable
      className='StyledTable'
      {...rest}
      data={localData}
      columns={_columns}
      rowClassName={_rowClassName}
      ref={tableRef}
      headerFrozenHeight={headerFrozenHeight}
      headerRenderer={({
        cells,
        columns,
      }: {
        cells: React.ReactNode[];
        columns: CustomColumn[];
      }) =>
        headerRenderer({
          cells,
          columns,
          selectedRowKeys,
          onSelectedRowsChange,
          searchKeys,
          hasSelectAll,
        })
      }
    />
  );
};

export default SelectableTable;

SelectableTable.defaultProps = {
  ...BaseTable.defaultProps,
  onRowSelect: noop,
  onSelectedRowsChange: noop,
};

const CheckboxContainer = styled.div`
  width: 65px;
  min-width: 65px;
  padding-left: 10px;
`;

const backgroundPrimary = css`
  --background-primary: ${(p) => p.theme.palette.backgroundPrimary};
  --background-secondary: ${(p) => p.theme.palette.backgroundSecondary};
  --background-tertiary: ${(p) => p.theme.palette.backgroundTertiary};
  --accent-primary: ${(p) => p.theme.palette.accentPrimary};
  --accent-secondary: ${(p) => p.theme.palette.accentPrimary};
  --text-primary: ${(p) => p.theme.palette.textPrimary};
`;

const StyledTable = styled(BaseTable)<{ headerFrozenHeight?: number }>`
  ${backgroundPrimary};
  --min-header-height: ${(p) => p.headerFrozenHeight}px;
`;
