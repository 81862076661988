import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}

const DoseResponseIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color} d="M21.2,20.5H3.6V2.8C3.6,2.3,3.2,2,2.8,2S2,2.3,2,2.8v18.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1C2,21.7,2.3,22,2.8,22h18.4
	c0.4,0,0.8-0.3,0.8-0.8C22,20.8,21.7,20.5,21.2,20.5z"/>
        <path fill={colorHighlight} d="M21.2,18.2C21.2,18.2,21.2,18.2,21.2,18.2L21.2,18.2C21.2,18.2,21.2,18.2,21.2,18.2c-3.7,0-4.7-0.3-5.6-1.1
	c-0.5-0.5-1.1-1-2.5-4.6c-0.1-0.3-0.2-0.6-0.4-1c-0.8-2.1-1.6-4.3-2.9-5.3C8.6,5.4,7,5.4,6,5.3c-0.2,0-0.3,0-0.4,0l0,0
	c0,0-0.1,0-0.1,0C5.1,5.3,4.8,5.6,4.8,6s0.3,0.7,0.7,0.7c0,0,0,0,0,0c0.1,0,0.3,0,0.5,0c0.9,0,2.2,0.1,3.1,0.7
	c1,0.7,1.8,2.8,2.4,4.6c0.1,0.3,0.2,0.7,0.4,1c1.5,3.8,2.2,4.5,2.9,5.1c1.2,1.1,2.7,1.4,6.4,1.4c0,0,0.1,0,0.1,0
	c0.4,0,0.7-0.3,0.7-0.7S21.6,18.2,21.2,18.2z"/>
      </svg>
    </>
  )
}

export default DoseResponseIcon;