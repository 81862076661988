import React, { useContext, useState } from 'react';
import useRepos, {RepositoryDetailsT} from '../useRepos';
import {UserContext, UserT} from '@stores/UserContext';
import {Container} from "../../shared/flexStyles";
import {FieldsTypeT, getObjectValue} from "@subApps/admin/components/ObjectDetails";
import BasicTable from "@as_core/tables/BasicTable";
import CheckBox from "@as_core/controls/inputs/CheckBox";
import {getObjectValueByKey} from "@utils/misc";
import MenuButton from "@components/controls/MenuButton";
import ViewIcon from "@as_core/icons/view.icon";
import EditIcon from "@components/icons/edit.icon";
import {getRepositoryAccessLabel} from "../useRepos";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";

// Disabled for the moment -- need to know if we need this component in the future.
// TODO -- Rework component to new version.
const columns: {owned: FieldsTypeT[], shared: FieldsTypeT[]} = {
  owned: [
    {value: 'active', label: 'Active', type: 'selected', id: {key: 'uuid', label: 'Repo'}, width: 50},
    {value: 'edit', label: 'Edit', type: 'action', id: {key: 'uuid', label: 'Repo'}, width: 50},
    {value: 'name', label: 'Repository Name', type: 'textTruncate', width: 260},
    {value: 'invitedUsers', label: '# Sharing', type: 'arrayCount', width: 100},
    {value: 'createdOn', label: 'Created', type: 'datetime', width: 150}
  ],
  shared: [
    {value: 'active', label: 'Active', type: 'selected', id: {key: 'uuid', label: 'Repo'}, width: 50},
    {value: 'view', label: 'View', type: 'action', id: {key: 'uuid', label: 'Repo'}, width: 50},
    {value: 'name', label: 'Repository Name', type: 'textTruncate', width: 250},
    {value: 'accessType', label: 'Access Type', type: 'accessType', width: 110},
    {value: 'ownerAuthEmail', label: 'Repository Owner', width: 210},
    {value: 'createdOn', label: 'Created', type: 'date', width: 100}
  ]
}

export function getTableRows(
  user: UserT,
  activeRepoId: string,
  selectedRepoId: string,
  repos: RepositoryDetailsT[],
  columns:FieldsTypeT[],
  handleClick: (action: string, id: string)=>void,
  setActiveRepo: (id: string) => void
) {
  const rows = [];
  repos.forEach((obj) => {
    let row = {};
    columns.forEach((c) => {
      if (c.type === 'accessType') {
        row[c.type] = getRepositoryAccessLabel(user, obj)
      } else if (c.type === 'textTruncate') {
        row[c.value] = <HoverTextTruncate text={getObjectValueByKey(obj, c.value)} showRear={false} characters={40}/>
      } else if (c.type === 'selected') {
        const id = getObjectValueByKey(obj, c.id.key)
        const active = obj.uuid===activeRepoId;
        row[c.value] = <CheckBox checked={active} onClick={() => setActiveRepo(id)}/>
      } else if (c.type === 'action') {
        const id = getObjectValueByKey(obj, c.id.key)
        if (c.value === 'view') {
          row[c.value] =
            <MenuButton
              onClick={() => handleClick(c.value, id)}
              icon={<ViewIcon />}
              text={`View ${c.id.label} Details`}
              isActive={id === selectedRepoId}
              tooltipPlacement={'left'}
            />;
        } else {
          row[c.value] =
            <MenuButton
              onClick={() => handleClick(c.value, id)}
              icon={<EditIcon />}
              text={`Select to Edit ${c.id.label} Details`}
              isActive={id === selectedRepoId}
              tooltipPlacement={'right'}
            />;
        }
      } else {
        row[c.value] = getObjectValue(obj, c, handleClick);
      }
    });
    rows.push(row);
  })
  return rows;
}

type PropsT = {
  type: string;
  activeRepoId: string;
  selectedRepoId: string;
  repos: RepositoryDetailsT[];
  onSelectChange?: (id: string)=>void;
  pushRefresh?: ()=>void;
}

const debug = false;
const RepoTableSelect = (props: PropsT) => {
  const { user, setUser } = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { activateRepository } = useRepos();
  if (debug) console.log('RepoTableSelect | props', props);

  const handleAction = (action: string, id:string) => {
    if (debug) console.log('handleView | action:', action, 'id:', id);
    if (props?.onSelectChange) {
      if (id === props.selectedRepoId) {
        props.onSelectChange(null)
      } else {
        props.onSelectChange(id)
      }
    }
  }

  const handleSetActiveOrgID = (repoId: string) => {
    if (debug) console.log('UPDATE | isUpdating', isUpdating, 'repoId', repoId, 'user repoId', user.appInfo.repositories.current.uuid);
    setIsUpdating(true);
    if (repoId !== user.appInfo.repositories.current.uuid) {
      activateRepository(repoId).then((updated_repos)=> {
        setIsUpdating(false);
        props.onSelectChange(null);
        setUser({...user, appInfo: {...user.appInfo, repositories: updated_repos}});
      });
    }
  };

  if (debug) console.log('MAIN | isUpdating', isUpdating, 'user repoId', user.appInfo.repositories.current.uuid);
  const rows = getTableRows(
    user,
    props.activeRepoId,
    props.selectedRepoId,
    props.repos,
    columns[props.type],
    handleAction,
    handleSetActiveOrgID
  )

  return (
    <>
      { props.repos.length ?
        <Container hasBorder={false}>
          <BasicTable
            key={'repo-table'}
            fields={columns[props.type]}
            rows={rows}
          />
        </Container>
        : null }
    </>
  );
};

export default RepoTableSelect;


