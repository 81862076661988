import React, { useState } from 'react';
import { Modal, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomSnackbar from '@components/dataAnalytics/CustomSnackbar';
import useCompounds from '@components/compounds/useCompounds';
import InputField from '@as_core/components/inputs/InputField';

interface Props {
  open: boolean;
  onClose: () => void;
  listData: { conditions: Condition[] }; // listData is now an object with a conditions array
  AndOR: string;
  id: string;
}

export interface Condition {
  selectedColumn: string;
  selectedDate: string;
  value: string;
  AndOR: string;
  title: string;
  inputValue: string;
  selectedLabel: string;
  selectedDatend: string;
  selectedDatestart: string;
  minValue: string;
}

const SaveFilter: React.FC<Props> = ({ open, onClose, id }) => {
  const { handleCreateNewFilter } = useCompounds();
  const [filterName, setFilterName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success'
  );

  const showSnackbar = (type: 'success' | 'error', message: string) => {
    setSnackbarType(type);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    const filterID = id; // Ensure 'id' is defined in your function's scope
    // const filterList = [
    //   {
    //     AndOR: AndOR,
    //     conditions: listData.conditions.map((condition) => ({
    //       selectedColumn: condition.selectedColumn,
    //       selectedDate: condition.selectedDate,
    //       value: condition.value,
    //       AndOR: condition.AndOR,
    //       title: condition.title,
    //       inputValue: condition.inputValue,
    //       minValue: condition.minValue,
    //       selectedLabel: condition.selectedLabel,
    //       selectedDatend: condition.selectedDatend,
    //       selectedDatestart: condition.selectedDatestart,
    //     })),
    //   },
    // ];

    let response = await handleCreateNewFilter();
    if (response.status === 200) {
      showSnackbar('success', 'Filter updated successfully');
    } else {
      showSnackbar('error', `Failed to update filter with ID ${filterID}`);
    }
    console.log(response.data);

    setFilterName('');
    onClose();
  };
  const handleNoClick = () => {
    onClose();
    setFilterName('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
  };

  return (
    <>
      <Modal open={open} onClose={handleNoClick}>
        <div
          style={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
            maxWidth: '400px',
            padding: '50px',
            textAlign: 'center',
            fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <Typography gutterBottom variant='h6' component='div'>
            Name your filter
          </Typography>
          <InputField
            id='filter-name-input'
            label='Filter Name'
            variant='outlined'
            value={filterName}
            onChange={handleInputChange}
          />
          <div style={{ marginTop: '16px' }}>
            <Button size='small' onClick={handleSaveClick}>
              Save
            </Button>
            <Button size='small' onClick={handleNoClick}>
              Cancel
            </Button>
          </div>
          <IconButton
            style={{ position: 'absolute', top: '8px', right: '8px' }}
            onClick={handleNoClick}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
      <CustomSnackbar
        open={snackbarOpen}
        type={snackbarType}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default SaveFilter;
