import ListIcon from '@components/icons/list.icon';
import TileIcon from '@components/icons/tile.icon';
import ZebrafishIcon from '@components/icons/zebrafish.icon';
import CellHealthIcon from '@components/icons/cellhealth.icon';
import PhysChemIcon from '@components/icons/physChem.icon';
import HeatMapIcon from '@components/icons/heatmap.icon';
import React, { ReactElement } from 'react';

export const CellHealthColumns = [
  'molSVGUUID',
  'compound_name',
  'casrn',
  'market_segment',
  'exact_mol_wt',
  'polar_surface_area',
  'zf_mort',
  'cell_morphology',
  'calcein',
  'mitosox',
  'mbbr',
  'sytox',
  'violet',
  'pi',
  'jc9',
  'cell_health_index',
];

export const ZebrafishColumns = [
  'molSVGUUID',
  'compound_name',
  'casrn',
  'exact_mol_wt',
  'cell_health_index',
  'zf_mo24',
  'zf_sm24',
  'zf_dp24',
  'zf_mort',
  'zf_cran',
  'zf_axis',
  'zf_edem',
  'zf_musc',
  'zf_ltrk',
  'zf_brn',
  'zf_skin',
  'zf_nc',
  'zf_tchr',
  'zf_epr',
  'zf_lpr_d',
  'zf_lpr_l',
];

export const PhysChemColumns = [
  'molSVGUUID',
  'compound_name',
  'casrn',
  'formula',
  'exact_mol_wt',
  'lipinski_hba',
  'lipinski_hbd',
  'rotatable_bonds',
  'heavy_atom_count',
  'c_sp3',
  'aromatic_rings',
  'polar_surface_area',
  'crippen_log_p',
  'heavy_atom_mol_wt',
  'fs_p3',
  'qed_weighted',
  'cell_health_index',
  'zf_mo24',
];

export type viewConfigT = {
  path: string;
  label: string;
  icon: ReactElement;
  columns: Array<string>;
  existsField?: string;
  restrict?: string[];
};

export const viewConfig: Array<viewConfigT> = [
  {
    path: 'tile',
    label: 'Tile View',
    icon: <TileIcon />,
    columns: [],
  },
  {
    path: 'table',
    label: 'Configurable Table View',
    icon: <ListIcon />,
    columns: [],
    restrict: ['basic'],
  },
  {
    label: 'Physical Chemistry Properties View',
    icon: <PhysChemIcon />,
    columns: PhysChemColumns,
    path: 'physChem',
  },
  {
    path: 'cellHealth',
    label: 'Cell Health View',
    icon: <CellHealthIcon />,
    columns: CellHealthColumns,
    existsField: 'cell_health_index',
  },
  {
    label: 'Zebrafish View',
    icon: <ZebrafishIcon />,
    columns: ZebrafishColumns,
    path: 'zebrafish',
    existsField: 'zf_mort',
  },
  {
    label: 'Zebrafish Heatmap View',
    icon: <HeatMapIcon />,
    columns: [],
    path: 'heatmap',
    existsField: 'zf_mort',
    restrict: ['basic'],
  },
];
