import React from "react";
import styled from "styled-components/macro";
import {truncateString} from "@utils/misc";
import PushPinIcon from "@components/icons/pushPin.icon";
import {AesSettingsT, GraphSettingsT, VertexT} from "../types";
import InsetSvg from "./InsetSvg";
import Vertex from "./Vertex";

interface PropsI {
  vertex: VertexT,
  vertexAesSettings: AesSettingsT,
  settings: GraphSettingsT,
  selected: boolean,
  inGraph?: boolean,
  isNeighbor?: boolean,
  radius: number,
  highlight?: boolean,
  labelColor?: string,
  secondaryColor?: string,
  secondaryFields?: {[key: string]: number},
  magnify?: number,
  onPinSelect?: ()=>void,
}

const LabeledVertex = (props:PropsI) => {

  const handlePinSelect = () => {
    if (props?.onPinSelect) {
      props.onPinSelect();
    }
  }

  return(
    <LabeledVertexContainer highlight={props?.highlight}>
      <LabelBanner>
        <ActionIcons />
        <VertexLabel color={props?.labelColor ? props.labelColor : 'accentPrimary'}>
          {truncateString(
            props?.vertex?.name || props.vertex?.id,
            25
          )}
        </VertexLabel>
        <ActionIcons>
          { props?.onPinSelect ?
            <Link onClick={handlePinSelect}>
              <PushPinIcon color={props?.labelColor} />
            </Link>
            : null}
        </ActionIcons>
      </LabelBanner>
      <InsetSvg magnify={props.magnify}>
        <Vertex {...props} />
      </InsetSvg>
  </LabeledVertexContainer>
  );
}

export default LabeledVertex;

const LabeledVertexContainer = styled.div<{highlight:boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 2px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  background: ${(p) => p.highlight ? p.theme.palette.backgroundQuaternary : p.theme.palette.backgroundSecondary};
`;

const LabelBanner = styled.div`
  display: flex;
  width: 100%;
  height: 25px;
  flex-direction: row;
  justify-content: space-between;
`;

const ActionIcons = styled.div`
  display: flex;
  width: max-content;
`;

const VertexLabel = styled.div<{color: string}>`
  display: flex;
  color: ${(p) => p.color !== 'accentPrimary' ? p.color : p.theme.palette.accentPrimary};
  font-size: 14px;
  font-weight: bold;
`;

const Link = styled.span`
  cursor: pointer;
  :hover {
    filter: brightness(1.5);
  }
`;