import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MagnifiedSvgImageStateT {
  visible: boolean;
  x: number;
  y: number;
  svg_url: string;
}

const initialState: MagnifiedSvgImageStateT = {
  visible: false,
  x: 150,
  y: 150,
  svg_url: 'bd',
};

const magnifiedSvgImageSlice = createSlice({
  name: 'magnifiedSvgImage',
  initialState,
  reducers: {
    setMagnifiedSvgImage: (
      state,
      { payload }: PayloadAction<MagnifiedSvgImageStateT>
    ) => {
      //console.log('setMagnifiedSvgImage');
      return payload;
    },
    hideMagnifiedSvgImage: (state) => {
      //console.log('hideMagnifiedSvgImage');
      state.visible = false;
    },
  },
});

export const { setMagnifiedSvgImage, hideMagnifiedSvgImage } =
  magnifiedSvgImageSlice.actions;

export default magnifiedSvgImageSlice.reducer;
