import React, { useEffect, useState } from 'react';

import {
  PlotBase,
  PlotConfig,
  PieBase,
  LineBase,
  BarBase,
  HistogramBase,
  BoxplotBase,
  VolcanoBase,
  DrcBase,
  ScatterBase,
} from '../single-plot-view/plot.types';
import { truncateString } from '@utils/misc';
import { Box } from '@mui/system';
import { PieWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/PieWrapper';
import { LineWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/LineWrapper';
import { BarWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/BarWrapper';
import { HistogramWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/HistogramWrapper';
import { BoxplotWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/BoxplotWrapper';
import { VolcanoplotWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/VolcanoplotWrapper';
import { DoseResponseCurveWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/DoseResponseCurveWrapper';
import { ScatterWrapper } from '@plotting/single-plot-view/plot-panel/vizWrappers/ScatterWrapper';
import { IconButton, Typography } from '@mui/material';
import usePlots from '@plotting/usePlots';
import useCognito from '@as_core/account/useCognito';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

type GraphTileProps = {
  plot: PlotConfig<PlotBase>;
  handleDeleteClick: (v: string) => void;
};

export const GraphTile = ({ plot, handleDeleteClick }: GraphTileProps) => {
  const { getPlot } = usePlots();
  const { getToken } = useCognito();
  const navigate = useNavigate();

  const [plotWithDetails, setPlotWithDetails] = useState(
    null as PlotConfig<PlotBase>
  );

  // the "plot" object received does not provided data nor config.
  // This must be retrieved using the "getPlot" function
  useEffect(() => {
    getPlot(getToken(), plot.uuid).then((base) => {
      setPlotWithDetails(base);
    });
  }, [plot]);

  // HANDLERS
  const _handleDeleteClick = () => {
    handleDeleteClick(plot.uuid);
  };

  const handleOpenEditPlot = () => {
    const loc = `/plots/${plot.uuid}`;
    navigate(loc, { state: { isDrawerEditorOpen: true } });
  };

  const handleOpenFullscreenPlot = () => {
    const loc = `/plots/${plot.uuid}`;
    navigate(loc, { state: { isDrawerEditorOpen: false } });
  };

  return (
    <div
      style={{
        height: 400,
        overflow: 'hidden',
        borderRadius: 1,
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        padding='2px'
        sx={{ borderBottom: 'solid', borderColor: 'grey', borderWidth: 0.1 }}
      >
        <Box flexGrow={1} paddingRight='10px' paddingLeft={'2px'}>
          <Typography
            variant='h5'
            component='h5'
            fontSize={13}
            overflow={'hidden'}
          >
            {truncateString(plot.name, 35)}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' gap={0}>
          <IconButton
            onClick={handleOpenFullscreenPlot}
            sx={{ padding: '1px' }}
          >
            <FullscreenIcon color='primary' fontSize='small' />
          </IconButton>
          <IconButton onClick={handleOpenEditPlot} sx={{ padding: '1px' }}>
            <EditIcon color='primary' fontSize='small' />
          </IconButton>
          <IconButton onClick={_handleDeleteClick} sx={{ padding: '1px' }}>
            <DeleteIcon color='primary' fontSize='small' />
          </IconButton>
        </Box>
      </Box>

      <div>
        <Box sx={{ height: 400 }}>
          {plotWithDetails?.config?.base === 'pie' && (
            <PieWrapper
              providedPlot={plotWithDetails as PlotConfig<PieBase>}
              isSmallChart={true}
            />
          )}

          {plotWithDetails?.config?.base === 'line' && (
            <LineWrapper
              providedPlot={plotWithDetails as PlotConfig<LineBase>}
              isSmallChart={true}
            />
          )}

          {plotWithDetails?.config?.base === 'bar' && (
            <BarWrapper
              providedPlot={plotWithDetails as PlotConfig<BarBase>}
              isSmallChart={true}
            />
          )}

          {plotWithDetails?.config?.base === 'histogram' && (
            <HistogramWrapper
              providedPlot={plotWithDetails as PlotConfig<HistogramBase>}
              isSmallChart={true}
            />
          )}

          {plotWithDetails?.config?.base === 'boxplot' && (
            <BoxplotWrapper
              providedPlot={plotWithDetails as PlotConfig<BoxplotBase>}
              isSmallChart={true}
            />
          )}

          {plotWithDetails?.config?.base === 'volcano' && (
            <VolcanoplotWrapper
              providedPlot={plotWithDetails as PlotConfig<VolcanoBase>}
              isSmallChart={true}
            />
          )}

          {plotWithDetails?.config?.base === 'drc' && (
            <DoseResponseCurveWrapper
              providedPlot={plotWithDetails as PlotConfig<DrcBase>}
              isSmallChart={true}
            />
          )}

          {(plotWithDetails?.config?.base === 'scatter' ||
            plotWithDetails?.config?.base === null) && (
            <ScatterWrapper
              providedPlot={plotWithDetails as PlotConfig<ScatterBase>}
              isSmallChart={true}
            />
          )}
        </Box>
      </div>
    </div>
  );
};
