import React, { useRef, useEffect, useState } from 'react';
import createREGL from 'regl';
import {ZoomTransform} from "d3";
import {standardEdgeColor, hoveredEdgeColor, selectedEdgeColor} from "../config/colors";
import { initCanvas } from '../utils/graphics';
import { createDrawLine } from '../utils/webgl';
import { EdgeT, GraphSettingsT, VertexT } from "../types";

interface PropsI {
  iteration: number,
  data: {
    vertices: VertexT[],
    edges: EdgeT[]
  },
  settings: GraphSettingsT,
  aesSettings?: { [key: string]: EdgeT[] },
  transform: ZoomTransform
}

const EdgeWebGLPane = (props: PropsI) => {
  const {
    iteration,
    data,
    settings,
    transform
  } = props;
  const [edgeWeights, setEdgeWeights] = useState([]);
  const { width, height } = settings.dimensions;

  const canvasRef = useRef<HTMLCanvasElement>();
  // eslint-disable-next-line
  const drawLine = useRef<any>();
  // eslint-disable-next-line
  const regl = useRef<any>();

  // utility function to make draws easier
  function drawLineCurrent(lines: EdgeT[], color: number[], count: number) {
    drawLine.current({
      edges: lines,
      edgeWeights,
      color: color,
      segments: count,
      transform,
    });
  }

  useEffect(() => {
    setEdgeWeights(data.edges.map(() => 0.0025));
  }, [data.edges]);

  useEffect(() => {
    initCanvas(canvasRef.current, width, height);
    regl.current = createREGL({
      canvas: canvasRef.current,
      pixelRatio: window.devicePixelRatio || 1,
      attributes: { antialias: true },
      extensions: ['ANGLE_instanced_arrays'],
    });

    drawLine.current = createDrawLine(regl.current, width, height);
  }, [settings.selectedVertex, regl]);

  // TODO -- neglected to do the splice to get correct edgeWeights -- but all are set same at moment
  // fix when we want to have edges with different widths
  useEffect(() => {
    const standardLines = data.edges.filter((v) => !settings.hoveredEdges.includes(v.id) &&
        !settings.selectedEdges.includes(v.id));
    const selectedLines = data.edges.filter((v) => settings.selectedEdges.includes(v.id));
    const hoveredLines = data.edges.filter((v) => settings.hoveredEdges.includes(v.id));
    if (
      regl.current &&
      data.edges.length &&
      typeof data.edges[0].source === 'object'
    ) {
      regl.current.clear({
        color: [0, 0, 0, 0.0],
        depth: 1,
      });

      drawLineCurrent(standardLines, standardEdgeColor, standardLines.length);
      drawLineCurrent(hoveredLines, hoveredEdgeColor, hoveredLines.length);
      drawLineCurrent(selectedLines, selectedEdgeColor, selectedLines.length);

    }
  }, [regl, iteration, data, settings.hoveredEdges, settings.selectedEdges, edgeWeights, transform]);

  return <canvas ref={canvasRef}></canvas>;
};

export default EdgeWebGLPane;
