import React, { useContext, useEffect, useState } from 'react';
import SideNav from './SideNav';
import { getUserSubscriptionType } from '@utils/useUserAppInfo';
import { UserContext, UserT } from '@stores/UserContext';
import { useLocation } from 'react-router-dom';
import { homeMenu, menuFunctions } from '@app_config/sideMenu';

const debug = false;

export type MenuItemsT = {
  id: string;
  name: string;
  pathname: string;
  icon: React.ReactNode;
  subscriptions?: string[];
}

export function getMenus(user: UserT): MenuItemsT[] {
  const { subApp } = user;
  const subscription = getUserSubscriptionType(user.appInfo);

  if (debug) console.log('SideMenu | getMenu: ', subApp, subscription);
  let menus = [homeMenu];
  if (Object.hasOwn(menuFunctions, subApp)) {
    menus = [ ...menus, ...menuFunctions[subApp](subscription)];
  }
  return menus;
}

export function getActiveMenuId(menus:MenuItemsT[], path: string): string {
  if (debug) console.log('SideMenu | getActiveMenuId: ', menus, path);
  const activeMenu = menus.find(menu => path.includes(menu.pathname));
  if (activeMenu) {
    return activeMenu.id;
  }
  return '';
}

const SideMenu = () => {
  const [activeMenus, setActiveMenus] = useState([]);
  const { user } = useContext(UserContext);
  const { subApp } = user;
  const subscription = getUserSubscriptionType(user.appInfo);
  const location = useLocation();
  const path = location.pathname.split('?')[0];
  const activeMenuId = getActiveMenuId(activeMenus, path);

  useEffect(() => {
    const menus = getMenus(user);
    setActiveMenus(menus);
  }, [subscription, subApp]);

  if (debug) console.log('SideNav | {activeMenus, path, activeMenuId}:', activeMenus, path, activeMenuId);
  return <SideNav menus={activeMenus} activeMenuId={activeMenuId} />;
};

export default SideMenu;
