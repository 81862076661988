import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { visuallyHidden } from '@mui/utils';
import Tooltip from '@mui/material/Tooltip';
import { Column } from './AggregateAndCalculate';

interface EnhancedTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  columns: Column[];
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: 'asc' | 'desc', orderBy): (a, b) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable(props: EnhancedTableProps) {
  const { data, columns } = props;

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>(columns[0].id);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (property: string) => {
    if (columns.some((headCell) => headCell.id === property)) {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [data, order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750, backgroundColor: 'white' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderRight: '1px solid #ddd',
                    fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: '1.4375em',
                    letterSpacing: '0.00938em',
                    color: 'rgba(0, 0, 0, 0.87)',
                    minWidth: '160px',
                  }}
                >
                  Index
                </TableCell>
                {columns.map((headCell, index) => (
                  <React.Fragment key={headCell.id}>
                    <TableCell
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={
                        orderBy === headCell.id ? order : undefined
                      }
                      style={{
                        borderRight:
                          index < columns.length - 1
                            ? '1px solid #ddd'
                            : 'none',
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleRequestSort(headCell.id)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily:
                              '"Roboto", "Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: '1rem',
                            lineHeight: '1.4375em',
                            letterSpacing: '0.00938em',
                            color: 'rgba(0, 0, 0, 0.87)',
                          }}
                        >
                          <div>{headCell.label}</div>
                          {orderBy === headCell.id ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </div>
                      </TableSortLabel>
                      <div
                        style={{
                          fontSize: '12px',
                          color: '#888',
                          paddingLeft: '3px',
                        }}
                      >
                        {typeof data[0][headCell.id]}
                      </div>
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {visibleRows.map((row, rowIndex) => (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.id} // Use the 'id' field as the key
                  sx={{
                    cursor: 'pointer',
                    borderBottom:
                      rowIndex < visibleRows.length - 1
                        ? '1px solid #ddd'
                        : 'none',
                  }}
                >
                  <TableCell
                    style={{
                      borderRight: '1px solid #ddd',
                      fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      fontSize: '1rem',
                      lineHeight: '1.4375em',
                      letterSpacing: '0.00938em',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    {page * rowsPerPage + rowIndex + 1}
                  </TableCell>
                  {columns.map((headCell, index) => (
                    <TableCell
                      key={`${headCell.id}-${row['your_unique_key']}`} // Use a combination of column ID and a unique identifier
                      style={{
                        borderRight:
                          index < columns.length - 1
                            ? '1px solid #ddd'
                            : 'none',
                        fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
                        fontWeight: 400,
                        fontSize: '1rem',
                        lineHeight: '1.4375em',
                        letterSpacing: '0.00938em',
                        color: 'rgba(0, 0, 0, 0.87)',
                        minWidth: '130px',
                      }}
                    >
                      {columns.length > 7 &&
                      typeof row[headCell.id] === 'string' &&
                      String(row[headCell.id]).length > 20 ? (
                        <Tooltip title={row[headCell.id]} enterTouchDelay={0}>
                          <div className='ellipsis-text'>
                            {String(row[headCell.id]).substring(0, 20)}...
                          </div>
                        </Tooltip>
                      ) : (
                        <div className='ellipsis-text'>{row[headCell.id]}</div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={columns.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
