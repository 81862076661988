import {
  forceSimulation,
  forceLink,
  forceManyBody,
  forceCenter,
  forceX,
  forceY,
  forceCollide,
} from 'd3';

// TODO -- update this to .ts and add stronger typing

export const createSimulation = () => forceSimulation();

export const updateSimulation = (
  simulation,
  settings,
  edgeForceFactor,
  vertices = null,
  edges = null
) => {
  const { height, width} = settings.dimensions;
  simulation
    .force('charge', forceManyBody().strength(-10 * edgeForceFactor)) // DHR 2023-03-31 original -10
    .force('center', forceCenter(width / 2, height / 2)) // DHR 2023-11-27 original /2 /2
    .force('collision', forceCollide().radius(1)) // DHR Original v.r*1.7
    .force("forceX", forceX().strength(0.01 )) // DHR Gravity to pull in tendrils
    .force("forceY", forceY().strength(0.01 ) // DHR Gravity
    );

  // collision detection
  // simulation.force('collision', forceCollide().radius(1)); // DHR 2023-03-31 original 7

  // vertices
  if (vertices) {
    simulation.nodes(vertices);
  }

  // edges
  if (edges) {
    simulation.force(
      'link',
      forceLink(edges)
        .id((e) => e?.id)
        .strength((e) => edges[e?.id].forceWeight || 2)  // DHR 2023-03-31 original *1.0
    );
  }

  return simulation;
};
