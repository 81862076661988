import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { StyleContext } from '@theme/AppStyles';

import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import useObjectArray from '@as_core/hooks/useObjectArray';
import {
  DataSetT,
  DimensionsT,
  DistributionsT,
  GraphSettingsT,
  SelectorT,
  SettingsT,
  VertexT,
  EdgeT
} from './types';
import {
  getAesValues,
  getGraphSettings,
  getRingFieldsFromSettings
} from './utils/compoundUniverse';
import { getDensities } from './utils/properties';
import Graph from './Graph';
import UniverseBanner from '@components/universe/controls/UniverseBanner';

export type UpdateSettingsT = string | number | string[] | number[] | VertexT | VertexT[] | EdgeT | EdgeT[] | DimensionsT | DistributionsT;

const getInitialWeightFactor = (settings:GraphSettingsT): number => {
  if (settings?.edge_weight_factor && Object.hasOwn(settings.edge_weight_factor, settings.edgeType)) {
    return settings.edge_weight_factor[settings.edgeType];
  }
  return 1.0;
}

export interface PropsI {
  universeSelector?: SelectorT;
  data: DataSetT;
  settings: SettingsT;
  images?: {[key: string]: string};
}

const CompoundUniverse = (props:PropsI) => {
  const {universeSelector, data, settings,  images = {}} = props;

  const [style] = useContext(StyleContext);
  const { height, width } = useWindowDimensions();
  const [ready, setReady] = useState(false);
  const [aesSettings, setAesSettings] = useState(null);
  const [graphSettings, setGraphSettings] = useState<GraphSettingsT>(
    getGraphSettings(settings, height, width)
  );
  const selectedCompounds = useObjectArray([]);
  const pinnedCompounds = useObjectArray([]);

  const [edgeWeightFactor, setEdgeWeightFactor] = useState<number>(getInitialWeightFactor(graphSettings));
  const [continueSimulation, setContinueSimulation] = useState<boolean>(false);
  // destructure for useEffect dependencies
  const { vertices: dataVertices, edges: dataEdges } = data;
  const { response_type, primary } = settings;
  const { edgeType } = graphSettings;

  const handleContinueSimulation = () => {
    setContinueSimulation((prev) => !prev);
  };

  // when the search string is updated -- update the matching nodes
  const handleSearchResponse = (search: string): {id: string, label: string}[] => {
    if (search.length > 2) {
      return data.vertices
        .filter((v) => v?.name.toLowerCase().includes(search.toLowerCase()))
        .map((v) => ({ id: v?.id, label: v?.name }));
    }
    return [];
  };

  // handle setting the selectedVertex from a search for names
  const handleSearchItemSelect = (newSelectedVertex: string) => {
    if (newSelectedVertex) {
      const fullVertexInfo = data.vertices.find((v) => v.id === newSelectedVertex);
      handleUpdateSettings('selectedVertex', fullVertexInfo);
      if (pinnedCompounds.data.includes(newSelectedVertex)) {
        pinnedCompounds.delete(newSelectedVertex);
      }
    }
  };

  // general function to update the various graphSettings
  const handleUpdateSettings = (field: string, value: UpdateSettingsT) => {
    setGraphSettings((prev) => ({ ...prev, [field]: value }));
  };

  // handle any changes that may affect the graph rendering
  useEffect(() => {
    pinnedCompounds.reset();
    selectedCompounds.reset();
    setGraphSettings(getGraphSettings(settings, height, width));
    setAesSettings(
      getAesValues(
        dataVertices,
        dataEdges,
        images,
        response_type,
        primary,
        edgeWeightFactor,
        style
      )
    );
    setReady(true);
  }, [dataVertices, dataEdges]);

  // handle any changes that may affect the graph rendering
  useEffect(() => {
    setEdgeWeightFactor(getInitialWeightFactor(graphSettings))
  }, [edgeType]);

  // handle any changes that may affect the graph rendering
  useEffect(() => {
    setAesSettings(
      getAesValues(
        dataVertices,
        dataEdges,
        images,
        response_type,
        primary,
        edgeWeightFactor,
        style
      )
    );
  }, [dataVertices, dataEdges, images, response_type, primary, edgeType, style]);

  useEffect(() => {
    handleUpdateSettings('dimensions', { height: height, width: width });
  }, [height, width]);

  useEffect(() => {
    handleUpdateSettings(
      'coronaFields',
      getRingFieldsFromSettings(graphSettings)
    );
  }, [graphSettings.primary, graphSettings.secondary]);

  useEffect(() => {
    if (data?.vertices) {
      handleUpdateSettings('densities', getDensities(data.vertices));
    }
  }, [data.vertices]);

  return (
    <Container>
      <UniverseBanner
        universeSelector={universeSelector}
        datasets={data?.datasets}
        settings={graphSettings}
        updateSettings={handleUpdateSettings}
        edgeForceWeight={edgeWeightFactor}
        setEdgeForceWeight={setEdgeWeightFactor}
        setContinueSimulation={handleContinueSimulation}
        getSearchResponse={handleSearchResponse}
        onSearchItemSelect={handleSearchItemSelect}
        selectedCompounds={selectedCompounds}
      />
      <GraphWrapper className='graph-wrapper'>
        {ready && data && data.vertices.length && (
          <Graph
            input={data}
            settings={graphSettings}
            updateSettings={handleUpdateSettings}
            aesSettings={aesSettings}
            edgeForceFactor={edgeWeightFactor}
            continueSimulation={continueSimulation}
            selectedCompounds={selectedCompounds}
            pinnedCompounds={pinnedCompounds}
          />
        )}
      </GraphWrapper>
    </Container>
  );
};

export default CompoundUniverse;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: ${(p) => p.theme.fonts.main};
`;

const GraphWrapper = styled.div`
  position: relative;
  width: calc(100vw - 50px);
  height: 100%;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  overflow: hidden;
`;
